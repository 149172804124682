import React, { useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Link, useHistory, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getWishcount } from "../redux/slices/settingSlice";
import { Col, Container, Form, FormGroup, Input, Row, Label } from "reactstrap";
import "../styles/authentications.css";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { userAction } from "../redux/slices/userSlice";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Select from "react-select";
import SignUpImage from "../assets/images/login-img/signin_couple.png";
import { countryCodes } from "../components/CountryCodes";
import FacebookLogin from "@greatsumini/react-facebook-login";

const Signup = () => {
  const [smData, setSmData] = useState([]);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const [responseData, setResponseData] = useState({});
  const [passwordConfirmation, setpasswordConfirmation] = useState("");
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  // const [showMobileForm, setShowMobileForm] = useState(false);
  const [otp, setOTP] = useState("");
  const [receivedOtp, setReceivedOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));
  const handleSuccess = (response) => {
    let token = response.credential;
    try {
      if (token) {
        const [headerBase64, payloadBase64, signature] = token.split(".");
        const decodedPayload = JSON.parse(window.atob(payloadBase64));
        smRegister(decodedPayload);
        console.log(decodedPayload, "user details");
      } else {
        console.error("Token is null or undefined");
      }
    } catch (error) {
      console.error("Error decoding JWT:", error.message);
    }
  };
  const smRegister = async (decodedPayload) => {
    const originalUrl = decodedPayload.picture;
    try {
      const response = await fetch(originalUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Url = reader.result;
        axiosClient
          .post("auth/register", {
            Email: decodedPayload.email,
            ssologin: true,
            RoleID: 2,
            UserName: decodedPayload.email,
            Photo: base64Url,
          })
          .then((response) => {
            console.log(response, "response");
            console.log(base64Url, "base64Url");
            if (response.data.statusCode === 200) {
              setSmData(response.data);
              setStep(2);
            } else if (response.data.statusCode === 404) {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error("Error during login:", error);
            toast.error("User already exists, please login", {
              theme: "colored",
            });
          });
      };
    } catch (error) {
      console.error("Error fetching image:", error);
      toast.error("Failed to fetch image", {
        theme: "colored",
      });
    }
  };
  const handleError = () => {
    // Handle login errors here
    console.log("Google login failed");
  };
  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    submitregForm();
  };
  const handleOtpSent = async (e) => {
    e.preventDefault();
    if (mobile && selectedCountry && mobile.length === 10) {
      console.log(mobile, selectedCountry, email);
      try {
        const userData = {
          email: email,
          Phone: mobile,
          country_code: selectedCountry,
        };
        await axiosClient
          .post("otp/SendOtp", userData)
          .then(function (response) {
            if (response.data.statusCode == 200) {
              setReceivedOtp(response.data.data.otp);
              toast.success("OTP Sent");
              setStep(3);
            } else {
              toast.error(response.msg, { theme: "colored" });
            }
          });
      } catch (error) {
        console.error("Error during OTP sending:", error);
        toast.error("Error during OTP sending");
      }
    } else if (mobile.length !== 0) {
      toast.error("Enter valid mobile number");
    } else {
      toast.error("Select the country code");
    }

    // setStep(3);
  };
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (e.target.value.length == 4) {
      const socialMedia = smData.token && smData.user && smData.user.Role;
      if (e.target.value == receivedOtp) {
        if (socialMedia) {
          console.log(smData);
          localStorage.setItem("ACCESS_TOKEN", smData.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("USER_DATA", JSON.stringify(smData.user));
          localStorage.setItem("USER_ROLE", smData?.user.Role);
          dispatch(getWishcount());
          if (smData.Role == "admin") {
            navigate("/admin", { replace: true });
            window.location.reload();
          } else {
            navigate("/dashboardpage/settings", { replace: true });
            window.location.reload();
          }
        } else if (responseData && responseData.user) {
          localStorage.setItem("ACCESS_TOKEN", responseData.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("USER_DATA", JSON.stringify(responseData.user));
          localStorage.setItem("USER_ROLE", responseData.user.Role);
          dispatch(getWishcount());
          if (responseData.user.Role == "admin") {
            navigate("/admin", { replace: true });
            window.location.reload();
          } else {
            navigate("/dashboardpage/settings", { replace: true });
            window.location.reload();
          }
        } else {
          console.log("No valid user data found");
        }
        toast.success("Login Successfully");
      } else {
        console.log("OTP not matched");
      }
    }
  };
  const handleFBSuccess = (response) => {
    console.log("FB login successful", response);
  };
  const handleFbFail = (error) => {
    console.log("Login Failed!", error);
    toast.error(error);
  };
  const handleFBProfileSuccess = (response) => {
    console.log("profile", response);
    smRegister(response);
  };
  const submitregForm = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Password validation regex
    // Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // Validate email
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address");
      return;
    }
    // Validate password
    if (!passwordRegex.test(password)) {
      // Check each password criterion separately
      if (password.length < 6) {
        toast.error("Password must have a minimum of 6 characters");
        return;
      } else if (!/(?=.*\d)/.test(password)) {
        toast.error("Password must contain at least one number");
        return;
      } else if (!/(?=.*[A-Z])/.test(password)) {
        toast.error("Password must contain at least one uppercase character");
        return;
      } else if (!/(?=.*[@$!%*?&])/.test(password)) {
        toast.error("Password must contain at least one special character");
        return;
      }
    }
    try {
      const response = await axiosClient.post("auth/register", {
        UserName: userName,
        Email: email,
        CountryCode: selectedCountry,
        Phone: mobile,
        Password: password,
        RoleID: 2,
        isVerified: 0,
      });
      console.log(response.data, "response.data");
      if (response.data.statusCode === 200) {
        setResponseData(response.data);
        setStep(2);
      } else {
        toast.error(response.msg, { theme: "colored" });
      }
    } catch (error) {
      console.error("Error during SignUP:", error);
      if (error.response) {
        // Server responded with a status other than 2xx
        const errorMessage =
          error.response.data?.message || "Error during SignUP";
        toast.error(errorMessage);
      } else if (error.request) {
        // The request was made, but no response was received
        toast.error("No response from the server");
      } else {
        // Something else went wrong in setting up the request
        toast.error("Error during SignUP");
      }
    }
  };
  console.log(email, password, userName);
  return (
    <Helmet title="Signup">
      <section style={{ overflowX: "hidden" }}>
        <div className="container">
          <div className="signin-wrapper m-lg-4 my-3">
            <Row className="login-feture pt-4 pt-lg-0">
              <Col sm={6}>
                <img className="signin-image" src={SignUpImage} alt="rr" />
              </Col>
              <Col sm={6} className="pb-3">
                <div className="">
                  {step === 1 && (
                    <Form
                      className="auth__form"
                      onSubmit={(e) => handleSignUpSubmit(e)}
                    >
                      <h3 className="fw-bold fs-4 mb-4 text-center pt-4">
                        {" "}
                        Sign Up{" "}
                      </h3>
                      <div className="row w-100 m-auto">
                        <div className="col-lg-6">
                          <Row className="mt-4 m-lg-0">
                            <Col
                              className="mt-2 fb-lg-container pb-2"
                              style={{ width: "100%" }}
                            >
                              <div className="fb-styles fb-styles-signin">
                                <FaFacebook />
                                <FacebookLogin
                                  appId={
                                    process.env.REACT_APP_SSO_FACEBOOK_CLIENT_ID
                                  }
                                  onSuccess={handleFBSuccess}
                                  onFail={handleFbFail}
                                  onProfileSuccess={handleFBProfileSuccess}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-lg-6">
                          <Row className="pb-3">
                            <Col
                              style={{
                                backgroundColor: "#fff",
                                color: "#000",
                                padding: "10px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "5px",
                                  // border: "1px solid lightgray",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <GoogleOAuthProvider
                                  clientId={
                                    process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID
                                  }
                                >
                                  <div>
                                    {/* Google Login button */}
                                    <GoogleLogin
                                      onSuccess={handleSuccess}
                                      onFailure={handleError}
                                      redirectUri={
                                        process.env
                                          .REACT_APP_SSO_GOOGLE_REDIRECT_URI
                                      }
                                      responseType="code"
                                      flow="auth-code"
                                      isSignedIn={true}
                                    >
                                      Login with Google
                                    </GoogleLogin>
                                  </div>
                                </GoogleOAuthProvider>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <Row className="w-100">
                        <hr style={{ width: "100%", marginRight: "10px" }} />
                        <span className="wd-signup">or SigUp</span>
                      </Row>
                      <div className="px-lg-5 mx-lg-3 py-lg-3">
                        <FormGroup className="form__group">
                          {/* <Label>User Name</Label> */}
                          <Input
                            type="text"
                            required
                            placeholder="Username"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="form__group">
                          {/* <Label>Email address</Label> */}
                          <Input
                            type="email"
                            placeholder="Enter Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="form__group">
                          {/* <Label>password</Label> */}
                          <Input
                            type="password"
                            placeholder="Enter Your password"
                            value={password}
                            minLength={6}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>

                        {/* <FormGroup className="form__group">
                    <Input
                      type="password"
                      placeholder="Enter Your password"
                      value={passwordConfirmation}
                      minLength={6}
                      onChange={(e) => setpasswordConfirmation(e.target.value)}
                    />
                  </FormGroup> */}

                        <FormGroup className="form__group text-center">
                          <button type="submit" class="auth__btn">
                            {" "}
                            Create an Account
                          </button>
                        </FormGroup>

                        <p className="text-center">
                          Alreaady have an account ? {""}
                          <Link className="text-primary fw-bold" to="/login">
                            {" "}
                            Login{" "}
                          </Link>
                        </p>
                      </div>
                    </Form>
                  )}
                  {step === 2 && (
                    <form className="auth__form p-5">
                      <FormGroup className="form__group ">
                        <Label for="mobile" className="mb-0">
                          Mobile Number
                        </Label>
                        <div className="d-flex align-items-center signup-ctr-select">
                          <Select
                            options={countryOptions}
                            placeholder="Select"
                            value={
                              selectedCountry
                                ? {
                                    label: selectedCountry,
                                    value: selectedCountry,
                                  }
                                : null
                            }
                            onChange={(selectedOption) =>
                              setSelectedCountry(selectedOption.value)
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: "100px",
                              }),
                              option: (styles) => ({
                                ...styles,
                                color: "black",
                                fontSize: "x-small",
                                width: "150px",
                              }),
                              menu: (styles) => ({
                                ...styles,
                                width: "150px", // Adjust the width of the menu as needed
                              }),
                            }}
                          />
                          <Input
                            className="form-control"
                            required
                            type="number"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter Your Mobile Number"
                            value={mobile}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setMobile(e.target.value);
                              }
                            }}
                            maxLength={10}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className="form__group mt-2">
                        <button onClick={handleOtpSent} className="auth__btn">
                          Send OTP
                        </button>
                      </FormGroup>
                    </form>
                  )}
                  {step === 3 && (
                    <form className="auth__form p-5">
                      <FormGroup className="form__group">
                        <Label>OTP</Label>
                        <Input
                          className="form-control"
                          required
                          type="number"
                          name="UserOTP"
                          placeholder="Enter OTP"
                          // value={otp}
                          // onChange={(e) => setOTP(e.target.value)}
                          onChange={handleVerifyOtp}
                        />
                      </FormGroup>

                      {/* <FormGroup className="form__group">
                    <button onClick={handleVerifyOtp} className="auth__btn">
                      Continue
                    </button>
                  </FormGroup> */}
                      <Link onClick={handleOtpSent}>Resend Otp</Link>
                    </form>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </Helmet>
  );
};

export default Signup;
