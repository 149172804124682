import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";

const VendorMaps = ({ location }) => {
  const [markLocation, setMarkLocation] = useState(null);

  console.log(location, "in maps");
  console.log(markLocation, "in maps - markLocation");

  // Load Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAICmvI7hoAZZg8Y9HpohqW2uz4Ojqv3Io",
  });

  useEffect(() => {
    if (!location) return;

    setMarkLocation(null); // Reset location before new API call

    const fetchCoordinates = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            location
          )}&key=AIzaSyDROtwqL701TVqixE8NPJAqxu6h5HIDWVE&timestamp=${Date.now()}`
        );

        if (response.data.results && response.data.results.length > 0) {
          const { lat, lng } = response.data.results[0].geometry.location;
          console.log(`New coordinates: lat: ${lat}, lng: ${lng}`);
          setMarkLocation({ lat, lng });
        } else {
          console.error(`No geocoding results for location: ${location}`);
        }
      } catch (error) {
        console.error(
          "Error fetching coordinates for location:",
          location,
          error
        );
      }
    };

    fetchCoordinates();
  }, [location]);

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  // Default to SF if geocoding fails
  const center = markLocation || { lat: 37.7749, lng: -122.4194 };

  return (
    <GoogleMap
      key={location} // Forces re-render when location changes
      mapContainerStyle={{ width: "100%", height: "280px" }}
      center={center}
      zoom={14}
    >
      {markLocation && (
        <Marker
          position={{ lat: markLocation.lat, lng: markLocation.lng }}
          onClick={() => console.log("Marker clicked")}
          animation={window.google.maps.Animation.DROP}
          optimized={false}
          zIndex={1}
          opacity={0.8}
          clickable
        />
      )}
    </GoogleMap>
  );
};

export default VendorMaps;
