import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { setFinalUsers } from "../../redux/slices/PeopleInComSlice";

const UsersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { finalUsers, userMaps } = useSelector((state) => state.peopleInCom);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("Recently-Active");
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleSortBy = (option) => {
    setSortBy(option);
    let sortedUsers = [...finalUsers];
    switch (option) {
      case "Recently-Active":
        sortedUsers.sort(
          (a, b) => new Date(b.lastOnline) - new Date(a.lastOnline)
        );
        break;
      case "Recently-Joined":
        sortedUsers.sort(
          (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
        );
        break;
      case "Oldest":
        sortedUsers.sort(
          (a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt)
        );
        break;
      case "Alphabetical":
        sortedUsers.sort((a, b) => {
          const fullNameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
          const fullNameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        });
        break;
      default:
        break;
    }
    dispatch(setFinalUsers(sortedUsers));
  };
  return (
    <div className="users-list-box">
      <Row>
        <div className="d-flex justify-content-between py-3">
          <h4>User In My Network ({finalUsers && finalUsers.length})</h4>
          <div>
            <div className="d-none d-md-block d-lg-block">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                  className="wd_filter-btn"
                >
                  {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
                  <FaCaretDown />
                </DropdownToggle>
                <DropdownMenu style={{ color: "#fff" }}>
                  <DropdownItem onClick={() => handleSortBy("Recently-Active")}>
                    Sort By: Recently Active
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSortBy("Recently-Joined")}>
                    Sort By: Recently Joined
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSortBy("Oldest")}>
                    Sort By: Oldest
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSortBy("Alphabetical")}>
                    Sort By: Alphabetical
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        {finalUsers.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "40vh" }}
          >
            <h5>No Users Found</h5>
          </div>
        )}
        {finalUsers.map((user, index) => (
          <Col
            xs="12"
            sm="6"
            md={userMaps ? "12" : "4"}
            lg={userMaps ? "6" : "4"}
            key={index}
            className="mb-3"
          >
            <span className="p-0"> {user.UserName.split("@")[0]} </span>
            <Card
              className="people-user-card"
              style={{
                borderRadius: "10px",
                overflow: "hidden",
                cursor: "pointer",
                transition: "transform 0.3s",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                navigate(`/people-profile/${user.Id}`);
              }}
            >
              <CardBody className="p-1">
                <div className="d-flex  align-items-center pc-user-cardbody">
                  <CardImg
                    className="rounded-circle"
                    src={
                      user.Photo
                        ? user.Photo
                        : "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                    }
                    alt="User Profile"
                  />
                  <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                    {user.FirstName && user.LastName
                      ? `${user.FirstName} ${user.LastName}`
                      : user.FirstName ||
                        user.LastName ||
                        user.UserName.split("@")[0]}
                  </CardTitle>
                </div>
                <CardText
                  style={{ fontSize: "12px", color: "#555", padding: 0 }}
                >
                  {user?.BioData
                    ? user.BioData.length > 90
                      ? `${user.BioData.substring(0, 90)}...`
                      : user.BioData
                    : ""}
                </CardText>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    fontSize: "10px",
                    color: "#555",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <span style={{ flex: 1, textAlign: "center" }}>
                    <strong>Job Role:</strong> {user?.JobRole || "N/A"}
                  </span>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    <strong>DOB:</strong> {user?.DateOfBirth || "N/A"}
                  </span>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    <strong>Location:</strong> {user?.ZipCode},{" "}
                    {user?.Location || "N/A"}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default UsersList;
