import React, { useEffect, useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { applyCoupon } from "../../../components/Header/Data2";
import Payment from "../settingsComponents/Payment";
import { toast } from "react-toastify";
import { getAmount, ItemShipping } from "../../GlobalFunctions/Global";
import userIcon from "../../../assets/images/ext/user-profile.jpg";
const PaymentDetails = ({
  pay,
  state,
  totalPrice,
  order,
  finalAmount,
  setFinalAmount,
  setPaymentResponse,
  handleRequest,
  isLoading,
  user,
  hire,
}) => {
  const [finalPayment, setFinalPayment] = useState(totalPrice);
  const [coupon, setCoupon] = useState("");
  const [totalHours, setTotalHours] = useState(0); // State to hold total hours
  let itemOrder = state?.itemOrder;
  const calculateTotalHours = () => {
    if (state && state.form?.startTime && state.form?.endTime) {
      const startTimeString = state.form.startTime;
      const endTimeString = state.form.endTime;

      // Parse 12-hour format with AM/PM suffixes to 24-hour format
      const startTimeParts = startTimeString.split(" ");
      const endTimeParts = endTimeString.split(" ");

      const startTimeHours = parseInt(startTimeParts[0].split(":")[0]);
      const endTimeHours = parseInt(endTimeParts[0].split(":")[0]);

      const startTimeMinutes = parseInt(startTimeParts[0].split(":")[1]);
      const endTimeMinutes = parseInt(endTimeParts[0].split(":")[1]);

      const startTimeSuffix = startTimeParts[1];
      const endTimeSuffix = endTimeParts[1];

      let startTimeHours24 = startTimeHours;
      let endTimeHours24 = endTimeHours;

      if (startTimeSuffix === "PM" && startTimeHours !== 12) {
        startTimeHours24 += 12;
      } else if (startTimeSuffix === "AM" && startTimeHours === 12) {
        startTimeHours24 = 0;
      }

      if (endTimeSuffix === "PM" && endTimeHours !== 12) {
        endTimeHours24 += 12;
      } else if (endTimeSuffix === "AM" && endTimeHours === 12) {
        endTimeHours24 = 0;
      }

      const startTime = new Date();
      startTime.setHours(startTimeHours24);
      startTime.setMinutes(startTimeMinutes);

      const endTime = new Date();
      endTime.setHours(endTimeHours24);
      endTime.setMinutes(endTimeMinutes);

      const timeDiff = endTime.getTime() - startTime.getTime();
      const hours = timeDiff / (1000 * 3600); // Convert milliseconds to hours
      const hoursRounded = Number(hours.toFixed(2));

      setTotalHours(hoursRounded);
    } else {
      setTotalHours(0);
    }
  };

  const handleCouponApply = async () => {
    const formData = {
      Code: coupon,
      serviceList: state?.selectedCard.Id,
      Amount: totalPrice,
    };
    if (!coupon) {
      toast.info("please Enter Coupon Code");
      return;
    }
    let bills = await applyCoupon(formData);
    if (bills && bills.errorCode) {
      toast.error(bills.message);
    } else {
      bills = bills.Data[0];
      if (bills.discount > totalPrice) {
        toast.error("Total Amount should be greater then Coupon amount");
        return;
      }
      toast.success("Coupon Applied");
      setFinalAmount(bills);
      setFinalPayment(bills?.payamount);
    }
  };
  useEffect(() => {
    setFinalPayment(totalPrice);
    calculateTotalHours();
  }, [totalPrice, state.item]);
  React.useEffect(() => {
    if (itemOrder) {
      setFinalAmount(getAmount(itemOrder.price, itemOrder.delivery_option));
    }
  }, [itemOrder, state.item]);
  console.log(finalAmount, "finalAmount");
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  console.log(state.selectedCard, "state.selectedCard");
  console.log(finalPayment, order, state, user, "finalPayment");
  return (
    <>
      <table class="table table-borderless" style={{ background: "none" }}>
        <tbody>
          {state && state.selectedDate && state.form && (
            <tr className="">
              <td style={{ width: "60%" }}>
                {state?.selectedDate.toLocaleString("en-US", {
                  weekday: "short",
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td className="text-end">
                {state?.form?.startTime} To{" "}
                {convertTo12HourFormat(state?.form?.endTime)}
              </td>
            </tr>
          )}
          <tr>
            <td>
              <table>
                <tr>
                  <td className="mr-3" style={{ paddingRight: "20px" }}>
                    <img
                      className="rounded-circle"
                      src={
                        (state && state.selectedCard?.profilePhoto) ||
                        (state.selectedConversation?.createdBy !== user.UserID
                          ? state.selectedConversation?.senderPhoto
                          : state.selectedConversation?.receiverProfilePhoto) ||
                        (state.item && state.itemOrder.image) ||
                        (state?.msg?.createdBy === user.UserID
                          ? state?.msg?.receiverProfilePhoto || userIcon
                          : state?.msg?.senderPhoto)
                      }
                      alt=""
                    />
                  </td>
                  <td>
                    <h6>
                      {(state &&
                        state.selectedCard?.userName.split("@")[0].trim()) ||
                        (state.selectedConversation?.createdBy === user.UserID
                          ? state.selectedConversation?.userName
                              .split("@")[0]
                              .trim()
                          : state.selectedConversation?.senderName) ||
                        (state.item && state.itemOrder.name) ||
                        (state?.msg?.createdBy === user.UserID
                          ? state?.msg?.userName.split("@")[0].trim()
                          : state?.msg?.senderName)}
                    </h6>
                    <p>
                      {state?.selectedCard?.Description?.length > 50
                        ? `${state.selectedCard.Description.slice(0, 50)}...`
                        : state?.selectedCard?.Description}
                    </p>
                  </td>
                </tr>
              </table>
            </td>
            <td className="text-end">
              ${" "}
              {/* {state && state?.selectedCard && state?.selectedCard?.hourlyPrice
                ? `${state.selectedCard.hourlyPrice}.00`
                : state?.form?.service?.price} */}
              {/* {state && state?.selectedCard && state?.selectedCard?.hourlyPrice
                ? `${state.selectedCard.hourlyPrice}.00`
                : state?.form?.service?.price
                ? state?.form?.service?.price
                : totalPrice} */}
              {totalPrice}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              {" "}
              <div
                style={{ border: "0.01em solid #f3f3f3" }}
                className="hr mb-2"
              ></div>
            </td>
          </tr>

          {finalPayment !== 20 && hire && (
            <tr>
              <td>
                <h6>
                  <b>Total Hours</b>
                </h6>
              </td>
              <td>
                <h6 className="text-end">
                  <b>{totalHours} hrs</b>
                </h6>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <h6>
                <b>Total Amount</b>
              </h6>
            </td>
            <td>
              <h6 className="text-end">
                <b>${totalPrice}</b>
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
      {finalPayment !== 20 && hire && (
        <Row className="p-3 d-flex justify-content-center align-items-center">
          <Col lg={4}>
            <p>Apply the coupon </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Col>
          <Col lg={8}>
            <Input
              type="text"
              value={coupon}
              placeholder="Enter Code"
              onChange={(e) => setCoupon(e.target.value)}
            />
            <div className="row pt-3">
              <Col lg={6} className="text-end">
                <button
                  className="btn btn-primary m-0 btn-sm"
                  onClick={handleCouponApply}
                >
                  Apply Coupon
                </button>
              </Col>
              <Col lg={6} className="text-start">
                <button
                  className="btn btn-primary m-0 py-1 btn-sm"
                  onClick={() => {
                    setFinalPayment(totalPrice);
                    setFinalAmount([]);
                    setCoupon("");
                  }}
                >
                  Clear Coupon
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      )}
      <Row className="p-4 d-flex justify-content-center align-items-center">
        <h4 className="p-0 mb-3">Payment Information</h4>
        <table style={{ width: "100%" }}>
          <tr>
            <td className="py-1">Value of Product(s) :</td>
            <td className="text-end">
              {!state.itemCost ? (
                <span> $ {totalPrice}</span>
              ) : (
                <span> $ {itemOrder.price}</span>
              )}
            </td>
          </tr>
          {finalPayment !== 20 && hire && (
            <tr>
              <td className="py-1">Coupon Discount(-)</td>
              <td className="text-end">
                ${" "}
                {finalAmount && finalAmount.discount ? finalAmount.discount : 0}
              </td>
            </tr>
          )}
          {state.item && (
            <tr>
              <td className="py-1">Shipping Charges :</td>
              <td className="text-end">
                ${" "}
                {/* {finalAmount && finalAmount.discount ? finalAmount.discount : 0} */}
                {ItemShipping(itemOrder?.delivery_option)}
              </td>
            </tr>
          )}
          <tr>
            <td className="py-1">Sub Total :</td>
            <td className="text-end">
              {hire ? (
                <>
                  <span
                    className={`${
                      finalAmount?.discount
                        ? "text-decoration-line-through"
                        : " d-none"
                    }`}
                  >
                    {totalPrice}
                  </span>{" "}
                  &nbsp; &nbsp;
                  <span>$ {finalPayment}</span>
                </>
              ) : state.item ? (
                <span>$ {finalPayment}</span>
              ) : (
                <span>$ {totalPrice}</span>
              )}
            </td>
          </tr>
          <hr />
          <tr>
            <td className="py-1">Final Payment :</td>
            <td className="text-end">$ {finalPayment}</td>
          </tr>
        </table>{" "}
      </Row>
      {(finalPayment === 20 || itemOrder) && (
        <div className="">
          <button
            type="button"
            className="btn btn-outline-secondary mx-2 os_btn"
            onClick={() => window.history.back()}
          >
            Back
          </button>
          <button
            type="button"
            class="btn btn-primary mx-2 os_btn"
            onClick={handleRequest}
            disabled={isLoading}
          >
            Request
          </button>
        </div>
      )}
      {pay && (
        <div className=" mt-3">
          {" "}
          <p className="text-center mb-1">
            Please Make payment using below methods
          </p>
          <Payment
            totalPrice={finalPayment}
            order={order}
            state={state}
            chatDetails={true}
          />
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
