import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { GetAllComments, GetLikes, LikeThePost } from "../../Data3";

const StyledCard = styled(Card)(({ theme }) => ({
  width: 220,
  height: 320,
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
  },
}));

const ScrollButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  color: theme.palette.grey[800],
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  padding: "4px",
}));

const CommunityCards = ({ post }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const isHtml = (str) => /<[a-z][\s\S]*>/i.test(str);
  const [allLikes, setAllLikes] = React.useState([]);
  const [allComments, setAllComments] = React.useState([]);

  const handleLikeClick = async (post) => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
      likeStatus: 1,
    };
    try {
      const response = await LikeThePost(req);
      if (response.statusCode === 200) {
        console.log("Post liked successfully");
        fetchLikes();
      } else {
        console.log("Error liking post");
      }
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };
  // Parse gallery images
  const galleryImages = React.useMemo(() => {
    if (!post?.galleryImage) return [];
    try {
      return typeof post.galleryImage === "string"
        ? JSON.parse(post.galleryImage)
        : Array.isArray(post.galleryImage)
        ? post.galleryImage
        : [];
    } catch (e) {
      console.error("Error parsing galleryImage:", e);
      return [];
    }
  }, [post?.galleryImage]);
  const fetchLikes = async () => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
    };
    const response = await GetLikes(req);
    setAllLikes(response);
  };

  const fetchComments = async () => {
    const response = await GetAllComments(post.Id);
    const filterActive = response.filter(
      (comment) => comment.commentStatus === 1
    );
    setAllComments(filterActive);
  };

  React.useEffect(() => {
    fetchLikes();
    fetchComments();
  }, []);
  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev > 0 ? prev - 1 : galleryImages.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev < galleryImages.length - 1 ? prev + 1 : 0
    );
  };

  return (
    <StyledCard>
      <CardHeader
        avatar={
          <Avatar
            sx={{ width: 30, height: 30 }}
            src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
          />
        }
        action={
          <>
            {/* <IconButton size="small" onClick={handleMenuClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              id="post-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem>More</MenuItem>
            </Menu> */}
          </>
        }
        title={
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "150px", // Adjust this value based on your layout
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {post?.firstName + " " + post?.lastName || "Creator Name"}
          </Typography>
        }
        subheader={
          <Typography variant="caption" color="text.secondary">
            {post.createdAt
              ? new Date(post.createdAt).toLocaleDateString()
              : "Sep 14, 2016"}
          </Typography>
        }
        sx={{ padding: "8px" }}
      />
      <Box sx={{ position: "relative", height: 140 }}>
        {post.postType === "cookingVideos" && post.videoUrl ? (
          <CardMedia
            component="video"
            height="140"
            src={post?.videoUrl}
            controls
            muted
            sx={{ objectFit: "cover", width: "100%" }}
          />
        ) : galleryImages.length > 0 ? (
          <Box sx={{ position: "relative", height: "100%" }}>
            <CardMedia
              component="img"
              height="140"
              src={galleryImages[currentImageIndex]}
              sx={{ objectFit: "cover", width: "100%" }}
            />
            {galleryImages.length > 1 && (
              <>
                <ScrollButton onClick={handlePrevImage} sx={{ left: 4 }}>
                  <ArrowLeftIcon fontSize="small" />
                </ScrollButton>
                <ScrollButton onClick={handleNextImage} sx={{ right: 4 }}>
                  <ArrowRightIcon fontSize="small" />
                </ScrollButton>
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    bottom: 4,
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    padding: "2px 6px",
                    borderRadius: "8px",
                  }}
                >
                  {`${currentImageIndex + 1}/${galleryImages.length}`}
                </Typography>
              </>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              height: "100%",
              bgcolor: "#f5f5f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption">No Media</Typography>
          </Box>
        )}
      </Box>
      <CardContent
        sx={{ padding: "8px" }}
        onClick={() => navigate(`/postDetails/${post.Title}`, { state: post })}
      >
        <Typography variant="subtitle1" fontWeight="bold" noWrap>
          <a
            style={{
              textDecoration: "none",
              color: "#2c43ff",
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {post?.Title || "Untitled"}
          </a>
        </Typography>
        {post?.Description ? (
          isHtml(post.Description) ? (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{ __html: post.Description }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
            >
              {post.Description}
            </Typography>
          )
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontStyle: "italic" }}
          >
            No description available
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          padding: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton
            size="small"
            onClick={() => handleLikeClick(post)}
            sx={{
              color: allLikes?.userLiked ? "red" : "grey.600",
              "&:hover": { color: "red" },
            }}
          >
            <FavoriteIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ minWidth: "20px" }}
          >
            {allLikes?.totalLikes || 0}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton
            size="small"
            onClick={() =>
              navigate(`/postDetails/${post.Title}`, { state: post })
            }
            sx={{
              color: "grey.600",
              "&:hover": { color: "primary.main" },
            }}
          >
            <ChatBubbleOutlineIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ minWidth: "20px" }}
          >
            {allComments?.length || 0}
          </Typography>
        </Box>
      </CardActions>
    </StyledCard>
  );
};

export default CommunityCards;
