import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios-client";

export const fetchCommunityList = createAsyncThunk(
  "Community/getCommunityList",
  async (id, { rejectWithValue }) => {
    // Accept id as the first argument
    try {
      const response = await axiosClient.put(
        `Community/getCommunityList/${id}` // Use the passed id here
      );
      return response.data.Data; // Return the data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching community list"
      );
    }
  }
);

const initialState = {
  sending: false,
  activeTab: "local-newspapers",
  communityList: [],
  loading: false,
  error: null,
  selectedSection: "localNewspapers",
  showComments: null,
};

const PostFormSlice = createSlice({
  name: "create-post",
  initialState,
  reducers: {
    setSending(state, action) {
      state.sending = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setSelectedSection(state, action) {
      state.selectedSection = action.payload;
    },
    setShowComments(state, action) {
      state.showComments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunityList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunityList.fulfilled, (state, action) => {
        state.loading = false;
        state.communityList = action.payload;
      })
      .addCase(fetchCommunityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSending, setActiveTab, setSelectedSection, setShowComments } =
  PostFormSlice.actions;

export default PostFormSlice.reducer;
