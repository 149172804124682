import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const EventDetailsModal = ({ isOpen, close, event }) => {
  console.log(event, "event");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dateTime = `${event?.reserveDate} ${event?.startTime}`;

  const providerZone = event?.providerTimezone;
  const CustZone = event?.customerTimezone;

  const proTime = providerZone?.split(",")[0];
  const custTime = CustZone?.split(",")[0];

  const proZone = providerZone?.split(",")[1];
  const custZone = CustZone?.split(",")[1];

  const timeZoneFormat = (newDate, timeZone1, timeZone2) => {
    let protimelast, custtimelast;
    if (event?.zoomRole === event?.createdBy) {
      const protime = moment.tz(newDate, timeZone1);
      protimelast = protime.format("MMMM D, YYYY [at] hh:mm A");
      const custtime = moment.tz(protime, timeZone2);
      custtimelast = custtime.format("MMMM D, YYYY [at] hh:mm A");
    } else {
      const protime = moment.tz(newDate, timeZone2);
      protimelast = protime.format("MMMM D, YYYY [at] hh:mm A");
      const custtime = moment.tz(protime, timeZone1);
      custtimelast = custtime.format("MMMM D, YYYY [at] hh:mm A");
    }
    return { protimelast, custtimelast };
  };
  const response = timeZoneFormat(dateTime, proTime, custTime);
  return (
    <div>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>Event Details</ModalHeader>
        <ModalBody className="text-center">
          {event?.zoomStatus ? (
            <>
              <div className="text-center mt-3">
                {event?.zoomStatus === 0 && (
                  <h5 className="mb-4">No Calls Scheduled</h5>
                )}
                {event?.zoomStatus === 1 && (
                  <>
                    {event?.zoomRole === user.UserID ? (
                      <>
                        <h5>
                          {" "}
                          Call Request Sent for {response.protimelast}
                          {providerZone?.split(",")[1]} for 30 mins with{" "}
                          {event?.zoomRole === event?.createdBy
                            ? event.senderName
                            : event.customerName}
                          <br />
                          <span>
                            Check&nbsp;
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary text-decoration-underline"
                              onClick={() =>
                                window.open(
                                  `/#/userDetails/${event?.zoomRole}`,
                                  "_blank"
                                )
                              }
                            >
                              {event?.zoomRole === event?.createdBy
                                ? event.senderName
                                : event.customerName}{" "}
                              Profile
                            </span>
                          </span>
                        </h5>
                      </>
                    ) : (
                      <>
                        <h5 className="">
                          Call Requested for{" "}
                          {user.RoleId !== 1
                            ? response.custtimelast
                            : response.protimelast}{" "}
                          {user.RoleId !== 1
                            ? CustZone?.split(",")[1]
                            : providerZone?.split(",")[1]}{" "}
                          for 30 mins with{" "}
                          {event?.zoomRole === event?.createdBy
                            ? event.senderName
                            : event.customerName}
                          <br />
                          <span>
                            Check&nbsp;
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary text-decoration-underline"
                              onClick={() =>
                                window.open(
                                  `/#/userDetails/${event?.zoomRole}`,
                                  "_blank"
                                )
                              }
                            >
                              {event?.zoomRole === event?.createdBy
                                ? event.senderName
                                : event.customerName}{" "}
                              Profile
                            </span>
                          </span>
                        </h5>
                      </>
                    )}
                  </>
                )}
                {event?.zoomStatus === 2 && <h5>Call rejected</h5>}
                {event?.zoomStatus === 3 && (
                  <>
                    {event?.zoomRole === user?.UserID ? (
                      <>
                        <h5 className="m-3">
                          {" "}
                          Call Scheduled for {response.protimelast}{" "}
                          {event?.zoomRole !== event?.createdBy
                            ? custZone
                            : proZone}
                          &nbsp;for 30 mins with{" "}
                          {event?.zoomRole === event?.createdBy
                            ? event.senderName
                            : event.customerName}{" "}
                          <br />
                          <span>
                            Check&nbsp;
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary text-decoration-underline"
                              onClick={() =>
                                window.open(
                                  `/#/userDetails/${event?.zoomRole}`,
                                  "_blank"
                                )
                              }
                            >
                              {event?.zoomRole === event?.createdBy
                                ? event.senderName
                                : event.customerName}{" "}
                              Profile
                            </span>
                          </span>
                        </h5>
                      </>
                    ) : user.RoleId !== 1 ? (
                      <>
                        <h5 className="m-3">
                          Call Scheduled for {response.custtimelast}
                          {event?.zoomRole !== event?.createdBy
                            ? proZone
                            : custZone}{" "}
                          for 30 mins with{" "}
                          {event?.zoomRole === event?.createdBy
                            ? event.senderName
                            : event.customerName}{" "}
                          <br />
                          <span>
                            Check&nbsp;
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary text-decoration-underline"
                              onClick={() =>
                                window.open(
                                  `/#/userDetails/${event?.zoomRole}`,
                                  "_blank"
                                )
                              }
                            >
                              {event?.zoomRole === event?.createdBy
                                ? event.senderName
                                : event.customerName}{" "}
                              Profile
                            </span>
                          </span>
                        </h5>
                      </>
                    ) : (
                      <h5 className="m-3">
                        {" "}
                        Call Scheduled for {response.protimelast}{" "}
                        {event?.zoomRole !== event?.createdBy
                          ? custZone
                          : proZone}{" "}
                        for 30 mins with{" "}
                        {event?.zoomRole === event?.createdBy
                          ? event.senderName
                          : event.customerName}{" "}
                        <br />
                        <span>
                          Check&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            className="text-primary text-decoration-underline"
                            onClick={() =>
                              window.open(
                                `/#/userDetails/${event?.zoomRole}`,
                                "_blank"
                              )
                            }
                          >
                            {event?.zoomRole === event?.createdBy
                              ? event.senderName
                              : event.customerName}{" "}
                            Profile
                          </span>
                        </span>
                      </h5>
                    )}
                  </>
                )}
                {event?.zoomStatus === 4 && (
                  <>
                    {event?.updatedBy === event?.zoomRole ? (
                      <h5>
                        Rescheduled To{" "}
                        {event?.zoomRole === user?.UserID
                          ? (user?.UserID === 4 && response.protimelast) ||
                            response.protimelast
                          : (user?.UserID !== 4 && response.custtimelast) ||
                            response.custtimelast}{" "}
                        {user && user.UserID === event?.createdBy
                          ? providerZone?.split(",")[1]
                          : user?.UserID === 1
                          ? proZone?.split(",")[1]
                          : CustZone?.split(",")[1]}
                        &nbsp;for 30 mins with{" "}
                        {event?.zoomRole === event?.createdBy
                          ? event.senderName
                          : event.customerName}
                        <br />
                        <span>
                          Check&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            className="text-primary text-decoration-underline"
                            onClick={() =>
                              window.open(
                                `/#/userDetails/${event?.zoomRole}`,
                                "_blank"
                              )
                            }
                          >
                            {event?.zoomRole === event?.createdBy
                              ? event.senderName
                              : event.customerName}{" "}
                            Profile
                          </span>
                        </span>
                      </h5>
                    ) : (
                      <h5>
                        Rescheduled To{" "}
                        {event?.zoomRole !== user?.UserID
                          ? response.protimelast
                          : response.custtimelast}{" "}
                        {user && user.UserID !== 4
                          ? providerZone?.split(",")[1]
                          : user?.UserID === 1
                          ? proZone?.split(",")[1]
                          : CustZone?.split(",")[1]}{" "}
                        &nbsp;for 30 mins with{" "}
                        {event?.zoomRole === event?.createdBy
                          ? event.senderName
                          : event.customerName}
                        <br />
                        <span>
                          Check&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            className="text-primary text-decoration-underline"
                            onClick={() =>
                              window.open(
                                `/#/userDetails/${event?.zoomRole}`,
                                "_blank"
                              )
                            }
                          >
                            {event?.zoomRole === event?.createdBy
                              ? event.senderName
                              : event.customerName}{" "}
                            Profile
                          </span>
                        </span>
                      </h5>
                    )}
                  </>
                )}
                {event?.zoomStatus === 5 && (
                  <h5>Both or either parties not joined the call</h5>
                )}
                {event?.zoomStatus === 6 && <h5>Call completed</h5>}
                {event?.zoomStatus === 7 && <h5>Call Cancelled</h5>}
                {event?.zoomStatus === 3 && event?.meetingLink !== null && (
                  <strong>
                    Meeting Link: <br />{" "}
                    <a
                      href={event?.meetingLink}
                      target="_blank"
                      className="text-decoration-underline"
                    >
                      {event?.meetingLink}
                    </a>
                  </strong>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center text-wrap mt-3">
                <h5>Status:</h5>
                {/* <div className="d-flex flex-column justify-content-center align-items-center text-wrap"></div> */}
                {event?.messageStatus === 1 && (
                  <>
                    {user.RoleId !== 1 ? (
                      <div className="status-bubble">
                        <p className="text-success">
                          <small>Awaiting Admin Approval</small>
                        </p>
                      </div>
                    ) : (
                      <div className="status-bubble">
                        <p className="text-warning">
                          <small>Provider Made An New Offer</small>
                        </p>
                      </div>
                    )}
                  </>
                )}
                {event?.messageStatus === 2 && (
                  <>
                    <div className="status-bubble ">
                      <p className="text-danger">
                        <small>Admin Rejected The Offer</small>
                      </p>
                    </div>
                  </>
                )}
                {event?.messageStatus === 3 && user.RoleId != 1 && (
                  <>
                    {event?.zoomStatus === 3 ? (
                      <div className="status-bubble">
                        <p className="text-primary">
                          <small>Awaiting to speak on Call</small>
                        </p>
                      </div>
                    ) : (
                      <>
                        {event?.ownerId === user.UserID ? (
                          <div className="status-bubble">
                            <p className="text-success">
                              <small>
                                Provider Made An New Offer &#129395;
                              </small>
                            </p>
                          </div>
                        ) : (
                          <div className="status-bubble">
                            <p className="text-success">
                              <small>Awaiting Customer Interaction</small>
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {event?.messageStatus === 11 && (
                  <>
                    {event?.zoomStatus === 3 ? (
                      <div className="status-bubble">
                        <p className="text-primary">
                          <small>
                            Customer viewed offer. Awaiting Interaction
                          </small>
                        </p>
                      </div>
                    ) : (
                      <>
                        {event?.ownerId === user.UserID ? (
                          <div className="status-bubble">
                            <p className="text-success">
                              <small>Interact with Provider &#129395;</small>
                            </p>
                          </div>
                        ) : (
                          <div className="status-bubble">
                            <p className="text-success">
                              <small>Customer viewed offer</small>
                              {/* <small>Awaiting Customer Interaction</small> */}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {event?.messageStatus === 4 && (
                  <div className="status-bubble ">
                    <p className="text-success">
                      <small>Customer Accepted Offer &#129395;</small>{" "}
                    </p>
                  </div>
                )}
                {event?.messageStatus === 9 && (
                  <div className="status-bubble ">
                    <p className="text-success">
                      <small>Provider Accepted The Deal &#129395;</small>{" "}
                    </p>
                  </div>
                )}
                {event?.messageStatus === 5 && (
                  <div className="status-bubble mb-1">
                    <p className="text-danger">
                      <small>Customer Rejected Offer</small>
                    </p>
                  </div>
                )}
                {event?.messageStatus === 10 && (
                  <div className="status-bubble">
                    <p className="text-danger">
                      <small>Provider Rejected Deal</small>
                    </p>
                  </div>
                )}
                {(event?.messageStatus === 6 || event?.messageStatus === 7) &&
                  user.RoleId !== 1 && (
                    <div className="status-bubble">
                      <p className="text-dark">
                        <small>Deal In Progress</small>
                      </p>
                    </div>
                  )}
                {user.RoleId === 1 &&
                  (event?.messageStatus === 3 ||
                    event?.messageStatus === 6 ||
                    event?.messageStatus === 7) && (
                    <div className="status-bubble">
                      <p className="text-dark">
                        <small>Deal In Progress</small>
                      </p>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="text-center">
              <h5>Event Details:</h5>
              <h6> Title: {event?.title}</h6>
              {event?.isGoogleEvent ? (
                <h6
                  dangerouslySetInnerHTML={{
                    __html: event?.dec.replace(/\n/g, "<br>"),
                  }}
                />
              ) : (
                <h6>Descreption: {event?.dec}</h6>
              )}
              <h6>
                Start Time: {event?.start && moment(event?.start).format("lll")}
              </h6>
              <h6>
                End Time: {event?.end && moment(event?.end).format("lll")}
              </h6>
            </div>
          )}
          {/* <h3>{event?.title}</h3>
          <p>{event?.dec}</p>
          <p>{event?.custmorEmail}</p> */}
        </ModalBody>
        <ModalFooter>
          <Button color="danger btn-sm" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EventDetailsModal;
