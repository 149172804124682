// FollowUpTasks.js
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import CreateFollowUpMd from "../../../../components/Header/modals/CreateFollowUpMd";
import { fetchMyServices } from "../../../../components/Header/Data";
import AllFollowUpTasks from "../../../../components/Header/modals/AllFollowUpTasks";
import axiosClient from "../../../../axios-client";

const FollowUpTasks = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const [openCreateModal, setCreateModal] = useState(false);
  const [openAllFollowUpsModal, setOpenAllFollowUpsModal] = useState(false);
  const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [followUpTasks, setFollowUpTasks] = React.useState([]);
  const fetchTasks = async () => {
    try {
      const response = await axiosClient.put(
        `message/listFollowUpTask/${selectedConvo?.Id}`
      );
      const filteredTasks = response.data.Data.filter(
        (task) => task.followupStatus !== "DELETED"
      );
      setFollowUpTasks(filteredTasks);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (openAllFollowUpsModal) {
      fetchTasks();
    }
  }, [openAllFollowUpsModal]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(false);
  };
  const openCreate = () => {
    setCreateModal(true);
    handleClose();
  };
  const openAllFollows = () => {
    setOpenAllFollowUpsModal(true);
    handleClose();
  };

  return (
    <div className="wc-chat-drop">
      <Button
        className="drop-button"
        style={{
          backgroundColor: "#01a2e8",
          color: "black",
          fontSize: "x-small",
        }}
        size="sm"
        onClick={handleClick}
      >
        <span className="drop-button-title">
          Follow Up Tasks
          <FaChevronDown />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={currentMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "120px",
            maxWidth: "200px",
          },
        }}
      >
        <MenuItem onClick={openAllFollows}>View Tasks</MenuItem>
        <MenuItem onClick={openCreate}>Add Task</MenuItem>
      </Menu>
      {openCreateModal && (
        <CreateFollowUpMd
          open={openCreateModal}
          handleClose={() => setCreateModal(false)}
          selectedConvo={selectedConvo}
        />
      )}
      <AllFollowUpTasks
        open={openAllFollowUpsModal}
        handleClose={() => setOpenAllFollowUpsModal(false)}
        followUpTasks={followUpTasks}
        fetchTasks={fetchTasks}
      />
    </div>
  );
};

export default FollowUpTasks;
