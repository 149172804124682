import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  resetFormState,
  setCreatedBy,
  setCreatedEmail,
  setEmail,
} from "../../../redux/slices/RequestFormSlice";
import { useNavigate } from "react-router-dom";
import {
  postRequest,
  fetchCategories,
  fetchSubCategories,
  fetchItems,
} from "../../../components/Header/Data";
import { toast } from "react-toastify";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import CreateNeedVersion2 from "./CreateNeedVersion2";
import DonateModal from "../../../components/Header/modals/DonateModal";
import {
  setDonationFrom,
  setDonationStatus,
} from "../../../redux/slices/DonationSlice";

const RequestNeedVersion2 = ({ editMode }) => {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [subCategories, setSubCategories] = useState([]);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formData = useSelector((state) => state.requestForm);
  const [categories, setCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const { donationStatus } = useSelector((state) => state.gurdwaraDonation);

  const fetchData = async () => {
    try {
      let categoriesData = await fetchCategories();
      let subCategoriesData = await fetchSubCategories();
      setCategories(categoriesData);
      setSubCategories1(subCategoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
    window.scroll(0, 0);
    if (islogin) {
      dispatch(setCreatedBy(user.UserID));
      dispatch(setCreatedEmail(user.Email));
    }
  }, []);
  const handleModalClose = () => {
    setModalClosed(true); // Track that the modal has been closed
    setModalIsOpen(false); // Close the modal
  };
  // console.log(formData, "formData");
  const handleFormSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isLoading) return;

    if (!formData.FullName) {
      toast.error("Please Enter Full Name");
      return;
    } else if (isAdmin && !formData.categoryId) {
      toast.error("Please Select Category");
      return;
    } else if (
      isAdmin &&
      subCategories?.length !== 0 &&
      !formData.subCategoryId
    ) {
      toast.error("Please Select Sub Category");
      return;
    } else if (isAdmin && !formData?.needRadius) {
      toast.error("Please Select Radius");
      return;
    } else if (isAdmin && formData.dialExtension === "") {
      toast.error("Please Enter Dial Extension");
      return;
    } else if (!formData?.Gurudwara && formData.engagedCouple === 0) {
      toast.error("Please Select Gurudwara");
      return;
    } else if (!formData.Title) {
      toast.error("Please Enter Title");
      return;
    } else if (!formData?.Gurudwara && formData.engagedCouple === 0) {
      toast.error("Please Select Gurdwara");
      return;
    } else if (!formData.Zipcode || formData.Zipcode.length < 5) {
      toast.error("Enter 5 digits Zip Code");
      return;
    } else if (!formData.Phone) {
      toast.error("Enter Phone Number");
      return;
    } else if (!formData?.alternativePhoneOne) {
      toast.error('"Mobile Number (Family Member)" is a required field.');
      return;
    } else if (
      formData?.alternativePhoneOne?.length < 10 &&
      formData?.alternativePhoneOne?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (
      formData?.alternativePhoneTwo?.length < 10 &&
      formData?.alternativePhoneTwo?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (!formData.Terms) {
      toast.error("Accept the Terms & Conditions");
      return;
    }
    // console.log(
    //   formData?.Gurudwara !== null &&
    //     formData?.engagedCouple !== 1 &&
    //     !modalClosed &&
    //     !isAdmin &&
    //     donationStatus !== "COMPLETED",
    //   "DonateModal"
    // );
    // if (
    //   (formData?.engagedCouple === 0 &&
    //     formData?.Gurudwara !== "" &&
    //     !modalClosed &&
    //     !isAdmin &&
    //     donationStatus !== "COMPLETED") ||
    //   (formData?.Gurudwara !== "" &&
    //     formData?.engagedCouple !== 1 &&
    //     !modalClosed &&
    //     !isAdmin &&
    //     donationStatus !== "COMPLETED")
    // ) {
    //   // console.log("entered");
    //   setModalIsOpen(true);
    //   dispatch(setDonationFrom("GURDWARA_NEED"));
    //   return;
    // } else {
    //   setModalIsOpen(false);
    // }

    const updatedFormData = { ...formData };

    // Ensure formData.availableFrom is a string
    if (
      updatedFormData.availableFrom &&
      typeof updatedFormData.availableFrom !== "string"
    ) {
      updatedFormData.availableFrom = JSON.stringify(
        updatedFormData.availableFrom
      );
    }
    setIsLoading(true);
    await postRequest(updatedFormData);
    toast.success("New Request sent to Admin for Approval");
    {
      !islogin
        ? navigate("/Localcommunityads")
        : navigate("/dashboardpage/needs-requests");
    }
    window.scroll(0, 0);
    dispatch(resetFormState());
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log(donationStatus, "donationStatus");
    if (donationStatus === "COMPLETED") {
      handleFormSubmit();
      dispatch(setDonationStatus(""));
      dispatch(setDonationFrom(""));
    }
  }, [donationStatus]);

  return (
    <div className="container pt-5" style={{ position: "relative" }}>
      <div>
        <h5 className="text-center" style={{ fontSize: "30px" }}>
          <b>Post Your Ad</b>
        </h5>
        {!islogin && (
          <Col className="request-form p-4 mt-3">
            {" "}
            <Row>
              <FormGroup>
                <Label>Enter Email ID</Label>
                <Input
                  type="email"
                  name="Email"
                  placeholder="Enter Email ID"
                  value={formData.Email}
                  onChange={(e) => dispatch(setEmail(e.target.value))}
                />
              </FormGroup>
            </Row>
          </Col>
        )}
        <CreateNeedVersion2
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          editMode={editMode}
          handleFormSubmit={handleFormSubmit}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          isLoading={isLoading}
        />
      </div>
      <DonateModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        handleFormSubmit={handleFormSubmit}
        onClose={handleModalClose} // Pass close handler to the modal
      />
    </div>
  );
};

export default RequestNeedVersion2;
