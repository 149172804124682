import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers,
  CAIdStatusUpdate,
} from "../../../components/Header/Data";
import { VscEye } from "react-icons/vsc";
import { IoMdDownload } from "react-icons/io";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Col, Row, Modal, ModalBody, Button } from "reactstrap";
import "./Settings.css";
import { fetchUsers } from "../../../redux/slices/settingSlice";
const VerifyID = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectUser, setRejectUser] = useState(null);
  const [rejectMessage, setRejectMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await fetchAllUsers();
      setUsers(fetchedUsers);
      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const handleStatusUpdate = async (user, status) => {
    const Id = user.Id;
    const formData = {
      caIdStatus: status.toString(),
      Email: user?.Email,
      caIdComment: "",
    };
    try {
      await CAIdStatusUpdate(Id, formData);
      toast.success(`CA ID & ${status === 1 ? "Approved" : "Unverified"}`);
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.Id === user.Id ? { ...u, caIdStatus: status } : u
        )
      );
      dispatch(fetchUsers());
    } catch (error) {
      toast.error(`Error updating user with ID ${user.Id}`);
    }
  };

  const openRejectModal = (user) => {
    setRejectUser(user);
    setRejectModalOpen(true);
  };

  const closeRejectModal = () => {
    setRejectUser(null);
    setRejectMessage("");
    setRejectModalOpen(false);
  };

  const handleReject = async () => {
    if (!rejectUser || !rejectMessage) {
      toast.error("Please enter a message");
      return;
    }
    const formData = {
      caIdStatus: 2,
      caIdComment: rejectMessage,
      Email: rejectUser.Email,
    };
    try {
      await CAIdStatusUpdate(rejectUser.Id, formData);
      toast.error("CA ID Rejected");
      dispatch(fetchUsers());
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.Id === rejectUser.Id ? { ...u, caIdStatus: 2 } : u
        )
      );
      closeRejectModal();
    } catch (error) {
      toast.error(`Error rejecting user with ID ${rejectUser.Id}`);
    }
  };

  const handleView = (userId) => {
    const userToView = users.find((user) => user.Id === userId);
    if (userToView) window.open(userToView.caId, "_blank");
  };

  const handleDownload = async (userId) => {
    const userToDownload = users.find((user) => user.Id === userId);
    if (userToDownload) {
      try {
        const response = await fetch(userToDownload.caId);
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        const blob = await response.blob();
        const urlParts = userToDownload.caId.split("/");
        const filename = urlParts[urlParts.length - 1];
        const extension = filename.split(".").pop();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute(
          "download",
          `${userToDownload.FirstName}_${userToDownload.LastName}_ID.${extension}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        toast.error(`Error downloading image for user ${userId}`);
      }
    }
  };

  return (
    <div className="container-fluid p-3 mt-4">
      <style>
        {`
          .name-text {
            font-size: 1.1rem;
            font-weight: 600;
            color: #333;
            margin-bottom: 10px;
          }
          .action-btn {
            font-size: 0.9rem;
            margin: 0 5px;
            transition: all 0.3s;
          }
          .icon-btn {
            padding: 4px;
            background: #f5f5f5;
            border: none;
            margin: 0 5px;
            transition: all 0.3s;
          }
          .icon-btn:hover {
            background: #e0e0e0;
          }
          .modal-content {
            border-radius: 15px;
          }
          .modal textarea {
            border-radius: 10px;
            resize: none;
          }
          @media (max-width: 768px) {
            .userInfo-card {
              padding: 12px;
            }
            .name-text {
              font-size: 1rem;
            }
            .action-btn {
              padding: 6px 12px;
              font-size: 0.85rem;
            }
            .icon-btn {
              padding: 6px;
            }
          }
        `}
      </style>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <FadeLoader color="#36d7b7" />
        </div>
      ) : users.length > 0 ? (
        <div className="row g-3">
          {users
            .filter((user) => user?.caId !== null)
            .map((user) => (
              <div className="col-12" key={user.Id}>
                <div className="userInfo-card">
                  <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                    <div className="name-text">
                      {user?.FirstName} {user?.LastName}
                    </div>
                    <div className="d-flex flex-wrap align-items-center mt-2 mt-md-0">
                      {user.caIdStatus === 0 && (
                        <>
                          <button
                            onClick={() => handleStatusUpdate(user, 1)}
                            className="btn btn-success action-btn"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => openRejectModal(user)}
                            className="btn btn-danger action-btn"
                          >
                            Reject
                          </button>
                        </>
                      )}
                      {user.caIdStatus === 1 && (
                        <button className="btn btn-success action-btn" disabled>
                          Approved
                        </button>
                      )}
                      {user.caIdStatus === 2 && (
                        <button className="btn btn-danger action-btn" disabled>
                          Rejected
                        </button>
                      )}
                      {user.caIdStatus !== 0 && (
                        <button
                          onClick={() => handleStatusUpdate(user, 0)}
                          className="btn btn-primary action-btn"
                        >
                          Unverified
                        </button>
                      )}

                      <div className="d-flex ms-md-2 mt-2 mt-md-0">
                        <button
                          onClick={() => handleView(user.Id)}
                          className="icon-btn"
                          title="View Image"
                        >
                          <VscEye size={20} />
                        </button>
                        <button
                          onClick={() => handleDownload(user.Id)}
                          className="icon-btn"
                          title="Download"
                        >
                          <IoMdDownload size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <h5 className="text-center mt-5 text-muted">No Users Found</h5>
      )}

      <Modal isOpen={rejectModalOpen} toggle={closeRejectModal} centered>
        <ModalBody className="p-4">
          <h5 className="mb-3">Reject ID</h5>
          <p>Please provide a reason for rejection:</p>
          <textarea
            className="form-control"
            rows="4"
            placeholder="Enter rejection reason..."
            value={rejectMessage}
            onChange={(e) => setRejectMessage(e.target.value)}
          />
          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              color="secondary"
              onClick={closeRejectModal}
              className="action-btn"
            >
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={handleReject}
              className="action-btn"
            >
              Reject
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VerifyID;
