import React, { useState, useEffect } from "react";
import MessageItem from "./MessageItem";
import "./messages.css";
import { useDispatch, useSelector } from "react-redux";
import SearchFilter from "./SearchFilter";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import "./Styles/MessagesList.css";
import { toast } from "react-toastify";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { setFinalConvos, setSearchTerm } from "../../../redux/slices/MsgSlice";
import { filterConversations } from "./Filters";

function MessageList({ onSelectConversation }) {
  const {
    convos,
    finalConvos,
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    unreadSearch,
    guestCount,
    showFollowUp,
    myConnections,
    myJobOffers,
    oppertunityFilter,
  } = useSelector((state) => state.messages);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";

  const handleClose = () => setAnchorEl(null);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-dot": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  // Async filtering logic
  useEffect(() => {
    const fetchFilteredConvos = async () => {
      setLoading(true); // Set loading to true
      const filters = {
        searchTerm,
        meetingDateSearch,
        eventMonthsearch,
        unreadSearch,
        guestCount,
        showFollowUp,
        oppertunityFilter,
      };
      try {
        const filtered = await filterConversations(convos, filters, user);
        if (JSON.stringify(filtered) !== JSON.stringify(finalConvos)) {
          console.log("Dispatching setFinalConvos:", filtered);
          dispatch(setFinalConvos(filtered));
        }
      } catch (error) {
        console.error("Error filtering conversations:", error);
        dispatch(setFinalConvos([])); // Fallback to empty array on error
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchFilteredConvos();
  }, [
    convos,
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    guestCount,
    oppertunityFilter,
    unreadSearch,
    showFollowUp,
  ]);

  const clearSearch = () => dispatch(setSearchTerm(""));

  let pendingJobs = Array.isArray(myJobOffers)
    ? myJobOffers.filter(
        (job) => job.created_by !== user.UserID && job.job_status == 0
      )
    : [];
  let pendingConnections = Array.isArray(myConnections)
    ? myConnections.filter(
        (connect) =>
          connect.created_by !== user.UserID && connect.connection_status == 0
      )
    : [];

  return (
    <div className="message-list">
      <div
        className="d-flex p-1"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div>
          <p
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "12px",
              left: "17px",
              fontSize: "18px",
              color: "#000",
              zIndex: 999,
            }}
          >
            <FaArrowLeft
              style={{
                marginRight: "6px",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            />
          </p>
        </div>
        <h2>Messages</h2>
        <div className="plus-icon">
          <i
            className="fas fa-ellipsis-h"
            style={{ cursor: "pointer" }}
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={handleClick}
          >
            <FaPlus />
          </i>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{ style: { minWidth: "100px", borderRadius: "10px" } }}
          >
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/weddingvendors")}
              >
                Browse Vendors
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/Localcommunityads")}
              >
                Browse Ads
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/notifications/Job-Offers")}
              >
                Job Requests
                <StyledBadge
                  badgeContent={pendingJobs?.length}
                  color="primary"
                  style={{ marginLeft: "12px" }}
                />
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/notifications/Connection-requests")}
              >
                Connection Requests
                <StyledBadge
                  badgeContent={pendingConnections.length}
                  color="primary"
                  style={{ marginLeft: "12px" }}
                />
              </span>
            </MenuItem>
            {isAdmin && (
              <MenuItem
                onClick={handleClose}
                className="wc-msg-filter-menu-item"
              >
                <span
                  style={{ flexGrow: 1 }}
                  onClick={() =>
                    navigate("/dashboardpage/notifications/Review")
                  }
                >
                  Reviews
                  <StyledBadge color="primary" style={{ marginLeft: "12px" }} />
                </span>
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <SearchFilter searchTerm={searchTerm} clearSearch={clearSearch} />
      <div className="all-messages">
        {loading ? (
          <p>Loading conversations...</p> // Optional: Show a loading indicator
        ) : Array.isArray(finalConvos) && finalConvos.length > 0 ? (
          [...finalConvos]
            .sort((a, b) => {
              const dateA = a.updatedAt || a.createdAt;
              const dateB = b.updatedAt || b.createdAt;
              return new Date(dateB) - new Date(dateA);
            })
            .map((conv) => (
              <MessageItem
                key={conv.Id}
                conversation={conv}
                onSelectConversation={onSelectConversation}
              />
            ))
        ) : (
          <p>No conversations found.</p> // Optional: Handle empty state
        )}
      </div>
    </div>
  );
}

export default MessageList;
