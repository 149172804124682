// OpportunityStage.js
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import { FormControl, Menu, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setOppertunity,
  setOppertunityStage,
} from "../../../../redux/slices/MsgSlice";
import { oppertunityInbox } from "../../../../components/Header/Data3";

const OpportunityStage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const { oppertunity, oppertunityStage, selectedConvo } = useSelector(
    (state) => state.messages
  );
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  // const [oppertunityStage, setOppertunityStage] = useState(false);
  const dispatch = useDispatch();
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(false);
  };

  // const [oppertunity, setOppertunity] = useState("");
  console.log(selectedConvo, "selectedConvo");
  const addOppertunityStage = async () => {
    if (oppertunity && selectedConvo) {
      const formData = {
        messageId: selectedConvo?.Id,
        Type: oppertunity,
        createdBy:
          selectedConvo?.createdBy !== user?.UserID
            ? selectedConvo?.customerId
            : selectedConvo?.createdBy,
        receivedBy:
          selectedConvo?.createdBy === user?.UserID
            ? selectedConvo?.customerId
            : selectedConvo?.createdBy,
      };
      console.log(formData, "OppertunityStage");
      await oppertunityInbox(formData);
      dispatch(setOppertunityStage(false));
    }
  };
  useEffect(() => {
    if (oppertunityStage) {
      addOppertunityStage();
    }
  }, [oppertunityStage]);
  const handleChange = (event) => {
    dispatch(setOppertunity(event.target.value));
    dispatch(setOppertunityStage(true));
  };
  console.log(oppertunity, "oppertunityhandleChange");
  return (
    <div className="">
      <FormControl sx={{ minWidth: 135 }}>
        <Select
          value={oppertunity}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          style={{
            fontSize: "12px",
            backgroundColor: "lightgray",
          }}
          disabled={!(isAdmin || selectedConvo.createdBy === user.UserID)}
          s
        >
          <MenuItem value={0}>
            <span>Opportunity Stage</span>
          </MenuItem>
          <MenuItem value={1}>Hot Lead ❤️‍🔥</MenuItem>
          <MenuItem value={2}>Cold Lead 🥶</MenuItem>
          <MenuItem value={3}>Invoice Sent</MenuItem>
          <MenuItem value={4}>Booked</MenuItem>
          <MenuItem value={5}>Event Completed</MenuItem>
          <MenuItem value={6}>Meeting Scheduled</MenuItem>
          <MenuItem value={7}>Not Interested</MenuItem>
          <MenuItem value={8}>Booked Elsewhere</MenuItem>
          <MenuItem value={9}>Cancelled</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default OpportunityStage;
