import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Box } from "@mui/material";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { GetAllComments } from "../../../components/Header/Data3";

const CommentsList = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin";
  const { loading, showComments } = useSelector((state) => state.postForm);
  const fetchComments = async () => {
    const response = await GetAllComments(showComments.Id);
    const filteredComments = response.filter(
      (comment) => comment.commentStatus !== 2
    );
    setComments(filteredComments || []);
  };
  useEffect(() => {
    fetchComments();
  }, [showComments]);

  const updateCommentStatus = async (commentId, status) => {
    try {
      const response = await axiosClient.put(
        `Community/updateCommentStatus/${commentId}`,
        { commentStatus: status }
      );
      fetchComments();
    } catch (error) {
      console.error("Failed to update comment status:", error);
      toast.error("Failed to update comment");
    }
  };

  const handleApprove = (commentId) => updateCommentStatus(commentId, 1);
  const handleReject = (commentId) => updateCommentStatus(commentId, 3);
  const handleDelete = (commentId) => updateCommentStatus(commentId, 2);

  return (
    <Box sx={{ padding: 2 }}>
      {comments.length > 0 ? (
        comments.map((comment) => (
          <Box
            key={comment.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="body1">
              {comment.commentText || "No comment text"}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              By {comment.firstName + " " + comment.lastName || "Anonymous"} on{" "}
              {new Date(comment.createdAt).toLocaleDateString()}
            </Typography>
            {isAdmin && (
              <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                {comment.commentStatus == 0 && (
                  <>
                    <Button
                      size="small"
                      className="text-primary"
                      onClick={() => handleApprove(comment.commentId)}
                    >
                      Approve
                    </Button>
                    <Button
                      size="small"
                      className="text-danger"
                      onClick={() => handleReject(comment.commentId)}
                    >
                      Reject
                    </Button>
                  </>
                )}
                <Button
                  size="small"
                  className="text-secondary"
                  onClick={() => handleDelete(comment.commentId)}
                >
                  Delete
                </Button>
              </Box>
            )}

            {comment.commentStatus === 1 && (
              <Typography variant="caption" color="green">
                Approved
              </Typography>
            )}
            {comment.commentStatus === 3 && (
              <Typography variant="caption" color="red">
                Rejected
              </Typography>
            )}
          </Box>
        ))
      ) : (
        <Typography>No comments available for this post.</Typography>
      )}
    </Box>
  );
};

export default CommentsList;
