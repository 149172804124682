import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import { Button, Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveTemplates from "./ActiveTemplates";
import PendingTemplates from "./PendingTemplates";
import { BiPlusCircle } from "react-icons/bi";
import CreateTemplate from "./CreateTemplate";
import { FadeLoader } from "react-spinners";
import templateImage from "../../../assets/images/img/message-template.png";
import "./Templates.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTemplates,
  setActiveTemps,
  setMyTemplates,
  setPendingTemps,
} from "../../../redux/slices/TemplateSlice";
const MyTemplates = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [activeTab, setActiveTab] = useState("Active");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { AllTemplates, loading, error } = useSelector(
    (state) => state.templates
  );
  const [templates, setTemplates] = useState([]);

  // Toggle Modal
  const toggleModal = (withTab = false) => {
    setIsModalOpen((prev) => !prev);
    if (!isModalOpen && withTab) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", "add-template");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else if (isModalOpen) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("tab");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };
  useEffect(() => {
    if (islogin) {
      dispatch(fetchTemplates());
    }
  }, []);
  const filterTemplates = () => {
    if (!AllTemplates) return { pending: [], active: [], myTemplates: [] };
    let myTemplates = [];
    let pendingTemplates = [];
    let activeTemplates = [];
    if (isAdmin) {
      myTemplates = AllTemplates;
      pendingTemplates = AllTemplates.filter(
        (template) => template.template_status == 0 // Assuming 0 indicates pending
      );
      activeTemplates = AllTemplates.filter(
        (template) => template.template_status == 1 // Assuming 1 indicates active
      );
    } else {
      myTemplates = AllTemplates.filter(
        (template) => template.createdBy == user?.UserID // Filter myTemplates by createdBy === user.UserID
      );
      pendingTemplates = myTemplates.filter(
        (template) => template.template_status == 0 // Assuming 0 indicates pending
      );
      activeTemplates = myTemplates.filter(
        (template) => template.template_status == 1 // Assuming 1 indicates active
      );
    }
    return {
      pending: pendingTemplates,
      active: activeTemplates,
      myTemplates: myTemplates,
    };
  };
  const { pending, active, myTemplates } = filterTemplates();
  useEffect(() => {
    if (islogin) {
      dispatch(setPendingTemps(pending));
      dispatch(setActiveTemps(active));
      dispatch(setMyTemplates(myTemplates));
    }
  }, [AllTemplates]);
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9}>
            {loading && (
              <FadeLoader
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={loading}
                className="position-absolute top-50 start-50 translate-middle"
              />
            )}
            {AllTemplates && AllTemplates.length > 0 ? (
              <div className="template-container py-2">
                <div className="d-flex justify-content-end">
                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => toggleModal(true)} // Open modal and update URL
                  >
                    <BiPlusCircle /> Add New
                  </Button>
                </div>
                <div
                  className="col-lg-6 d-flex row mt-3 view-tabs w-100"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "Active" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("Active");
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("tab", "active");
                      navigate(
                        {
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    style={{
                      textDecoration:
                        activeTab === "Active" ? "underline" : "none",
                      cursor: "pointer",
                      width: "50%",
                    }}
                  >
                    Active Templates
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "Pending" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("Pending");
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("tab", "pending");
                      navigate(
                        {
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    style={{
                      textDecoration:
                        activeTab === "Pending" ? "underline" : "none",
                      cursor: "pointer",
                      width: "50%",
                    }}
                  >
                    Pending Templates
                  </h5>
                </div>
                {activeTab === "Active" && <ActiveTemplates />}
                {activeTab === "Pending" && <PendingTemplates />}
              </div>
            ) : (
              !loading && (
                <div className="no-templates">
                  <img
                    src={templateImage}
                    alt="bags"
                    style={{ width: "10rem" }}
                  />
                  <h3 className="mb-4">
                    You haven't created any templates yet
                  </h3>
                  <p className="mb-4">Create a template to get started.</p>
                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => toggleModal(true)} // Open modal and update URL
                  >
                    <BiPlusCircle /> Add New
                  </Button>
                </div>
              )
            )}
          </Col>
        </Row>
      </div>
      {/* Create Template Modal */}
      <CreateTemplate open={isModalOpen} handleClose={() => toggleModal()} />
    </section>
  );
};

export default MyTemplates;
