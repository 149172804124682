import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  FormGroup,
  Input,
} from "@mui/material";
import Helmet from "../../../components/Helmet/Helmet";
import { Col, Label, Row } from "reactstrap";
import Sidebar from "../../SideBar";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { getAllWebsites } from "../../../redux/slices/ComWebSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import uploadToS3 from "../../../utils/awsS3Upload";
import { FaPlusCircle } from "react-icons/fa";

const CreateWebSite = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [editSite, setEditSite] = useState(null);
  const [imageFile, setImageFile] = useState(null); // Changed to null for consistency
  const [errors, setErrors] = useState({}); // State for validation errors

  const initialState = {
    Url: "",
    Title: "",
    Description: "",
    createdBy: user?.UserID,
    status: 0,
    Image: "",
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Description") {
      const maxLength = 120;
      if (value.length > maxLength) {
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleImageChange = () => {
    const files = document.getElementById("imageUpload").files;
    if (files.length > 0) {
      const file = files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({ ...prev, Image: reader.result }));
        setErrors((prev) => ({ ...prev, Image: "" })); // Clear image error
      };
      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.Title.trim()) tempErrors.Title = "Title is required";
    if (!formData.Url.trim()) tempErrors.Url = "URL is required";
    if (!formData.Description.trim())
      tempErrors.Description = "Description is required";
    if (!editSite && !imageFile) tempErrors.Image = "Image is required"; // Only for create mode

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields");
      return;
    }

    let imageUrl = editSite ? formData.Image : ""; // Keep existing URL in edit mode

    if (imageFile) {
      try {
        const folder = "website-image/";
        imageUrl = await uploadToS3(imageFile, folder);
      } catch (error) {
        console.error("Error uploading image to S3:", error);
        toast.error("Image upload failed. Please try again.");
        return;
      }
    }

    if (editSite) {
      const { websiteStatus, ...restFormData } = formData;
      const req = {
        ...restFormData,
        Image: imageUrl,
        status: 0,
        updatedBy: user?.UserID,
      };

      try {
        const response = await axiosClient.put(
          `ads/UpdateCommunityWebsite/${editSite.id}`,
          req
        );
        if (response.data.statusCode === 200) {
          toast.success("Website updated successfully");
          dispatch(getAllWebsites());
          setFormData(initialState);
          setImageFile(null);
          setEditSite(null);
          navigate(-1); // Optional: navigate back after success
        } else {
          toast.error("Failed to update website");
        }
      } catch (error) {
        console.error("Failed to update website", error);
        toast.error("Failed to update website");
      }
    } else {
      const req = {
        ...formData,
        Image: imageUrl,
      };
      try {
        const response = await axiosClient.post("ads/AddCommunityWebsite", req);
        if (response.data.statusCode === 200) {
          toast.success(`${formData.Title} Created successfully`);
          setFormData(initialState);
          setImageFile(null);
          dispatch(getAllWebsites());
          navigate(-1); // Optional: navigate back after success
        } else {
          toast.error("Failed to create website");
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to create website");
      }
    }
  };

  useEffect(() => {
    if (location.state?.site) {
      setEditSite(location.state.site);
      setFormData(location.state.site);
    }
  }, [location.state]);

  return (
    <Helmet title="My Community Web Sites">
      <section className="home-after-login bg-white">
        <div className="container">
          <Row>
            <Col lg={3}>
              <Sidebar />
            </Col>
            <Col lg={9}>
              <div className="p-2 mt-3">
                <Typography
                  align="center"
                  color="secondary"
                  variant="h5"
                  component="h1"
                  gutterBottom
                >
                  {editSite ? "Edit Website" : "Create New Website"}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Title"
                    name="Title"
                    value={formData.Title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.Title}
                    helperText={errors.Title}
                  />
                  <TextField
                    label="URL"
                    name="Url"
                    value={formData.Url}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.Url}
                    helperText={errors.Url}
                  />
                  <TextField
                    label="Description"
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.Description}
                    helperText={errors.Description}
                  />
                  <div>
                    <FormGroup>
                      <Label for="imageUpload" className="m-0">
                        Website Image<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        id="imageUpload"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        name="image"
                      />
                    </FormGroup>
                    <div
                      className={`image-box ${
                        formData.Image
                          ? "image-box-uploaded"
                          : "image-box-initial"
                      }`}
                      onClick={() =>
                        document.getElementById("imageUpload").click()
                      }
                    >
                      {formData.Image ? (
                        <div className="image-preview">
                          <img
                            src={formData.Image}
                            alt="Uploaded Image"
                            className="uploaded-image"
                            onMouseOver={(e) =>
                              (e.target.style.transform = "scale(1.05)")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.transform = "scale(1)")
                            }
                          />
                        </div>
                      ) : (
                        <>
                          <FaPlusCircle className="upload-icon" />
                        </>
                      )}
                    </div>
                    {errors.Image && (
                      <Typography color="error" variant="caption">
                        {errors.Image}
                      </Typography>
                    )}
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {editSite ? "Update Website" : "Create Website"}
                  </Button>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Helmet>
  );
};

export default CreateWebSite;
