import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./Posts.css"; // Import external styles
import { useSelector } from "react-redux";
import MyCommunityCard from "./MyCommunityCard";


const MyCommunityProjects = () => {
  const { communityList, loading, error } = useSelector(
    (state) => state.postForm
  );
  const [activeExpanded, setActiveExpanded] = useState(false);
  const [pendingExpanded, setPendingExpanded] = useState(false);
  const projects = communityList?.filter(
    (post) => post.postType === "communityProjects"
  );
  const activeProjects = projects.filter((post) => post.communityStatus == 1);
  const pendingProjects = projects.filter((post) => post.communityStatus == 0);

  return (
    <div>
      <div
        className="category-header"
        onClick={() => setActiveExpanded(!activeExpanded)}
      >
        <h5 className={`category-title ${activeExpanded ? "active" : ""}`}>
          Active Projects
          {activeExpanded ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </h5>
      </div>
      {activeExpanded && (
        <div className="news-grid">
          {activeProjects.length > 0 ? (
            <MyCommunityCard myPosts={activeProjects} />
          ) : (
            <h6 className="no-posts">No Active Projects</h6>
          )}
        </div>
      )}
      <div
        className="category-header mt-1"
        onClick={() => setPendingExpanded(!pendingExpanded)}
      >
        <h5
          className={`category-title pending ${
            pendingExpanded ? "expanded" : ""
          }`}
        >
          Pending Projects
          {pendingExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h5>
      </div>
      {pendingExpanded && (
        <div className="news-grid">
          {pendingProjects.length > 0 ? (
            <MyCommunityCard myPosts={pendingProjects} />
          ) : (
            <h6 className="no-posts">No Pending Projects</h6>
          )}
        </div>
      )}
    </div>
  );
};

export default MyCommunityProjects;
