import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar"; // Added import
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CommentOnPost,
  GetAllComments,
  GetLikes,
  LikeThePost,
} from "../../Data3";

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "lg",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "12px",
  background: "linear-gradient(145deg, #ffffff, #f8f9fa)",
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

// export const MediaWrapper = styled(Box)(({ theme }) => ({
//   maxWidth: "300px",
//   margin: "0 auto",
//   borderRadius: "12px",
//   overflow: "hidden",
//   boxShadow: theme.shadows[4],
//   backgroundColor: "#fff",
// }));

export const VideoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: "56.25%", // Maintains 16:9 aspect ratio
  maxHeight: "200px", // Reduced from 250px
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    maxHeight: "150px", // Reduced from 200px
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "100px", // Reduced from 150px
  },
}));

// export const ImageContainer = styled(Box)(({ theme }) => ({
//   position: "relative",
//   maxHeight: "350px",
//   [theme.breakpoints.down("md")]: {
//     maxHeight: "250px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     maxHeight: "200px",
//   },
// }));

// export const ScrollButton = styled(IconButton)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   transform: "translateY(-50%)",
//   backgroundColor: "rgba(255, 255, 255, 0.9)",
//   color: theme.palette.primary.main,
//   "&:hover": {
//     backgroundColor: "rgba(255, 255, 255, 1)",
//   },
//   boxShadow: theme.shadows[2],
// }));

// Lazy-loaded Components
const MediaSection = React.lazy(() => import("./MediaSection"));
const ActionsSection = React.lazy(() => import("./ActionsSection"));
const CommentSection = React.lazy(() => import("./CommentSection"));

const PostDetails = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const location = useLocation();
  const navigate = useNavigate();
  const post = location.state;
  // const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [showCommentBox, setShowCommentBox] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [allLikes, setAllLikes] = React.useState([]);
  const [allComments, setAllComments] = React.useState([]);

  const images = React.useMemo(() => {
    if (!post || !post.galleryImage) return [];
    try {
      return typeof post.galleryImage === "string"
        ? JSON.parse(post.galleryImage)
        : post.galleryImage || [];
    } catch (e) {
      console.error("Error parsing galleryImage:", e);
      return [];
    }
  }, [post?.galleryImage]);

  const handleLikeClick = React.useCallback(async () => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
      likeStatus: allLikes?.userLiked ? 0 : 1,
    };
    try {
      const response = await LikeThePost(req);
      if (response.statusCode === 200) {
        await fetchLikes();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("Error liking post:", error);
    }
  }, [post, user, allLikes]);

  const fetchLikes = React.useCallback(async () => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
    };
    const response = await GetLikes(req);
    setAllLikes(response);
  }, [post, user]);

  const fetchComments = React.useCallback(async () => {
    const response = await GetAllComments(post.Id);
    const filterActive = response.filter(
      (comment) => comment.commentStatus === 1
    );
    setAllComments(filterActive);
  }, [post]);

  const handleCommentSubmit = React.useCallback(async () => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
      commentText: comment,
    };
    try {
      await CommentOnPost(req);
      setComment("");
      setShowCommentBox(false);
      fetchComments();
      toast.success("Comment Sent For Admin Approval");
    } catch (error) {
      toast.error("Error posting comment:", error);
    }
  }, [post, user, comment, fetchComments]);

  React.useEffect(() => {
    fetchLikes();
    fetchComments();
  }, [fetchLikes, fetchComments]);

  const isHtml = (str) => /<[a-z][\s\S]*>/i.test(str);

  // const handlePrevImage = () => {
  //   setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  // };

  // const handleNextImage = () => {
  //   setCurrentImageIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  // };

  if (!post) {
    return (
      <StyledContainer>
        <Typography variant="h6" color="error">
          No post data available
        </Typography>
        <IconButton onClick={() => navigate(-1)} sx={{ mt: 2 }}>
          <ArrowBackIcon />
        </IconButton>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledPaper>
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              flexGrow: 1,
              background: "linear-gradient(45deg, #1976d2, #42a5f5)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {post.Title || "Untitled"}
          </Typography>
        </Box>

        {/* User Info */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Avatar
            sx={{ width: 48, height: 48, mr: 2 }}
            src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
          />
          <Box>
            <Typography variant="subtitle1" fontWeight="medium" >
              {post?.firstName + " " + post?.lastName || "Creator"}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {post.createdAt
                ? new Date(post.createdAt).toLocaleDateString()
                : "Sep 14, 2016"}
            </Typography>
          </Box>
        </Box>

        {/* Description */}
        <Box sx={{ mb: 3 }}>
          {post.Description ? (
            isHtml(post.Description) ? (
              <Typography
                variant="body1"
                sx={{ wordBreak: "break-word" }}
                dangerouslySetInnerHTML={{ __html: post.Description }}
              />
            ) : (
              <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                {post.Description}
              </Typography>
            )
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              fontStyle="italic"
            >
              No description available
            </Typography>
          )}
        </Box>

        {/* Lazy-loaded Sections */}
        <React.Suspense fallback={<Typography>Loading...</Typography>}>
          <MediaSection post={post} images={images} />
          <ActionsSection
            allLikes={allLikes}
            allComments={allComments}
            handleLikeClick={handleLikeClick}
            setShowCommentBox={setShowCommentBox}
            showCommentBox={showCommentBox}
          />
          <CommentSection
            showCommentBox={showCommentBox}
            comment={comment}
            setComment={setComment}
            handleCommentSubmit={handleCommentSubmit}
            allComments={allComments}
            setShowCommentBox={setShowCommentBox} // Added prop
          />
        </React.Suspense>
      </StyledPaper>
    </StyledContainer>
  );
};

export default PostDetails;
