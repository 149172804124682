//Conversations
import React, { useEffect, useRef, useState } from "react";
import "./messages.css";
import { useDispatch, useSelector } from "react-redux";
import { FcVideoCall } from "react-icons/fc";
import moment from "moment-timezone";
import {
  // FaCheck,
  FaCheckDouble,
  // FaPaperPlane,
  FaRegClock,
  FaRegPaperPlane,
  FaRegTrashAlt,
} from "react-icons/fa";
import paypalLogo from "../../../assets/images/paypal-logo.png";
// import { useSocket } from "../../../contexts/SocketContext";
// import { MessageSeen } from "../../../components/Header/Data2";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import Payment from "../settingsComponents/Payment";
import { setChildId, setOrder } from "../../../redux/slices/MsgSlice";
import { Menu, MenuItem } from "@mui/material";
import { scheduleMessage } from "../../../components/Header/Data3";
import MsgScheduleModal from "../../../components/Header/modals/MsgScheduleModal";
import { useSocket } from "../../../contexts/SocketContext";

function Conversation({ message, isChatModal, fetchMsgs, fetchAllMsg }) {
  const { sendNewMessage } = useSocket();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const userId = user?.UserID;
  const [anchorEl, setAnchorEl] = useState(null);
  const [reScheduleModal, setRescheduleModal] = useState(false);
  const dispatch = useDispatch();
  const { selectedConvo } = useSelector((state) => state.messages);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = async (type) => {
    if (type === "Edit") {
      setRescheduleModal(true);
    } else {
      const messageData = {
        messageId: message.Id,
        mType: type,
      };
      await scheduleMessage(messageData);
      fetchMsgs();
      fetchAllMsg();
      sendNewMessage(message);
    }

    handleClose();
  };
  function renderAttachment() {
    const photoUrl = message.Photo;
    if (!photoUrl) return null;

    const fileExtension = photoUrl?.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "webp"].includes(fileExtension)) {
      return (
        <img
          src={photoUrl}
          alt="attachment"
          className="attachment-image"
          style={{ width: "200px", height: "auto" }} // Adjust size as needed
        />
      );
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      return (
        <video
          controls
          className="attachment-video"
          style={{ width: "300px", height: "auto" }} // Adjust size as needed
        >
          <source src={photoUrl} type={`video/${fileExtension}`} />
        </video>
      );
    } else if (fileExtension === "pdf") {
      return (
        <div>
          <iframe
            src={photoUrl}
            width="100%"
            height="500" // Adjust height as needed
            frameBorder="0"
            scrolling="no"
          />
        </div>
      );
    }
    return null;
  }
  // function convertToUserTimezone(utcTime) {
  //   let timezone = "America/Los_Angeles"; // Default to system timezone
  //   if (islogin && user?.TimeZone) {
  //     timezone = user?.TimeZone.split(",")[0];
  //   }
  //   const localTime = moment
  //     .utc(utcTime)
  //     .tz(timezone)
  //     .format("YYYY-MM-DD HH:mm:ss");
  //   return localTime;
  // }
  function getMessageStatus() {
    if (
      message.createdBy == userId ||
      (isAdmin && message.messageStatus == 13)
    ) {
      if (message.seenCustomer == 1) {
        return <FaCheckDouble style={{ color: "red" }} title="Read" />;
      }
      return <FaCheckDouble style={{ color: "gray" }} title="Delivered" />;
    }
    return null;
  }
  const userTimeZone = moment.tz.guess();
  const [paymentDetails, setPaymentDetails] = useState({
    description: "",
    amount: "",
    status: "",
  });
  let paymentDescription;
  let PaymentStatus;
  let totalPrice;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const eventListenerAdded = useRef(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  // const [pendingState, setPendingState] = useState("");
  const handlePendingPayment = (event) => {
    const messageId = event.target.id.replace("pendingPayment-", "");
    const clickedMessage = message.Id === parseInt(messageId);
    if (clickedMessage) {
      const paymentDetails = message.Message.replace(
        "payment for chat with",
        ""
      )
        .trim()
        .split(",")
        .map((item) => item.trim());
      setPaymentDetails({
        description: paymentDetails[0],
        amount: paymentDetails[1],
        status: paymentDetails[2],
      });
      setModalIsOpen(true);
      dispatch(setOrder(message?.orderId));
      dispatch(setChildId(message?.Id));
    }
  };

  useEffect(() => {
    // Add the event listener only once per message and user
    const pendingPayments = document.querySelectorAll(
      '[id^="pendingPayment-"]'
    );

    if (message?.createdBy === user?.UserID && !eventListenerAdded.current) {
      // setPendingState(pendingPayments);
      pendingPayments.forEach((element) => {
        element.addEventListener("click", handlePendingPayment);
      });
    }

    // Clean up listeners when the component unmounts or re-renders
    return () => {
      pendingPayments.forEach((element) => {
        element.removeEventListener("click", handlePendingPayment);
      });
    };
  }, [modalIsOpen]); // Dependencies: message or user changes trigger the effect

  useEffect(() => {
    if (message?.orderStatus === "COMPLETED") {
      setModalIsOpen(false);
    }
  }, [message?.orderStatus]);
  console.log(selectedConvo, "selectedConvo");
  const providerZone = selectedConvo?.providerTimezone;
  const CustZone = selectedConvo?.customerTimezone;

  const proTime = providerZone?.split(",")[0];
  const custTime = CustZone?.split(",")[0];

  const proZone = providerZone?.split(",")[1];
  const custZone = CustZone?.split(",")[1];

  console.log(proZone, custZone, "proZone");

  const timeZoneFormat = (newDate, timeZone1, timeZone2) => {
    let protimelast, custtimelast;
    if (user?.UserID === message?.customerId) {
      const protime = moment.tz(newDate, timeZone1);
      protimelast = protime.format("MMMM D, YYYY [at] hh:mm A");
      const custtime = moment.tz(protime, timeZone2);
      custtimelast = custtime.format("MMMM D, YYYY [at] hh:mm A");
      console.log(protimelast, custtimelast, "@@@@@@@@@");
    } else {
      const protime = moment.tz(newDate, timeZone2);
      protimelast = protime.format("MMMM D, YYYY [at] hh:mm A");
      const custtime = moment.tz(protime, timeZone1);
      custtimelast = custtime.format("MMMM D, YYYY [at] hh:mm A");
      console.log(protimelast, custtimelast, "##############");
    }
    return { protimelast, custtimelast };
  };
  // const custDateTime = moment(
  //   response.custtimelast,
  //   "MMMM DD, YYYY [at] hh:mm A"
  // );
  // const custoDate = custDateTime.format("YYYY-MM-DD");
  // const custoTime = custDateTime.format("HH:mm");
  // Parse and format the professional's date and time
  // const proDateTime = moment(
  //   response.protimelast,
  //   "MMMM DD, YYYY [at] hh:mm A"
  // );
  // const provDate = proDateTime.format("YYYY-MM-DD");
  // const provTime = proDateTime.format("HH:mm");

  return (
    <div
      className={`chat-message ${
        message.createdBy === userId || (isAdmin && message.messageStatus == 13)
          ? "own"
          : "other"
      } `}
    >
      <div
        className={`message-content ${
          message.messageStatus == 0 ? "scheduled-msg" : ""
        }`}
      >
        <div className="avatar d-flex align-items-center mb-1 position-relative">
          <img
            // src={
            //   message?.customerId !== user?.UserID
            //     ? message?.providerProfilePhoto &&
            //       message?.providerProfilePhoto.trim() !== ""
            //       ? message.providerProfilePhoto
            //       : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
            //     : message?.customerProfilePhoto &&
            //       message?.customerProfilePhoto.trim() !== ""
            //     ? message.customerProfilePhoto
            //     : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
            // }
            src={
              message?.createdBy !== message.customerId
                ? message?.providerProfilePhoto &&
                  message?.providerProfilePhoto.trim() !== ""
                  ? message.providerProfilePhoto
                  : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
                : message?.customerProfilePhoto &&
                  message?.customerProfilePhoto.trim() !== ""
                ? message.customerProfilePhoto
                : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
            }
            alt="Avatar"
            className="rounded-circle border border-1 border-secondary bg-light avatar shadow p-1 d-inline-block"
            width={30}
            height={30}
          />
          <div className="sender mt-1" style={{ fontSize: "14px" }}>
            {message?.senderName}
          </div>
          {message?.messageStatus == 0 && (
            <div
              className="position-absolute end-0 me-2"
              style={{ cursor: "pointer" }}
              onClick={(event) => handleMenuClick(event)}
            >
              ⋮
            </div>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                minWidth: "100px", // Adjust width as needed
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem className=".wc-msg-menu-item">
              <FaRegPaperPlane className="me-2" />
              <span
                style={{ flexGrow: 1 }}
                onClick={() => handleChange("Send")}
              >
                Send Now
              </span>
            </MenuItem>
            <MenuItem className="wc-msg-menu-item">
              <FaRegClock className="me-2" />
              <span
                style={{ flexGrow: 1 }}
                onClick={() => handleChange("Edit")}
              >
                Change Time
              </span>
            </MenuItem>
            <MenuItem className="wc-msg-menu-item">
              <FaRegTrashAlt className="me-2" />
              <span
                style={{ flexGrow: 1 }}
                className="bg-danger text-white rounded p-1 text-center"
                onClick={() => handleChange("Delete")}
              >
                Delete
              </span>
            </MenuItem>
          </Menu>
        </div>

        {message?.postType != 4 && (
          <p
            className="my-2 p-0 text-dark"
            style={{ fontSize: "medium" }}
            dangerouslySetInnerHTML={{
              __html: (() => {
                const messageText =
                  message.Message ||
                  message.messageComment ||
                  message.giftDescription ||
                  message.Description ||
                  "";

                // Determine the name based on the condition selectedcon?.createdby === user.userid
                const name =
                  message?.createdBy === user?.UserID
                    ? message?.customerName
                    : message?.senderName;

                // Check if the message contains "Click to join zoom meeting"
                if (messageText.includes("Click to join zoom meeting")) {
                  // Insert the condition after the "Click to join zoom meeting"
                  const zoomMessage = messageText.replace(
                    "Click to join zoom meeting",
                    `Click to join zoom meeting with ${message?.postName}`
                  );

                  // Render the updated message with the zoom link
                  return zoomMessage;
                }

                if (messageText.startsWith("My Business Profile")) {
                  // Split the remaining part based on commas
                  const detailsArray = messageText
                    .split(",")
                    .map((item) => item.trim());

                  // Extract values from the detailsArray
                  const description = detailsArray[0];
                  const userId = detailsArray[1];
                  // Generate  HTML with dynamic values
                  const htmlContent = `Check my Profile page<br/><a href="/#/userDetails/${userId}" target="_blank" style="text-decoration: underline !important;"> View Profile</a>`;
                  // const htmlContent = `Check My BusinessProfile<br/><a href="/#/vendorDetails/${userId}" target="_blank" style="text-decoration: underline !important;"> View Profile</a>`;
                  // Now, htmlContent contains the HTML structure with dynamic values inserted
                  // Render the updated message with the zoom link
                  return htmlContent;
                }

                if (
                  messageText.startsWith("Call Scheduled for") ||
                  messageText.startsWith("Call Rescheduled for")
                ) {
                  const dateRegex = /for (\d{4}-\d{2}-\d{2}) at/;
                  const timeRegex = /at (\d{2}:\d{2}) for/;
                  const zoomLinkRegex = /with (.*)$/;

                  const dateMatch = messageText.match(dateRegex);
                  const timeMatch = messageText.match(timeRegex);
                  const zoomLinkMatch = messageText.match(zoomLinkRegex);

                  const extractedData = {
                    date: dateMatch && dateMatch[1],
                    time: timeMatch && timeMatch[1],
                    zoomLink: zoomLinkMatch && zoomLinkMatch[1],
                  };
                  const dateTime = `${extractedData.date} ${extractedData.time}`;
                  console.log(dateTime, proTime, custTime, "dateTime");
                  const response = timeZoneFormat(dateTime, proTime, custTime);
                  console.log(
                    "custZone before using in string:",
                    proZone,
                    custZone
                  );
                  console.log("messageText", response);

                  const htmlContent = `Call ${
                    messageText?.includes("Rescheduled")
                      ? "Rescheduled"
                      : "Scheduled"
                  } for ${
                    user?.UserID === message?.customerId
                      ? response.custtimelast
                      : response.protimelast
                  } ${
                    message?.createdBy === user?.UserID ? proZone : custZone
                  } for 30 mins. <br/> Click to join zoom meeting <br/>  <a href="${
                    extractedData?.zoomLink
                  }" target="_blank"  style="
                    padding: 5px 15px;
                    border-radius: 6px;
                    border: 1px solid;
                    background-color: #0D6EFD;
                    color: white;
                  "> Join Call</a> <br/> Do you approve?`;

                  // let htmlContent;
                  // if (message?.zoomStatus === 3) {
                  //   if (message?.zoomRole === user?.UserID) {
                  //     htmlContent = `<div style=""><div style="display:flex;align-items:center;flex-direction: ${
                  //       window.innerWidth < 768 ? "column" : "row"
                  //     }">
                  //     <img src=${zoomLogo} style="height:100px;width:100px"/><div style="display:grid;margin-left:10px"><div style="font-weight:bold;font-size:16px">Call Scheduled for ${
                  //       response.protimelast
                  //     } ${
                  //       message?.zoomRole !== message?.createdBy
                  //         ? custZone
                  //         : proZone
                  //     } for 30 mins.</div><div><a href="${
                  //       extractedData?.zoomLink
                  //     }" target="_blank" style="text-decoration: underline !important;">Click here to join Zoom meeting</a></div><div>Do you approve?</div></div></div></div>`;
                  //   } else if (user.RoleId !== 1) {
                  //     htmlContent = `<div style=""><div style="display:flex;align-items:center;flex-direction: ${
                  //       window.innerWidth < 768 ? "column" : "row"
                  //     }">
                  //     <img src=${zoomLogo} style="height:100px;width:100px"/><div style="display:grid;margin-left:10px"><div style="font-weight:bold;font-size:16px">Call Scheduled for ${
                  //       response.custtimelast
                  //     } ${
                  //       message?.zoomRole !== message?.createdBy
                  //         ? proZone
                  //         : custZone
                  //     } for 30 mins.</div><div><a href="${
                  //       extractedData?.zoomLink
                  //     }" target="_blank" style="text-decoration: underline !important;">Click here to join Zoom meeting</a></div><div>Do you approve?</div></div></div></div>`;
                  //   } else {
                  //     htmlContent = `<div style=""><div style="display:flex;align-items:center;flex-direction: ${
                  //       window.innerWidth < 768 ? "column" : "row"
                  //     }"><img src=${zoomLogo} style="height:100px;width:100px"/><div style="display:grid; margin-left:10px"><div style="font-weight:bold;font-size:16px">Call Scheduled for ${
                  //       response.protimelast
                  //     } ${
                  //       message?.zoomRole !== message?.createdBy
                  //         ? custZone
                  //         : proZone
                  //     } for 30 mins.</div><div><a href="${
                  //       extractedData?.zoomLink
                  //     }" target="_blank" style="text-decoration: underline !important;">Click here to join Zoom meeting</a></div><div>Do you approve?</div></div></div></div>`;
                  //   }
                  // } else {
                  //   htmlContent = `<div style=""><div style="display:flex; align-items:center;flex-direction: ${
                  //     window.innerWidth < 768 ? "column" : "row"
                  //   }"><img src=${zoomLogo} style="height:100px;width:100px"/><div style="display:grid;margin-left:10px"><div style="font-weight:bold;font-size:16px">Call Scheduled for ${
                  //     response.protimelast
                  //   } ${
                  //     message?.zoomRole !== message?.createdBy
                  //       ? custZone
                  //       : proZone
                  //   } for 30 mins.</div><div><a href="${
                  //     extractedData?.zoomLink
                  //   }" target="_blank" style="text-decoration: underline !important;">Click here to join Zoom meeting</a></div><div>Do you approve?</div></div></div></div>`;
                  // }

                  return htmlContent;
                  // return htmlContent;
                }

                // Example messageText: "payment for chat with Payment, 400, COMPLETED"
                if (messageText.startsWith("payment for chat with")) {
                  // Remove the "payment for chat with" part
                  const paymentDetails = messageText
                    .replace("payment for chat with", "")
                    .trim();

                  // Split the remaining part based on commas
                  const detailsArray = paymentDetails
                    .split(",")
                    .map((item) => item.trim());

                  // Extract values from the detailsArray
                  paymentDescription = detailsArray[0]; // Payment Type
                  totalPrice = detailsArray[1]; // 400
                  PaymentStatus = detailsArray[2]; // COMPLETED

                  // Generate the HTML with dynamic values
                  const htmlContent = `<div style="display:flex;align-items:center;height:80px"><img src="${paypalLogo}" style="height:60px;width:60px"/><div style="margin-left:10px"><div style="font-weight:bold;font-size:23px">$ ${
                    message?.totalAmount
                  }</div><div>${
                    message?.orderMessage?.length > 30
                      ? `${message?.orderMessage.slice(0, 30)}...`
                      : message?.orderMessage
                  }</div><div>Payment Status: <span style="font-weight:bold; cursor:${
                    message?.createdBy === user?.UserID &&
                    message?.orderStatus !== "COMPLETED"
                      ? "pointer"
                      : "auto"
                  };color:${
                    message?.createdBy === user?.UserID
                      ? message?.orderStatus === "COMPLETED"
                        ? "#198754"
                        : "blue"
                      : message?.orderStatus === "COMPLETED"
                      ? "#198754"
                      : "black"
                  };text-decoration:${
                    message?.createdBy === user?.UserID &&
                    message?.orderStatus !== "COMPLETED"
                      ? "underline"
                      : "none"
                  }">${
                    message?.orderStatus === "PENDING"
                      ? `<span id="pendingPayment-${message.Id}">PENDING</span>`
                      : message?.orderStatus
                  }</span></div></div></div>`;
                  return htmlContent;
                }
                // Default case: Just render the message normally with the name.
                return messageText;
              })(),
            }}
          />
        )}
        {message?.postType == 4 && (
          <div>
            <p
              className="my-2 p-0 text-dark attachment-title"
              style={{ fontSize: "medium" }}
              dangerouslySetInnerHTML={{
                __html:
                  message.Message ||
                  message.messageComment ||
                  message.giftDescription ||
                  "",
              }}
            />
            {renderAttachment()}
          </div>
        )}
        <div
          className={`time text-dark mt-1 ${
            message.createdBy === userId ? "me-1" : "ms-1"
          }`}
          style={{
            textAlign:
              message.createdBy === userId ||
              (isAdmin &&
                (message.messageStatus === 13 || message.messageStatus === 14))
                ? "right"
                : "left",
          }}
        >
          {message.messageStatus == 0 &&
          message.scheduleDate &&
          message.scheduleTime ? (
            <span className="me-2">
              {message.messageStatus == 0 && "Scheduled For"} &nbsp;
              {moment
                .utc(`${message.scheduleDate}T${message.scheduleTime}:00Z`) // Ensure UTC format
                .tz(userTimeZone)
                .format("MMMM DD, YYYY hh:mm A")}
            </span>
          ) : (
            <span className="me-2">
              {moment
                .utc(message?.createdAt) // Ensure UTC before converting
                .tz(userTimeZone)
                .format("MMMM DD, YYYY hh:mm A")}
            </span>
          )}
          {message.messageStatus == 0 ? (
            <FaRegClock />
          ) : (
            <span className="ms-2 message-status">{getMessageStatus()}</span>
          )}
        </div>
        {message.messageStatus == 13 && (
          <div
            style={{
              textAlign:
                message.createdBy === userId ||
                (isAdmin && message.messageStatus == 13)
                  ? "right"
                  : "left",
              fontSize: "xx-small",
            }}
            className="mx-3 text-danger"
          >
            Admin Sending As Vendor
          </div>
        )}
        {message.messageStatus == 14 && (
          <div
            style={{
              textAlign:
                message.createdBy === userId ||
                (isAdmin && message.messageStatus == 14)
                  ? "right"
                  : "left",
              fontSize: "xx-small",
            }}
            className="mx-3 text-danger"
          >
            Admin scheduled As Vendor
          </div>
        )}
      </div>
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalBody>
          <div>
            <Label>
              Description <b className="text-danger">*</b>
            </Label>
            <Input
              type="textarea"
              name="descrption"
              placeholder="Please Enter Descrption"
              value={paymentDetails.description}
              // onChange={(e) => dispatch(setDescription(e.target.value))}
              disabled
            />
          </div>
          <div>
            <Label>Amount</Label>
            <InputGroup>
              <InputGroupText className="fw-bold">$</InputGroupText>
              <Input
                type="number"
                name="amount"
                placeholder="amount"
                value={paymentDetails.amount}
                // onChange={handleAmountChange}
                disabled
              />
            </InputGroup>
            <div>
              <Payment
                totalPrice={Number(paymentDetails.amount)}
                state={{ chatPay: true }}
                chatDetails={true}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      {reScheduleModal && (
        <MsgScheduleModal
          open={reScheduleModal}
          close={() => setRescheduleModal(false)}
          msg={selectedConvo}
          isChatModal={isChatModal}
          fetchMsgs={fetchMsgs}
          fetchAllMsg={fetchAllMsg}
          editMsg={message}
        />
      )}
    </div>
  );
}

export default Conversation;
