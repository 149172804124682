import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import { orderInvoice } from "../Data3";
import moment from "moment-timezone";
import {
  Checkbox,
  checkboxClasses,
  colors,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { IoDocumentAttachSharp } from "react-icons/io5";
import DealFactors from "../WeddingVendors/DealFactors";
import { FaRegEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import {
  setInvoiceCreatedPackages,
  setInvoiceItemsToDisplay,
  setInvoiceOptions,
  setNewCreatedPackages,
  setNewItemsToDisplay,
  setNewOptions,
  setSelectedPackages,
  setSelectedPrice,
} from "../../../redux/slices/MsgSlice";
import uploadToS3 from "../../../utils/awsS3Upload";
import { toast } from "react-toastify";
import Editor from "react-simple-wysiwyg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { canvas2pdf } from "canvas2pdf";

const CreateInvoice = ({
  isOpen,
  toggleModal,
  viewType,
  setViewType,
  selectedInvoice,
}) => {
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const {
    invoiceItemsToDisplay,
    invoiceCreatedPackages,
    invoiceOptions,
    newItemsToDisplay,
    newCreatedPackages,
    newOptions,
    selectedPackage,
    selectedPackagePrice,
    selectedPackages,
  } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const [invoceData, setInvoceData] = useState({});
  const [checked, setChecked] = useState(false);
  const [contractCheck, setContractCheck] = useState([]);
  const [editType, setEditType] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [initialDealPackage, setInitialDealPackage] = useState([]);
  const [initialdealdealItem, setinitialdealdealItem] = useState([]);
  const [initialdealdealItemCustom, setinitialdealdealItemCustom] = useState(
    []
  );
  const [collectInsurance, setcollectInsurance] = useState(false);
  const [editedPackageIndex, setEditedPackageIndex] = useState(null);
  const [initialdealserviceSelectType, setinitialdealserviceSelectType] =
    useState([]);
  const [
    initialdealserviceSelectTypeCustom,
    setinitialdealserviceSelectTypeCustom,
  ] = useState([]);
  const [details, setDetails] = useState({
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    providerName: "",
    providerAddress: "",
    providerEmail: "",
    providerPhone: "",
  });
  console.log(details, invoceData, "clientDetails");

  const [events, setEvents] = useState([
    {
      eventDate: "",
      eventTime: "",
      eventType: "",
      eventLocation: "",
      totalGuestCount: "",
    },
  ]);

  const [eventSchedule, setEventSchedule] = useState([
    {
      title: "",
      engage: "",
      eventStart: "",
      eventEnd: "",
      handOver: "",
    },
  ]);

  const [paymentSchedule, setPaymentSchedule] = useState([
    {
      payment: "",
      due: "",
      amount: "",
    },
  ]);
  useEffect(() => {
    if (selectedPackagePrice) {
      setPaymentSchedule((prevPaymentSchedule) => {
        if (prevPaymentSchedule.length > 0) {
          const fifteenPercent = (selectedPackagePrice * 15) / 100;
          const remainingBalance = selectedPackagePrice - fifteenPercent;
          return [
            {
              payment: "Booking Deposit",
              due: invoceData?.createdAt.split("T")[0],
              amount: fifteenPercent,
            },
            {
              payment: "In-Person Payment",
              due: "",
              amount: remainingBalance,
            },
          ];
        }
        return [...prevPaymentSchedule];
      });
    }
  }, [selectedPackagePrice]);
  // useEffect(() => {
  //   if (invoceData?.createdAt) {
  //     const dueDate = new Date(invoceData?.createdAt);
  //     const formattedDueDate = dueDate.toISOString().split("T")[0];
  //     setPaymentSchedule((prevPaymentSchedule) => {
  //       if (prevPaymentSchedule.length > 0) {
  //         prevPaymentSchedule[0].due = formattedDueDate;
  //       }
  //       return [...prevPaymentSchedule];
  //     });
  //   }
  // }, [invoceData?.createdAt]);
  console.log(paymentSchedule, "paymentSchedule");
  const filteredPaySchedule = paymentSchedule?.filter(
    (payment) =>
      payment?.payment !== "" || payment?.due !== "" || payment?.amount !== ""
  );

  const filteredEventSchedule = eventSchedule?.filter(
    (event) =>
      event?.title !== "" ||
      event?.engage !== "" ||
      event?.eventStart !== "" ||
      event?.eventEnd !== "" ||
      event?.handOver !== ""
  );

  console.log(filteredEventSchedule, "filteredEventSchedule");

  const handleAddEventSchedule = () => {
    const currentEventSchedule = eventSchedule[eventSchedule.length - 1];
    if (
      currentEventSchedule.title.trim() === "" &&
      currentEventSchedule.engage.trim() === "" &&
      currentEventSchedule.eventStart.trim() === "" &&
      currentEventSchedule.eventEnd.trim() === "" &&
      currentEventSchedule.handOver.trim() === ""
    ) {
      return;
    }
    setEventSchedule((prevEventSchedule) => [
      ...prevEventSchedule,
      {
        title: "",
        engage: "",
        eventStart: "",
        eventEnd: "",
        handOver: "",
      },
    ]);
  };

  const handleAddPaymentSchedule = () => {
    const updatedPayments = [...paymentSchedule];
    const totalAmount = selectedPackagePrice;
    const bookingDeposit = updatedPayments[0].amount;
    const remainingBalance = totalAmount - bookingDeposit;
    const paymentsMade = updatedPayments
      .slice(2)
      .reduce(
        (acc, curr) => acc + (curr.amount ? parseInt(curr.amount) : 0),
        0
      );
    const newRemainingBalance = remainingBalance - paymentsMade;
    const newPaymentAmount = Math.floor(
      newRemainingBalance / (updatedPayments.length - 2)
    );
    updatedPayments.push({
      payment: "",
      due: "",
      amount: newPaymentAmount,
    });
    setPaymentSchedule(updatedPayments);
  };

  const handleRemoveEventSchedule = (index) => {
    setEventSchedule((prevEventSchedule) =>
      prevEventSchedule.filter((_, i) => i !== index)
    );
  };

  const handleRemovePaySchedule = (index) => {
    setPaymentSchedule((prevPaySchedule) =>
      prevPaySchedule.filter((_, i) => i !== index)
    );
    const updatedPayments = [...paymentSchedule];
    updatedPayments.splice(index, 1);
    const totalAmount = selectedPackagePrice;
    const bookingDeposit = updatedPayments[0].amount;
    const paymentsMade = updatedPayments
      .slice(2)
      .reduce(
        (acc, curr) => acc + (curr.amount ? parseInt(curr.amount) : 0),
        0
      );
    if (updatedPayments.length > 1) {
      if (updatedPayments[1].payment === "In-Person Payment" && index > 1) {
        const remainingBalance = totalAmount - bookingDeposit - paymentsMade;
        updatedPayments[1].amount = remainingBalance;
      }
    }
    setPaymentSchedule(updatedPayments);
  };

  const [dealNote, setDealNote] = useState("");
  const [selectedContract, setSelectedContract] = useState({});

  const handleCheckboxChange = (event, index) => {
    const newChecked = [...contractCheck];
    newChecked.forEach((contract, i) => {
      if (i === index) {
        contract.checked = true;
      } else {
        contract.checked = false;
      }
    });
    setContractCheck(newChecked);
    setSelectedContract(newChecked[index]);
  };

  const handleDealNote = (e) => {
    setDealNote(e.target.value);
  };

  const handleChange = (value, index, field) => {
    const updatedEvents = [...events];
    if (updatedEvents[index]) {
      updatedEvents[index] = {
        ...updatedEvents[index],
        [field]: value instanceof Date ? value.toISOString() : value,
      };
      setEvents(updatedEvents);
    } else {
      console.error(`Event at index ${index} does not exist.`);
    }
  };

  const handleScheduleChange = (value, index, field) => {
    const updatedEvents = [...eventSchedule];
    if (updatedEvents[index]) {
      updatedEvents[index] = {
        ...updatedEvents[index],
        [field]: value instanceof Date ? value.toISOString() : value,
      };
      setEventSchedule(updatedEvents);
    } else {
      console.error(`Event at index ${index} does not exist.`);
    }
  };

  const handlePayScheduleChange = (value, index, field) => {
    const updatedPayments = [...paymentSchedule];
    if (updatedPayments[index]) {
      updatedPayments[index] = {
        ...updatedPayments[index],
        [field]: value instanceof Date ? value.toISOString() : value,
      };
      // Calculate remaining balance
      if (field === "amount" && index > 1) {
        const totalAmount = selectedPackagePrice;
        const bookingDeposit = updatedPayments[0].amount;
        const paymentsMade = updatedPayments
          .slice(2)
          .reduce(
            (acc, curr) => acc + (curr.amount ? parseInt(curr.amount) : 0),
            0
          );
        const newPaymentsMade = paymentsMade + (parseInt(value) || 0);
        const remainingBalance = totalAmount - bookingDeposit - newPaymentsMade;
        updatedPayments[1].amount = Math.max(remainingBalance, 0); // Update the remaining balance
      }
      setPaymentSchedule(updatedPayments);
    } else {
      console.error(`Event at index ${index} does not exist.`);
    }
  };
  console.log(eventSchedule, "eventSchedule");
  // const handleCheckboxChange = (event, index) => {
  //   const newChecked = [...contractCheck];
  //   newChecked[index] = event.target.checked;
  //   console.log(newChecked, "newChecked");
  //   setContractCheck(newChecked);
  // };
  console.log(contractCheck, "contractCheck");
  const handleToggleChange = (event) => {
    console.log(event.target.checked, "checked");
    setChecked(event.target.checked);
    if (event.target.checked) {
      dispatch(setNewCreatedPackages(newCreatedPackages));
      dispatch(setNewOptions(initialdealserviceSelectTypeCustom));
      dispatch(setNewItemsToDisplay(initialdealdealItemCustom));
      // dispatch(setInvoiceCreatedPackages(newCreatedPackages));
      // dispatch(setInvoiceOptions(newOptions));
      // dispatch(setInvoiceItemsToDisplay(newItemsToDisplay));
      dispatch(setSelectedPackages({}));
    } else {
      dispatch(setInvoiceCreatedPackages(initialDealPackage));
      dispatch(setInvoiceOptions(initialdealserviceSelectType));
      dispatch(setInvoiceItemsToDisplay(initialdealdealItem));
      dispatch(setSelectedPackages({}));
    }
  };

  console.log(newCreatedPackages, newOptions, newItemsToDisplay, "newCreated");
  console.log(
    initialDealPackage,
    initialdealserviceSelectType,
    initialdealdealItem,
    "initial"
  );
  console.log(checked, "checked");

  const fetchInvoiceData = async () => {
    if (viewType === "view") {
      let formData = {
        Id: selectedInvoice?.Id,
        messageId: selectedConversation?.Id,
        Type: "view",
      };
      const response = await orderInvoice(formData);
      setInvoceData(response);
      console.log(response, "view");
    } else {
      let formData = {
        messageId: selectedConversation?.Id,
        userId: user?.UserID,
        // Type: "view",
      };
      const response = await orderInvoice(formData);
      setInvoceData(response);
      console.log(response, "Create");
    }
  };
  // console.log(events, "events");
  //   const eventDetails = invoceData?.eventDetails
  //     ? JSON.parse(invoceData?.eventDetails)
  //     : [];
  const filteredEvents = events?.filter(
    (event) =>
      event?.eventDate ||
      event?.eventLocation ||
      event?.eventTime ||
      event?.eventType ||
      event?.totalGuestCount
  );

  useEffect(() => {
    console.log(isOpen, selectedConversation, "editOpenselectedConversation");
    if (isOpen) {
      fetchInvoiceData();
    }
  }, [isOpen]);
  console.log(selectedPackagePrice, "selectedPrice");
  useEffect(() => {
    if (invoceData) {
      setDetails({
        clientName: invoceData?.customerName,
        clientEmail: invoceData?.customerEmail,
        clientPhone: invoceData?.customerPhone,
        providerName: invoceData?.providerName,
        providerEmail: invoceData?.providerEmail,
        providerPhone: invoceData?.providerPhone,
      });
      dispatch(setSelectedPrice(invoceData?.paymentDetails));
      setDealNote(invoceData?.dealNote);
      setFile(invoceData?.invoicePdf);
      setcollectInsurance(invoceData?.collectInsurance);
      setEditedPackageIndex(invoceData?.editedPackageIndex);
    }
    if (invoceData?.paymentDetails) {
      try {
        const initialPaySchedule = JSON.parse(invoceData.paymentDetails);
        if (Array.isArray(initialPaySchedule)) {
          setPaymentSchedule(initialPaySchedule);
          dispatch(setSelectedPrice(null));
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.eventDetails) {
      try {
        const initialEvents = JSON.parse(invoceData.eventDetails);
        if (Array.isArray(initialEvents)) {
          setEvents(initialEvents);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.dealPackage) {
      try {
        const initialdealPackage = JSON.parse(invoceData.dealPackage);
        if (Array.isArray(initialdealPackage)) {
          console.log(initialdealPackage, "initialdealPackage");
          setInitialDealPackage(initialdealPackage);
          dispatch(setInvoiceCreatedPackages(initialdealPackage));
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.dealItem) {
      try {
        const initialdealdealItem = JSON.parse(invoceData.dealItem);
        if (Array.isArray(initialdealdealItem)) {
          dispatch(setInvoiceItemsToDisplay(initialdealdealItem));
          setinitialdealdealItem(initialdealdealItem);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.serviceSelectType) {
      try {
        const initialdealserviceSelectType = JSON.parse(
          invoceData.serviceSelectType
        );
        if (Array.isArray(initialdealserviceSelectType)) {
          dispatch(setInvoiceOptions(initialdealserviceSelectType));
          setinitialdealserviceSelectType(initialdealserviceSelectType);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.dealItemCustom) {
      try {
        const initialdealdealItemCustom = JSON.parse(invoceData.dealItemCustom);
        if (Array.isArray(initialdealdealItemCustom)) {
          dispatch(setNewItemsToDisplay(initialdealdealItemCustom));
          setinitialdealdealItemCustom(initialdealdealItemCustom);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.serviceSelectTypeCustom) {
      try {
        const initialdealserviceSelectTypeCustom = JSON.parse(
          invoceData.serviceSelectTypeCustom
        );
        if (Array.isArray(initialdealserviceSelectTypeCustom)) {
          dispatch(setInvoiceOptions(initialdealserviceSelectTypeCustom));
          setinitialdealserviceSelectTypeCustom(
            initialdealserviceSelectTypeCustom
          );
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.eventTimeDetails) {
      try {
        const initialEventSchedule = JSON.parse(invoceData.eventTimeDetails);
        if (Array.isArray(initialEventSchedule)) {
          setEventSchedule(initialEventSchedule);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
    if (invoceData?.contractTerm) {
      try {
        const initialcontractTerm = JSON.parse(invoceData.contractTerm);
        if (Array.isArray(initialcontractTerm)) {
          const contractCheckArray = initialcontractTerm.map(
            (contract, index) => {
              if (index === 0) {
                return { ...contract, checked: true };
              }
              return { ...contract, checked: false };
            }
          );
          const filteredContracts = contractCheckArray.filter((contract) => {
            return (
              contract.contractType !== "" && contract.contractDetails !== ""
            );
          });
          setContractCheck(filteredContracts);
          setSelectedContract(contractCheckArray[0]);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
  }, [invoceData, isOpen]);

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 7,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      border: "3px solid green", // Add a green border
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        left: 12,
      },
      "&::after": {
        right: 12,
      },
      backgroundColor: "white",
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: "green",
    },
    "&.Mui-checked .MuiSwitch-thumb": {
      backgroundColor: "green", // Change the circle button color to green when on
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "white", // Change the background color to green when on
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "gray", // Change the circle button color to gray when disabled
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "gray", // Change the background color to gray when disabled
    },
  }));

  const handleOpen = (editType) => {
    setEditType(editType);
    setEditOpen(true);
  };

  const toggleEdit = () => {
    setEditOpen(!editOpen);
  };
  console.log(contractCheck, "contractCheck");
  console.log(selectedPackage, "selectedPackage");
  console.log(selectedContract, "selectedContract");
  // console.log(invoiceCreatedPackages, "sdfasdfasdfsdf");

  const [pdfFile, setPdfFile] = useState("");
  const [insuranceFile, setInsuranceFile] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const maxFileSizeMB = 5;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
    // let folder = "invoicePdf/";
    if (!file) {
      toast.error("No file selected.");
      return;
    }
    // if (file.size > maxFileSizeBytes) {
    //   toast.error(`File size should be less than ${maxFileSizeMB} MB.`);
    //   return;
    // }
    // try {
    //   const pdfUrl = await uploadToS3(file, folder); // Upload to AWS S3
    //   console.log(pdfUrl, "InvoiceUrl");
    //   if (editType === "Collect Insurance") {
    //     setInsuranceFile(pdfUrl);
    //   } else {
    //     setPdfFile(pdfUrl);
    //   }
    // } catch (error) {
    //   toast.error("Failed to upload the PDF. Please try again.");
    // }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result); // Show the preview
    };
    reader.readAsDataURL(file);
    setcollectInsurance(false);
  };
  const handleContractTermChange = (e) => {
    const updatedContractCheck = [...contractCheck];
    const index = updatedContractCheck.findIndex(
      (contract) => contract.checked
    );
    if (index !== -1) {
      updatedContractCheck[index].contractDetails = e.target.value;
    }
    setContractCheck(updatedContractCheck);
    setSelectedContract(updatedContractCheck[index]);
  };

  const handleCollectInsurance = (event) => {
    setcollectInsurance(event.target.checked);
  };

  // const generatePdf = (modalId) => {
  //   const modal = document.getElementById(modalId);
  //   const elementsToRemove = modal.querySelectorAll(
  //     ".attach-files, .fa-edit, .save-button, .createdPakaage"
  //   );
  //   const rswToolbar = modal.querySelector(".rsw-toolbar");
  //   rswToolbar.style.display = "none";
  //   elementsToRemove.forEach((element) => {
  //     element.style.display = "none";
  //   });
  //   const invoceDataNeedName = modal.querySelector(".invoceData-needName");
  //   const contract = modal.querySelector(".contract");
  //   const event = modal.querySelector(".event");
  //   event.style.fontSize = "20px";
  //   event.style.fontWeight = "500";
  //   contract.style.fontSize = "20px";
  //   contract.style.fontWeight = "500";
  //   invoceDataNeedName.style.fontSize = "30px";
  //   invoceDataNeedName.style.fontWeight = "700";
  //   const updatedDate = modal.querySelector(".updated-date");
  //   updatedDate.style.fontSize = "30px";
  //   updatedDate.style.fontWeight = "700";
  //   // const createdPakaage = modal.querySelector(".createdPakaage");
  //   // createdPakaage.style.writingMode = "vertical-rl";
  //   // createdPakaage.style.textAlign = "center";
  //   // createdPakaage.style.fontSize = "24px";
  //   // createdPakaage.style.lineHeight = "1.5";
  //   // createdPakaage.style.border = "1px solid black";
  //   // createdPakaage.style.borderRadius = "10px";
  //   // createdPakaage.style.backgroundColor = "#efe3af";
  //   // createdPakaage.style.padding = "10px";
  //   // createdPakaage.style.width = "fit-content";
  //   // createdPakaage.style.transform = "rotate(180deg)";
  //   return html2canvas(modal, {
  //     scale: 2,
  //     logging: true,
  //   })
  //     .then(async (canvas) => {
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297);
  //       console.log(pdf, "html2canvas");
  //       pdf.save("modal-data.pdf");
  //       const pdfBlob = pdf.output("blob");
  //       const file = new File([pdfBlob], "modal-data.pdf", {
  //         type: "application/pdf",
  //       });
  //       if (file) {
  //         let folder = "invoicePdf/";
  //         try {
  //           const pdfUrl = await uploadToS3(file, folder); // Upload to AWS S3
  //           console.log(pdfUrl, "InvoiceUrl");
  //           return pdfUrl; // Ret
  //         } catch (error) {
  //           toast.error("Failed to upload the PDF. Please try again.");
  //         }
  //       }
  //       console.log(file, "blobfile");
  //       // pdf.save("modal-data.pdf", { returnPromise: true }).then(async (blob) => {
  //       //   const file = new File([blob], "modal-data.pdf", {
  //       //     type: "application/pdf",
  //       //   });
  //       //   file.metadata = null;

  //       //   // Use a consistent encoding scheme
  //       //   const encoding = "UTF-8";
  //       //   const pdfData = new Uint8Array(pdfBlob);
  //       //   const pdfBuffer = new Uint8Array(pdfData);
  //       //   console.log(pdfData, pdfBuffer, "pdfBuffer");
  //       //   if (pdfBuffer) {
  //       //     let folder = "invoicePdf/";
  //       //     try {
  //       //       const pdfUrl = await uploadToS3(pdfBuffer, folder); // Upload to AWS S3
  //       //       console.log(pdfUrl, "InvoiceUrl");

  //       //       setInvoicePdf(pdfUrl);
  //       //     } catch (error) {
  //       //       toast.error("Failed to upload the PDF. Please try again.");
  //       //     }
  //       //   }
  //       //   console.log(file, "blobfile");
  //       // });
  //     })
  //     .finally(() => {
  //       elementsToRemove.forEach((element) => {
  //         element.style.display = "block";
  //       });
  //       rswToolbar.style.display = "flex";
  //       invoceDataNeedName.style.fontSize = "30px";
  //       invoceDataNeedName.style.fontWeight = "700";
  //       event.style.fontSize = "20px";
  //       event.style.fontWeight = "500";
  //       contract.style.fontSize = "20px";
  //       contract.style.fontWeight = "500";
  //       updatedDate.style.fontSize = "30px";
  //       updatedDate.style.fontWeight = "700";
  //     });
  //   // createdPakaage.style.writingMode = "vertical-rl";
  //   // createdPakaage.style.textAlign = "center";
  //   // createdPakaage.style.fontSize = "24px";
  //   // createdPakaage.style.lineHeight = "1.5";
  //   // createdPakaage.style.border = "1px solid black";
  //   // createdPakaage.style.borderRadius = "10px";
  //   // createdPakaage.style.backgroundColor = "#efe3af";
  //   // createdPakaage.style.padding = "10px";
  //   // createdPakaage.style.width = "fit-content";
  //   // createdPakaage.style.transform = "rotate(180deg)";
  // };
  const generatePdf = (modalId) => {
    const modal = document.getElementById(modalId);

    // Clone the modal to ensure the UI isn't affected
    const modalClone = modal.cloneNode(true);
    document.body.appendChild(modalClone); // Append the cloned modal to the body (temporarily)

    // Hide elements and apply styles to the clone
    const elementsToRemove = modalClone.querySelectorAll(
      ".attach-files, .fa-edit, .pencil_Icon, .save-button, .created_Package, .dealFactors, .invoceButton, .invoiceCheckbox"
    );
    const rswToolbar = modalClone.querySelector(".rsw-toolbar");
    const invoceDataNeedName = modalClone.querySelector(".invoceData-needName");
    const contract = modalClone.querySelector(".contract");
    const event = modalClone.querySelector(".event");
    const updatedDate = modalClone.querySelector(".updated-date");

    // Store original styles of elements in the clone
    const originalStyles = {};
    elementsToRemove.forEach((element) => {
      originalStyles[element] = element.style.display;
      element.classList.remove("d-block"); // Remove the d-block class
      element.classList.add("d-none"); // Add a class to hide the element
    });
    if (rswToolbar) {
      originalStyles[rswToolbar] = rswToolbar.style.display;
      rswToolbar.style.display = "none";
    }

    // Modify styles for PDF generation in the cloned modal
    event.style.fontSize = "17px";
    event.style.fontWeight = "600";
    contract.style.fontSize = "17px";
    contract.style.fontWeight = "600";
    invoceDataNeedName.style.fontSize = "23px";
    // invoceDataNeedName.style.fontWeight = "700";
    updatedDate.style.fontSize = "30px";
    updatedDate.style.fontWeight = "700";

    // Create the PDF using html2canvas from the cloned modal
    return html2canvas(modalClone, {
      scale: 2,
      logging: true,
    })
      .then(async (canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 0.8),
          "JPEG",
          0,
          0,
          210,
          297
        );
        console.log(pdf, "html2canvas");

        pdf.save(`${invoceData?.customerName}.pdf`);

        const pdfBlob = pdf.output("blob");
        const file = new File([pdfBlob], "modal-data.pdf", {
          type: "application/pdf",
        });

        if (viewType === "create") {
          if (file) {
            let folder = "invoicePdf/";
            try {
              const pdfUrl = await uploadToS3(file, folder); // Upload to AWS S3
              console.log(pdfUrl, "InvoiceUrl");
              return pdfUrl;
            } catch (error) {
              toast.error("Failed to upload the PDF. Please try again.");
            }
          }
        }

        console.log(file, "blobfile");
      })
      .finally(() => {
        // Clean up: Remove the cloned modal from the DOM after the process
        document.body.removeChild(modalClone);

        // Restore original styles (although this is redundant now as we never modified the original modal)
        elementsToRemove.forEach((element) => {
          element.style.display = originalStyles[element] || "block";
        });
        if (rswToolbar) {
          rswToolbar.style.display = originalStyles[rswToolbar] || "flex";
        }

        // Restore the font sizes and font weights for the UI (not needed, but for completeness)
        event.style.fontSize = "17px";
        event.style.fontWeight = "600";
        contract.style.fontSize = "17px";
        contract.style.fontWeight = "600";
        invoceDataNeedName.style.fontSize = "23px";
        // invoceDataNeedName.style.fontWeight = "700";
        updatedDate.style.fontSize = "30px";
        updatedDate.style.fontWeight = "700";
      });
  };

  const [loading, setLoading] = useState("");

  const handleCreateInvoice = async () => {
    setLoading(true);
    let folder = "invoicePdf/";
    // if (collectInsurance) {
    //   setEditType("Collect Insurance");
    //   setEditOpen(true);
    //   setLoading(false);
    // } else {
    const invoiceUrl = await generatePdf("my-modal");
    try {
      const pdfUrl = await uploadToS3(pdfFile, folder); // Upload to AWS S3
      console.log(pdfUrl, "InvoiceUrl");
      if (editType === "Collect Insurance") {
        setInsuranceFile(pdfUrl);
      } else {
        setPdfFile(pdfUrl);
      }
    } catch (error) {
      toast.error("Failed to upload the PDF. Please try again.");
    }
    // if (pdfBlob) {
    //   try {
    //     const pdfUrl = await uploadToS3(pdfBlob, folder); // Upload to AWS S3
    //     console.log(pdfUrl, "InvoiceUrl");

    //     setInvoicePdf(pdfUrl);
    //   } catch (error) {
    //     toast.error("Failed to upload the PDF. Please try again.");
    //   }
    // }
    await new Promise((resolve) => setTimeout(resolve, 500));
    const formData = {
      Id: invoceData.Id || selectedInvoice?.Id,
      messageId: selectedConversation?.Id,
      userId: user?.UserID,
      needName: invoceData?.needName,
      customerName: details?.clientName,
      customerPhone: details?.clientPhone,
      customerEmail: details?.clientEmail,
      providerName: details?.providerName,
      providerPhone: details?.providerPhone,
      providerEmail: details?.providerEmail,
      providerAddress: details?.providerAddress,
      eventDetails: events ? JSON.stringify(events) : "",
      eventTimeDetails: eventSchedule ? JSON.stringify(eventSchedule) : "",
      paymentDetails: paymentSchedule ? JSON.stringify(paymentSchedule) : "",
      dealPackage: invoiceCreatedPackages
        ? JSON.stringify(invoiceCreatedPackages)
        : "",
      serviceSelectType: invoiceOptions ? JSON.stringify(invoiceOptions) : "",
      dealItem: invoiceItemsToDisplay
        ? JSON.stringify(invoiceItemsToDisplay)
        : "",
      serviceSelectTypeCustom: newOptions ? JSON.stringify(newOptions) : "",
      dealItemCustom: newItemsToDisplay
        ? JSON.stringify(newItemsToDisplay)
        : "",
      dealNote: dealNote,
      attachedFile: pdfFile,
      invoicePdf: invoiceUrl,
      insuranceFile: insuranceFile,
      collectInsurance: collectInsurance,
      editedPackageIndex: editedPackageIndex,
      contractTerm: contractCheck ? JSON.stringify(contractCheck) : "",
      updatedBy: user?.UserID,
      Type: "edit",
    };
    console.log(formData, "invoiceformData");
    await orderInvoice(formData);
    // setcollectInsurance(false);
    setLoading(false);
    toast.success("Invoice has been successfully sent to the customer");
    toggleModal();
    // }
  };
  const [file, setFile] = useState(null);

  const handleDownloadInvoice = () => {
    const link = document.createElement("a");
    link.href = invoceData;
    link.target = "_blank";
    link.download = "invoice.pdf";
    link.click();
  };
  console.log(viewType, "viewType");
  console.log(collectInsurance, "collectInsurance");
  return (
    <Modal
      id="my-modal"
      isOpen={isOpen}
      toggle={() => {
        toggleModal();
        setInsuranceFile("");
        setPdfFile("");
        setViewType("");
        // setcollectInsurance(false);
      }}
      size="xl"
    >
      <ModalBody className="py-5 px-4">
        <Row className="d-flex justify-content-between">
          <Col lg={4} sm={4} xs={4} md={4}>
            <h6>MAJESTIC EVENTS</h6>
            <div
              className="updated-date"
              style={{ fontSize: "23px", fontWeight: "700" }}
            >
              {invoceData?.needName}
            </div>
          </Col>
          <Col lg={4} sm={4} xs={4} md={4}>
            <div
              className="text-center updated-date"
              style={{ fontSize: "23px", fontWeight: "700" }}
            >
              INVOICE
            </div>
            <h6 className="text-center">Invoice ID: {invoceData?.Id}</h6>
          </Col>
          <Col lg={4} xs={4} sm={4} md={4} className="text-end">
            <div>
              Updated{" "}
              {invoceData?.updatedAt !== null
                ? moment(invoceData?.updatedAt).format("MM/DD/YYYY")
                : moment(invoceData?.createdAt).format("MM/DD/YYYY")}
            </div>
            <div
              className="updated-date"
              style={{ fontSize: "23px", fontWeight: "700" }}
            >
              {moment(invoceData?.createdAt).format("ddd. MMM. DD, YYYY")}
            </div>
          </Col>
          <hr
            style={{ height: "2px", color: "black", backgroundColor: "black" }}
          />
        </Row>
        <Row className="mb-3">
          <Col lg={6} className="text-start">
            <div>
              <div className="d-flex">
                <div
                  className="contract"
                  style={{ fontSize: "17px", fontWeight: "600" }}
                >
                  Client Contract
                </div>{" "}
                &nbsp; &nbsp;
                <FaRegEdit
                  size={30}
                  style={{ cursor: "pointer" }}
                  className={`fa-edit pencil_Icon ${
                    viewType === "view" ? "d-none" : "d-block"
                  }`}
                  onClick={() => handleOpen("Client Contract")}
                />
              </div>
              <div>{details?.clientName}</div>
              <div>{details?.clientEmail}</div>
              <div>{details?.clientPhone}</div>
            </div>
          </Col>
          <Col lg={6} className="text-start">
            <div>
              <div className="d-flex">
                <div
                  className="contract"
                  style={{ fontSize: "17px", fontWeight: "600" }}
                >
                  Provider Contract
                </div>{" "}
                &nbsp; &nbsp;{" "}
                <FaRegEdit
                  size={30}
                  style={{ cursor: "pointer" }}
                  className={`fa-edit pencil_Icon ${
                    viewType === "view" ? "d-none" : "d-block"
                  }`}
                  onClick={() => handleOpen("Provider Contract")}
                />
              </div>
              <div>Majestic Events</div>
              <div>{details.providerName}</div>
              {details.providerAddress && <div>{details.providerAddress}</div>}
              <div>{details.providerEmail}</div>
              <div>{details.providerPhone}</div>
            </div>
          </Col>
        </Row>
        <Row className="p-3">
          <Col lg={4} className="p-0 mt-3">
            <div className="d-flex justify-content-between">
              <div
                className="event"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
                Event Details
              </div>{" "}
              &nbsp; &nbsp;{" "}
              {/* <FaRegEdit
                size={30}
                style={{ cursor: "pointer" }}
                     className={`fa-edit pencil_Icon ${
                    viewType === "view" ? "d-none" : "d-block"
                  }`}
                onClick={() => handleOpen("Event Details")}
              /> */}
            </div>
            {filteredEvents?.length > 0 ? (
              <Table responsive className="table-light border">
                <thead>
                  <tr>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Event Type</th>
                    <th>Event Location</th>
                    <th>Guest Count</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEvents.map((event, i) => (
                    <tr key={i}>
                      <td>
                        {moment(event.eventDate.split("T")[0]).format(
                          "MMM DD, YYYY"
                        )}
                      </td>
                      <td>
                        {moment(event.eventTime, "HH:mm").format("hh:mm A")}
                      </td>
                      <td>{event.eventType}</td>
                      <td>{event.eventLocation}</td>
                      <td>{event.totalGuestCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center my-3">No Events</div>
            )}
          </Col>
          <Col lg={4} className="mt-3">
            <div className="d-flex justify-content-between">
              <div
                className="event"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
                Event Schedule
              </div>{" "}
              &nbsp; &nbsp;{" "}
              <FaRegEdit
                size={30}
                style={{ cursor: "pointer" }}
                className={`fa-edit pencil_Icon ${
                  viewType === "view" ? "d-none" : "d-block"
                }`}
                onClick={() => handleOpen("Event Schedule")}
              />
            </div>
            <div>
              {/* {eventSchedule.map((event, index) => (
                <Row key={index}>
                  <Col lg={6}>
                    <Label>Engage</Label>
                    <Input
                      type="time"
                      name="engage"
                      value={event.engage}
                      // onChange={(event) =>
                      //   handleScheduleChange(
                      //     event.target.value,
                      //     index,
                      //     "engage"
                      //   )
                      // }
                      disabled
                    />
                    <Label>Event End</Label>
                    <Input
                      type="time"
                      name="eventEnd"
                      value={event.eventEnd}
                      // onChange={(event) =>
                      //   handleScheduleChange(
                      //     event.target.value,
                      //     index,
                      //     "eventEnd"
                      //   )
                      // }
                      disabled
                    />
                  </Col>
                  <Col lg={6}>
                    <Label>Event Start</Label>
                    <Input
                      type="time"
                      name="eventStart"
                      value={event.eventStart}
                      // onChange={(event) =>
                      //   handleScheduleChange(
                      //     event.target.value,
                      //     index,
                      //     "eventStart"
                      //   )
                      // }
                      disabled
                    />
                    <Label>Handover</Label>
                    <Input
                      type="time"
                      name="handover"
                      value={event.handOver}
                      // onChange={(event) =>
                      //   handleScheduleChange(
                      //     event.target.value,
                      //     index,
                      //     "handover"
                      //   )
                      // }
                      disabled
                    />
                  </Col>
                </Row>
              ))} */}
              {filteredEventSchedule?.length > 0 ? (
                <Table responsive className="table-light border m-0">
                  <thead>
                    <tr>
                      <th>Event Title</th>
                      <th>Event Engage</th>
                      <th>Event Start</th>
                      <th>Event End</th>
                      <th>Hand Over</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEventSchedule.map((event, i) => (
                      <tr key={i}>
                        <td>{event.title}</td>
                        <td>
                          {event.engage &&
                            moment(event.engage, "HH:mm").format(
                              "hh:mm A"
                            )}{" "}
                        </td>
                        <td>
                          {event.eventStart &&
                            moment(event.eventStart, "HH:mm").format("hh:mm A")}
                        </td>
                        <td>
                          {event.eventEnd &&
                            moment(event.eventEnd, "HH:mm").format("hh:mm A")}
                        </td>
                        <td>
                          {event.handOver &&
                            moment(event.handOver, "HH:mm").format("hh:mm A")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center my-3">No Schedules</div>
              )}
            </div>
          </Col>
          <Col lg={4} className="p-0 mt-3">
            <div className="d-flex justify-content-between">
              <div
                className="event"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
                Payment Schedule
              </div>{" "}
              &nbsp; &nbsp;{" "}
              <FaRegEdit
                size={30}
                style={{ cursor: "pointer" }}
                className={`fa-edit pencil_Icon ${
                  viewType === "view" ? "d-none" : "d-block"
                }`}
                onClick={() => handleOpen("Payment Schedule")}
              />
            </div>
            {filteredPaySchedule?.length > 0 ? (
              <Table responsive striped className="table-light border">
                <thead>
                  <tr>
                    <th>Payment</th>
                    <th>Due</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPaySchedule.map((schedule, i) => (
                    <tr key={i}>
                      <td>{schedule?.payment}</td>
                      <td>
                        {schedule?.due &&
                          moment(schedule?.due).format("MMMM DD, YYYY")}
                      </td>
                      <td>
                        {schedule.amount
                          ? "$ " + parseInt(schedule.amount)
                          : "$ 0.00"}
                        {/* {selectedPackagePrice} */}
                      </td>
                    </tr>
                  ))}
                  {/* <tr>
                  <td>Final Balance</td>
                  <td>December 16, 2025</td>
                  <td>$ 3500.00</td>
                </tr> */}
                </tbody>
              </Table>
            ) : (
              <div className="text-center my-3">No Payment Schedules</div>
            )}
          </Col>
        </Row>
        <div className="mt-1">
          <div className="d-flex align-items-center justify-content-center gap-10 created_Package">
            <div className="invoceData-needName" style={{ fontSize: "23px" }}>
              Select Pre-made Package
            </div>
            <div className="text-center swichbgcolor">
              <FormControlLabel
                control={
                  <Android12Switch
                    checked={checked}
                    onChange={handleToggleChange}
                    disabled={viewType === "view" ? true : false}
                  />
                }
                className="m-0"
              />
            </div>
            <div className="invoceData-needName" style={{ fontSize: "23px" }}>
              Create custom Package
            </div>
          </div>
          <div>
            <DealFactors
              checked={checked}
              state={{ invoice: true }}
              setEditedPackageIndex={setEditedPackageIndex}
              editedPackageIndex={editedPackageIndex}
            />
          </div>
          <div className="text-center px-3">
            <div className="" style={{ fontSize: "23px" }}>
              Deal Notes
            </div>
            <Input
              type="textarea"
              name="dealNotes"
              style={{ height: "150px" }}
              value={dealNote}
              disabled={viewType === "view" ? true : false}
              onChange={(e) => handleDealNote(e)}
            />
            <div className="d-flex justify-content-between align-items-center mt-3 save-button">
              <div className="">
                <span className="mb-1 attach-files">
                  <IoDocumentAttachSharp size={25} />
                  &nbsp; &nbsp;Attach Files and Images
                </span>
                <div>
                  <Input
                    type="file"
                    name="dealNotes"
                    onChange={handleFileChange}
                    disabled={viewType === "view" ? true : false}
                  />
                </div>
              </div>
              {/* <div>
                <Button color="primary" className="save-button">
                  Save
                </Button>
              </div> */}
            </div>
          </div>
          {contractCheck.length > 0 && (
            <div className="mt-1 text-center px-3">
              <div className="invoceData-needName" style={{ fontSize: "23px" }}>
                Select Contract Type
              </div>
              <div>
                {contractCheck.map((contract, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(e, index)}
                        checked={contract.checked}
                        disabled={viewType === "view" ? true : false}
                        className={contract.checked ? "" : "invoiceCheckbox"}
                      />
                    }
                    label={
                      <span
                        className={contract.checked ? "" : "invoiceCheckbox"}
                      >
                        {contract.contractType} Contract
                      </span>
                    }
                  />
                ))}
                {/* <div className="d-flex justify-content-between">
                <h4>{selectedContract?.contractType}</h4>
                <FaRegEdit
                  size={30}
                  style={{ cursor: "pointer" }}
                  className="fa-edit pencil_Icon"
                  onClick={() => handleOpen("Contract Type")}
                />
              </div> */}

                {selectedContract && selectedContract.contractDetails && (
                  <div className="mt-2">
                    {/* <h4>{selectedContract.contractDetails}</h4> */}

                    {/* <Input
                    type="textarea"
                    name="contractDetails"
                    style={{ height: "150px" }}
                    value={selectedContract.contractDetails}
                    disabled
                  /> */}
                    <Editor
                      style={{ height: "200px" }}
                      value={selectedContract.contractDetails}
                      onChange={handleContractTermChange}
                      disabled={viewType === "view" ? true : false}
                    />
                  </div>
                )}
              </div>
              <div className="text-start my-3">
                <Input
                  type="checkbox"
                  checked={collectInsurance}
                  onChange={handleCollectInsurance}
                  disabled={viewType === "view" ? true : false}
                />{" "}
                &nbsp; collect insurance record from Customer
              </div>
              <div className="text-end my-1">
                <strong className="me-5">Signature</strong>
                <br />
                {viewType === "view" && (
                  <strong className="me-5 signature">
                    {invoceData?.Signature}
                  </strong>
                )}
                <div>----------------------------------------</div>
              </div>
              <div className="text-center save-button">
                {viewType === "create" ? (
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={handleCreateInvoice}
                    disabled={loading}
                  >
                    Create Invoice
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={async () => {
                      await generatePdf("my-modal");
                      toggleModal();
                    }}
                  >
                    {/* <a
                    href={invoceData?.invoicePdf}
                    target="_blank"
                    download="invoice.pdf"
                    style={{ color: "white", fontSize: "smaller" }}
                  > */}
                    Download Invoice
                    {/* </a> */}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={editOpen}
          toggle={toggleEdit}
          size={`${
            editType === "Event Details" ||
            (editType === "Contract Type" && "lg")
          } `}
        >
          <ModalBody>
            {editType === "Client Contract" && (
              <div>
                <h3 className="text-center">Update Client Details</h3>
                <FormGroup>
                  <Label for="clientName">Client Name</Label>
                  <Input
                    type="text"
                    value={details?.clientName}
                    onChange={(e) =>
                      setDetails({ ...details, clientName: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="clientemail">Client Email</Label>
                  <Input
                    type="email"
                    value={details?.clientEmail}
                    onChange={(e) =>
                      setDetails({ ...details, clientEmail: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="clientemail">Client Phone</Label>
                  <Input
                    type="email"
                    value={details?.clientPhone}
                    onChange={(e) => {
                      const { value } = e.target;
                      const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                      if (isValidPhone) {
                        setDetails({ ...details, clientPhone: e.target.value });
                      }
                    }}
                  />
                </FormGroup>
                <div className="text-end mt-2">
                  <Button color="warning" onClick={toggleEdit}>
                    Update
                  </Button>
                </div>
              </div>
            )}
            {editType === "Provider Contract" && (
              <div>
                <h3 className="text-center">Update Provider Details</h3>
                <FormGroup>
                  <Label for="providerName">Provider Name</Label>
                  <Input
                    type="text"
                    value={details?.providerName}
                    onChange={(e) =>
                      setDetails({ ...details, providerName: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="providerAddress">Provider Address</Label>
                  <Input
                    type="text"
                    value={details?.providerAddress}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        providerAddress: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="provideremail">Provider Email</Label>
                  <Input
                    type="email"
                    value={details?.providerEmail}
                    onChange={(e) =>
                      setDetails({ ...details, providerEmail: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="provderPhone">Provider Phone</Label>
                  <Input
                    type="email"
                    value={details?.providerPhone}
                    onChange={(e) => {
                      const { value } = e.target;
                      const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                      if (isValidPhone) {
                        setDetails({
                          ...details,
                          providerPhone: e.target.value,
                        });
                      }
                    }}
                  />
                </FormGroup>
                <div className="text-end mt-2">
                  <Button color="warning" onClick={toggleEdit}>
                    Update
                  </Button>
                </div>
              </div>
            )}
            {editType === "Event Details" && (
              <>
                <h3 className="text-center">Update Event Details</h3>
                <Row
                  className="d-flex flex-row mt-3"
                  style={{
                    // marginLeft: 0,
                    color: "black",
                    padding: 0,
                  }}
                >
                  <Col sm={3} className="d-none d-lg-block">
                    Event Date<b className="text-danger">*</b>
                  </Col>
                  <Col sm={2} className="d-none d-lg-block">
                    Event Time<b className="text-danger">*</b>
                  </Col>
                  <Col sm={2} className="d-none d-lg-block">
                    Event Type<b className="text-danger">*</b>
                  </Col>
                  <Col sm={2} className="d-none d-lg-block">
                    Event Location<b className="text-danger">*</b>
                  </Col>
                  <Col sm={2} className="d-none d-lg-block">
                    Guest Count<b className="text-danger">*</b>
                  </Col>
                  <Col sm={1} className="d-none d-lg-block"></Col>
                </Row>
                {events.map((event, index) => (
                  <Row key={index}>
                    <Col
                      sm={3}
                      xs="12"
                      className="d-flex flex-column mt-3 justify-content-around "
                    >
                      <Label className="text-nowrap d-block d-lg-none">
                        Event Date <b className="text-danger">*</b>
                      </Label>
                      <DatePicker
                        name="eventDate"
                        dateFormat="MMM/dd/yyyy"
                        selected={
                          event.eventDate ? new Date(event.eventDate) : null
                        }
                        onChange={(date) =>
                          handleChange(date, index, "eventDate")
                        }
                        className="form-control "
                        minDate={new Date()}
                        placeholderText="mmm/dd/yyyy"
                      />
                    </Col>
                    <Col
                      sm={2}
                      xs="12"
                      className="d-flex flex-column mt-3 justify-content-around"
                    >
                      <Label className="text-nowrap d-block d-lg-none">
                        Event Time <b className="text-danger">*</b>
                      </Label>
                      <Input
                        type="time"
                        name="eventTime"
                        className="form-control "
                        value={event?.eventTime}
                        onChange={(event) =>
                          handleChange(event.target.value, index, "eventTime")
                        }
                      />
                    </Col>
                    <Col
                      sm={2}
                      xs="12"
                      className="d-flex flex-column mt-3 justify-content-around"
                    >
                      <Label className="text-nowrap d-block d-lg-none">
                        Event Type <b className="text-danger">*</b>
                      </Label>
                      <Input
                        type="text"
                        name="eventType"
                        className="form-control "
                        value={event.eventType}
                        placeholder="Enter event type..."
                        onChange={(event) =>
                          handleChange(event.target.value, index, "eventType")
                        }
                      />
                    </Col>
                    <Col
                      sm={2}
                      xs="12"
                      className="d-flex flex-column mt-3 justify-content-around"
                    >
                      <Label className="text-nowrap d-block d-lg-none">
                        Event Location <b className="text-danger">*</b>
                      </Label>
                      <Input
                        type="text"
                        name="eventLocation"
                        className="form-control "
                        placeholder="Enter city..."
                        value={event.eventLocation}
                        onChange={(event) =>
                          handleChange(
                            event.target.value,
                            index,
                            "eventLocation"
                          )
                        }
                      />
                    </Col>
                    <Col
                      sm={2}
                      xs="12"
                      className="d-flex flex-column mt-3 justify-content-around"
                    >
                      <Label className="text-nowrap d-block d-lg-none">
                        Guest Count <b className="text-danger">*</b>
                      </Label>
                      <Input
                        type="number"
                        name="totalGuestCount"
                        className="form-control "
                        value={event.totalGuestCount}
                        placeholder="No of guests.."
                        max={10}
                        maxLength={10}
                        onChange={(event) =>
                          handleChange(
                            event.target.value,
                            index,
                            "totalGuestCount"
                          )
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <div className="mt-3 text-end">
                  <Button color="warning" onClick={toggleEdit}>
                    Update
                  </Button>
                </div>
              </>
            )}
            {editType === "Event Schedule" && (
              <div>
                <h3>Update Event Schedle</h3>
                {eventSchedule.map((event, index) => (
                  <Row key={index}>
                    <div className="my-2">
                      <Label>Event Title</Label>
                      <Input
                        type="text"
                        name="title"
                        value={event.title}
                        onChange={(event) =>
                          handleScheduleChange(
                            event.target.value,
                            index,
                            "title"
                          )
                        }
                      />
                    </div>
                    <Col lg={6}>
                      <Label>Engage</Label>
                      <Input
                        type="time"
                        name="engage"
                        value={event.engage}
                        onChange={(event) =>
                          handleScheduleChange(
                            event.target.value,
                            index,
                            "engage"
                          )
                        }
                      />
                      <Label>Event End</Label>
                      <Input
                        type="time"
                        name="eventEnd"
                        value={event.eventEnd}
                        onChange={(event) =>
                          handleScheduleChange(
                            event.target.value,
                            index,
                            "eventEnd"
                          )
                        }
                      />
                    </Col>
                    <Col lg={6}>
                      <Label>Event Start</Label>
                      <Input
                        type="time"
                        name="eventStart"
                        value={event.eventStart}
                        onChange={(event) =>
                          handleScheduleChange(
                            event.target.value,
                            index,
                            "eventStart"
                          )
                        }
                      />
                      <Label>Handover</Label>
                      <Input
                        type="time"
                        name="handOver"
                        value={event.handOver}
                        onChange={(event) =>
                          handleScheduleChange(
                            event.target.value,
                            index,
                            "handOver"
                          )
                        }
                      />
                    </Col>
                    <div className="mt-3 text-end">
                      {index < eventSchedule.length - 1 && (
                        <Button
                          color="danger"
                          onClick={() => handleRemoveEventSchedule(index)}
                        >
                          Remove
                        </Button>
                      )}
                      {index === eventSchedule.length - 1 && (
                        <div className="text-end">
                          <Button
                            color="primary"
                            className="me-2"
                            onClick={handleAddEventSchedule}
                          >
                            Add
                          </Button>
                          <Button color="warning" onClick={toggleEdit}>
                            Update
                          </Button>
                        </div>
                      )}
                    </div>
                  </Row>
                ))}
              </div>
            )}
            {editType === "Contract Type" && (
              <div>
                <h3>Update Contract Terms</h3>
                <Editor
                  style={{ height: "300px" }}
                  value={selectedContract.contractDetails}
                  onChange={handleContractTermChange}
                />
                <div className="text-end mt-3">
                  <Button color="warning" onClick={toggleEdit}>
                    Update
                  </Button>
                </div>
              </div>
            )}
            {editType === "Collect Insurance" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {!filePreview ? (
                  <form class="file-upload-form">
                    <label class="file-upload-label" for="file">
                      <div class="file-upload-design">
                        <svg height="1em" viewBox="0 0 640 512">
                          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                        </svg>
                        <h5>Upload proof of insurance converage</h5>
                        <p>Drag and Drop</p>
                        <p>or</p>
                        <span class="browse-button">Browse file</span>
                      </div>
                      <input
                        type="file"
                        id="file"
                        onChange={handleFileChange}
                      />
                    </label>
                  </form>
                ) : (
                  <div style={{ width: "250px", height: "220px" }}>
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <embed src={filePreview} width="250" height="220" />
                      </div>
                    </div>
                  </div>
                )}
                {/* <h4>Please upload your Insurance file </h4>
                <div style={{ width: "250px", height: "220px" }}>
                  {filePreview ? (
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <embed src={filePreview} width="250" height="220" />
                      </div>
                    </div>
                  ):(
                    <h5>Upload File</h5>
                  )}
                </div>
                <Input
                  type="file"
                  name="collectInsurance"
                  onChange={handleFileChange}
                /> */}
                <div className="mt-2">
                  <Button
                    color="secondary"
                    onClick={handleCreateInvoice}
                    className="save-button"
                    disabled={loading}
                  >
                    Create Invoice{" "}
                  </Button>
                </div>
              </div>
            )}
            {editType === "Payment Schedule" && (
              <div>
                <h3>Update Payment Schedule</h3>
                {paymentSchedule.map((schedule, index) => (
                  <Row key={index}>
                    <div className="">
                      <Label>Payment</Label>
                      <Input
                        type="text"
                        name="payment"
                        value={schedule?.payment}
                        onChange={(event) =>
                          handlePayScheduleChange(
                            event.target.value,
                            index,
                            "payment"
                          )
                        }
                        disabled={index === 0 || index === 1}
                      />
                    </div>
                    <Col lg={6} sm={12} xs={12} className="my-2">
                      <Label>Due</Label>
                      <Input
                        type="date"
                        name="due"
                        value={schedule.due}
                        onChange={(event) =>
                          handlePayScheduleChange(
                            event.target.value,
                            index,
                            "due"
                          )
                        }
                        // disabled={index === 0}
                      />
                    </Col>
                    <Col lg={6} sm={12} xs={12} className="my-2">
                      <Label>Amount</Label>
                      <Input
                        type="number"
                        name="amount"
                        value={schedule.amount}
                        onChange={(event) =>
                          handlePayScheduleChange(
                            event.target.value,
                            index,
                            "amount"
                          )
                        }
                        disabled={index === 0 || index === 1}
                      />
                    </Col>
                    <div className="mt-3 text-end">
                      {index > 1 && (
                        <Button
                          color="danger"
                          onClick={() => handleRemovePaySchedule(index)}
                        >
                          Remove
                        </Button>
                      )}
                      {index === paymentSchedule.length - 1 && (
                        <div className="text-end mt-2">
                          <Button
                            color="primary"
                            className="me-2"
                            onClick={handleAddPaymentSchedule}
                          >
                            Add
                          </Button>
                          <Button color="warning" onClick={toggleEdit}>
                            Update
                          </Button>
                        </div>
                      )}
                    </div>
                  </Row>
                ))}
              </div>
            )}
          </ModalBody>
        </Modal>
      </ModalBody>
    </Modal>
  );
};

export default CreateInvoice;
