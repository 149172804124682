import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, TextField, Grid } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa";
import { setSendingMsg } from "../../../redux/slices/MsgSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { scheduleMessage } from "../Data3";
import { Spinner } from "reactstrap";
import moment from "moment";

const MsgScheduleModal = ({
  open,
  close,
  msg,
  isChatModal,
  fetchMsgs,
  fetchAllMsg,
  editMsg,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const initialFormData = {
    message: "",
    scheduleDate: "",
    scheduleTime: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const { sendingMsg } = useSelector((state) => state.messages);
  const isAdmin = user && user.Role === "admin";
  const { message, scheduleDate, scheduleTime } = formData;
  const [errors, setErrors] = React.useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "scheduleTime" &&
      scheduleDate === currentDate &&
      value < currentTime
    ) {
      setErrors((prev) => ({
        ...prev,
        scheduleTime: "Cannot select a past time.",
      }));
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleClose = () => {
    close();
    setFormData(initialFormData);
  };
  const validateFields = () => {
    const newErrors = {};
    if (!formData.message) newErrors.message = "This field is required.";
    if (!formData.scheduleDate)
      newErrors.scheduleDate = "This field is required.";
    if (!formData.scheduleTime)
      newErrors.scheduleTime = "This field is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  React.useEffect(() => {
    if (editMsg) {
      setFormData({
        message: editMsg.Message,
        scheduleDate: editMsg.scheduleDate,
        scheduleTime: editMsg.scheduleTime,
      });
    }
  }, [editMsg]);

  const handleSend = async (userType) => {
    console.log(userType, "userType");
    let messageForm;
    if (!validateFields()) return;
    dispatch(setSendingMsg(true));
    if (editMsg) {
      messageForm = {
        messageId: editMsg.Id,
        mType: "Edit",
        scheduleDate: scheduleDate,
        scheduleTime: scheduleTime,
        timeZone: user.TimeZone,
      };
    } else {
      if (message.trim() && msg) {
        messageForm = {
          customerId:
            msg.customerId === user.UserID ? msg.createdBy : msg.customerId,
          postId: msg.postId,
          postType: 1, // message 1 or requestQuote 2 or offer my service 3
          parentId: msg.parentId === 0 ? msg.Id : msg.parentId,
          Description: message,
          messageStatus: userType === "user" ? 0 : 14,
          createdBy: userType === "user" ? user.UserID : msg?.createdBy,
          roleId: user.RoleId,
          ownerId: msg.ownerId,
          userName:
            userType === "user"
              ? user.UserName.split("@")[0].trim()
              : msg?.senderName,
          Email: user.Email,
          serviceNeed: "need",
          needName: msg.postName,
          mType: "New",
          receivedBy:
            msg.customerId === user.UserID ? msg.createdBy : msg.customerId,
          scheduleDate: scheduleDate,
          scheduleTime: scheduleTime,
          timeZone: userType == "user" ? user.TimeZone : msg?.providerTimezone,
        };
      }
    }
    await scheduleMessage(messageForm);
    fetchMsgs();
    if (!isChatModal) {
      fetchAllMsg();
    }
    toast.success("Message Scheduled");
    handleClose();
    dispatch(setSendingMsg(false));
  };
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const minTime = scheduleDate === currentDate ? currentTime : "00:00";
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Schedule Message
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginBottom: "10px",
          }}
        >
          <FaExclamationTriangle
            style={{ color: "orange", fontSize: "14px" }}
          />
          <p style={{ margin: 0, fontSize: "14px" }}>
            Your message will be sent according to US/Pacific Time Zone set in
            your <a href="/dashboardpage/settings">Profile</a>.
          </p>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Message"
                type="text"
                name="message"
                multiline
                rows={2}
                value={message}
                variant="outlined"
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={!!editMsg}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Schedule Date"
                type="date"
                name="scheduleDate"
                value={scheduleDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!errors.scheduleDate}
                helperText={errors.scheduleDate}
                inputProps={{
                  min: currentDate, // Disable past dates
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Schedule Time"
                type="time"
                name="scheduleTime"
                value={scheduleTime}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: minTime, // Dynamically disable past times if today is selected
                }}
                fullWidth
                error={!!errors.scheduleTime}
                helperText={errors.scheduleTime}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end", padding: 2 }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="secondary"
          sx={{ minWidth: 120 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSend("user")}
          variant="contained"
          color="primary"
          sx={{ minWidth: 100, fontSize: "small" }}
          disabled={sendingMsg}
        >
          {sendingMsg ? (
            <Spinner />
          ) : (
            <span>{editMsg ? "ReSchedule Message" : "Schedule Message"}</span>
          )}
        </Button>
        {isAdmin && (
          <Button
            onClick={() => handleSend("admin")}
            variant="contained"
            color="danger"
            sx={{ minWidth: 100, fontSize: "small" }}
            disabled={sendingMsg}
          >
            {sendingMsg ? (
              <Spinner />
            ) : (
              <span>
                {editMsg ? "ReSchedule Message" : "Schedul Message As Vendor"}
              </span>
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MsgScheduleModal;
