import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { fetchSponsordAdList } from "../Data";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../assets/images/Placeholder.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";

const SpAds = () => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [sponsordAds, setSponsordAds] = useState([]);
  const [visibleAds, setVisibleAds] = useState([0, 1]); // Start with first two ads

  const fetchAds = async () => {
    let roleId = { roleId: 1 };
    try {
      const ads = await fetchSponsordAdList(roleId);
      const filterAds = ads.filter((ad) => ad.Status === "1");
      const apiKey = process.env.REACT_APP_ZIPCODEBASE_KEY;
      const baseUrl = process.env.REACT_APP_ZIPCODEBASE_URL;
      const userZipCode = +user?.ZipCode || 0;

      // Process each ad to check if userZipCode falls within its radius
      const filteredByRadius = await Promise.all(
        filterAds.map(async (ad) => {
          const adZipCode = +ad.Location || 0;
          const adRadius = +ad.Radius || 0; // Assuming Radius is in miles

          // Fetch zip codes within the ad's radius
          const url = `${baseUrl}?apikey=${apiKey}&code=${adZipCode}&radius=${adRadius}&country=us`;
          const response = await axios.get(url);
          const resultval = response.data;
          const zipcodes = resultval.results || [];
          console.log(resultval.results, "resultval.results");
          if (!resultval.results?.error) {
            const processedZipcodes = zipcodes.map((dataval) => dataval.code);
            // Check if user's zip code is within the ad's radius
            console.log(userZipCode, processedZipcodes, "processedZipcodes");
            return (
              processedZipcodes.includes(userZipCode.toString()) ||
              adZipCode === userZipCode
            );
          }
          return false; // If there's an error, exclude this ad
        })
      );

      // Filter ads where the user's zip code is within the ad's radius
      const finalAds = filterAds.filter((_, index) => filteredByRadius[index]);
      setSponsordAds(finalAds);
    } catch (error) {
      console.error("Error fetching ads:", error);
      setSponsordAds([]);
    }
  };
  console.log(sponsordAds, "sponsordAds");
  useEffect(() => {
    fetchAds();
  }, []);

  useEffect(() => {
    if (sponsordAds.length > 2) {
      const interval = setInterval(() => {
        setVisibleAds(([left, right]) => {
          let newRight = (right + 1) % sponsordAds.length;
          return [right, newRight]; // Shift left ad out, move right ad to left, new ad appears on right
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [sponsordAds]);

  const handleAdClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Ad URL is not available.");
    }
  };

  const handleAdCreate = () => {
    if (islogin) {
      navigate("/ad/sponsored/new");
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };

  return (
    <div className="sponsord-ads-container">
      <div className="row">
        <div className="text-end">
          <h6
            onClick={handleAdCreate}
            className="text-primary ad-taret-link py-1 d-inline-block"
            style={{ cursor: "pointer" }}
          >
            Create your own Sponsored ad
          </h6>
        </div>
      </div>

      {sponsordAds.length > 0 ? (
        <Row>
          {visibleAds.map((index) => (
            <Col lg="6" key={index}>
              <div
                className="card"
                onClick={() => handleAdClick(sponsordAds[index]?.Url)}
              >
                <div className="row position-relative">
                  <div className="col-lg-5">
                    <img
                      className="w-100"
                      style={{ height: "150px" }}
                      src={sponsordAds[index]?.Photo || Placeholder}
                      alt="ad"
                    />
                  </div>
                  <div className="col-lg-7 d-flex align-items-center">
                    <div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "20px" }}
                      >
                        <img
                          style={{ width: "40px" }}
                          src={sponsordAds[index]?.profilePhoto || Placeholder}
                          alt="user"
                          className="rounded-circle"
                        />
                        <p>
                          Sponsored by{" "}
                          {sponsordAds[index]?.firstName
                            ? `${sponsordAds[index].firstName} ${sponsordAds[index].lastName}`
                            : sponsordAds[index]?.userName.split("@")[0].trim()}
                        </p>
                      </div>
                      <h6>
                        {sponsordAds[index]?.Header?.length > 15
                          ? sponsordAds[index]?.Header.slice(0, 15) + "..."
                          : sponsordAds[index]?.Header}
                      </h6>
                      <p>
                        {sponsordAds[index]?.description?.length > 30
                          ? sponsordAds[index]?.description.slice(0, 30) + "..."
                          : sponsordAds[index]?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="text-center py-3">
          <h5>No Sponsored Ads Available</h5>
        </div>
      )}
    </div>
  );
};

export default SpAds;
