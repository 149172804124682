import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import ProductSection from "../../UI/ProductSection";
import "../../../styles/allproduct.css";
import {
  fetchCategories,
  fetchLocalCommunityAds,
  fetchSubCategories,
} from "../Data";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import { setGuruSubCat } from "../../../redux/slices/LcaSlice";
import {
  setItemsInAds,
  setLoadingItems,
} from "../../../redux/slices/LatestAdsSlice";
import { toast } from "react-toastify";
import axiosClient from "../../../axios-client";
import { useParams } from "react-router-dom";
import Helmet from "../../Helmet/Helmet";
const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [productlist, setProductList] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const { itemsInAds } = useSelector((state) => state.latestState);
  const { gurdwara } = useParams();
  let pageNumber = 1;

  console.log("Params:", useParams()); // Check the full output
  console.log("gurdwara:", gurdwara);
  const calculateLastRefreshed = () => {
    const currentTime = new Date();
    const diff = currentTime - new Date(lastRefreshed) + elapsedSeconds * 1000; // Add elapsed seconds to the difference
    const seconds = Math.floor(diff / 1000) % 60; // Calculate seconds
    const minutes = Math.floor(diff / (1000 * 60)) % 60; // Calculate minutes
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Calculate hours
    const days = Math.floor(hours / 24); // Calculate days
    let result = "Last refreshed: ";
    result += lastRefreshed.toLocaleDateString() + " ";
    result += " (";
    result += days > 0 ? `${days} days ` : "";
    result += hours > 0 ? `${hours} hours ` : "";
    result += minutes > 0 ? `${minutes} minutes ` : "";
    result += `${seconds} seconds ago)`;
    return result;
  };
  const getAllIfs = async () => {
    dispatch(setLoadingItems(true));
    try {
      const response = await axiosClient.get(`item/getAllItem`);
      if (response.data.statusCode === 200) {
        let items = response.data.itemList.filter(
          (item) => item.item_status == 1
        );
        if (location.state && location.state.gurudwara) {
          items = items.filter(
            (item) => item.gurudwaraName === location.state.gurudwara
          );
        }
        dispatch(setItemsInAds(items));
      } else {
        toast.error(`${response.data.message} ${response.data.statusCode}`);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items.");
    }
    dispatch(setLoadingItems(false));
  };
  const fetchData = async () => {
    setLoading(true);
    const category = await fetchCategories();
    const subCategories = await fetchSubCategories();
    const filterCategoryByName = category.filter(
      (cat) => cat.Name === "Local Community Ads"
    );
    const filterSubCat = subCategories.filter(
      (subCat) => subCat.CategoryId === filterCategoryByName[0].Id
    );
    dispatch(setGuruSubCat(filterSubCat));
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    getAllIfs();
    const intervalId = setInterval(() => {
      setLastRefreshed(new Date());
      setElapsedSeconds((prevSeconds) => prevSeconds + 1); // Increment elapsed seconds
    }, 1000);
    const reloadIntervalId = setInterval(() => {
      window.location.reload();
    }, 3600000); // 60 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
      clearInterval(reloadIntervalId); // Cleanup reload interval on component unmount
    };
  }, []);
  const fetchLCA = async (payload) => {
    setLoading(true);

    // Only add Gurdwara to payload if it's present and valid
    if (gurdwara) {
      payload.Gurudwara = gurdwara;
    }
    const ads = await fetchLocalCommunityAds(payload); // pass the payload to the API request
    setProductList((prevProducts) => {
      const existingIds = prevProducts.map((product) => product.Id);
      const newProducts = ads.filter((ad) => !existingIds.includes(ad.Id));
      return [...prevProducts, ...newProducts];
    });
    setLoading(false);
    if (ads.length === 50) {
      pageNumber += 1;
      const payload = { pageId: pageNumber };
      fetchLCA(payload);
    }
  };
  useEffect(() => {
    const payload = { pageId: pageNumber };
    if (gurdwara) {
      payload.Gurudwara = gurdwara;
    }
    fetchLCA(payload);
  }, [location.search, location.state, gurdwara]);

  const filterGurdwara = useMemo(
    () => productlist.filter((item) => item.engagedCouple != 1),
    [productlist]
  );

  const memoizedProductSection = useMemo(
    () => <ProductSection data={filterGurdwara} />,
    [filterGurdwara]
  );

  return (
    <Helmet title={gurdwara}>
      <section className="bg-white  inter-font">
        <Container fluid className="px-4">
          <Row className="px-2">
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="text-start mt-2"
            >
              <h5>Latest Local Community ADs - </h5>
            </Col>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="flashing-text mt-2"
            >
              <span>
                <b>CALL (800)245-3610 & Dial Listing number</b>
              </span>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-end mt-2">
              <small>{calculateLastRefreshed()}</small>
            </Col>
          </Row>
          <Row className="all_product_Card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {!loading ? (
                <>{memoizedProductSection}</>
              ) : (
                <FadeLoader
                  color={"#36D7B7"}
                  loading={true}
                  className="top-50 start-50 translate-middle"
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Dashboard;
