import {
  Alert,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";

const SendJobModal = ({ open, toggle, userDetails }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const [sending, setSending] = React.useState(false);
  const dispatch = useDispatch();
  const toggleModal = () => {
    toggle();
    setJobDetails({
      jobRole: "",
      jobDescription: "",
      joiningPeriod: "",
      salary: "",
      timeFrom: "",
      timeTo: "",
      workingDays: [],
    });
  };

  // Form state for job details
  const [jobDetails, setJobDetails] = useState({
    jobRole: "",
    jobDescription: "",
    joiningPeriod: "",
    salary: "",
    timeFrom: "",
    timeTo: "",
    workingDays: [],
  });

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "workingDays") {
      setJobDetails({ ...jobDetails, [name]: value });
    } else {
      setJobDetails({ ...jobDetails, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    setSending(true);
    e.preventDefault();
    const req = {
      ...jobDetails,
      workingDays: JSON.stringify(jobDetails.workingDays), // Stringify the workingDays
      userId: userDetails.Id,
      createdBy: user.UserID,
      connectionStatus: 0,
    };

    try {
      const response = await axiosClient.post(`message/CreateJobOp`, req);
      if (response.status === 200) {
        toast.success(
          `Job Opportunity Sent to ${
            userDetails.FirstName || userDetails.UserName.split("@")[0].trim()
          }`
        );
        setSending(false);
        toggleModal();
      }
    } catch (error) {
      toast.error("Failed to send Job Opportunity");
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            Send Job Opportunity To{" "}
            {userDetails.FirstName || userDetails.UserName.split("@")[0].trim()}
          </ModalHeader>
          <ModalBody>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Job Role"
                  name="jobRole"
                  value={jobDetails.jobRole}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Job Description"
                  name="jobDescription"
                  value={jobDetails.jobDescription}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Joining Period"
                  name="joiningPeriod"
                  value={jobDetails.joiningPeriod}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Salary"
                  name="salary"
                  value={jobDetails.salary}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Time From"
                  name="timeFrom"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ type: "time" }}
                  type="time"
                  value={jobDetails?.timeFrom}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Time To"
                  name="timeTo"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ type: "time" }}
                  type="time"
                  value={jobDetails.timeTo}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="working-days-label">Working Days</InputLabel>
                  <Select
                    label="Working Days"
                    labelId="working-days-label"
                    id="working-days"
                    name="workingDays"
                    value={jobDetails.workingDays}
                    onChange={handleChange}
                    multiple
                    fullWidth
                    required
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            color="primary"
                            size="small"
                          />
                        ))}
                      </Box>
                    )}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{ mt: 2 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {weekdays.map((day, index) => (
                      <MenuItem key={index} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={sending}>
              {sending ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Send Job Opportunity"
              )}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SendJobModal;
