import React from "react";
import DealFactorItems from "./DealFactorItems";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { TbArrowBigLeft, TbArrowBigRight } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { IoMdAddCircle } from "react-icons/io";
import {
  setInvoiceItemsToDisplay,
  setNewItemsToDisplay,
} from "../../../redux/slices/MsgSlice";

const DealFactorTitle = ({
  itemsToDisplay,
  sliceSize,
  handlePrevious,
  currentIndex,
  options,
  newItems,
  selectedPackages,
  handleInputChange,
  handlePackageSelection,
  handleAddItemToPackages,
  handleDealFactorItemDeletion,
  setModal,
  handleNext,
  setItemsToDisplay,
  state,
  checked,
  setCurrentIndex,
}) => {
  const dispatch = useDispatch();
  const handleDeleteItem = (index) => {
    const actualIndex = currentIndex + index;
    const updatedItems = itemsToDisplay.filter((item, i) => i !== actualIndex);

    if (itemsToDisplay.length === 1) {
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay([]));
        } else {
          dispatch(setInvoiceItemsToDisplay([]));
        }
      } else {
        dispatch(setItemsToDisplay([]));
      }

      dispatch(setItemsToDisplay([]));
    } else {
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay(updatedItems));
        } else {
          dispatch(setInvoiceItemsToDisplay(updatedItems));
        }
      } else {
        dispatch(setItemsToDisplay(updatedItems));
      }
      dispatch(setItemsToDisplay(updatedItems));
      if (index === 0 && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else if (index === sliceSize - 1) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };
  console.log(itemsToDisplay, options, "itemsToDisplay");
  return (
    <div className="d-flex align-items-center justify-content-center">
      {itemsToDisplay?.length > sliceSize && (
        <div
          style={{ cursor: "pointer", marginRight: "10px" }}
          onClick={handlePrevious}
          disabled={currentIndex === 0}
        >
          <TbArrowBigLeft size={35} />
        </div>
      )}
      <Row
        className="d-flex flex-nowrap"
        style={{
          overflowX: "auto",
          width: "95%",
        }}
      >
        {itemsToDisplay?.length > 0 ? (
          <>
            {itemsToDisplay
              .slice(currentIndex, currentIndex + sliceSize)
              .map((item, index) => (
                <Col key={index} xs={12} md={6} lg={6} className="my-2 p-1">
                  <div className="border rounded p-2">
                    <FormGroup>
                      <Input
                        type="select"
                        value={item.type}
                        onChange={(e) => {
                          const updatedItems = [...itemsToDisplay];
                          updatedItems[index + currentIndex] = {
                            ...updatedItems[index + currentIndex],
                            type: e.target.value,
                          };
                          dispatch(setItemsToDisplay(updatedItems));
                        }}
                        // onChange={(e) => handleTypeChange(e, index)}
                      >
                        {/* <option value="">Select One TimeZone</option> */}
                        {options.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}

                        {/* Add more options as needed */}
                      </Input>
                    </FormGroup>
                    <DealFactorItems
                      item={item}
                      newItems={newItems}
                      selectedPackages={selectedPackages}
                      handleInputChange={handleInputChange}
                      handlePackageSelection={handlePackageSelection}
                      handleAddItemToPackages={handleAddItemToPackages}
                      handleDealFactorItemDeletion={
                        handleDealFactorItemDeletion
                      }
                      state={state}
                      checked={checked}
                    />
                  </div>
                  {/* {!state?.invoice && ( */}
                  <div className="d-flex justify-content-center my-2">
                    <Button
                      color="danger"
                      onClick={() => handleDeleteItem(index)}
                    >
                      Delete
                    </Button>
                  </div>
                  {/* )} */}
                </Col>
              ))}
          </>
        ) : (
          <div className="text-center">
            <Button color="danger" size="sm" onClick={() => setModal(true)}>
              <IoMdAddCircle size={20} />
              <span>Add New Deal Factor</span>
            </Button>
          </div>
        )}
      </Row>
      {itemsToDisplay?.length > sliceSize && (
        <div
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={() => handleNext(itemsToDisplay?.length)}
          disabled={currentIndex + 2 >= itemsToDisplay.length}
        >
          <TbArrowBigRight size={35} />
        </div>
      )}
    </div>
  );
};

export default DealFactorTitle;
