import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Grid,
  Select,
  InputLabel,
  FormControl,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { fetchMyServices } from "../Data";

const CreateFollowUpMd = ({ open, handleClose, selectedConvo }) => {
  // const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [teamMembers, setTeamMembers] = useState([]);
  const initialFormData = {
    messageId: selectedConvo?.Id,
    customerName: selectedConvo?.customerName,
    memberDetails: [], // Updated to support multiple selections
    customerEmail: selectedConvo?.customerEmail,
    taskDate: "",
    reminderDate: "",
    Title: "",
    taskDetail: "",
    googleCalendar: 0,
    Notify: 0,
    createdBy: user?.UserID,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const closeModal = () => {
    handleClose();
    setFormData(initialFormData);
  };
  const handleChange = (e) => {
    // handleGoogle();
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "", // Clear error message when user starts typing
    }));
  };
  const fetchServices = async () => {
    try {
      const response = await fetchMyServices({
        createdBy: selectedConvo?.createdBy,
      });
      console.log("teamMembers");
      const service = response.filter((service) => service.isactive === "3");
      const additionalPeople = service[0]?.additionalPeople;
      const parsedPeople = additionalPeople ? JSON.parse(additionalPeople) : [];

      // Filter out team members with empty values
      const validTeamMembers = parsedPeople.filter(
        (member) => member.email && member.phoneNumber && member.countryCode
      );

      setTeamMembers(validTeamMembers);
      console.log(validTeamMembers, "teamMembers");
    } catch (error) {
      console.error("Error fetching services or parsing data:", error);
    }
  };

  useEffect(() => {
    if (user?.UserID && open) {
      fetchServices();
    }
  }, [open]);
  const handleMultiSelectChange = (event) => {
    const { value } = event.target;
    // Update the memberDetails based on the selection/deselection
    const updatedMemberDetails = formData.memberDetails.filter((detail) =>
      value.some(
        (member) =>
          member.email === detail.email && member.phone === detail.phone
      )
    );
    value.forEach((member) => {
      const exists = updatedMemberDetails.some(
        (detail) =>
          detail.email === member.email && detail.phone === member.phone
      );
      if (!exists) {
        updatedMemberDetails.push({ email: member.email, phone: member.phone });
      }
    });
    setFormData((prev) => ({
      ...prev,
      memberDetails: updatedMemberDetails,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.memberDetails.length)
      newErrors.memberDetails = "This field is required.";
    if (!formData.taskDate) newErrors.taskDate = "This field is required.";
    if (!formData.reminderDate)
      newErrors.reminderDate = "This field is required.";
    if (!formData.Title) newErrors.Title = "This field is required.";
    if (!formData.taskDetail) newErrors.taskDetail = "This field is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async () => {
    if (!validateFields()) return; // Prevent submission if validation fails
    let guestCount = [];
    try {
      guestCount = selectedConvo?.eventDetails
        ? JSON.parse(selectedConvo.eventDetails)
        : [];
    } catch (error) {
      console.error("Error parsing eventDetails:", error);
    }
    const count = guestCount[0]?.totalGuestCount || 0;
    const submissionData = {
      ...formData,
      memberDetails: JSON.stringify(formData.memberDetails), // Convert to JSON string
      totalGuestCount: Number(count),
    };
    try {
      const response = await axiosClient.post(
        `message/AddFollowUpTask`,
        submissionData
      );
      if (response.data.statusCode === 200) {
        toast.success(`Follow-up task created successfully`);
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
  };
  console.log(formData, "formData");
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="create-template-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="create-template-dialog-title">
        Create Follow-Up Task
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="p-3">
          {/* Team Member Assigned */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.memberDetails}>
              <InputLabel>Team Members Assigned to Task</InputLabel>
              <Select
                multiple
                value={formData.memberDetails}
                onChange={handleMultiSelectChange}
                renderValue={(selected) => (
                  <div
                    style={{ display: "flex", overflowX: "scroll", gap: "8px" }}
                  >
                    {selected.map((member, index) => (
                      <Chip
                        key={index}
                        label={`${member.email} (${member.phone})`}
                        style={{ margin: "2px" }}
                      />
                    ))}
                  </div>
                )}
              >
                {selectedConvo?.senderEmail && (
                  <MenuItem
                    value={{
                      email: selectedConvo.senderEmail,
                      phone: selectedConvo.Phone,
                    }}
                  >
                    &#9733; {selectedConvo.senderEmail} ({selectedConvo.Phone})
                  </MenuItem>
                )}
                {teamMembers.map((member, index) => (
                  <MenuItem
                    key={index}
                    value={{
                      email: member.email,
                      phone: `${member.countryCode} ${member.phoneNumber}`,
                    }}
                  >
                    {`${member.email} (${member.countryCode}${member.phoneNumber})`}
                  </MenuItem>
                ))}
              </Select>
              {errors.memberDetails && (
                <p style={{ color: "red" }}>{errors.memberDetails}</p>
              )}
            </FormControl>
          </Grid>
          {/* Task Due Date */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Task Due Date"
              type="date"
              name="taskDate"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formData.taskDate}
              onChange={handleChange}
              helperText={errors.taskDate || ""}
              error={!!errors.taskDate}
            />
          </Grid>
          {/* Date to Remind Assignee */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Date to Remind Assignee"
              type="date"
              name="reminderDate"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formData.reminderDate}
              onChange={handleChange}
              helperText={errors.reminderDate || ""}
              error={!!errors.reminderDate}
            />
          </Grid>
          {/* Title of Follow-Up Task */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Title of Follow-Up Task"
              name="Title"
              fullWidth
              value={formData.Title}
              onChange={handleChange}
              helperText={errors.Title || ""}
              error={!!errors.Title}
            />
          </Grid>
          {/* Checkbox Options */}
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="googleCalendar"
                  checked={formData.googleCalendar == 1}
                  onChange={handleChange}
                />
              }
              label="Send to their Google Calendar?"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Notify"
                  checked={formData.Notify == 1}
                  onChange={handleChange}
                />
              }
              label="Notify Customer?"
            />
          </Grid>
          {/* Task Details */}
          <Grid item xs={12}>
            <TextField
              label="Task Details"
              name="taskDetail"
              fullWidth
              multiline
              rows={4}
              value={formData.taskDetail}
              onChange={handleChange}
              helperText={errors.taskDetail || ""}
              error={!!errors.taskDetail}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create Follow-Up Task
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFollowUpMd;
