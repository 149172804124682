import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { setDevice, setLinkModal } from "../../../redux/slices/BwvData";
import { toast } from "react-toastify";
import { Link, useLoaderData, useLocation } from "react-router-dom";
import { FaSync } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import { MdVideoChat } from "react-icons/md";
import { FaSquarePhone } from "react-icons/fa6";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  endorsementStatus,
  makeCall,
  sendMsgMail,
  serviceEndorsement,
  twilioToken,
  virtualTourClick,
} from "../Data";
import { GetZoomLink } from "../Data2";
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaYelp,
} from "react-icons/fa";
import { countryCodes } from "../../../components/CountryCodes";
import Select from "react-select";
import VendorMaps from "./vendorMaps";
import MsgModal from "../modals/MsgModal";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import axiosClient from "../../../axios-client";

const VendoeProfileDt = ({ openHireModal, OpenInvite }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const dispatch = useDispatch();
  const [isInCall, setIsInCall] = useState(false);
  const { device, viewers, viewCount } = useSelector((state) => state.bwvData);
  const [mobileModalOpen, setMobileModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMsgModal, setIsMsgModal] = useState(false);
  const location = useLocation();
  const [avgReplyTime, setAvgReplyTime] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));

  const handleCodeChange = (value) => {
    console.log(value, "value");
    setSelectedCountry(value);
  };

  const logUser = localStorage.getItem("USER_ROLE");
  useEffect(() => {
    fetchAvgReply();
    // const queryParams = new URLSearchParams(location.search);
    // const redirect = queryParams.get("redirect");
    // if (redirect && redirect === "email") {
    // if (islogin) {
    setTimeout(() => {
      dispatch(setLinkModal(true));
      // queryParams.delete("redirect");
      // const hashWithoutQuery = window.location.hash.split("?")[0];
      // const newUrl = `${window.location.origin}${window.location.pathname}${hashWithoutQuery}`;
      // window.history.replaceState({}, "", newUrl);
    }, 5000);
    // }
    // }
  }, []);

  const fetchAvgReply = async () => {
    try {
      const response = await axiosClient.get(`message/avgReplyTime`);
      if (response.data.statusCode === 200) {
        setAvgReplyTime(response.data.Data);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Error fetching average reply time");
    }
  };
  const convertMinutesToHours = (minutes) => {
    const totalMinutes = parseFloat(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = Math.round(totalMinutes % 60);
    return `${hours} hours, ${remainingMinutes} minutes`;
  };
  const avgResponseTime =
    avgReplyTime && avgReplyTime[0].avg_response_time_in_minutes;
  const formattedTime = convertMinutesToHours(avgResponseTime);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const now = new Date();
  const formattedDate = formatDate(now);
  const handleClick = async () => {
    if (!islogin && !name) {
      toast.error("Please Enter Email");
      return;
    }
    if (!islogin && !phoneNumber) {
      toast.error("Please Enter Phone Number");
      return;
    }
    if (!islogin && !selectedCountry) {
      selectedCountry = "+1";
    }
    const serviceInfo = JSON.parse(selectedCard?.additionalPeople);
    const newPerson1 = {
      email: !islogin ? name : user?.Email,
      phoneNumber: !islogin ? phoneNumber : user?.Phone,
      countryCode: !islogin ? selectedCountry : user?.CountryCode,
    };
    let newPerson2;
    let newPerson3;
    if (selectedCard.profilePhone === selectedCard.Phone) {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
    } else {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
      newPerson3 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.Phone,
        countryCode: selectedCard?.countryCode,
      };
    }
    serviceInfo.unshift(newPerson1);
    serviceInfo.push(newPerson2);
    if (newPerson3) {
      serviceInfo.push(newPerson3);
    }
    const stringifiedAdditionalPeople = JSON.stringify(serviceInfo);
    let formData = {
      email: !islogin ? name : user?.Email,
      phoneNumber: !islogin ? phoneNumber : user?.Phone,
      countryCode: !islogin ? selectedCountry : user?.CountryCode,
      phoneDetails: stringifiedAdditionalPeople,
    };
    const response = await makeCall(formData);
    toast.success(
      "We will be sending you a text message link to join the meeting within 30 seconds"
    );
    if (response === "initiating call") {
      const formData = {
        startTime: formattedDate,
        needName: selectedCard?.listName,
      };
      const linkResponse = await GetZoomLink(formData, selectedCard?.Id);
      let formData1 = {
        zoomlink: linkResponse.join_url,
        phoneDetails: stringifiedAdditionalPeople,
        customerId: user?.UserID,
        ownerId: parseInt(selectedCard?.createdBy),
        Type: "details",
        postId: selectedCard?.Id,
        postName: selectedCard?.listName,
      };
      await sendMsgMail(formData1);
      await virtualTourClick(selectedCard?.Id);
    } else {
      throw new Error("Response from makeCall was invalid");
    }
  };
  const handleCallButtonClick = async () => {
    const serviceInfo = JSON.parse(selectedCard?.additionalPeople);
    let newPerson2;
    let newPerson3;
    if (selectedCard.profilePhone === selectedCard.Phone) {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.Phone,
        countryCode: selectedCard?.countryCode,
      };
    } else {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.Phone,
        countryCode: selectedCard?.countryCode,
      };
      newPerson3 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
    }
    if (newPerson3) {
      serviceInfo.unshift(newPerson3);
    }
    serviceInfo.unshift(newPerson2);
    // Conditionally combining countryCode and phoneNumber
    const combinedInfo = serviceInfo
      .map((item) =>
        item.phoneNumber
          ? `${item.countryCode}${item.phoneNumber}`
          : item.phoneNumber
      )
      .filter((item) => item); // Remove any empty entries
    try {
      const response = await twilioToken("111111");
      const newDevice = await new window.Twilio.Device(response.token);
      dispatch(setDevice(newDevice));
      newDevice.on("ready", () => {});
      newDevice.on("error", (error) => {});
      const roomName = "room1";
      // Initiate the call and add users to the conference room
      combinedInfo.map(async (phoneNumber) => {
        await newDevice.connect({
          params: JSON.stringify({
            To: phoneNumber, // The phone number to dial`, // This makes the user join the conference room
            callerId: "+18664520710", // The caller ID to display
            intent: "dial", // Intent for dialing the call
            serviceId: selectedCard.Id,
          }),
        });
      });

      setIsInCall(true); // Set call status to true
    } catch (error) {
      console.error("Error during call initiation:", error);
    }
  };
  const handleLinkModal = () => {
    console.log("Clicked");
    dispatch(setLinkModal(true));
  };

  const handleMobileDevice = () => {
    if (!islogin) {
      console.log(!islogin, "islogin");
      setMobileModalOpen(true);
      console.log(mobileModalOpen, "mobileModalOpen");
    } else {
      handleClick();
    }
  };
  const handleDisconnectButtonClick = () => {
    if (device) {
      device.disconnectAll(); // Disconnect all active calls
      setIsInCall(false); // Update the call status to false
    }
  };
  return (
    <>
      <Row className="image">
        <div className="d-flex align-items-center justify-content-center p-0">
          {/* <button
              onClick={handlePrev}
              className="arrow left-arrow  d-flex justify-content-center mt-0 mx-1"
              style={{ width: "25px", fontSize: "30px" }}
            >
              &#10094;
            </button> */}
          <img
            src={selectedCard?.Photo ? JSON.parse(selectedCard?.Photo)[0] : ""}
            alt="Static Image image-fluid rounded"
            // PlaceHolder={staticProfilePhoto}
            style={{
              width: "inherit",
              height: "revert-layout",
              objectFit: "fill",
            }}
            className="rounded "
          />
          {/* <button
              onClick={handleNext}
              className="arrow right-arrow  d-flex justify-content-center mt-0 mx-1"
              style={{ width: "25px", fontSize: "30px" }}
            >
              &#10095;
            </button> */}
        </div>
      </Row>
      <Row className="bio-container">
        {" "}
        <Row style={{ cursor: "pointer", gap: 5 }}>
          <div className="">
            <h5>{selectedCard?.listName}</h5>
            {/* <h5>{selectedCard?.FullName}</h5> */}
            {selectedCard?.caIdStatus === 1 ? (
              <h5 className="text-nowrap my-2">
                <span className="text-primary">
                  <MdVerified size={30} />
                </span>
                &nbsp;Verified & Trusted
              </h5>
            ) : null}
          </div>
          <p>
            {/* <i className="fa-solid fa-location-dot"></i>{" "} */}
            <FaLocationArrow />
            {selectedCard?.Location || selectedCard?.serviceLocation}
          </p>
          <Col className="pb-3">
            <ReactStars
              count={5}
              size={24}
              value={selectedCard?.Rating}
              activeColor="#ffd700"
              edit={false}
            />
          </Col>
        </Row>
        <Row className="mx-0">
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <button
              type="button"
              className="btn btn-primary btn-sm w-100"
              style={{ fontSize: "small" }}
              onClick={() => openHireModal()}
            >
              Hire
            </button>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <button
              type="button"
              className="btn btn-success btn-sm w-100"
              style={{ fontSize: "x-small" }}
              onClick={OpenInvite}
            >
              Invite to listing
            </button>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn-sm w-75 mt-1"
            style={{ fontSize: "small" }}
            onClick={() => setIsMsgModal(true)}
          >
            Send Message
          </button>
        </div>
        <Row
          className="d-flex justify-content-center"
          style={{ marginLeft: "1px" }}
        >
          {parseInt(selectedCard?.createdBy) !== parseInt(user?.UserID) &&
            selectedCard?.customerToVenue === 1 && (
              <>
                <Col
                  xs={12}
                  sm={6}
                  className="d-flex justify-content-center mt-3"
                >
                  {/* <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                    <DropdownToggle
                      caret
                      color="success"
                      size="lg"
                      className="w-100"
                      style={{ fontSize: "medium" }}
                    >
                      Start Virtual Tour
                    </DropdownToggle>
                    <DropdownMenu style={{ marginLeft: "-10px" }}>
                      <DropdownItem
                        onClick={handleCallButtonClick}
                        style={{ fontSize: "medium" }}
                      >
                        Continue via VOIP
                      </DropdownItem>
                      <DropdownItem
                        onClick={handleClick}
                        style={{ fontSize: "medium" }}
                      >
                        Continue via Mobile Device
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                  <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                    <DropdownToggle
                      caret
                      color="success"
                      size="lg"
                      className="w-100"
                      style={{ fontSize: "medium" }}
                    >
                      Start Virtual Tour
                    </DropdownToggle>
                    <DropdownMenu className="virtualDropdown">
                      {/* <DropdownItem
                                            onClick={handleCallButtonClick}
                                            style={{ fontSize: "14px" }}
                                          >
                                            Continue via VOIP
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={handleClick}
                                            style={{ fontSize: "14px" }}
                                          >
                                            Continue via Mobile Device
                                          </DropdownItem> */}
                      <Row className="px-1 py-2">
                        <Col
                          sm={12}
                          lg={6}
                          className="text-center virtual_col p-1"
                        >
                          <FaSquarePhone size={50} style={{ color: "green" }} />
                          <h3 className="mb-2">Phone Call</h3>
                          <Button
                            size="sm"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={handleCallButtonClick}
                          >
                            Call Service Provider
                          </Button>
                          <div className="text-center">
                            {isInCall && (
                              <div className="my-2">Calling Vendor...</div>
                            )}
                            {isInCall && (
                              <Button
                                color="danger"
                                onClick={handleDisconnectButtonClick}
                                className="my-2"
                              >
                                Disconnect Call
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col sm={12} lg={6} className="text-center px-1 py-2">
                          <MdVideoChat size={50} style={{ color: "#0097CD" }} />
                          <h3 className="mb-2">Video Call</h3>
                          <Row>
                            <Col sm={12} lg={12}>
                              <button
                                className="btn btn-sm btn-primary"
                                style={{ cursor: "pointer" }}
                                onClick={handleLinkModal}
                              >
                                Connect via website
                              </button>
                            </Col>
                            <Col sm={12} lg={12}>
                              <button
                                className="btn btn-sm btn-primary mt-2"
                                style={{ cursor: "pointer" }}
                                onClick={handleMobileDevice}
                              >
                                Connect via mobile device
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </DropdownMenu>
                  </Dropdown>
                </Col>

                {/* Call Status Section */}
                {/* <Col xs={12} className="text-center mt-3">
                  {isInCall && <div className="my-2">Calling Vendor...</div>}
                  {isInCall && (
                    <Button
                      color="danger"
                      onClick={handleDisconnectButtonClick}
                      className="my-2"
                    >
                      Disconnect Call
                    </Button>
                  )}
                </Col> */}
              </>
            )}
        </Row>
        <Row className="d-flex justify-content-between align-items-center mb-2 mt-0">
          <h6 className="p-1">Special Offer</h6>
          <p>{selectedCard?.specialOffer}</p>
        </Row>
        <Row className="mt-1">
          <p>
            <b>Bio</b>
          </p>
          <span>{selectedCard?.Bio}</span>
        </Row>
        {selectedCard?.facebookUrl !== "" &&
          selectedCard?.instagramUrl !== "" &&
          selectedCard?.yelpUrl !== "" && (
            <Row className="mt-3">
              <p>
                <b>Profile Links</b>
              </p>
              <div>
                <ul className="d-inline-flex gap-10">
                  <li>
                    {selectedCard?.facebookUrl !== "" && (
                      <Link
                        to={selectedCard?.facebookUrl}
                        style={{ fontSize: "22px" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <FaFacebook />
                      </Link>
                    )}
                  </li>
                  <li>
                    {selectedCard?.instagramUrl !== "" && (
                      <Link
                        to={selectedCard?.instagramUrl}
                        style={{ fontSize: "22px" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <FaInstagram />
                      </Link>
                    )}
                  </li>
                  <li>
                    {selectedCard?.yelpUrl !== "" && (
                      <Link
                        to={selectedCard?.yelpUrl}
                        style={{ fontSize: "22px" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYelp />
                      </Link>
                    )}
                  </li>
                </ul>
                <div className="text-center my-2">
                  <ChatBubbleOutlineIcon
                    sx={{ fontSize: 15, marginRight: 1 }}
                  />
                  Typically replies within {formattedTime}
                  <a
                    onClick={() => setIsMsgModal(true)}
                    className="text-success cursor-pointer"
                  >
                    Message Now
                  </a>
                </div>
                <p>
                  👀 {viewers - 1} other people are looking at this vendor right
                  now
                </p>
                <div className="text-center bg-danger text-white p-1 rounded">
                  📈 Vendor page viewed {viewCount / 2} times in the last 24
                  hours
                </div>
              </div>
            </Row>
          )}
      </Row>
      <VendorMaps
        location={selectedCard?.Location || selectedCard?.serviceLocation}
      />
      <Modal
        isOpen={mobileModalOpen}
        toggle={() => {
          setMobileModalOpen(false);
          setName("");
          setSelectedCountry("+1");
          setPhoneNumber(null);
        }}
      >
        <ModalBody>
          <div className="">
            <FormGroup>
              <Label>
                Email<b className="text-danger">*</b>
              </Label>
              <Input
                type="email"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Row className="mt-2">
                <Col sm={4}>
                  <Label>Country Code:</Label>
                  <FormGroup>
                    <div className="d-flex align-items-center login-ctr-select">
                      <Select
                        options={countryOptions}
                        name="CountryCode"
                        placeholder="Select"
                        value={
                          // selectedCountry
                          //   ? selectedCountry
                          //   : userDetails.CountryCode
                          selectedCountry
                            ? {
                                label: selectedCountry,
                                value: selectedCountry,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          handleCodeChange(selectedOption.value);
                        }}
                        className="w-100"
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <Label>
                      Phone Number <b className="text-danger">*</b>
                    </Label>
                    <Input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                        if (isValidPhone) {
                          setPhoneNumber(value);
                        }
                      }}
                      maxLength={10}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            <div className="text-center">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mb: 2 }}
                onClick={handleClick}
              >
                Start Virtual Tour Now
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <MsgModal
        isOpen={isMsgModal}
        closeModal={() => setIsMsgModal()}
        selectedCard={selectedCard}
        userDetails={user}
      />
    </>
  );
};

export default VendoeProfileDt;
