// get Order Detail By Id

import { toast } from "react-toastify";
import axiosClient from "../../axios-client";

export const GetItemOrderById = async (id) => {
  try {
    const response = await axiosClient.get(`item/OrderDetailById/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.itemList;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error fetching questions");
  }
};
// get connections By Id

export const GetConnections = async (id) => {
  console.log(id, "FetchConnection");
  try {
    const response = await axiosClient.put(`message/FetchConnection/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      // throw new Error(response.data.message);
      toast.info(response.data.message);
    }
  } catch (error) {
    // throw new Error("Error fetching questions");
    toast.error("Error fetching connections");
  }
};
export const GetJobOffers = async (id) => {
  try {
    const response = await axiosClient.put(`message/ListJobOp/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      // throw new Error(response.data.message);
      toast.info(response.data.message);
    }
  } catch (error) {
    // throw new Error("Error fetching questions");
    toast.error("Error fetching connections");
  }
};
// api to post  new template
export const CreateNewTemplate = async (request) => {
  try {
    const response = await axiosClient.post(`message/messageTemplate`, request);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      // throw new Error(response.data.msg);
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error adding new template");
  }
};
// api to Update template
export const updateTemplate = async (id, request) => {
  try {
    const response = await axiosClient.post(
      `message/messageTemplateUpdate/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response;
    } else {
      // throw new Error(response.data.msg);
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error update template");
  }
};

// Get all templates

export const GetAllTemplates = async () => {
  try {
    const response = await axiosClient.put(`message/ListAllMessageTemplate`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Error fetching templates");
  }
};

export const GetImageSentListCustomer = async (id) => {
  try {
    const response = await axiosClient.get(
      `image/GetImageSentListCustomer/${id}`
    );
    if (response.data.statusCode === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error fetching Images");
  }
};
export const GetAllMediaTemplates = async (request) => {
  try {
    const response = await axiosClient.put(`image/getAllImage`, request);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Error fetching templates");
  }
};
export const UpdateImageStatus = async (id, request) => {
  try {
    const response = await axiosClient.put(`image/UpdateStatus/${id}`, request);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Error update template");
  }
};
export const UpdateImagePosition = async (request) => {
  try {
    const response = await axiosClient.put(`image/UpdatePosition`, request);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Error update poisition");
  }
};

// add img temp
export const AddImgTemplate = async (request) => {
  try {
    const response = await axiosClient.post(`image/addImage`, request);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      // throw new Error(response.data.msg);
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error update template");
  }
};
// Add Oppertunity Inbox
export const oppertunityInbox = async (request) => {
  try {
    const response = await axiosClient.post(
      `message/OpportunityInbox`,
      request
    );
    if (response.data.statusCode === 200) {
      return response;
    } else {
      // throw new Error(response.data.msg);
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error to add Oppertunity Inbox");
  }
};
export const usersAdTypesApi = async (id) => {
  try {
    const response = await axiosClient.get(
      `services/ServiceCategoryNameByUser`
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error fetching Images");
  }
};

export const orderInvoice = async (request) => {
  try {
    const response = await axiosClient.post(`order/OrderInvoice`, request);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error fetching Images");
  }
};
// api to Send message
export const scheduleMessage = async (formData) => {
  try {
    const response = await axiosClient.post(
      `message/CreateScheduleMessage`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Sending Message");
  }
};

export const getInvoicePay = async (Id) => {
  try {
    const response = await axiosClient.get(`/Order/getInvoicePay/${Id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Invoice details");
  }
};

export const addGurudwaraNews = async (formData) => {
  try {
    const response = await axiosClient.post(
      `/Gurudwara/addGurudwaraNews/`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to  Add Gurudwara News");
  }
};

export const updateGurudwaraNews = async (id, formData) => {
  try {
    const response = await axiosClient.put(
      `/Gurudwara/updateGurudwaraNews/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to Update Gurudwara News");
  }
};

export const getGurudwaraNewsList = async (formData) => {
  try {
    const response = await axiosClient.put(
      `/Gurudwara/getGurudwaraNewsList`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to fetch Gurudwara News");
  }
};
// get  all post
// export const GetAllPosts = async (Id) => {
//   try {
//     const response = await axiosClient.get(`Community/getCommunityList/${Id}`);
//     if (response.data.statusCode === 200) {
//       return response.data.Data;
//     } else {
//       toast.error(response.message);
//     }
//   } catch (error) {
//     toast.error("Error fetching getCommunityList");
//   }
// };
export const LikeThePost = async (formData) => {
  try {
    const response = await axiosClient.post(
      `Community/likeCommunityPost`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error Liking Post");
  }
};
// comment on post
export const CommentOnPost = async (formData) => {
  try {
    const response = await axiosClient.post(
      `Community/AddCommunityComment`,
      formData
    );
    console.log(response, "response");
    if (response.data.success) {
      return response.data.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error Sending Comment");
  }
};

// get Likes
export const GetLikes = async (formData) => {
  try {
    const response = await axiosClient.put(
      `Community/countLikesByCommunityId`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.data;
    } else {
      toast.error(response.data.msg);
    }
  } catch (error) {
    toast.error("Error to fetch Likes");
  }
};
// get All comments
export const GetAllComments = async (id) => {
  try {
    const response = await axiosClient.get(
      `Community/listCommunityComments?communityId=${id}`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Error fetching get Community Comments");
  }
};

export const listInvoices = async (formData) => {
  try {
    const response = await axiosClient.put(`/order/listInvoices`, formData);
    if (response.data.statusCode === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to fetch Gurudwara News");
  }
};
