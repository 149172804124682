import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { Card, Container } from "reactstrap";
import "../LcaCards.css"; // Import your custom CSS file
import "moment-timezone/builds/moment-timezone-with-data";
import LcaBox from "../LcaBox";
import { myOffers } from "../../Data2";
import { setMyOffers, setSelectedAd } from "../../../../redux/slices/LcaSlice";
import ChatModal from "../../modals/ChatModal";
import {
  setMessages,
  setSelectedConversation,
} from "../../../../redux/slices/MsgSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CarouselComponent from "../BizzList";

const LcaCards2 = ({ allProducts, carouselData }) => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userdata = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { lcaLoading, selectedAd } = useSelector((state) => state.lcAds);
  const navigate = useNavigate();
  const [isMsgModalOpen, setIsMsgModalOpen] = React.useState(false);
  const { selectedConvo } = useSelector((state) => state.messages);
  const closeModal = () => {
    dispatch(setSelectedConversation(null));
    setIsMsgModalOpen(false);
    dispatch(setSelectedAd(null));
    dispatch(setMessages(null));
  };
  console.log(selectedConvo, "selectedConvo");
  React.useEffect(() => {
    if (islogin) {
      fetchMyOffers();
    }
  }, []); // Only run on component mount
  const fetchMyOffers = async () => {
    console.log("function called");
    const type = {
      Type: "provider",
    };
    let id = user?.UserID;
    const response = await myOffers(id, type);
    dispatch(setMyOffers(response));
  };
  if (lcaLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <FadeLoader color={"#36D7B7"} />
      </div>
    );
  }
  return (
    <Container>
      {allProducts && allProducts.length > 0 ? (
        <div>
          {allProducts.map((ad, index) => (
            <div key={ad.Id} style={{ cursor: "pointer" }}>
              <p
                className="mb-1"
                style={{ fontSize: "medium" }}
                onClick={() => {
                  islogin
                    ? navigate(`/userDetails/${ad.createdBy}`)
                    : toast.info("Please login to view profile");
                }}
              >
                {ad?.FullName}
              </p>
              <LcaBox
                ad={ad}
                isMsgModalOpen={isMsgModalOpen}
                setIsMsgModalOpen={setIsMsgModalOpen}
                selectedAd={selectedAd}
              />
              {index === 2 && carouselData?.row1?.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData?.row1} />
                </Card>
              )}
              {index === 5 && carouselData?.row2?.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData?.row2} />
                </Card>
              )}
              {index === 8 && carouselData?.row3?.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData.row3} />
                </Card>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center"></div>
        // <div
        //   className="text-center py-5 text-primary"
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     margin: "50px 0px",
        //   }}
        // >
        //   <p
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       margin: "10px 0px",
        //       fontSize: "20px",
        //     }}
        //     className="text-center py-5"
        //   >
        //     No cards to display
        //   </p>
        // </div>
      )}
      <ChatModal
        isOpen={isMsgModalOpen}
        closeModal={closeModal}
        ad={selectedAd}
      />
    </Container>
  );
};

export default LcaCards2;
