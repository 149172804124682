import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import GurdwaraNews from "./GurdwaraNews";
import MediaOrRadioNews from "./MediaOrRadioNews";
import { getGurudwaraNewsList } from "../../../components/Header/Data3";
import "../DashboardPages.css";

const MyGurudwaraNewsPage = () => {
  const [activeTab, setActiveTab] = useState("Gurdwara News");
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const [gurdwaraList, setGurdwaraList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const id = user?.Gurudwara;
  const navigate = useNavigate();

  const fetchGurudwaraNewsList = async () => {
    if (isAdmin) {
      const newsList = await getGurudwaraNewsList();
      const gurdwaraList = newsList.filter((news) => news.newsType === 0);
      setGurdwaraList(gurdwaraList);
      const mediaList = newsList.filter((news) => news.newsType === 1);
      setMediaList(mediaList);
    } else {
      const newsList = await getGurudwaraNewsList(user?.userID);
      const gurdwaraList = newsList.filter((news) => news.newsType === 0);
      setGurdwaraList(gurdwaraList);
      const mediaList = newsList.filter((news) => news.newsType === 1);
      setMediaList(mediaList);
    }
  };

  useEffect(() => {
    fetchGurudwaraNewsList();
  }, [id]);

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col
            sm={8}
            lg={9}
            className="requests-container gurdwara-container position-relative "
          >
            <Row
              className="d-flex row my-2 wc_ib-tabs w-100"
              style={{ marginLeft: "0px" }}
            >
              <div className="text-end mb-3" md={4} lg={4} xs={4}>
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    navigate("/dashboardpage/createGurdwaraNews");
                  }}
                >
                  <BiPlusCircle /> Add News
                </Button>
              </div>
              <div className="d-flex p-0">
                <h5
                  className={`col-sm-4 ${
                    activeTab === "Gurdwara News" ? "active " : ""
                  } mx-0`}
                  onClick={() => {
                    setActiveTab("Gurdwara News");
                  }}
                  style={{
                    textDecoration: activeTab !== "" && "none", // Apply underline when active
                    cursor: "pointer", // Add cursor style to indicate clickable
                    width: "50%",
                  }}
                >
                  Gurdwara News
                </h5>

                <h5
                  className={`col-sm-4 ${
                    activeTab === "Media/Radio News" ? "active" : ""
                  } mx-0`}
                  onClick={() => {
                    setActiveTab("Media/Radio News");
                  }}
                  style={{
                    textDecoration: activeTab !== "Media/Radio News" && "none", // Apply underline when active
                    cursor: "pointer", // Add cursor style to indicate clickable
                    width: "50%",
                  }}
                >
                  Media/Radio News
                </h5>
              </div>
            </Row>
            <div className="tab-content">
              {activeTab === "Gurdwara News" && (
                <div>
                  <GurdwaraNews
                    gurdwaraList={gurdwaraList}
                    fetchGurudwaraNewsList={fetchGurudwaraNewsList}
                  />
                </div>
              )}
              {activeTab === "Media/Radio News" && (
                <div>
                  <MediaOrRadioNews
                    mediaList={mediaList}
                    fetchGurudwaraNewsList={fetchGurudwaraNewsList}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyGurudwaraNewsPage;
