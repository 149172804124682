import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios-client";

// Async thunk for fetching websites
const getAllWebsites = createAsyncThunk(
  "myWebsites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("ads/GetAllCommunityWebsites");
      console.log("API Response:", response.data);
      return response.data.Data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch websites"
      );
    }
  }
);

const initialState = {
  loadingWebSites: false,
  myWebsites: [],
  error: null,
};

const comWebSlice = createSlice({
  name: "CommunityWebSite",
  initialState,
  reducers: {
    setLoadingWebSites(state, action) {
      state.loadingWebSites = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWebsites.pending, (state) => {
        state.loadingWebSites = true;
      })
      .addCase(getAllWebsites.fulfilled, (state, action) => {
        state.loadingWebSites = false;
        state.myWebsites = action.payload;
      })
      .addCase(getAllWebsites.rejected, (state, action) => {
        state.loadingWebSites = false;
        state.error = action.payload;
      });
  },
});

export const { setLoadingWebSites } = comWebSlice.actions;
export { getAllWebsites };
export default comWebSlice.reducer;
