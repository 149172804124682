import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import CustomFields from "./CustomFields";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerToVenue,
  setDealTerms,
  setDialExtension,
  setMandate,
  setPdfFile,
  setPhone,
  setVenueToCustomer,
  setContractTerm,
} from "../../../redux/slices/ServiceInfo";
import AdditionalDetails from "./AdditionalDetails";
import Switch from "@mui/material/Switch";
import uploadToS3 from "../../../utils/awsS3Upload";
import Editor from "react-simple-wysiwyg";
const Details = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [filePreview, setFilePreview] = useState(null);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file, "handleFileChange");
    const maxFileSizeMB = 5;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
    let folder = "servicePdf/";
    if (!file) {
      toast.error("No file selected.");
      return;
    }
    if (file.type !== "application/pdf") {
      toast.error("Please select a PDF file.");
      return;
    }
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size should be less than ${maxFileSizeMB} MB.`);
      return;
    }
    try {
      const pdfUrl = await uploadToS3(file, folder); // Upload to AWS S3
      dispatch(setPdfFile(pdfUrl)); // Store the result in the Redux state
    } catch (error) {
      toast.error("Failed to upload the PDF. Please try again.");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result); // Show the preview
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    if (serviceInfo.pdfFile) {
      setFilePreview(serviceInfo.pdfFile);
    }
  }, [serviceInfo.pdfFile]);

  const handleFileRemove = () => {
    dispatch(setPdfFile(null));
    setFilePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };
  const handleDialExtensionChange = (e) => {
    const dialExtension = e.target.value;
    if (!/^\d{0,4}$/.test(dialExtension)) {
      return;
    }
    dispatch(setDialExtension(dialExtension));
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (!/^\d{0,10}$/.test(phone)) {
      return;
    }
    dispatch(setPhone(phone));
    dispatch(setMandate(!!phone));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isAdmin) {
      dispatch(setMandate(!!serviceInfo.dialExtension));
    }
  }, [serviceInfo.dialExtension]);

  const [contractTerms, setContractTerms] = useState(
    serviceInfo.contractTerm.length !== 0
      ? serviceInfo.contractTerm
      : [
          {
            contractType: "",
            contractDetails: "",
          },
        ]
  );

  const handleAddContractTerm = () => {
    const currentContractTerm = contractTerms[contractTerms.length - 1];
    if (
      currentContractTerm.contractType.trim() === "" &&
      currentContractTerm.contractDetails.trim() === ""
    ) {
      return;
    }
    setContractTerms((prevContractTerms) => [
      ...prevContractTerms,
      {
        contractType: "",
        contractDetails: "",
      },
    ]);
  };

  const handleRemoveContractTerm = (index) => {
    setContractTerms((prevContractTerms) =>
      prevContractTerms.filter((_, i) => i !== index)
    );
  };

  const handleContractTermChange = (index, field, value) => {
    setContractTerms((prevContractTerms) =>
      prevContractTerms.map((contractTerm, i) =>
        i === index ? { ...contractTerm, [field]: value } : contractTerm
      )
    );
  };

  useEffect(() => {
    dispatch(setContractTerm(contractTerms));
  }, [contractTerms]);
  console.log(contractTerms, "contractTerms");
  return (
    <>
      <CustomFields />
      <Row className="form-container p-5 m-3 ">
        <h6>Deal Terms</h6>
        <span style={{ padding: "10px" }}>Details of your Deal Terms</span>
        <FormGroup>
          <Input
            type="textarea"
            name="dealTerms"
            value={serviceInfo.dealTerms || ""}
            onChange={(e) => dispatch(setDealTerms(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <Label>Phone Number</Label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Input
                type="number"
                name="Phone"
                placeholder="Type phone number customers can reach you at."
                value={serviceInfo.Phone || ""}
                onChange={(e) => handlePhoneChange(e)}
              />
            </Col>
            {isAdmin && (
              <Col>
                <Label>Extension: </Label>
                <span style={{ color: "red" }}>*</span>
                <Input
                  type="number"
                  name="Extension"
                  placeholder="Add dial extension"
                  value={serviceInfo.dialExtension || ""}
                  onChange={(e) => handleDialExtensionChange(e)}
                  disabled={user?.RoleId === 1 ? false : true}
                />
              </Col>
            )}
          </Row>
        </FormGroup>
        {/* {isAdmin && ( */}
        <Row>
          <Col>
            <AdditionalDetails />
          </Col>
          {isAdmin && (
            <Row className="mt-3">
              <Label style={{ fontSize: "18px" }}>Enable Virtual tour:</Label>
              <Col>
                <div>
                  <span className="mx-2">Customer to Venue</span>
                  <Switch
                    name="customerToVenue"
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setCustomerToVenue(newChecked)); // Update Redux state
                    }}
                    checked={serviceInfo?.customerToVenue}
                  />
                  {/* <Input
                    type="checkbox"
                    name="customerToVenue"
                    // value={serviceInfo?.customerToVenue || ""}
                    checked={serviceInfo?.customerToVenue}
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setCustomerToVenue(newChecked)); // Update Redux state
                    }}
                  /> */}
                  {/* <span className="mx-2">Customer to Venue</span> */}
                </div>
              </Col>
              <Col>
                <div>
                  <span className="mx-2">Venue to Customer</span>
                  <Switch
                    name="venueToCustomer"
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setVenueToCustomer(newChecked)); // Update Redux state
                    }}
                    checked={serviceInfo?.venueToCustomer}
                  />
                  {/* <Input
                    type="checkbox"
                    name="venueToCustomer"
                    // value={serviceInfo?.venueToCustomer || ""}
                    checked={serviceInfo?.venueToCustomer}
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setVenueToCustomer(newChecked)); // Update Redux state
                    }}
                  /> */}
                  {/* <span className="mx-2">Venue to Customer</span> */}
                </div>
              </Col>
            </Row>
          )}
        </Row>
        {/* )} */}
      </Row>
      <Row className="form-container p-5 m-3 ">
        <h6>Contract Terms</h6>
        {serviceInfo?.contractTerm &&
          serviceInfo?.contractTerm.map((term, index) => (
            <div key={index}>
              <FormGroup>
                <Label>Contract Type</Label>
                <Input
                  type="text"
                  name="contractType"
                  value={term.contractType}
                  onChange={(e) =>
                    handleContractTermChange(
                      index,
                      "contractType",
                      e.target.value
                    )
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Contract Details</Label>
                <Editor
                  value={term.contractDetails}
                  onChange={(e) =>
                    handleContractTermChange(
                      index,
                      "contractDetails",
                      e.target.value
                    )
                  }
                />
              </FormGroup>
              <div className="text-end gap-10">
                {index < contractTerms.length - 1 && (
                  <Button
                    color="danger"
                    onClick={() => handleRemoveContractTerm(index)}
                  >
                    Remove
                  </Button>
                )}
                {index === contractTerms.length - 1 && (
                  <Button
                    color="primary"
                    className="ms-1"
                    onClick={handleAddContractTerm}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          ))}
      </Row>
      <Row className="form-container p-5 m-3">
        <h6>Upload PDF file</h6>
        <span>Files</span>
        <span style={{ fontSize: "small", padding: "5px" }}>
          Add PDF files of your listing (optional). File size limit: 5mb
        </span>
        <FormGroup>
          <Input
            type="file"
            name="pdfFile"
            accept=".pdf"
            onChange={handleFileChange}
            innerRef={fileInputRef} // Attach the ref to the input
          />
        </FormGroup>
        {filePreview && (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <embed src={filePreview} width="250" height="220" />
              <button onClick={handleFileRemove} className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        )}
      </Row>
    </>
  );
};

export default Details;
