import React from "react";
import "./WebsitesInDashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { MdMoreVert } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { getAllWebsites } from "../../../redux/slices/ComWebSlice";

const ActiveSites = () => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSite, setSelectedSite] = React.useState(null);
  const { myWebsites } = useSelector((state) => state.website);
  const activeSites = myWebsites.filter((site) =>
    isAdmin
      ? site.websiteStatus == 1
      : site.createdBy === user.UserID && site.websiteStatus == 1
  );

  console.log(activeSites, "activeSites");

  const handleMenuOpen = (event, site) => {
    setAnchorEl(event.currentTarget);
    setSelectedSite(site);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSite(null);
  };

  const handleEdit = (site) => {
    if (site) {
      navigate(`/dashboardpage/create-website`, { state: { site } });
    }
  };

  const handleUpdate = async (site) => {
    const req = {
      websiteStatus: 2,
      updatedBy: user?.UserID,
    };
    try {
      const response = await axiosClient.put(
        `ads/UpdateCommunityWebsite/${site?.id}`,
        req
      );
      if (response.data.statusCode === 200) {
        toast.success("Deleted Successfully");
        dispatch(getAllWebsites());
        handleMenuClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (activeSites.length === 0) {
    return <div className="active-sites-no-data">No sites found</div>;
  }

  return (
    <div className="active-sites-container">
      {activeSites.map((site, index) => (
        <div key={index} className="active-sites-card">
          <div className="active-sites-header">
            <h5 className="active-sites-name">{site.Title}</h5>
            <MdMoreVert
              className="active-sites-menu-icon"
              onClick={(e) => handleMenuOpen(e, site)}
            />
          </div>
          <div>
            <img
              src={site?.Image}
              alt={site.Title}
              className="active-sites-image"
            />
          </div>
          <p className="active-sites-description">{site.Description}</p>
        </div>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => handleEdit(selectedSite)}>Edit</MenuItem>
        <MenuItem onClick={() => handleUpdate(selectedSite)}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default ActiveSites;
