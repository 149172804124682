import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Row, Col, Input, Label, FormGroup, Form } from "reactstrap";
import {
  GetZoomLink,
  ScheduleCall,
  ZoomStatusUpdate,
} from "../../../../components/Header/Data2";
import CustomerAvaliableCalander from "../../../../components/Header/CustomerAvaliableCalander";
import {
  setOppertunity,
  setOppertunityStage,
  setSelectedConversation,
} from "../../../../redux/slices/MsgSlice";
import { sendMessage } from "../../../../components/Header/Data";
import { useSocket } from "../../../../contexts/SocketContext";

const ScheduleCallModal = ({
  isOpen,
  closeModal,
  msg,
  fetchData,
  fetchMsgs,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [reserveDate, setReserveDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [loading, setLoading] = useState(false);
  const providerZone = msg?.providerTimezone;
  const custZone = msg?.customerTimezone;
  const logUser = localStorage.getItem("USER_ROLE");
  const dispatch = useDispatch();
  const { sendNewMessage } = useSocket();
  console.log(msg, "msg");
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  console.log(providerZone, custZone, msg?.Id, "providerZonecustZone");
  const handleSend = async (zoomLink) => {
    if (zoomLink.trim()) {
      const messageForm = {
        customerId:
          selectedConversation.customerId === user.UserID
            ? selectedConversation.createdBy
            : selectedConversation.customerId,
        postId: selectedConversation.postId,
        postType: 1, // message 1 or requestQuote 2 or offer my service 3
        parentId:
          selectedConversation.parentId === 0
            ? selectedConversation.Id
            : selectedConversation.parentId,
        Description: `Call Scheduled for ${reserveDate} at ${startTime} for 30 mins with ${zoomLink} `,
        messageStatus: 3,
        customerTimezone: custZone,
        providerTimezone: providerZone,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedConversation.ownerId,
        userName: user.UserName.split("@")[0].trim(),
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedConversation.postName,
      };
      const response = await sendMessage(messageForm);

      // toast.success("Zoom link sent");
      if (response.Data) {
        let data = response.Data;
        if (data.parentId == 0) {
          dispatch(setSelectedConversation(data));
        } else {
          fetchMsgs();
        }
        sendNewMessage(response.Data);
        toast.success("Message Sent");
      }
      setTimeout(() => {
        fetchMsgs();
      }, 1000);
    }
    fetchMsgs();
    if (fetchData) fetchData();
    closeModal();
  };

  const AcceptZoomCall = async () => {
    setLoading(true);
    const formattedStartTime = "2024-04-20T08:09:58.000Z";
    const formData = {
      startTime: formattedStartTime,
      customerName: msg?.userName.split("@")[0].trim(),
      vendorName: msg?.senderName,
      needName: msg?.postName,
    };
    try {
      const linkResponse = await GetZoomLink(formData, msg.Id);
      if (linkResponse && linkResponse.id && linkResponse.join_url) {
        const updateFormData = {
          updatedBy: user.UserID,
          zoomStatus: 3,
          meetingId: linkResponse.id,
          meetingLink: linkResponse.join_url,
          meetingStartLink: linkResponse.start_url,
          customerTimezone: custZone,
          providerTimezone: providerZone,
          reserveDate: reserveDate,
          startTime: startTime,
          needName: msg?.postName,
        };
        await ZoomStatusUpdate(updateFormData, msg.Id);
        // closeModal();
        await handleSend(linkResponse.join_url);
      } else {
        toast.error("Error: Invalid response format or join_url not found");
      }
    } catch (error) {
      console.error("Error accepting Zoom call:", error);
      toast.error("An error occurred while accepting the Zoom call");
    }
    fetchMsgs();
    setLoading(false);
  };

  const handleCallwithoutLogin = async () => {
    if (msg?.createdBy === user?.UserID && logUser === "provider") {
      await AcceptZoomCall();
      fetchMsgs();
      if (fetchData) fetchData();
    } else {
      await ScheduleNewCall();
    }
  };

  const ScheduleNewCall = async () => {
    setLoading(true);
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date();
    if (!reserveDate) {
      toast.error("Please enter date");
      return;
    }
    if (!startTime) {
      toast.error("Please enter time");
      return;
    }
    if (reserveDate < currentDate) {
      toast.error("Please do not enter a previous date");
      return;
    }

    const selectedDateTime = new Date(`${reserveDate}T${startTime}`);
    if (selectedDateTime < currentTime) {
      toast.error("Please do not enter a completed time");
      return;
    }
    const request = {
      reserveDate: reserveDate,
      startTime: startTime,
      customerTimezone: custZone,
      providerTimezone: providerZone,
      zoomStatus:
        msg?.createdBy === user?.UserID && logUser === "provider" ? 3 : 1,
      //zoomStatus: 1,
      zoomRole: user.UserID,
    };
    let id = msg.Id;
    await ScheduleCall(id, request);
    if (msg?.createdBy === user?.UserID && logUser === "provider") {
      await AcceptZoomCall();
    }
    setStartTime("");
    setReserveDate("");
    setLoading(false);
    closeModal();
    fetchMsgs();
    if (fetchData) fetchData();
    dispatch(setOppertunity(6));
    dispatch(setOppertunityStage(true));
  };

  let parsedAvailability = [];
  if (msg && typeof msg.availableFrom === "string") {
    try {
      parsedAvailability = JSON.parse(msg.availableFrom);
    } catch (error) {
      console.error("Error parsing msg.availableFrom:", error);
      parsedAvailability = [];
    }
  }
  const convertTo12Hour = (time) => {
    if (typeof time !== "string") {
      return "";
    }
    const [hours, minutes] = time.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${suffix}`;
  };
  console.log(msg?.ownerId, "msg?.ownerId");
  console.log(user.UserID, "msg?.ownerId");
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={() => {
        closeModal();
      }}
      className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          border: "1px solid gray",
          borderRadius: "20px",
          maxHeight: "80%",
          overflowY: "scroll",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <Form>
          {/* {parsedAvailability.length > 0 && (
            <p>
              <small>
                {selectedCard?.userName} is available for a call from{" "}
                {convertTo12Hour(parsedAvailability[0].from)} to{" "}
                {convertTo12Hour(parsedAvailability[0].to)} on{" "}
                {parsedAvailability[0].days.join(", ")}
              </small>
            </p>
          )} */}
          <span>
            <small>
              <b>
                {" "}
                Please schedule the call using the tool below within the Other
                Party mentioned availability schedule.
              </b>
            </small>
          </span>
          <p className="py-2">
            <b className="text-black">Note: </b>All the scheduled calls are set
            to the default PST timezone. If necessary, please go to the Profile
            section to change the timezone
          </p>
          {msg?.ownerId === user.UserID ? (
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Date <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="date"
                    name="date"
                    onChange={(e) => setReserveDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </FormGroup>
              </Col>
              <Col>
                {" "}
                <FormGroup>
                  <Label>
                    From<b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="time"
                    name="StartTime"
                    // min={minTime}
                    // max={maxTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            <>
              <CustomerAvaliableCalander
                reserveDate={reserveDate}
                startTime={startTime}
                setReserveDate={setReserveDate}
                setStartTime={setStartTime}
                availableDays={parsedAvailability}
                ownerId={msg?.customerId}
              />
            </>
          )}

          {startTime && (
            <p>
              <small>
                The Calls will be scheduled for 30 min starting from{" "}
                {convertTo12Hour(startTime)}
              </small>
            </p>
          )}
        </Form>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={ScheduleNewCall}
            disabled={loading}
          >
            Schedule
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleCallModal;
