import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./Posts.css"; // Import external styles
import { useSelector } from "react-redux";
import MyCommunityCard from "./MyCommunityCard";

const MyCookingVideos = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const { communityList, loading, error } = useSelector(
    (state) => state.postForm
  );
  const [activeExpanded, setActiveExpanded] = useState(false);
  const [pendingExpanded, setPendingExpanded] = useState(false);

  const Videos = communityList?.filter(
    (post) => post.postType === "cookingVideos"
  );

  const activeVideos = Videos.filter(
    (post) => post.communityStatus === 1 // Admins see all active news
  );
  const pendingVideos = Videos.filter(
    (post) => post.communityStatus === 0 // Admins see all pending news
  );

  return (
    <div style={{ width: "96%" }}>
      <div>
        <div
          className="category-header"
          onClick={() => setActiveExpanded(!activeExpanded)}
        >
          <h5 className={`category-title ${activeExpanded ? "active" : ""}`}>
            Active Videos
            {activeExpanded ? (
              <FaAngleUp size={20} />
            ) : (
              <FaAngleDown size={20} />
            )}
          </h5>
        </div>
        {activeExpanded && (
          <div className="news-grid">
            {activeVideos.length > 0 ? (
              <MyCommunityCard myPosts={activeVideos} />
            ) : (
              <h6 className="no-posts">No Active Videos</h6>
            )}
          </div>
        )}
        <div
          className="category-header mt-1"
          onClick={() => setPendingExpanded(!pendingExpanded)}
        >
          <h5
            className={`category-title pending ${
              pendingExpanded ? "expanded" : ""
            }`}
          >
            Pending Videos
            {pendingExpanded ? (
              <FaAngleUp size={20} />
            ) : (
              <FaAngleDown size={20} />
            )}
          </h5>
        </div>
        {pendingExpanded && (
          <div className="news-grid">
            {pendingVideos.length > 0 ? (
              <MyCommunityCard myPosts={pendingVideos} />
            ) : (
              <h6 className="no-posts">No Pending Videos</h6>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCookingVideos;
