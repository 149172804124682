import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Shop from "../pages/Shop";
import ProductDetails from "../components/Header/LocalCummunityAds/ProductDetails";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import HirePay from "../components/Header/modals/HirePay";
import UserDashboard from "../pages/userDashboard/UserDashboard";
import WishList from "../pages/Wishlist";
import AllProductList from "../pages/AllproductList";
import Dashboard from "../components/Header/LocalCummunityAds/dashboard";
import AboutUs from "../pages/AboutUs";
import TermsCondition from "../pages/policy/TermsCondition";
import Refund from "../pages/policy/Refund";
import Shipping from "../pages/policy/Shipping";
import Privacy from "../pages/policy/Privacy";
import DashboardFreeGifts from "../pages/DashboardPages/FreeGiftPages/FreeGiftsDashboard";
import ContactUs from "../pages/ContactUs";
import Brands from "../components/Brands/Brands";
import AllCategoris from "../components/Categories/AllCategoris";
import Faq from "../pages/Faq";
import LocalCommunityAds from "../components/Header/LocalCummunityAds/LcaDuplicate/LocalCommunityAds2";
import FlashDeals from "../pages/DashboardPages/FlashDealPages/FlashDeals";
import Orders from "../pages/DashboardPages/Orders/Orders";
import DashboardPage from "../pages/DashboardPages/DashboardPage";
import MyServicesPage from "../pages/DashboardPages/ServicePages/MyServicesPage";
import MyNeedsOrRequests from "../pages/DashboardPages/NeedPages/MyNeedsOrRequests";
import Settings from "../pages/DashboardPages/settingsComponents/Settings";
import SponsoredAds from "../pages/DashboardPages/SponsordAds/SponsoredAds";
import CalendarPage from "../pages/DashboardPages/CalendarPage";
import CardDetails from "../components/Header/LocalCummunityAds/CardDetails";
import Request from "../pages/DashboardPages/NeedPages/Request";
import AddNewAds from "../pages/DashboardPages/SponsordAds/AddNewAds";
import BrowseWeddingVendors from "../components/Header/WeddingVendors/BrowseWeddingVendors";
import User from "../admin/userManagement/Users";
import Chat from "../pages/DashboardPages/inboxPages/ChatWindow/Chat";
import Categories from "../components/Categories/Categories";
import CategoryDetails from "../components/Categories/CategoryDetails";
import Pricing from "../pages/Pricing";
import OrderSummary from "../pages/DashboardPages/Orders/OrderSummary";
import EngagedDetails from "../pages/Home/EngagedDetails";
import CreateDeal from "../pages/DashboardPages/FlashDealPages/CreateDeal";
import ThankYouPage from "../pages/DashboardPages/Orders/ThankYouPage";
import VendorDetails from "../components/Header/WeddingVendors/VendorDetails";
import ZoomEndPage from "../components/ZoomMeeting/ZoomEndPage";
import ZoomWelcomePage from "../components/ZoomMeeting/ZoomWelcomePage";
import PaymentHistory from "../pages/DashboardPages/settingsComponents/PaymentHistory";
import PaidList from "../pages/DashboardPages/settingsComponents/PaidList";
import UserProfile from "../components/Header/UserDetails/UserProfile";
import Questions from "../admin/Questions/Questions";
import ReviewService from "../components/Header/ReviewService";
import MyFavourites from "../pages/DashboardPages/MyFavourites";
import CreateService from "../pages/DashboardPages/ServicePages/CreateService";
import NotifyList from "../pages/DashboardPages/NotifyList";
import ResetPwPage from "../pages/ResetPwPage";
import RequestNeedVersion2 from "../pages/DashboardPages/NeedPages/RequestNeedVersion2";
import InboxPage2 from "../pages/DashboardPages/inboxPages/InboxPage2";
import MyItemsForSale from "../pages/DashboardPages/ItemsForSale/MyItemsForSale";
import CreateItem from "../pages/DashboardPages/ItemsForSale/CreateItem";
import PeopleInMyCom from "../pages/PeopleInMyCommunity/PeopleInMyCom";
import PeopleProfile from "../pages/PeopleInMyCommunity/PeopleProfile";
import ItemsForSale from "../components/Header/Items/ItemsForSale";
import MyGurudwaraPage from "../pages/DashboardPages/GurdwaraPages/MyGurudwaraPage";
import CreateGurdwara from "../pages/DashboardPages/GurdwaraPages/CreateGurdwara";
import BookGurdwara from "../pages/DashboardPages/GurdwaraPages/BookGurdwara/BookGurdwara";
import ItemReview from "../components/Header/Items/ItemReview";
import { NotifyCustomer } from "../pages/DashboardPages/ItemsForSale/NotifyCustomer";
import ConnectionNotify from "../pages/DashboardPages/InboxPages2/Notifuications/ConnectionNotify";
import JobNotify from "../pages/DashboardPages/InboxPages2/Notifuications/JobNotify";
import MyTemplates from "../pages/DashboardPages/MessageTemplates/MyTemplates";
import ItemDetails from "../components/Header/Items/itemsDetails";
import Home from "../pages/Home/Home";
import MyTemplatesDashboard from "../pages/DashboardPages/MessageTemplates/MediaTemplates/MyTemplatesDashboard";
import ReviewMessages from "../pages/DashboardPages/inboxPages/ReviewMessages";
import DealFactors from "../components/Header/WeddingVendors/DealFactors";
import UserAddress from "../pages/userDashboard/UserAddress";
import MyCommunityWebSites from "../pages/DashboardPages/CommunityWebSites/MyCommunityWebSites";
import MyCommunityDiscussions from "../pages/DashboardPages/CommunityDiscussions/MyCommunityDiscussions";
import InvoicePayment from "../pages/DashboardPages/inboxPages/AllDropDowns/InvoicePayment";
import MyGurudwaraNewsPage from "../pages/DashboardPages/GurdwaraPages/MyGurudwaraNewsPage";
import CreateGurdwaraNews from "../pages/DashboardPages/GurdwaraPages/CreateGurdwaraNews";
import CreateWebSite from "../pages/DashboardPages/CommunityWebSites/CreateWebSite";
import GurdwaraNewsDetail from "../pages/DashboardPages/GurdwaraPages/GurdwaraNewsDetail";
import CreatePost from "../pages/DashboardPages/CommunityDiscussions/CreatePost";
import PostDetails from "../components/Header/LocalCummunityAds/CommunityDisscussions/PostDetails";
import CommentsList from "../pages/DashboardPages/CommunityDiscussions/CommentsList";

const Routers = ({ open, setOpen }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  console.log("login", islogin);
  return (
    <>
      <Routes>
        <>
          <Route path="/*" element={<LocalCommunityAds />} />
          <Route path="majesticHomePage" element={<Home />} />
          {!islogin && (
            <>
              <Route
                path="login"
                element={<Login open={open} setOpen={setOpen} />}
              />
              <Route path="signup" element={<Signup />} />
            </>
          )}
          <Route path="order-list" element={<Cart />} />
          <Route path="settings/reset-password/:Id" element={<ResetPwPage />} />
          {/* <Route path="home" element={<Home />} /> */}
          <Route path="home" element={<LocalCommunityAds />} />
          <Route path="Localcommunityads" element={<LocalCommunityAds />} />
          {/* <Route path="items-for-sale" element={<ItemsForSale />} /> */}
          <Route path="/weddingvendors" element={<BrowseWeddingVendors />} />
          <Route path="faq/" element={<Faq />} />
          <Route path="aboutus/" element={<AboutUs />} />
          <Route path="/privacypolicies" element={<Privacy />} />
          <Route path="about/shipping/:shipping" element={<Shipping />} />
          <Route path="about/refund/:refund" element={<Refund />} />
          <Route
            path="about/terms/condition/:termsCondition"
            element={<TermsCondition />}
          />
          <Route path="contact/us/:contactUs" element={<ContactUs />} />
          <Route path="shop/:id" element={<ProductDetails />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="pricing/" element={<Pricing />} />
          <Route path="/requestDetails/:Id" element={<CardDetails />} />
          <Route path="/vendordetails/:Id" element={<VendorDetails />} />
          <Route path="/engagedCouple/:Id" element={<EngagedDetails />} />
          <Route path="/needs-requests/request" element={<Request />} />
          <Route path="/need/request" element={<RequestNeedVersion2 />} />
          <Route path="/zoom-meeting" element={<ZoomWelcomePage />} />
          <Route path="/thankyou" element={<ZoomEndPage />} />
          <Route path="/feedBack/:id" element={<ReviewService />} />
          <Route path="/item-review" element={<ItemReview />} />
          <Route path="/notify-pickup/:id" element={<NotifyCustomer />} />
          <Route
            path="/bookGurdwara/:selectedGurdwara/"
            element={<BookGurdwara />}
          />
          <Route path="invoicePayment/:Id" element={<InvoicePayment />} />
          <Route path="/userDetails/:Id" element={<UserProfile />} />
        </>
        {islogin && (
          <>
            <Route path="/dashboardpage" element={<DashboardPage />} />

            <Route
              path="/dashboardpage/my-services"
              element={<MyServicesPage />}
            />
            <Route
              path="/dashboardpage/free-gifts"
              element={<DashboardFreeGifts />}
            />
            <Route path="/dashboardpage/calendar" element={<CalendarPage />} />
            <Route
              path="/dashboardpage/myFavorites"
              element={<MyFavourites />}
            />
            <Route
              path="/dashboardpage/create-website"
              element={<CreateWebSite />}
            />
            <Route
              path="/dashboardpage/create-post/:tab"
              element={<CreatePost />}
            />
            <Route path="/dashboardpage/notifyList" element={<NotifyList />} />
            <Route
              path="/dashboardpage/needs-requests"
              element={<MyNeedsOrRequests />}
            />
            <Route path="/dashboardpage/flash-Deals" element={<FlashDeals />} />
            <Route path="/flash-deals/new-deal" element={<CreateDeal />} />
            <Route
              exact
              path="/dashboardpage/settings"
              element={<Settings />}
            />
            <Route path="/dashboardpage/settings/:tab" element={<Settings />} />
            <Route
              path="/dashboardpage/sponsored-ads"
              element={<SponsoredAds />}
            />
            <Route exact path="/dashboardpage/inbox" element={<InboxPage2 />} />
            <Route path="/dashboardpage/inbox/:tab" element={<InboxPage2 />} />
            <Route
              path="/dashboardpage/message-templates"
              element={<MyTemplates />}
            />
            <Route
              path="/dashboardpage/community-websites"
              element={<MyCommunityWebSites />}
            />
            <Route
              path="/dashboardpage/community-discussions/:tab"
              element={<MyCommunityDiscussions />}
            />
            <Route
              path="/dashboardpage/media-templates"
              element={<MyTemplatesDashboard />}
            />
            <Route path="/dashboardpage/orders" element={<Orders />} />
            <Route
              path="/dashboardpage/gurdwaramanagement"
              element={<MyGurudwaraPage />}
            />
            <Route
              path="/dashboardpage/gurdwaraNews"
              element={<MyGurudwaraNewsPage />}
            />
            <Route
              path="/gurdwaraNewsDetails/:Id"
              element={<GurdwaraNewsDetail />}
            />
            <Route
              path="/dashboardpage/createGurdwaraNews"
              element={<CreateGurdwaraNews />}
            />
            <Route path="/manageGurdwara" element={<CreateGurdwara />} />
            <Route
              path="/dashboardpage/my-items"
              element={<MyItemsForSale />}
            />
            <Route path="items-for-sale" element={<ItemsForSale />} />
            <Route path="/create-item" element={<CreateItem />} />
            <Route path="/create-service" element={<CreateService />} />
            <Route path="/all/product" element={<AllProductList />} />
            <Route path="/ad/sponsored/new" element={<AddNewAds />} />
            <Route path="dealFactors" element={<DealFactors />} />
            <Route
              path="product/list/:category_id/:subcategory_id/:type/:offer_id"
              element={<Shop />}
            />
            <Route
              path="products/list/:category_id/:subcategory_id/:type/:offer/:brand_id"
              element={<AllProductList />}
            />
            <Route path="product/wishlist" element={<WishList />} />
            <Route path="shop/:id" element={<ProductDetails />} />
            <Route path="cart" element={<Cart />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="all/product" element={<AllProductList />} />
            {/* <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} /> */}
            <Route path="user/deshboard/:activ" element={<UserDashboard />} />
            <Route path="aboutus/" element={<AboutUs />} />
            <Route path="about/privacy/:privacy" element={<Privacy />} />
            <Route path="about/shipping/:shipping" element={<Shipping />} />
            <Route path="about/refund/:refund" element={<Refund />} />
            <Route
              path="about/terms/condition/:termsCondition"
              element={<TermsCondition />}
            />
            <Route path="contact/us/:contactUs" element={<ContactUs />} />
            {/* <Route path="Orders" element={<Orders />} /> */}
            <Route path="all/brands" element={<Brands />} />
            <Route path="all/category" element={<AllCategoris />} />
            <Route path="faq/" element={<Faq />} />
            <Route path="/dashboard/:gurdwara" element={<Dashboard />} />
            <Route path="/admin/usermanagement" element={<User />} />
            <Route path="/order/payment" element={<HirePay />} />
            <Route path="/chat/:id" element={<Chat />} />
            <Route path="/admin/categorymanagement" element={<Categories />} />
            <Route path="/admin/questions" element={<Questions />} />
            <Route path="/category/:id" element={<CategoryDetails />} />
            {/* <Route path="/engagedCouple/:Id" element={<EngagedDetails />} /> */}
            <Route path="/Order/summary" element={<OrderSummary />} />
            <Route path="/Order/placed" element={<ThankYouPage />} />
            <Route path="/cashout/history" element={<PaymentHistory />} />
            <Route path="/cashout/PaidList" element={<PaidList />} />
            <Route
              path="/notifications/Connection-requests"
              element={<ConnectionNotify />}
            />
            <Route
              path="/dashboardpage/notifications/Review"
              element={<ReviewMessages />}
            />
            <Route path="/notifications/Job-Offers" element={<JobNotify />} />
            <Route path="/people-in-mycommunity" element={<PeopleInMyCom />} />
            <Route path="/people-profile/:Id" element={<PeopleProfile />} />
            <Route path="/item-details/:Id" element={<ItemDetails />} />
            <Route path="/postDetails/:title" element={<PostDetails />} />
            <Route path="/test" element={<CommentsList />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default Routers;
