import React, { Suspense, lazy, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../../components/Helmet/Helmet";
import Sidebar from "../../SideBar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SectionSelector = lazy(() => import("./SectionSelector"));
const PostForm = lazy(() => import("./PostForm"));

const CreatePost = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin";

  return (
    <Helmet title="My Community Posts">
      <section className="home-after-login bg-white">
        <div className="container">
          <Row>
            <Col lg={3}>
              <Sidebar />
            </Col>
            <Col lg={9}>
              <Container maxWidth="md" sx={{ mt: 4 }}>
                <Suspense fallback={<div>Loading...</div>}>
                  <SectionSelector />
                  <PostForm user={user} isAdmin={isAdmin} />
                </Suspense>
              </Container>
            </Col>
          </Row>
        </div>
      </section>
    </Helmet>
  );
};

export default CreatePost;
