import React, { useState, useEffect } from "react";
import Helmet from "../../../components/Helmet/Helmet";
import { Col, Row } from "reactstrap";
import Sidebar from "../../SideBar";
import { FadeLoader } from "react-spinners";
import { Button } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyLocalNewsPapers from "./MyLocalNewsPapers";
import MyCookingVideos from "./MyCookingVideos";
import MyCommunityProjects from "./MyCommunityProject";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommunityList,
  setShowComments,
} from "../../../redux/slices/PostFormSlice";
import CommentsList from "./CommentsList";

const MyCommunityDiscussions = () => {
  const { tab } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, showComments } = useSelector((state) => state.postForm);
  const [activeTab, setActiveTab] = useState(tab || "local-newspapers");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";

  useEffect(() => {
    setActiveTab(tab || "local-newspapers");
  }, [tab]);

  useEffect(() => {
    let id = null;
    if (isAdmin) {
      id = 1;
    } else {
      id = user?.UserID;
    }
    dispatch(fetchCommunityList(id));
  }, []);

  // Define tabs, conditionally including "community-projects" for admins only
  const tabs = [
    "local-newspapers",
    "indian-cooking-videos",
    ...(isAdmin ? ["community-projects"] : []),
  ];
  console.log(showComments, "showComments");
  return (
    <Helmet title="My Community Posts">
      <section className="home-after-login bg-white">
        <div className="container">
          <Row>
            <Col sm={3}>
              <Sidebar />
            </Col>
            <Col sm={9}>
              {loading ? (
                <FadeLoader
                  css={{ margin: "0 auto" }}
                  color={"#36D7B7"}
                  loading={loading}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              ) : (
                <div className="posts-dashboard">
                  <div
                    className="text-end px-3 pt-3"
                    style={{ marginTop: "7px" }}
                  >
                    <Button
                      type="button"
                      className="btn btn-danger text-end"
                      onClick={() => navigate("/dashboardpage/create-post/new")}
                    >
                      <BiPlusCircle /> Add New
                    </Button>
                  </div>

                  {/* Tabs */}
                  <div className="col-lg-6 d-flex row mt-3 view-tabs w-100">
                    {tabs.map((item) => (
                      <h5
                        key={item}
                        className={`col-sm-4 ${
                          activeTab === item ? "active" : ""
                        } mx-0`}
                        onClick={() => {
                          navigate(
                            `/dashboardpage/community-discussions/${item}`
                          );
                          dispatch(setShowComments(null));
                        }}
                        style={{
                          textDecoration:
                            activeTab === item ? "underline" : "none",
                          cursor: "pointer",
                          width: `${100 / tabs.length}%`, // Dynamic width based on number of tabs
                        }}
                      >
                        {item.replace("-", " ").toUpperCase()}
                      </h5>
                    ))}
                  </div>

                  {/* Content Based on Active Tab */}
                  <div className=" posts-dasgboard mt-2">
                    {!showComments && (
                      <>
                        {activeTab === "local-newspapers" && (
                          <MyLocalNewsPapers />
                        )}
                        {activeTab === "indian-cooking-videos" && (
                          <MyCookingVideos />
                        )}
                        {isAdmin && activeTab === "community-projects" && (
                          <MyCommunityProjects />
                        )}
                      </>
                    )}
                    {showComments && showComments.Id && <CommentsList />}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </Helmet>
  );
};

export default MyCommunityDiscussions;
