import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaPlusCircle, FaCheckCircle } from "react-icons/fa";
import Editor from "react-simple-wysiwyg";
import { detailsGurudwara } from "../../../components/Header/Data";
import { toast } from "react-toastify";
import {
  addGurudwaraNews,
  updateGurudwaraNews,
} from "../../../components/Header/Data3";
import uploadToS3 from "../../../utils/awsS3Upload";

const CreateGurdwaraNews = () => {
  const [NewsType, setNewsType] = useState(0);
  const [NewsTitle, setNewsTitle] = useState("");
  const [NewsDescription, setNewsDescription] = useState("");
  const [filePreview, setfilePreview] = useState(null);
  const [loading, setLoading] = useState(null);
  const [image, setImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const Id = user?.Gurudwara;
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationFrom, setNotificationFrom] = useState("");
  const [gurdwara, setGurdwara] = useState("");
  const fetchDetailsGurdwara = async () => {
    if (!isAdmin) {
      const response = await detailsGurudwara(Id);
      console.log(response.Data, "notificationFrom");
      const selectedGurdwaraName = response.Data.find(
        (gurdwara) => gurdwara.Id === Id
      );
      console.log(selectedGurdwaraName.Title, "selectedGurdwaraName");
      setGurdwara(selectedGurdwaraName.Title);
    }
    window.scroll(0, 0);
  };

  useEffect(() => {
    if (NewsType === 0) {
      setNotificationFrom(gurdwara);
    } else {
      setNotificationFrom(notificationFrom);
    }
  }, [NewsType, gurdwara]);
  console.log(location.state, "location.state");
  useEffect(() => {
    if (location.state) {
      const title = location.state.news.Title;
      const description = location.state.news.Description;
      const newsType = location.state.news.newsType;
      const Author = location.state.news.Author;
      const image = location.state.news.Image;
      setNewsTitle(title);
      setNewsDescription(description);
      setNewsType(newsType);
      setNotificationFrom(Author);
      setfilePreview(image);
    }
  }, [location.state]);

  useEffect(() => {
    fetchDetailsGurdwara();
  }, [Id]);
  console.log(NewsType, "NewsType");

  const handleImageChange = async () => {
    const files = document.getElementById("imageUpload").files;
    console.log(files, "file");
    if (files.length > 0) {
      const file = files[0];
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePostNews = async (e) => {
    setLoading(true);
    let fileUrl = null;
    if (filePreview && image) {
      let folder = "/news";
      fileUrl = await uploadToS3(image, folder);
      setUploadedImageUrl(fileUrl);
      console.log(fileUrl, "fileUrl");
    } else if (location.state && location.state.news.Image) {
      // Use existing image URL if not changed
      fileUrl = location.state.news.Image;
    }
    if (location.state) {
      const Id = location.state.news.id;
      let updatedFormData = {
        gurudwaraId: Id,
        Author: notificationFrom,
        newsType: parseInt(NewsType),
        Title: NewsTitle,
        Description: NewsDescription,
        updatedBy: user.UserID,
        Image: fileUrl,
        newsStatus: 0,
      };
      console.log(updatedFormData, "updatedFormData");
      await updateGurudwaraNews(Id, updatedFormData);
      toast.success("News Updated Successfully");
    } else {
      let formData = {
        gurudwaraId: Id,
        newsType: parseInt(NewsType),
        Author: notificationFrom,
        Title: NewsTitle,
        Description: NewsDescription,
        createdBy: user.UserID,
        Image: fileUrl,
        newsStatus: 0,
      };
      console.log(formData, "formData");
      await addGurudwaraNews(formData);
      toast.success("News Added Successfully");
    }
    setLoading(false);
    setfilePreview(null);
    navigate("/dashboardpage/gurdwaraNews");
    window.scroll(0, 0);
  };

  return (
    <section className="home-after-login bg-white">
      <div className="container" style={{ position: "relative" }}>
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="mt-3">
            <Row className="m-3 w-100 mt-3" style={{ marginBottom: "20%" }}>
              <p
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  cursor: "pointer",
                  padding: "0px",
                  fontSize: "18px",
                  color: "#000",
                  zIndex: 999,
                  fontWeight: "bold",
                }}
                className="mb-2"
              >
                <FaArrowLeft
                  style={{
                    marginRight: "6px",
                    fontSize: "18px",
                    marginTop: "-3px",
                  }}
                />
                Back
              </p>
              <Row className="form-container p-4 mb-3">
                <Form>
                  <h4 className="mb-2">Add News Information</h4>
                  <FormGroup className="my-3">
                    <h6 className="my-3">Select News Type</h6>
                    <Input
                      type="radio"
                      name="NewsType"
                      value={0}
                      checked={parseInt(NewsType) === 0}
                      onChange={(e) => {
                        setNewsType(e.target.value);
                        setNotificationFrom(gurdwara);
                        setNewsTitle("");
                        setNewsDescription("");
                      }}
                    />
                    &nbsp; <Label for="html">News Broadcast</Label> &nbsp;
                    &nbsp;
                    <Input
                      type="radio"
                      name="NewsType"
                      value={1}
                      checked={parseInt(NewsType) === 1}
                      onChange={(e) => {
                        setNewsType(e.target.value);
                        setNotificationFrom("");
                        setNewsTitle("");
                        setNewsDescription("");
                      }}
                    />
                    &nbsp;<Label for="html">Media/Radio News</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="imageUpload" className="m-0">
                      News Image<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="imageUpload"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      name="images"
                    />
                  </FormGroup>
                  <div
                    className="ad-image-box"
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {filePreview && (
                      <>
                        <img
                          src={filePreview}
                          alt="ad-Uploaded Image"
                          className="uploaded-image image-fluid rounded"
                        />
                      </>
                    )}
                    {!filePreview && <FaPlusCircle className="upload-icon" />}
                  </div>
                  <FormGroup>
                    <Label>Notification From</Label>

                    <Input
                      type="text"
                      value={notificationFrom}
                      onChange={(e) => setNotificationFrom(e.target.value)}
                      disabled={parseInt(NewsType) === 0}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>News Title</Label>
                    <Input
                      type="text"
                      name="NewsTitle"
                      value={NewsTitle}
                      onChange={(e) => {
                        setNewsTitle(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>News Description</Label>
                    <Editor
                      style={{ height: "200px" }}
                      value={NewsDescription}
                      onChange={(e) => {
                        setNewsDescription(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Form>
              </Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  className="btn btn-danger"
                  onClick={handlePostNews}
                  disabled={loading}
                >
                  <FaCheckCircle />{" "}
                  {location.state ? "Update Ad" : "Request Admin Approval"}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CreateGurdwaraNews;
