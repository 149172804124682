// import React from "react";

// const Helmet = (props) => {
//   document.title = "My Sikh Community-" + props.title;
//   return <div className="w-100">{props.children}</div>;
// };

// export default Helmet;
import React from "react";

const Helmet = ({ title, children }) => {
  React.useEffect(() => {
    document.title = `My Sikh Community - ${title}`;

    const metaTags = [
      {
        name: "description",
        content:
          "Free Sikh Community Classified ADs. Categories include real estate, job opportunities, matrimonial listings, local businesses, and childcare services, all posted by individuals within the Sikh community. Connect with fellow Sikh community members.",
      },
      {
        name: "keywords",
        content:
          "Sikh classifieds, free Sikh listings, Gurdwara community classifieds, Sikh community services, Sikh real estate, Sikh jobs, Sikh matrimony, Sikh businesses, Sikh childcare",
      },
    ];

    metaTags.forEach(({ name, content }) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", content);
      } else {
        metaTag = document.createElement("meta");
        metaTag.name = name;
        metaTag.content = content;
        document.head.appendChild(metaTag);
      }
    });
  }, [title]);

  return <div className="w-100">{children}</div>;
};

export default Helmet;
