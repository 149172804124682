import React, { useState } from "react";
import { FormGroup, Input, Spinner } from "reactstrap"; // Ensure Reactstrap is installed
import { FaPlusCircle } from "react-icons/fa"; // Ensure react-icons is installed
import uploadToS3 from "../../../../utils/awsS3Upload";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AddImgTemplate,
  UpdateImagePosition,
  UpdateImageStatus,
} from "../../../../components/Header/Data3";
import ImagePreviewModal from "./ImagePreviewModal";
import TitleDialog from "./TitleDialog";

const SingleImg = ({ image, index, fetchImages, highlightedImages }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const [fileSending, setFileSending] = useState(false);
  const isAdmin = user && user.Role === "admin";
  const [imageObject, setImageObject] = useState(image || null);
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [titleDialog, setTitleDialog] = useState(false);
  const handleFileChange = async (event) => {
    setFileSending(true);
    const files = event.target.files;
    if (files.length > 0) {
      const imageFile = files[0];
      if (imageFile) {
        if (imageFile.size > 1024 * 1024) {
          toast.error("File size exceeds 1MB. Please upload a smaller image.");
          setFileSending(false);
          return;
        }
        try {
          const folder = "mediaTemp/";
          const uploadedImageUrl = await uploadToS3(imageFile, folder); // Upload to S3 and get URL
          const newImage = {
            imageUrl: uploadedImageUrl,
            imageTitle: ``,
            imageDescription: ``,
            imagePosition: index + 1,
          };
          setTitleDialog(true);
          setImageObject(newImage); // Store full image object locally
        } catch (error) {
          console.error("Error uploading image to S3:", error);
          toast.error("Image upload failed. Please try again.");
        }
      }
      setFileSending(false);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleStatus = async (status) => {
    const request = {
      imageStatus: status,
      updatedBy: user?.UserID,
      createdBy: imageObject?.created_by,
    };
    await UpdateImageStatus(imageObject.id, request);
    if (status === 2) {
      // Remove the image from state and notify parent component
      setImageObject(null); // Clear the image object locally
      setModalOpen(false); // Close the modal
      toast.success("Image deleted successfully.");
    } else if (status === 1) {
      // change image_status in image object
      setImageObject((prevImage) => ({ ...prevImage, image_status: status }));
      fetchImages();
      setModalOpen(false); // Close the modal
    }
    fetchImages();
  };
  const ImageStatus = () => {
    const getStatusStyle = (status) => {
      switch (status) {
        case 0:
          return "status-pending"; // Yellow for pending
        case 1:
          return "status-approved"; // Green for approved
        case 2:
          return "status-deleted"; // Red for deleted
        case 3:
          return "status-rejected"; // Red for rejected
        default:
          return "";
      }
    };
    return (
      <div
        className={`image-status ${getStatusStyle(imageObject.image_status)}`}
      >
        <p>
          {imageObject.image_status === 0
            ? "PENDING"
            : imageObject.image_status === 1
            ? "APPROVED"
            : "REJECTED"}
        </p>
      </div>
    );
  };
  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("dragIndex", index.toString()); // Store the drag index
  };

  const handleDrop = async (index, event) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("dragIndex"), 10); // Get the drag index

    if (
      dragIndex === index || // No need to update if dropped on the same position
      dragIndex < 0 || // Invalid index
      dragIndex >= highlightedImages.length
    ) {
      return;
    }

    // Reorder images locally
    const updatedImages = [...highlightedImages];
    const [draggedImage] = updatedImages.splice(dragIndex, 1); // Remove dragged image
    updatedImages.splice(index, 0, draggedImage); // Insert at the new position

    const updatedImageData = updatedImages.map((image, idx) => ({
      id: image.id, // Assuming image has an `id` field
      position: idx, // New position based on index
    }));
    await UpdateImagePosition(updatedImageData);
    fetchImages();
  };

  return (
    <div className="">
      {!isAdmin && index < 4 && (
        <div className="preferred-title">Preferred</div>
      )}
      <FormGroup>
        {/* accept JPEG (.jpg, .jpeg),PNG (.png),GIF (.gif) */}
        <Input
          type="file"
          id={`imageUpload${index}`}
          onChange={handleFileChange}
          accept=".jpg, .jpeg, .png, .gif"
          style={{ display: "none" }}
        />
      </FormGroup>
      {/* Image or Upload Icon */}
      <div
        className={
          !isAdmin && index < 4
            ? "highlighted temp-image-box"
            : "temp-image-box"
        }
        onClick={() => {
          if (imageObject) {
            toggleModal(); // If image exists, open modal
          } else {
            document.getElementById(`imageUpload${index}`).click(); // Otherwise, trigger file input
          }
        }}
        onDragStart={(e) => !isAdmin && handleDragStart(index, e)} // Don't trigger dragStart for admins
        onDragOver={(e) => !isAdmin && e.preventDefault()} // Prevent drop for admins
        onDrop={(e) => !isAdmin && handleDrop(index, e)} // Don't allow drop for admins
        draggable={!isAdmin} // Make image non-draggable for admins
      >
        {fileSending ? (
          <Spinner color="primary" /> // Show a loading spinner while uploading
        ) : imageObject ? (
          <img
            src={imageObject.image_url}
            alt={`Highlighted Image ${index}`}
            className="uploaded-image image-fluid rounded"
            style={{ width: "110px", height: "110px", objectFit: "cover" }}
          />
        ) : (
          <FaPlusCircle className="upload-icon" style={{ fontSize: "24px" }} />
        )}
      </div>
      {imageObject && <ImageStatus />}
      {imageObject && (
        <ImagePreviewModal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          imageUrl={imageObject}
          index={index}
          handleStatus={handleStatus}
        />
      )}
      <TitleDialog
        open={titleDialog}
        handleClose={() => setTitleDialog(false)}
        newImage={imageObject}
        fetchImages={fetchImages}
        type={"img"}
      />
    </div>
  );
};

export default SingleImg;
