import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listName: "",
  serviceLocation: "",
  category: "",
  subCategory: "",
  item: "",
  categoryId: null,
  subCategoryId: null,
  itemId: 0,
  description: "",
  hourlyPrice: "",
  packages: {},
  uploadedImages: [],
  videos: {},
  customDetails: {},
  Phone: "",
  dialExtension: null,
  specialOffer: "",
  giftAmount: null,
  giftTitle: "",
  giftDescription: "",
  dealTerms: "",
  pdfFile: "",
  timeSlots: [{ days: [], from: "", to: "" }],
  ApplyAll: false,
  serviceId: null,
  additionalPeople: null,
  paypalEmail: "",
  venueToCustomer: false,
  customerToVenue: false,
  mandate: false,
  itemsToDisplay: [],
  createdPackages: [],
  options: [],
  contractTerm: [
    {
      contractType: "",
      contractDetails: "",
    },
  ],
};

const ServiceInfoSlice = createSlice({
  name: "serviceInfo",
  initialState,
  reducers: {
    resetServiceInfo: (state) => initialState,
    setListName: (state, action) => {
      state.listName = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategory = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload;
    },
    setItemId: (state, action) => {
      state.itemId = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setHourlyPrice: (state, action) => {
      state.hourlyPrice = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setUploadedImages: (state, action) => {
      state.uploadedImages = action.payload;
    },

    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setCustomDetails: (state, action) => {
      state.customDetails = action.payload;
    },
    setPhone: (state, action) => {
      state.Phone = action.payload;
    },
    setDialExtension: (state, action) => {
      state.dialExtension = action.payload;
    },
    setSpecialOffer: (state, action) => {
      state.specialOffer = action.payload;
    },
    setGiftAmount: (state, action) => {
      state.giftAmount = action.payload;
    },
    setGiftTitle: (state, action) => {
      state.giftTitle = action.payload;
    },
    setGiftDescription: (state, action) => {
      state.giftDescription = action.payload;
    },
    setDealTerms: (state, action) => {
      state.dealTerms = action.payload;
    },
    setPdfFile: (state, action) => {
      state.pdfFile = action.payload;
    },
    setTimeSlots: (state, action) => {
      state.timeSlots = action.payload;
    },
    setApplyAll: (state, action) => {
      state.ApplyAll = action.payload;
    },
    setServiceId: (state, action) => {
      state.serviceId = action.payload;
    },
    setAdditionalPeople: (state, action) => {
      state.additionalPeople = action.payload;
    },
    setPayPalEmail: (state, action) => {
      state.paypalEmail = action.payload;
    },
    setVenueToCustomer: (state, action) => {
      state.venueToCustomer = action.payload;
    },
    setCustomerToVenue: (state, action) => {
      state.customerToVenue = action.payload;
    },
    setServiceLocation: (state, action) => {
      state.serviceLocation = action.payload;
    },
    setMandate: (state, action) => {
      state.mandate = action.payload;
    },
    setItemsToDisplay: (state, action) => {
      state.itemsToDisplay = action.payload;
    },
    setCreatedPackages: (state, action) => {
      state.createdPackages = action.payload;
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    setContractTerm: (state, action) => {
      state.contractTerm = action.payload;
    },
  },
});

export const {
  resetServiceInfo,
  setListName,
  setCategory,
  setSubCategory,
  setItem,
  setCategoryId,
  setSubCategoryId,
  setItemId,
  setDescription,
  setHourlyPrice,
  setPackages,
  setUploadedImages,
  setVideos,
  setCustomDetails,
  setPhone,
  setDialExtension,
  setSpecialOffer,
  setGiftAmount,
  setGiftTitle,
  setGiftDescription,
  setDealTerms,
  setPdfFile,
  setTimeSlots,
  setApplyAll,
  setServiceId,
  setAdditionalPeople,
  setPayPalEmail,
  setVenueToCustomer,
  setCustomerToVenue,
  setServiceLocation,
  setMandate,
  setCreatedPackages,
  setItemsToDisplay,
  setOptions,
  setContractTerm,
} = ServiceInfoSlice.actions;

export default ServiceInfoSlice.reducer;
