import React, { useEffect, useState } from "react";
import { Row, Col, Input, Label, Button, FormGroup } from "reactstrap";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { ShareNeedToMail } from "../Data2";

const ShareModal = ({ isOpen, closeModal, selectedCard }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [email, setEmail] = useState("");
  const handleSendMail = async () => {
    if (!islogin) {
      toast.info("Please Login First");
      return;
    }
    if (!email) {
      toast.error("Please Enter Email");
      return;
    }
    const request = {
      Email: email,
      Title: selectedCard.Title,
      UserName: user.UserName.split("@")[0].trim(),
    };
    await ShareNeedToMail(selectedCard.Id, request);
    closeModal();
    toast.success("Mail Sent Successfully");
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      //   className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "20px",
          backgroundColor: "#fff",
          padding: "20px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          border: "1px solid gray",
          borderRadius: "20px",
          height: "18rem",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <div className="d-flex flex-row align-items-center pb-3">
          <div style={{ width: "50px", height: "50px", marginRight: "30px" }}>
            <img
              src={
                selectedCard && selectedCard?.profilePhoto!==""
                ? JSON.parse(selectedCard?.profilePhoto)[0]
                : PlaceHolder
              }
              // alt="Selected Card"
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
            />
          </div>
          <div>
            <h5>
              <b>{selectedCard ? selectedCard.Title : ""} </b> By{" "}
              {selectedCard ? selectedCard.userName.split("@")[0].trim() : ""}
            </h5>
          </div>
        </div>
        <div>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter Email.."
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleSendMail()}
          >
            Share
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
