import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { MdMoreVert } from "react-icons/md";
import { updateTemplate } from "../../../components/Header/Data3";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CreateTemplate from "./CreateTemplate";
import { fetchTemplates } from "../../../redux/slices/TemplateSlice";

const ActiveTemplates = () => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false); // State for modal
  const { AllTemplates, loading, error, ActiveTemps } = useSelector(
    (state) => state.templates
  );
  const location = useLocation();
  const handleMenuOpen = (event, template) => {
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(template);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTemplate(null);
  };

  const toggleModal = (withTab = false) => {
    setIsModalOpen((prev) => !prev);
    if (!isModalOpen && withTab) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", "edit-template");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else if (isModalOpen) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("tab");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };

  const handleDelete = async () => {
    if (selectedTemplate) {
      await updateTemplate(selectedTemplate.Id, { templateStatus: 2 });
      toast.success("Template deleted successfully");
      dispatch(fetchTemplates());
    }
    handleMenuClose();
  };

  return (
    <div className="pending-templates">
      {ActiveTemps.map((template, index) => (
        <div key={index} className="template-card">
          <div className="template-header">
            <div>
              <h5 className="template-name">{template.Title}</h5>
              <p className="template-description">
                {template.Description.length > 120
                  ? `${template.Description.substring(0, 120)}...`
                  : template.Description}
              </p>
            </div>
            <div>
              <MdMoreVert
                className="menu-icon-template"
                onClick={(e) => handleMenuOpen(e, template)}
              />
            </div>
          </div>
        </div>
      ))}
      {/* Dropdown menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleModal}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <CreateTemplate
        open={isModalOpen}
        handleClose={() => toggleModal()}
        editTemp={selectedTemplate}
      />
    </div>
  );
};

export default ActiveTemplates;
