import Modal from "react-modal";

const OrderInfo = ({ Order, isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      className="order-modal"
      style={{
        content: {},
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <div className="modal-body ">
          <div className="card p-4">
            <div className="row">
              <div className="col-lg-4 text-center d-flex align-items-center justify-content-center">
                <div>
                  <img
                    className="rounded-circle"
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }}
                    src={Order?.profilePhoto}
                    alt=""
                  />
                  <h6 className="pt-3">
                    <b>{Order?.userName.split("@")[0].trim()}</b>
                  </h6>
                </div>
              </div>
              <div className="col-lg-8">
                <p>{Order?.categoryName}</p>
                <h3>{Order?.serviceName}</h3>
                <div className="row pt-3">
                  <div className="col-6">
                    <h6 className="pb-3">Reserve Date :</h6>
                    <h6 className="pb-3">Start Time :</h6>
                    <h6 className="pb-3">End Time :</h6>
                  </div>
                  <div className="col-6">
                    <h6 className="pb-3">{Order?.reserveDate}</h6>
                    <h6 className="pb-3">{Order?.startTime}</h6>
                    <h6 className="pb-3">{Order?.endTime}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default OrderInfo;
