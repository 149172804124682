import React, { useEffect, useState } from "react";
import Sidebar from "../../../SideBar";
import { Button, Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TempImages from "./TempImages";
import "./imgTemp.css";
import TempVideos from "./TempVideos";
import { GetAllMediaTemplates } from "../../../../components/Header/Data3";
import { FadeLoader } from "react-spinners";
import AdminImages from "./AdminImages";
import AdminVideos from "./AdminVideos";
const MyTemplatesDashboard = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [tab, setTab] = useState("Images");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [highlightedImages, setHighlightedImages] = useState([]);
  const [highlightedVideos, setHighlightedVideos] = useState([]);
  const [loading, setloading] = useState(false);

  const fetchImages = async () => {
    console.log("API called");
    try {
      const request = isAdmin
        ? { userName: user?.UserName }
        : { createdBy: user?.UserID };
      const response = await GetAllMediaTemplates(request);
      const mediaData = response?.data?.data || []; // Ensure it's an array
      console.log(mediaData, "response");
      // Sort images safely
      const sortedImages = mediaData
        .filter((item) => item.image_position !== undefined)
        .sort((a, b) => a.image_position - b.image_position);
      // Separate images & videos
      const filterImages = sortedImages.filter((item) => item.image_type === 0);
      const filterVideos = sortedImages.filter((item) => item.image_type === 1);
      // Set images/videos with placeholder handling
      const images = isAdmin
        ? [...filterImages]
        : [
            ...filterImages.slice(0, 30),
            ...Array(30 - filterImages.length).fill(""),
          ];
      const videos = isAdmin
        ? [...filterVideos]
        : [
            ...filterVideos.slice(0, 4),
            ...Array(4 - filterVideos.length).fill(""),
          ];
      setHighlightedImages(images);
      setHighlightedVideos(videos);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchImages().finally(() => setloading(false));
  }, []);
  console.log(highlightedImages, "highlightedImages");
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9}>
            {loading ? (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={loading}
                className="position-absolute top-50 start-50 translate-middle"
                //style={{ left: "0%", top: "40%" }}
              />
            ) : (
              <>
                <div
                  className="col-lg-6 d-flex row mt-3 view-tabs w-100"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      tab === "Images" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setTab("Images");
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("tab", "media-images");
                      navigate(
                        {
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    style={{
                      textDecoration: tab === "Images" ? "underline" : "none",
                      cursor: "pointer",
                      width: "50%",
                    }}
                  >
                    Images
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      tab === "Videos" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setTab("Videos");
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("tab", "media-videos");
                      navigate(
                        {
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    style={{
                      textDecoration: tab === "Videos" ? "underline" : "none",
                      cursor: "pointer",
                      width: "50%",
                    }}
                  >
                    Videos
                  </h5>
                </div>
                {tab === "Images" &&
                  (isAdmin ? (
                    <AdminImages
                      highlightedImages={highlightedImages}
                      setHighlightedImages={setHighlightedImages}
                      fetchImages={fetchImages}
                    />
                  ) : (
                    <TempImages
                      highlightedImages={highlightedImages}
                      setHighlightedImages={setHighlightedImages}
                      fetchImages={fetchImages}
                    />
                  ))}

                {tab === "Videos" &&
                  (isAdmin ? (
                    <AdminVideos
                      highlightedVideos={highlightedVideos}
                      setHighlightedVideos={setHighlightedVideos}
                      fetchImages={fetchImages}
                    />
                  ) : (
                    <TempVideos
                      highlightedVideos={highlightedVideos}
                      setHighlightedVideos={setHighlightedVideos}
                      fetchImages={fetchImages}
                    />
                  ))}
              </>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyTemplatesDashboard;
