// AllDropdowns.js
import React from "react";
import "../AllDropDowns/Dropdowns.css";
import MsgEventDetails from "./MsgEventDetails";
import MsgFollowUpTasks from "./MsgFollowUpTasks";
import MsgOpportunityStage from "./MsgOpportunityStage";
import { useSelector } from "react-redux";

const MsgDropdowns = ({ convo }) => {
  const { showFollowUp, eventMonthsearch, oppertunityFilter } = useSelector(
    (state) => state.messages
  );
  return (
    <div className="wc-msg-drops">
      {eventMonthsearch && convo?.postType == 3 && (
        <MsgEventDetails convo={convo} />
      )}
      {showFollowUp && convo?.postType == 3 && (
        <MsgFollowUpTasks convo={convo} />
      )}
      {oppertunityFilter !== null && convo?.postType == 3 && (
        <MsgOpportunityStage convo={convo} />
      )}
    </div>
  );
};

export default MsgDropdowns;
