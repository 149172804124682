import React, { useEffect, useState } from "react";
import "./WebsitesInHome.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllWebsites } from "../../../../../redux/slices/ComWebSlice";
import axiosClient from "../../../../../axios-client";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Styled card with explicit min/max dimensions
const StyledWebsiteCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "isDraggable", // Prevent 'isDraggable' from being passed to DOM
})(({ theme, isDraggable }) => ({
  width: 220, // Fixed width
  height: 270, // Fixed height
  minWidth: 220, // Ensure it doesn't shrink
  maxWidth: 220, // Ensure it doesn't expand
  minHeight: 270, // Ensure consistent height
  maxHeight: 270, // Cap the height
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
  },
  display: "flex",
  flexDirection: "column",
  overflow: "hidden", // Prevent content overflow
  cursor: isDraggable ? "move" : "default", // Conditionally apply cursor
}));

const CommunityWebsites = () => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const { myWebsites, loadingWebSites, error } = useSelector(
    (state) => state.website
  );
  const [websites, setWebsites] = useState([]);

  useEffect(() => {
    dispatch(getAllWebsites()); // Fetch websites from API
  }, [dispatch]);

  useEffect(() => {
    if (myWebsites?.length > 0) {
      const sortedWebsites = [...myWebsites].sort(
        (a, b) => a.Position - b.Position
      );
      const activeSites = sortedWebsites.filter(
        (site) => site.websiteStatus === 1
      );
      setWebsites(activeSites);
    }
  }, [myWebsites]);

  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (index, event) => {
    if (!isAdmin) return;
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData("index"), 10);

    if (dragIndex === index || dragIndex < 0 || dragIndex >= websites.length)
      return;

    const newWebsites = [...websites];
    const [draggedWebsite] = newWebsites.splice(dragIndex, 1);
    newWebsites.splice(index, 0, draggedWebsite);

    const updatedWebsites = newWebsites.map((website, newIndex) => ({
      ...website,
      Position: newIndex + 1,
    }));

    setWebsites(updatedWebsites);
    handleUpdate(draggedWebsite.id, index + 1);
  };

  const handleUpdate = async (siteId, position) => {
    const req = {
      Position: position,
      updatedBy: user?.UserID,
    };
    try {
      const response = await axiosClient.put(
        `ads/UpdateCommunityWebsite/${siteId}`,
        req
      );
      if (response.data.statusCode === 200) {
        dispatch(getAllWebsites());
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const truncateDescription = (text, maxLength = 100) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text || "";
  };

  return (
    <div className="community-websites-container">
      <Typography variant="h5" component="h5" sx={{ fontStyle: "italic" }}>
        Community Websites
      </Typography>
      <Box className="community-websites">
        <StyledWebsiteCard isDraggable={false}>
          <CardContent sx={{ padding: "8px", flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              component="a"
              href="#/weddingvendors"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
                color: "#2c43ff",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Browse Vendors
            </Typography>
            <Typography variant="body2" color="text.secondary">
              All type of Vendors
            </Typography>
          </CardContent>
        </StyledWebsiteCard>
        {websites.map((website, index) => (
          <StyledWebsiteCard
            key={website.id}
            isDraggable={isAdmin} // Pass isDraggable prop
            draggable={isAdmin}
            onDragStart={(event) => handleDragStart(index, event)}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(index, event)}
          >
            <CardMedia
              component="img"
              height="140"
              src={website.Image}
              alt={website.Title}
              sx={{ objectFit: "cover", width: "100%" }}
            />
            <CardContent sx={{ padding: "8px", flexGrow: 1 }}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                component="a"
                href={website.Url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "#2c43ff",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {website.Title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {truncateDescription(website.Description)}
              </Typography>
            </CardContent>
          </StyledWebsiteCard>
        ))}
      </Box>
    </div>
  );
};

export default CommunityWebsites;
