import React from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Col, Input, Row } from "reactstrap";
import { MdDelete } from "react-icons/md";

const DealFactorItems = ({
  item,
  newItems,
  checked,
  state,
  selectedPackages,
  handleInputChange,
  handlePackageSelection,
  handleAddItemToPackages,
  handleDealFactorItemDeletion,
}) => {
  return (
    <div>
      {item.packages.map((packageItem, packageIndex) => (
        <div
          key={packageIndex}
          className="d-flex justify-content-between align-items-center p-2"
        >
          <div>
            <Input
              type="checkbox"
              className="mt-2"
              onChange={() =>
                handlePackageSelection(item.Id, packageIndex, {
                  ...packageItem,
                  checked: !(`${item.Id}-${packageIndex}` in selectedPackages),
                })
              }
              checked={`${item.Id}-${packageIndex}` in selectedPackages}
              // disabled={state?.invoice ? !checked : false}
            />
            <span className="ms-2">
              <span className="fs-6">{packageItem.title}</span>
              <br />
              <p
                style={{
                  marginLeft: "25px",
                  marginBottom: "0px",
                }}
              >
                {packageItem.description}
              </p>
            </span>
          </div>
          <div className="d-flex flex-column justify-content-end">
            <strong>
              ${packageItem.price}/{packageItem.units}
            </strong>
            <span
              className="text-end fs-5 text-danger"
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleDealFactorItemDeletion(item.Id, packageIndex)
              }
            >
              <MdDelete />
            </span>
          </div>
        </div>
      ))}
      <div className="ms-2">
        {/* {!state?.invoice && ( */}
        <div className="d-flex justify-content-between row w-100">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-1">
            <Input
              type="text"
              size={"sm"}
              placeholder="Item Title"
              value={newItems[item.Id]?.title || ""}
              onChange={(e) =>
                handleInputChange(item.Id, "title", e.target.value)
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-1">
            <Row>
              <Col lg={6} md={6} sm={12} className="pe-1">
                <Input
                  type="number"
                  size={"sm"}
                  value={newItems[item.Id]?.price || ""}
                  onChange={(e) =>
                    handleInputChange(item.Id, "price", e.target.value)
                  }
                  placeholder="Price"
                />
              </Col>
              <Col lg={6} md={6} sm={12} className="px-1">
                <Input
                  type="select"
                  size={"sm"}
                  value={newItems[item.Id]?.units || ""}
                  onChange={(e) =>
                    handleInputChange(item.Id, "units", e.target.value)
                  }
                  placeholder="units"
                  style={{ height: "auto" }}
                >
                  <option value="">select....</option>
                  <option value="piece">piece</option>
                  <option value="box">box</option>
                  <option value="pack">pack</option>
                  <option value="case">case</option>
                  <option value="set">set</option>
                  <option value="pair">pair</option>
                  <option value="unit">unit</option>
                  <option value="hour">hour</option>
                  <option value="minute">minute</option>
                  <option value="day">day</option>
                  <option value="month">month</option>
                  <option value="liter">liter</option>
                  <option value="pound">pound (lb)</option>
                  <option value="ounce">ounce (oz)</option>
                  <option value="Inch">Inch</option>
                  <option value="foot">foot</option>
                  <option value="yard">yard</option>
                  <option value="barrel">barrel</option>
                  <option value="bag">bag</option>
                  <option value="carton">carton</option>
                  <option value="event">event</option>
                  <option value="person">person</option>
                  <option value="use">use</option>
                  <option value="visit">visit</option>
                </Input>
              </Col>
            </Row>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-6 col-6 p-1">
            <Input
              type="text"
              size={"sm"}
              value={newItems[item.Id]?.description || ""}
              onChange={(e) =>
                handleInputChange(item.Id, "description", e.target.value)
              }
              placeholder="Item Description"
            />
          </div>

          <div
            className="col-lg-2 col-md-2 col-sm-6 col-6 p-1 text-center"
            style={{ cursor: "pointer" }}
          >
            <div onClick={() => handleAddItemToPackages(item.Id)}>
              <IoMdAddCircle size={25} />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default DealFactorItems;
