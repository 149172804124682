import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { fetchMyServices } from "../Data";

const CreateInteractionNotesMd = ({ open, handleClose }) => {
  const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const initialFormData = {
    messageId: selectedConvo?.Id,
    customerName: selectedConvo?.customerName,
    teamMember: [],
    interactionDetail: "",
    customerEmail: selectedConvo?.customerEmail,
    Title: "",
    createdBy: user?.UserID,
    receivedBy:
      selectedConvo?.createdBy == user?.UserID
        ? selectedConvo?.customerId
        : selectedConvo?.createdBy,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const closeModal = () => {
    handleClose();
    setFormData(initialFormData);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "", // Clear error message when user starts typing
    }));
  };
  const fetchServices = async () => {
    try {
      const response = await fetchMyServices({
        createdBy: selectedConvo?.createdBy,
      });
      console.log("teamMembers");
      const service = response.filter((service) => service.isactive === "3");
      const additionalPeople = service[0]?.additionalPeople;
      const parsedPeople = additionalPeople ? JSON.parse(additionalPeople) : [];

      // Filter out team members with empty values
      const validTeamMembers = parsedPeople.filter(
        (member) => member.email && member.phoneNumber && member.countryCode
      );

      setTeamMembers(validTeamMembers);
      console.log(validTeamMembers, "teamMembers");
    } catch (error) {
      console.error("Error fetching services or parsing data:", error);
    }
  };

  useEffect(() => {
    if (user?.UserID && open) {
      fetchServices();
    }
  }, [open]);
  const handleMultiSelectChange = (event) => {
    const { value } = event.target;
    const updatedTeamMember = formData.teamMember.filter((detail) =>
      value.some(
        (member) =>
          member.email === detail.email && member.phone === detail.phone
      )
    );
    value.forEach((member) => {
      const exists = updatedTeamMember.some(
        (detail) =>
          detail.email === member.email && detail.phone === member.phone
      );
      if (!exists) {
        updatedTeamMember.push({ email: member.email, phone: member.phone });
      }
    });
    setFormData((prev) => ({
      ...prev,
      teamMember: updatedTeamMember,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.teamMember.length)
      newErrors.teamMember = "This field is required.";
    if (!formData.Title) newErrors.Title = "This field is required.";
    if (!formData.interactionDetail)
      newErrors.interactionDetail = "This field is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
    let guestCount = [];
    try {
      guestCount = selectedConvo?.eventDetails
        ? JSON.parse(selectedConvo.eventDetails)
        : [];
    } catch (error) {
      console.error("Error parsing eventDetails:", error);
    }
    const count = guestCount[0]?.totalGuestCount || 0;
    const submissionData = {
      ...formData,
      teamMember: JSON.stringify(formData.teamMember),
      totalGuestCount: Number(count),
    };
    try {
      const response = await axiosClient.post(
        `message/AddInteractionNote`,
        submissionData
      );
      if (response.data.statusCode === 200) {
        toast.success(`Notes created successfully`);
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="create-template-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="create-template-dialog-title">
        Create Interaction Notes
      </DialogTitle>
      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }}
          gap={2}
          p={3}
        >
          {/* Team Member Assigned */}
          <FormControl fullWidth error={!!errors.teamMember}>
            <InputLabel>Team Members Assigned *</InputLabel>
            <Select
              multiple
              value={formData.teamMember}
              onChange={handleMultiSelectChange}
              renderValue={(selected) => (
                <Box display="flex" overflow="auto" gap={1}>
                  {selected.map((member, index) => (
                    <Chip
                      key={index}
                      label={`${member.email} (${member.phone})`}
                    />
                  ))}
                </Box>
              )}
            >
              {selectedConvo?.senderEmail && (
                <MenuItem
                  value={{
                    email: selectedConvo.senderEmail,
                    phone: selectedConvo.Phone,
                  }}
                >
                  &#9733; {selectedConvo.senderEmail} ({selectedConvo.Phone})
                </MenuItem>
              )}
              {teamMembers.map((member, index) => (
                <MenuItem
                  key={index}
                  value={{
                    email: member.email,
                    phone: `${member.countryCode} ${member.phoneNumber}`,
                  }}
                >
                  {`${member.email} (${member.countryCode}${member.phoneNumber})`}
                </MenuItem>
              ))}
            </Select>
            {errors.teamMember && (
              <p style={{ color: "red" }}>{errors.teamMember}</p>
            )}
          </FormControl>

          {/* Title of Notes */}
          <TextField
            label="Title of Notes *"
            name="Title"
            fullWidth
            value={formData.Title}
            onChange={handleChange}
            helperText={errors.Title || ""}
            error={!!errors.Title}
          />

          <Box gridColumn="span 2">
            <TextField
              label="Interaction Details *"
              name="interactionDetail"
              fullWidth
              multiline
              rows={4}
              value={formData.interactionDetail}
              onChange={handleChange}
              helperText={errors.interactionDetail || ""}
              error={!!errors.interactionDetail}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create Note
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInteractionNotesMd;
