import React, { useEffect, useState } from "react";
import { Row, Col, Input, Label, Button, FormGroup, Form } from "reactstrap";
import { FaStar } from "react-icons/fa";
import Modal from "react-modal";
import { sendMessage, MakeAnOffer } from "../Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { InviteUser } from "../Data2";

const SendInvite = ({ isOpen, closeModal, userDetails }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [message, setMessage] = useState("");
  const [mail, setMail] = useState(null);
  const MessageSend = async () => {
    const messageForm = {
      Message: message,
      Email: mail,
    };
    if (!messageForm.Message) {
      toast.error("Enter Message");
      return;
    } else if (!messageForm.Email) {
      toast.error("Enter Email");
      return;
    }
    await InviteUser(userDetails.Id, messageForm);
    toast.success("Invite sent");
    closeModal();
    setMail("");
    setMessage(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          border: "1px solid gray",
          borderRadius: "20px",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <div className="modal-body ">
          <Form onSubmit={MessageSend}>
            <FormGroup>
              <Label for="message">Message</Label>
              <Input
                type="textarea"
                id="message"
                placeholder="Type message.."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <div className="invalid-feedback">Please enter a message.</div>
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                placeholder="Enter Email"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                required
              />
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
            </FormGroup>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SendInvite;
