import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";
import {
  fetchUserDetails,
  updateProfile,
  fetchCategories,
  fetchSubCategories,
  fetchItems,
} from "../../../components/Header/Data";
import Sidebar from "../../SideBar";
import { useSelector, useDispatch } from "react-redux";
import axiosClient from "../../../axios-client";
import { Notifications, Profile } from "./Profile";
import Password from "./ChangePassward";
import Cashout from "./CashOut";
import "../DashboardPages.css";
import "./Settings.css";
import { toast } from "react-toastify";
import PaymentSettings from "./PaymentSettings";
import { updatePaypalSettings } from "../../../components/Header/Data2";
import ProfilePayment from "./ProfilePayment";
import { Link, useParams, useLocation } from "react-router-dom";
import NotifyMe from "./NotifyMe";
import UploadID from "./UploadID";
import VerifyID from "./VerifyID";
import { setSearchTerm } from "../../../redux/slices/BwvData";
import { Badge } from "@mui/material";

const Settings = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const { searchTerm } = useSelector((state) => state.bwvData);
  const location = useLocation();
  const logUser = localStorage.getItem("USER_ROLE");
  //const [selectedItem, setSelectedItem] = useState("profile");
  const [clientId, setClientId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const { tab } = useParams(); // Get the active tab from URL
  const defaultTab = tab || "profile";
  const [updating, setUpdating] = useState(false);
  let payment = 100;

  useEffect(() => {
    localStorage.setItem("activeTab", defaultTab);
  }, [tab]);
  useEffect(() => {
    dispatch(setSearchTerm(userDetails.Location || ""));
  }, [userDetails.Location, dispatch]);

  const { finalUsers } = useSelector((state) => state.setting);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const caIdPending = finalUsers.filter(
    (user) => user.caId != null && user.caIdStatus == 0
  );

  const [Notification, setNotification] = useState(0);
  const fetchData = async () => {
    const userData = await fetchUserDetails(user?.UserID);
    setUserDetails(userData);
    setNotification(userData.Notification);
    window.scroll(0, 0);
  };
  const updatepPayPal = async () => {
    if (!clientId) {
      toast.error("Enter Client Id");
      return;
    } else if (!secretKey) {
      toast.error("Enter Secret Key");
      return;
    }
    const formData = {
      updatedBy: user.UserID,
      clientId: clientId,
      Key: secretKey,
    };
    await updatePaypalSettings(formData);
    toast.success("Paypal Settings Updated Successfully");
  };
  useEffect(() => {
    fetchData();
  }, []);
  const validateForm = (userDetails) => {
    const errors = {};

    if (!userDetails.FirstName?.trim()) {
      errors.FirstName = "First Name is required";
    }
    if (!userDetails.LastName?.trim()) {
      errors.LastName = "Last Name is required";
    }
    if (!userDetails.UserName?.trim()) {
      errors.UserName = "Username is required";
    }
    if (!userDetails.Email?.trim()) {
      errors.Email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.Email)) {
      errors.Email = "Invalid email format";
    }
    if (!userDetails.ZipCode?.trim()) {
      errors.ZipCode = "Zip Code is required";
    } else if (!/^\d{5}(-\d{4})?$/.test(userDetails.ZipCode)) {
      errors.ZipCode = "Invalid Zip Code format";
    }
    if (!userDetails.Radius) {
      errors.Radius = "Radius is required";
    } else if (isNaN(userDetails.Radius) || userDetails.Radius <= 0) {
      errors.Radius = "Radius must be a positive number";
    }
    if (userDetails.Phone && !/^\d{10}$/.test(userDetails.Phone)) {
      errors.Phone = "Invalid phone number format (10 digits required)";
    }
    if (
      userDetails.secondaryPhone &&
      !/^\d{10}$/.test(userDetails.secondaryPhone)
    ) {
      errors.secondaryPhone =
        "Invalid secondary phone number format (10 digits required)";
    }

    return errors;
  };

  const handleUpdateProfile = async (e) => {
    setUpdating(true);
    e.preventDefault();
    if (defaultTab === "profile") {
      const errors = validateForm(userDetails);
      if (Object.keys(errors).length > 0) {
        Object.values(errors).forEach((error) => toast.error(error));
      } else {
        const userData = {
          FirstName: userDetails.FirstName,
          LastName: userDetails.LastName,
          UserName: userDetails.UserName,
          Email: userDetails.Email,
          Photo: userDetails.Photo,
          Phone: userDetails.Phone,
          secondaryPhone: userDetails.secondaryPhone,
          BioData: userDetails.BioData,
          yelpUrl: userDetails.yelpUrl,
          facebookUrl: userDetails.facebookUrl,
          instagramUrl: userDetails.instagramUrl,
          Location: searchTerm,
          Radius: userDetails.Radius,
          UpdatedBy: user.UserID,
          TimeZone: userDetails.TimeZone,
          CountryCode: userDetails.CountryCode,
          EngagedCoupled: userDetails.EngagedCoupled,
          eventDetails: userDetails.eventDetails || [],
          ZipCode: userDetails.ZipCode,
          phoneShow: userDetails.phoneShow,
        };
        const response = await updateProfile(userData, user.UserID);
        const updatedLocalStorageData = {
          ...userData,
          Role: user.Role,
          RoleId: user.RoleId,
          UserID: user.UserID,
          TwilioNumber: user.TwilioNumber,
          Gurudwara: user.Gurudwara,
        };
        localStorage.setItem(
          "USER_DATA",
          JSON.stringify(updatedLocalStorageData)
        );
        toast.success("Profile Updated Successfully");
        fetchData();
      }
    } else if (defaultTab === "notifications") {
      await axiosClient.put(`setting/updateNotification/${user.UserID}`, {
        Notification: Notification,
      });
      toast.success(`Notifications`);
      window.location.reload();
    } else if (defaultTab === "paymentsettings") {
      updatepPayPal();
    }
    setUpdating(false);
  };
  const selectedForm = () => {
    switch (defaultTab) {
      case "payment":
        return <ProfilePayment userDetails={userDetails} />;
      case "cashout":
        return <Cashout />;
      case "password":
        return <Password />;
      case "paymentsettings":
        return (
          <PaymentSettings
            clientId={clientId}
            setClientId={setClientId}
            secretKey={secretKey}
            setSecretKey={setSecretKey}
          />
        );
      case "notifications":
        return (
          <Notifications
            Notification={Notification}
            setNotification={setNotification}
          />
        );
      case "notifyMe":
        return <NotifyMe />;
      case "uploadID":
        return <UploadID />;
      case "verifyID":
        return <VerifyID />;
      default:
        return (
          <Profile
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            updating={updating}
          />
        );
    }
  };
  console.log(user, "user");
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row className="settings-container px-0">
          <Col sm={3} className="stgs_sbar mt-2 mx-0">
            <Sidebar />
          </Col>
          <Col sm={9} className="stgs_content mt-2">
            <Col className="settings-nav">
              <Row>
                <div className="mb-3 d-flex justify-content-end">
                  {updating ? (
                    <Spinner color="primary" />
                  ) : (
                    <Button
                      className="btn btn-success m-0 "
                      onClick={handleUpdateProfile}
                      disabled={
                        defaultTab === "notifyMe" ||
                        defaultTab === "password" ||
                        defaultTab === "uploadID" ||
                        defaultTab === "verifyID"
                      }
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
                <nav>
                  <ul className="horizontal-list hnav-list d-flex justify-content-start">
                    <Link
                      to="/dashboardpage/settings/profile"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "profile" ? "selected" : ""}
                      >
                        Profile
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/payment"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "payment" ? "selected" : ""}
                      >
                        PayPal
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/cashout"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "cashout" ? "selected" : ""}
                      >
                        Cashout
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/password"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "password" ? "selected" : ""}
                      >
                        Password
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/notifications"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={
                          defaultTab === "notifications" ? "selected" : ""
                        }
                      >
                        Notifications
                      </li>
                    </Link>
                    {logUser !== "admin" && (
                      <>
                        <Link
                          to="/dashboardpage/settings/notifyMe"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "notifyMe" ? "selected" : ""
                            }
                          >
                            Notify Me
                          </li>
                        </Link>
                        <Link
                          to="/dashboardpage/settings/uploadID"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "uploadID" ? "selected" : ""
                            }
                          >
                            Upload ID
                          </li>
                        </Link>
                      </>
                    )}
                    {user?.Role === "admin" && (
                      <>
                        <Link
                          to="/dashboardpage/settings/paymentsettings"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "paymentsettings" ? "selected" : ""
                            }
                          >
                            Payment Settings
                          </li>
                        </Link>
                        <Link
                          to="/dashboardpage/settings/verifyID"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "verifyID" ? "selected" : ""
                            }
                          >
                            Verify ID
                            {isAdmin && caIdPending?.length > 0 && (
                              <Badge
                                badgeContent={caIdPending?.length}
                                color="error"
                              >
                                &nbsp; <span className="invisible">.</span>
                              </Badge>
                            )}
                          </li>
                        </Link>
                      </>
                    )}
                  </ul>
                </nav>
              </Row>
              <Row
                style={{
                  width: `${
                    user.RoleId !== 1 && defaultTab === "cashout"
                      ? "935px"
                      : "auto"
                  }`,
                }}
              >
                <Col>{selectedForm()}</Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Settings;
