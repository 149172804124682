import axiosClient from "../../../axios-client";

export const filterConversations = async (convos, filters, user) => {
  let filteredConvos = Array.isArray(convos) ? [...convos] : []; // Ensure convos is an array

  const {
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    unreadSearch,
    guestCount,
    showFollowUp,
    oppertunityFilter,
  } = filters;

  console.log(
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    unreadSearch,
    guestCount,
    showFollowUp,
    oppertunityFilter,
    "filteredConvos"
  );

  if (searchTerm) {
    const trimmedQuery = searchTerm.trim().toLowerCase();
    filteredConvos = filteredConvos.filter((conv) => {
      const {
        senderName,
        customerName,
        providerPhone,
        customerPhone,
        senderEmail,
        customerEmail,
      } = conv;
      return (
        senderName?.trim().toLowerCase().includes(trimmedQuery) ||
        customerName?.trim().toLowerCase().includes(trimmedQuery) ||
        providerPhone?.trim().toLowerCase().includes(trimmedQuery) ||
        customerPhone?.trim().toLowerCase().includes(trimmedQuery) ||
        senderEmail?.trim().toLowerCase().includes(trimmedQuery) ||
        customerEmail?.trim().toLowerCase().includes(trimmedQuery)
      );
    });
  }

  if (meetingDateSearch) {
    const trimmedQuery = meetingDateSearch.trim().toLowerCase();
    filteredConvos = filteredConvos.filter((conv) =>
      conv.reserveDate?.trim().toLowerCase().includes(trimmedQuery)
    );
  }

  if (eventMonthsearch) {
    filteredConvos = filteredConvos.filter((conv) => {
      const { eventDetails } = conv;
      if (!eventDetails) return false;
      try {
        const event = JSON.parse(eventDetails);
        const eventDateMonth = new Date(event[0].eventDate)
          .toISOString()
          .slice(0, 7);
        return eventDateMonth === eventMonthsearch.trim() && conv.postType == 3;
      } catch (error) {
        console.error("Invalid eventDetails format:", error);
        return false;
      }
    });
  }

  if (guestCount) {
    filteredConvos = filteredConvos.filter((conv) => {
      const { eventDetails } = conv;
      if (!eventDetails) return false;
      try {
        const event = JSON.parse(eventDetails);
        return event[0].totalGuestCount <= parseInt(guestCount);
      } catch (err) {
        console.error("Invalid eventDetails format:", err);
        return false;
      }
    });
  }

  if (oppertunityFilter) {
    filteredConvos = filteredConvos.filter(
      (conv) => conv.latestType == oppertunityFilter
    );
  }

  if (unreadSearch) {
    filteredConvos = filteredConvos.filter(
      (conv) =>
        conv.createdBy != conv.customerId &&
        conv.customerId == user.UserID &&
        conv.seenCustomer == 0
    );
  }

  if (showFollowUp) {
    filteredConvos = filteredConvos.filter((conv) => conv.postType == 3);

    const convosWithTasks = await Promise.all(
      filteredConvos.map(async (conv) => {
        try {
          const response = await axiosClient.put(
            `message/listFollowUpTask/${conv.Id}`
          );
          const filteredTasks = response.data.Data.filter(
            (task) => task.followupStatus !== "DELETED"
          );
          return { ...conv, filteredTasks };
        } catch (error) {
          console.error(`Error fetching tasks for convo ${conv.Id}:`, error);
          return { ...conv, filteredTasks: [] };
        }
      })
    );

    filteredConvos = convosWithTasks.filter(
      (conv) => conv.filteredTasks.length > 0
    );
  }

  console.log(filteredConvos, "filteredConvos");
  return filteredConvos; // Always return an array
};
