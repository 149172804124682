import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";

const MessageInteractionNotes = ({ open, handleClose }) => {
  const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [msgNotes, setMsgNotes] = React.useState([]);

  const fetchNotes = async () => {
    try {
      const response = await axiosClient.put(
        `message/listInteractionNote/${selectedConvo?.Id}`
      );
      console.log(response, "msgNotes");
      setMsgNotes(response.data.Data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedConvo) {
      fetchNotes();
    }
  }, [selectedConvo]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-template-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="create-template-dialog-title">
        All Interaction Notes
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {msgNotes && msgNotes.length === 0 && <p>No Interaction Notes.</p>}
            {msgNotes && msgNotes.length > 0 && (
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={styles.headerCell}>#</th>
                    <th style={styles.headerCell}>Note Title</th>
                    <th style={styles.headerCell}>Note Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {msgNotes?.map((data, index) => (
                    <tr key={data.id}>
                      <td style={styles.bodyCell}>{index + 1}</td>
                      <td style={styles.bodyCell}>{data.Title}</td>
                      <td style={styles.bodyCell}>{data.interactionDetail}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  headerCell: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
  },
  bodyCell: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
  },
};

export default MessageInteractionNotes;
