import listing from "../../src/assets/images/listing.png";
import pay from "../../src/assets/images/pay.png";
import profile from "../../src/assets/images/profile.png";
import banner1 from "../../src/assets/images/home/banner1.jpg";
import banner2 from "../../src/assets/images/home/banner2.jpg";
import banner3 from "../../src/assets/images/home/banner3.jpg";
import banner4 from "../../src/assets/images/home/banner4.jpg";
import banner5 from "../../src/assets/images/home/home10.jpg";

export const usaCities = [
  "New York",
  "Los Angeles",
  "Chicago",
  "Houston",
  "Phoenix",
  "Philadelphia",
  "San Antonio",
  "San Diego",
  "Dallas",
  "San Jose",
  "Austin",
  "Jacksonville",
  "Fort Worth",
  "Columbus",
  "San Francisco",
  "Charlotte",
  "Indianapolis",
  "Seattle",
  "Denver",
  "Washington, D.C.",
  "Boston",
  "El Paso",
  "Nashville",
  "Detroit",
  "Oklahoma City",
  "Portland",
  "Las Vegas",
  "Memphis",
  "Louisville",
  "Milwaukee",
  "Albuquerque",
  "Tucson",
  "Fresno",
  "Sacramento",
  "Long Beach",
  "Kansas City",
  "Mesa",
  "Atlanta",
  "Colorado Springs",
  "Virginia Beach",
  "Raleigh",
  "Omaha",
  "Miami",
  "Oakland",
  "Minneapolis",
  "Tulsa",
  "Wichita",
  "New Orleans",
];

export const howItWorksData = {
  seekers: {
    types: [
      {
        heading: "Pay Conveniently & Safely",
        image: pay,
        description:
          "Platform will release funds to vendor when they have delivered the promised service. Contact us to Submit Disputes if vendor acted unethically",
      },
      {
        heading: "Vendor Profiles",
        image: profile,
        description:
          "Read vendor reviews, view work history, view sample work & communicate via messages on the platform to keep record of your transaction details",
      },
      {
        heading: "Create Auction Listings!",
        image: listing,
        description:
          "Post your Need via Auction Listing. Set your reasonable budget and project criteria. Interested vendors will place competitive bids in attempt to win your business. At auction end, select the best bidder you prefer to work with.",
      },
    ],
  },
  providers: {
    types: [
      {
        heading: "Grow your Business!",
        image:
          "https://rebornstorage.blob.core.windows.net/uploads/5fef9f41-35f8-4922-a5d5-8da9224f158fmedium-image-cropper.webp",
        description:
          "Hundreds of couples visit our site monthly to find Indian Wedding Vendors",
      },
      {
        heading: "Get Paid Conveniently",
        image: pay,
        description:
          "The platform auto releases payment to vendor on date of service rendering",
      },
      {
        heading: "Professional Representation",
        image: listing,
        description:
          "Share hyperlink to your listing on social networks and effectively improve your lead generation and sales ratios",
      },
    ],
  },
};

export const categories = [
  // "Local Community Ads",
  "Event Decorations",
  "Event Venues",
  "Gurdwara/Temple",
  "Hair/Makeup",
  "Indian Cuisine",
  "Jewelry",
  "Mehndi",
  "Photography/Videography",
  "Wedding Clothing",
  "Transportation",
  "Dance Performances",
  "Musicians & Instruments",
  "MC/Host",
  "Turban Tying Service",
];

export const posts = [
  // Status 0 - 5 records
  {
    id: 1,
    title: "Breaking News 1",
    description: "Latest updates from around the world.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video1",
    postType: "NEWSPAPER",
    status: 1,
  },
  {
    id: 2,
    title: "Cooking Show Episode 1",
    description: "Delicious recipes for beginners.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video2",
    postType: "COOKING-VIDEOS",
    status: 0,
  },
  {
    id: 3,
    title: "Community Garden Project",
    description: "Local volunteers making a difference.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video3",
    postType: "COMMUNITY-PROJECT",
    status: 0,
  },
  {
    id: 4,
    title: "Newspaper Exclusive Report",
    description: "Deep dive into current affairs.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video4",
    postType: "NEWSPAPER",
    status: 1,
  },
  {
    id: 5,
    title: "Cooking Secrets Revealed",
    description: "Master chef tips for home cooks.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video5",
    postType: "COOKING-VIDEOS",
    status: 0,
  },

  // Status 1 - 5 records
  {
    id: 6,
    title: "Daily News Highlights",
    description: "Top headlines of the day.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video6",
    postType: "NEWSPAPER",
    status: 1,
  },
  {
    id: 7,
    title: "Quick & Easy Recipes",
    description: "Tasty dishes in under 30 minutes.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video7",
    postType: "COOKING-VIDEOS",
    status: 1,
  },
  {
    id: 8,
    title: "Eco-Friendly Initiative",
    description: "Green projects in the neighborhood.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video8",
    postType: "COMMUNITY-PROJECT",
    status: 1,
  },
  {
    id: 9,
    title: "Morning Bulletin",
    description: "Important updates for your day.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video9",
    postType: "NEWSPAPER",
    status: 1,
  },
  {
    id: 10,
    title: "Gourmet at Home",
    description: "Luxury cooking made simple.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video10",
    postType: "COOKING-VIDEOS",
    status: 1,
  },

  // Status 2 - 1 record
  {
    id: 11,
    title: "Special Report: Climate Change",
    description: "An in-depth analysis of global warming.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video11",
    postType: "NEWSPAPER",
    status: 1,
  },

  // Status 3 - 5 records
  {
    id: 12,
    title: "Editorial Column",
    description: "Expert opinions on today's issues.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video12",
    postType: "NEWSPAPER",
    status: 3,
  },
  {
    id: 13,
    title: "Cooking Challenge",
    description: "Competing chefs create magic.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video13",
    postType: "COOKING-VIDEOS",
    status: 3,
  },
  {
    id: 14,
    title: "Community Cleanup Drive",
    description: "Volunteers unite to clean the city.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video14",
    postType: "COMMUNITY-PROJECT",
    status: 3,
  },
  {
    id: 15,
    title: "Weekend News Recap",
    description: "Everything you missed this week.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video15",
    postType: "NEWSPAPER",
    status: 3,
  },
  {
    id: 16,
    title: "Healthy Cooking Hacks",
    description: "Eat better without compromising taste.",
    galleryImages: ["image1", "image2", "image3"],
    videoUrl: "https://example.com/video16",
    postType: "COOKING-VIDEOS",
    status: 3,
  },
];

export const imageArray = [banner5, banner1, banner2, banner3, banner4];
