import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPackages } from "../../../redux/slices/ServiceInfo";
import DealFactors from "../../../components/Header/WeddingVendors/DealFactors";

const Packages = () => {
  const [packageBox, setPackageBox] = useState(false);
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  useEffect(() => {
    if (
      serviceInfo.itemsToDisplay &&
      serviceInfo.itemsToDisplay.length > 0 &&
      serviceInfo.createdPackages &&
      serviceInfo.createdPackages.length > 0 &&
      !packageBox
    ) {
      setPackageBox(true);
    }
  }, [serviceInfo.itemsToDisplay, serviceInfo.createdPackages]);

  const handleAddInput = () => {
    const lastPackageIndex = Object.keys(serviceInfo.packages).length - 1;
    const lastPackage = serviceInfo.packages[lastPackageIndex];

    if (lastPackage) {
      if (!lastPackage.serviceName || !lastPackage.price) {
        toast.error("Please enter the details before adding a new one.");
        return; // don't add a new package if the last one has empty fields
      }
    }

    const newPackages = {
      ...serviceInfo.packages,
      [`${Object.keys(serviceInfo.packages).length}`]: {
        serviceName: "",
        price: "",
        description: "",
      },
    };
    dispatch(setPackages(newPackages));
  };

  const handleInputChange = (index, fieldName, value) => {
    if (fieldName === "price" && !isNaN(value)) {
      //value = "$" + value;
    }
    const newPackages = {
      ...serviceInfo.packages,
      [index]: {
        ...serviceInfo.packages[index],
        [fieldName]: value,
      },
    };
    dispatch(setPackages(newPackages));
  };

  const handleDeletePackage = (index) => {
    const newPackages = { ...serviceInfo.packages };
    delete newPackages[index];
    dispatch(setPackages(newPackages));
  };

  return (
    <>
      <h6>Packages</h6>
      <FormGroup check className="p-3">
        <Input
          type="switch"
          id="custom-switch"
          label="Toggle Switch"
          checked={packageBox}
          onChange={() => {
            setPackageBox(!packageBox);
          }}
        />
        Allow buyers to buy your different service offerings at a fixed rate
      </FormGroup>
      <FormGroup>
        {packageBox && (
          <div>
            {/* <div>
              {Object.entries(serviceInfo.packages).map(([index, input]) => (
                <Row key={index}>
                  <Col className="flat-flee-offerings" xs={11}>
                    <Row className="w-100">
                      <Col>
                        <FormGroup>
                          <Label>
                            Service Name <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            value={input.serviceName}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "serviceName",
                                e.target.value
                              )
                            }
                            placeholder="Service Name"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            Price <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            value={input.price}
                            onChange={(e) =>
                              handleInputChange(index, "price", e.target.value)
                            }
                            placeholder="$00.00"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input
                            type="textarea"
                            value={input.description}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            style={{ height: "120px" }}
                            placeholder="Description"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaTrashAlt onClick={() => handleDeletePackage(index)} />
                  </Col>
                </Row>
              ))}
            </div>
            <div
              className="flat-flee-offerings"
              style={{ fontSize: "xx-large" }}
            >
              <FaPlusCircle onClick={handleAddInput} />
            </div> */}
            <DealFactors state={{ invoice: false }}/>
          </div>
        )}
      </FormGroup>
    </>
  );
};

export default Packages;
