import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setSelectedPrice } from "../../../redux/slices/MsgSlice";

const ServicePackageCreation = ({
  selectedPackages,
  handlePackageSelection,
  totalPrice,
  packageName,
  packageToEdit,
  handleSaveEditedPackage,
  handleItemClick,
  handleCreatePackage,
  packageNameRef,
  totalPriceRef,
  setGuestCount,
  guestCount,
  state,
  checked,
}) => {
  const [editPackageName, setEditPackageName] = useState(false);
  const [editTotalPrice, setEditTotalPrice] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        packageNameRef.current &&
        !packageNameRef.current.contains(event.target)
      ) {
        setEditPackageName(false);
      }
      if (
        totalPriceRef.current &&
        !totalPriceRef.current.contains(event.target)
      ) {
        setEditTotalPrice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log(selectedPackages, "selectedPackages");
  const handlePackageType = () => {
    console.log(totalPrice, "totalPrice");
    if (state?.invoice) {
      if (checked) {
        dispatch(setSelectedPrice(totalPriceRef.current.textContent));
      } else {
        dispatch(setSelectedPrice(totalPriceRef.current.textContent));
      }
    } else {
      handleCreatePackage();
    }
  };
  return (
    <>
      <div style={{ fontSize: "17px", fontWeight: "600" }}>
        Service Package Creation
      </div>
      <div className="my-3">
        <Row
          className=" border"
          style={{
            borderRadius: "25px",
          }}
        >
          <Col lg={10}>
            <h3 className="text-center mb-4 mt-1">
              <span
                contentEditable={editPackageName}
                ref={packageNameRef}
                style={{ width: "50px" }}
              >
                {packageName ? packageName : "Package Name"}
              </span>{" "}
              &nbsp; &nbsp;
              {!editPackageName && (
                <FaEdit
                  onClick={() => {
                    setTimeout(() => {
                      packageNameRef.current.focus();
                    }, 0);
                    setEditPackageName(true);
                  }}
                  size={20}
                />
              )}
            </h3>
            <div className="my-4">
              <Row>
                {Object.keys(selectedPackages).map((key, index) => (
                  <Col
                    key={index}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    className="my-4 "
                  >
                    <div
                      onClick={() => handleItemClick(key, index)}
                      style={{ cursor: "pointer" }}
                    >
                      <Input
                        type="checkbox"
                        className="mt-2"
                        checked={selectedPackages[key].checked}
                        disabled
                        onChange={() =>
                          handlePackageSelection(
                            selectedPackages[key].Id,
                            index, // Assuming the package item is at index 0
                            {
                              ...selectedPackages[key],
                              checked: !selectedPackages[key].checked,
                            }
                          )
                        }
                      />
                      <span className="ms-2">
                        <span className="fw-bold fs-5">
                          {selectedPackages[key].title}
                        </span>
                        <br />
                        <p
                          style={{
                            marginLeft: "25px",
                            marginBottom: "0px",
                          }}
                        >
                          {selectedPackages[key].description}
                        </p>
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col
            lg={2}
            className="dealFactorCardBody"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   // justifyContent: "center",
            //   // textAlign: "center",
            //   backgroundColor: "#c3c3c3",
            //   borderTopRightRadius: "25px",
            //   borderBottomRightRadius: "25px",
            // }}
          >
            <Row style={{ gap: "30px" }}>
              <Col lg={12} className="text-center">
                <div>
                  <Label> Select Guest Count </Label>
                  <Input
                    type="select"
                    name="guestCount"
                    value={guestCount}
                    onChange={(e) => {
                      setGuestCount(e.target.value);
                    }}
                  >
                    {Array.from({ length: 17 }, (_, index) => (
                      <option key={index} value={index * 50}>
                        {index * 50}
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
              <Col lg={12} className="text-center">
                <div>
                  <strong>
                    Total Price $
                    <span contentEditable={editTotalPrice} ref={totalPriceRef}>
                      {totalPrice ? totalPrice : "0"}
                    </span>{" "}
                    {!editTotalPrice && (
                      <FaEdit
                        onClick={() => {
                          setTimeout(() => {
                            totalPriceRef.current.focus();
                          }, 0);
                          setEditTotalPrice(true);
                        }}
                        size={20}
                      />
                    )}
                  </strong>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="text-center invoceButton">
        {packageToEdit !== null ? (
          <Button
            color="primary"
            // onClick={() => {
            //   setModal(true);
            //   setModalType("PackageCreation");
            // }}
            onClick={handleSaveEditedPackage}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            color="warning"
            className="my-2"
            // onClick={() => {
            //   const selectedItems = Object.values(selectedPackages).filter(
            //     (item) => item.checked
            //   );
            //   if (selectedItems.length === 0) {
            //     toast.error("Please select at least one item");
            //     return;
            //   }
            //   setModal(true);
            //   setModalType("PackageCreation");
            // }}
            onClick={handlePackageType}
          >
            {state?.invoice && "Add to Invoice"}
          </Button>
        )}
      </div>
    </>
  );
};

export default ServicePackageCreation;
