/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Input, FormGroup, Label } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { postOrder, viewPhonePayment } from "../../../components/Header/Data";
import { toast } from "react-toastify";
import Select from "react-select";
import { countryCodes } from "../../../components/CountryCodes";
import PaymentDetails from "./PaymentDetails";
import ThankYouModal from "./ThankYouPage";
import axiosClient from "../../../axios-client";
const OrderSummary = () => {
  const [finalAmount, setFinalAmount] = useState([]);
  const [pay, setPay] = useState(false);
  const logUser = localStorage.getItem("USER_ROLE");
  const [order, setOrder] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [totalPrice, setTotalPrice] = useState(0);
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const [thankYou, setThankYou] = useState(false);
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  console.log(state, "state");
  useEffect(() => {
    if (state && state?.selectedPackageRate) {
      let total = Number(state.selectedPackageRate);
      setTotalPrice(total);
    } else if (state && state?.offerAmount) {
      let total = Number(state.offerAmount);
      setTotalPrice(total);
    } else if (state && state?.reqPhoneAmount) {
      let total = Number(state.reqPhoneAmount);
      setTotalPrice(total);
    } else if (state && state.item) {
      let total = Number(state.itemCost);
      setTotalPrice(total);
    } else {
      const { selectedCard, form } = state;
      const startTimeParts = form?.startTime.split(":");
      const endTimeParts = form?.endTime.split(":");
      const start = new Date(state.selectedServiceDate);
      start.setHours(
        parseInt(startTimeParts[0]),
        parseInt(startTimeParts[1]),
        0,
        0
      );
      const end = new Date(state.selectedServiceDate);
      end.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);
      console.log(end, start, "end - start");
      let durationInHours = (end - start) / (1000 * 60 * 60);
      let total = Number(selectedCard?.hourlyPrice);
      let calculatedPrice = durationInHours * total;
      console.log(durationInHours, "total");
      console.log(total, "total");
      calculatedPrice = calculatedPrice.toFixed(2); // Format to two decimal place
      console.log(calculatedPrice, "calculatedPrice");
      setTotalPrice(calculatedPrice);
    }
  }, [state]);
  const handleRequest = async () => {
    if (isLoading) return;
    setIsLoading(true);
    if (state?.reqPhone) {
      let formData = {
        customerId: user.UserID,
        serviceId: state?.msg?.postId,
        createdBy: user.UserID,
        roleId: user.RoleId,
        orderStatus: "PENDING",
        totalAmount: state?.reqPhoneAmount,
        //totalAmount: 0,
        Message: "null",
        serviceNeed: state?.msg?.serviceNeed,
      };
      let formData1 = {
        senderId: logUser === "provider" ? user?.UserID : user?.UserID,
        requestPhone: logUser === "provider" ? "provider" : "customer",
        paypalResponse: "details",
      };
      const id = state.msg.Id;
      if (islogin) {
        const orders = await postOrder(formData);
        await viewPhonePayment(formData1, id);
        toast.success("Order Created");
        localStorage.setItem("ORDER_DETAILS", JSON.stringify(orders));
        setOrder(orders);
        // setThankYou(true);
        setPay(true);
      } else {
        navigate("/login");
        toast.info("Please Login");
      }
    } else if (state.selectedConversation) {
      setPay(true);
      let formData = {
        customerId: user.UserID,
        serviceId: state?.selectedConversation?.postId,
        createdBy: user.UserID,
        roleId: user.RoleId,
        orderStatus: "PENDING",
        totalAmount: totalPrice,
        Message: "null",
        serviceNeed: state?.selectedConversation?.serviceNeed,
      };
      if (islogin) {
        const orders = await postOrder(formData);
        setOrder(orders);
        localStorage.setItem("ORDER_DETAILS", JSON.stringify(orders));
        toast.success("Order Created");
      } else {
        navigate("/login");
        toast.info("Please Login");
      }
    } else if (state.item) {
      let itemdata = state.itemOrder;
      let formData = {
        userId: user.UserID,
        itemId: itemdata.item_id,
        Description: "string",
        paymentMethod: "PayPal",
        deliveryOption: itemdata.delivery_option,
        Address: itemdata.address ? itemdata.address : itemdata.selleraddress,
        Phone: itemdata.phone,
        Quantity: 1,
        Price: itemdata.price,
        orderStatus: "PENDING",
        createdBy: user.UserID,
      };
      try {
        const response = await axiosClient.post(
          `item/CreateItemOrder`,
          formData
        );
        if (response.status === 200) {
          setOrder(response.data);
          setPay(true);
          localStorage.setItem(
            "ITEM_ORDER_DETAILS",
            JSON.stringify(response.data.cartList)
          );
        } else {
          toast.error(response.data.message);
        }
      } catch (err) {
        console.log(err);
        if (err.response.data.statusCode === 422) {
          toast.error(err.response.data.message);
        }
      }
    } else {
      setPay(true);
      let formData = {
        customerId: user.UserID,
        serviceId: state?.selectedCard?.Id,
        reserveDate: state?.form?.date,
        startTime: state?.form?.startTime,
        endTime: state?.form?.endTime,
        Deadline: state?.form?.deadline,
        Service: state?.form?.service?.serviceName,
        optionType: state?.selectedCard?.hourlyPrice ? 0 : 1, // hourly based 0 or flat free 1
        Photo: state?.form?.images,
        Budget: state?.form?.budget ? state?.form?.budget : 0,
        serviceNeed: "service",
        createdBy: user.UserID,
        roleId: user.RoleId,
        Message: state?.form?.message,
        orderStatus: "PENDING",
        totalAmount: totalPrice,
        Discount: finalAmount ? finalAmount.discount : 0,
        paidAmount: finalAmount ? finalAmount.payamount : totalPrice,
        couponId: finalAmount ? finalAmount.dealId : 0,
      };
      if (islogin) {
        const orders = await postOrder(formData);
        setOrder(orders);
        localStorage.setItem("ORDER_DETAILS", JSON.stringify(orders));
        toast.success("Order Created");
      } else {
        navigate("/login");
        toast.info("Please Login");
      }
    }
  };
  console.log(state, "state");
  return (
    <>
      <div className="order-summary bg-white">
        <div className="container">
          <div className="card" style={{ cursor: "auto" }}>
            <div className="row">
              {totalPrice !== 20 && !state.item && (
                <div
                  className={`${
                    totalPrice !== 20 ? "col-lg-6 " : "d-none"
                  } bg-white p-5`}
                  style={{ borderRight: "0.01em solid #dedede" }}
                >
                  <h4 className="event-title pb-3">
                    {state?.selectedCard?.listName}
                  </h4>
                  <div>
                    <div
                      style={{
                        border: "0.01em solid #f3f3f3",
                      }}
                      className="hr mb-4 order-sum-about"
                    ></div>
                    <h6 className="pb-3">About Your appointment</h6>
                    <textarea
                      class="form-control pb-3"
                      placeholder="Do you have any special requests or ideas to share with your services provider?"
                    ></textarea>
                    <form>
                      <FormGroup>
                        <Label for="mobile" className="mb-0">
                          Mobile Number
                        </Label>
                        <div className="d-flex align-items-center login-ctr-select">
                          <Select
                            options={countryOptions}
                            placeholder="Select"
                            value={
                              selectedCountry
                                ? {
                                    label: selectedCountry,
                                    value: selectedCountry,
                                  }
                                : null
                            }
                            onChange={(selectedOption) =>
                              setSelectedCountry(selectedOption.value)
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: "100px",
                              }),
                              option: (styles) => ({
                                ...styles,
                                color: "black",
                                fontSize: "x-small",
                                width: "150px",
                              }),
                              menu: (styles) => ({
                                ...styles,
                                width: "150px", // Adjust the width of the menu as needed
                              }),
                            }}
                          />
                          <Input
                            type="number"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter Your Mobile Number"
                            value={mobile}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setMobile(e.target.value);
                              }
                            }}
                            maxLength={10}
                          />
                        </div>
                      </FormGroup>
                    </form>
                    <div
                      style={{ border: "0.01em solid #f3f3f3" }}
                      className="hr mb-4"
                    ></div>
                    <h6 className="pb-3">Cancellation Policy</h6>
                    <p className="d-none">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Tenetur obcaecati animi nesciunt! Possimus expedita,
                      aliquam optio molestias neque inventore culpa quo modi.
                      Odit deserunt ut fuga nemo aut sit accusantium.
                    </p>
                    <h6 className="pb-3 pt-4">Fine Print</h6>
                    <ul>
                      <li>
                        <p className="pb-2">
                          Request Appointments are pending for service provider
                          Acceptance
                        </p>
                      </li>
                    </ul>
                    <h6>Deal Term</h6>
                    {state?.selectedCard?.dealTerm && state.hire && (
                      <div class="form-check">
                        <Input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <p>{state?.selectedCard?.dealTerm}</p>
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      className="btn btn-outline-secondary mx-2 os_btn"
                      onClick={() => window.history.back()}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary mx-2 os_btn"
                      onClick={handleRequest}
                      disabled={isLoading}
                    >
                      Request
                    </button>
                  </div>
                </div>
              )}
              <div
                className={`${
                  totalPrice !== 20 && !state.item ? "col-lg-6 " : "col-lg-12"
                } bg-white p-5`}
                style={{
                  width: (totalPrice === 20 || state.item) && "60%",
                  marginLeft: (totalPrice === 20 || state.item) && "22%",
                }}
              >
                <PaymentDetails
                  pay={pay}
                  state={state}
                  totalPrice={totalPrice}
                  order={order}
                  finalAmount={finalAmount}
                  setFinalAmount={setFinalAmount}
                  handleRequest={handleRequest}
                  isLoading={isLoading}
                  user={user}
                  hire={state.hire}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ThankYouModal
        show={thankYou}
        onHide={() => !thankYou}
        totalPrice={totalPrice}
      />
    </>
  );
};
export default OrderSummary;
