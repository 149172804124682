import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  convos: [],
  finalConvos: [],
  messageText: "",
  temps: [],
  selectedConvo: null,
  messages: [],
  loadingNotif: false,
  myConnections: [],
  myJobOffers: [],
  selectedFile: null,
  lodingConvo: false,
  sendingFile: false,
  sendingMsg: false,
  searchTerm: "",
  meetingDateSearch: "",
  eventDateSearch: "",
  eventMonthsearch: "",
  unreadSearch: false,
  guestCount: null,
  description: "",
  order: null,
  orderStatus: "",
  newMessage: null,
  childId: null,
  oppertunity: 0,
  invoiceItemsToDisplay: [],
  invoiceCreatedPackages: [],
  invoiceOptions: [],
  newItemsToDisplay: [],
  newCreatedPackages: [],
  newOptions: [],
  selectedPackage: {},
  selectedPackagePrice: null,
  oppertunityFilter: null,
  showFollowUp: false,
  oppertunityStage: false,
  selectedPackages: {},
  paypalResponse: "",
};

const MessagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessagesState: (state) => initialState,
    setConversations: (state, action) => {
      state.convos = action.payload;
    },
    setFinalConvos: (state, action) => {
      state.finalConvos = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setMeetingDateSearch: (state, action) => {
      state.meetingDateSearch = action.payload;
    },
    setEventDateSearch: (state, action) => {
      state.eventDateSearch = action.payload;
    },
    setEventMonthsearch: (state, action) => {
      state.eventMonthsearch = action.payload;
    },
    setUnreadSearch: (state, action) => {
      state.unreadSearch = action.payload;
    },
    setGuestCount: (state, action) => {
      state.guestCount = action.payload;
    },
    setMessageText: (state, action) => {
      state.messageText = action.payload;
    },
    setTemps: (state, action) => {
      state.temps = action.payload;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConvo = action.payload;
    },

    setMessages: (state, action) => {
      state.messages = action.payload;
    },

    setLoadingNotif: (state, action) => {
      state.loadingNotif = action.payload;
    },

    setMyConnections: (state, action) => {
      state.myConnections = action.payload;
    },

    setMyJobOffers: (state, action) => {
      state.myJobOffers = action.payload;
    },

    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },

    setLodingConvo: (state, action) => {
      state.lodingConvo = action.payload;
    },

    setSendingFile: (state, action) => {
      state.sendingFile = action.payload;
    },

    setSendingMsg: (state, action) => {
      state.sendingMsg = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    setChildId: (state, action) => {
      state.childId = action.payload;
    },
    setOppertunity: (state, action) => {
      state.oppertunity = action.payload;
    },
    setInvoiceItemsToDisplay: (state, action) => {
      state.invoiceItemsToDisplay = action.payload;
    },
    setInvoiceCreatedPackages: (state, action) => {
      state.invoiceCreatedPackages = action.payload;
    },
    setInvoiceOptions: (state, action) => {
      state.invoiceOptions = action.payload;
    },
    setNewItemsToDisplay: (state, action) => {
      state.newItemsToDisplay = action.payload;
    },
    setNewCreatedPackages: (state, action) => {
      state.newCreatedPackages = action.payload;
    },
    setNewOptions: (state, action) => {
      state.newOptions = action.payload;
    },
    setOppertunityFilter: (state, action) => {
      state.oppertunityFilter = action.payload;
    },
    setSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload;
    },
    setSelectedPrice: (state, action) => {
      state.selectedPackagePrice = action.payload;
    },
    setShowFollowUp: (state, action) => {
      state.showFollowUp = action.payload;
    },
    setOppertunityStage: (state, action) => {
      state.oppertunityStage = action.payload;
    },
    setSelectedPackages: (state, action) => {
      state.selectedPackages = action.payload;
    },
    setPaypalResponse: (state, action) => {
      state.paypalResponse = action.payload;
    },
  },
});

export const {
  resetMessagesState,
  setConversations,
  setFinalConvos,
  setSearchTerm,
  setMeetingDateSearch,
  setEventDateSearch,
  setEventMonthsearch,
  setUnreadSearch,
  setGuestCount,
  setMessageText,
  setTemps,
  setSelectedConversation,
  setMessages,
  setLoadingNotif,
  setMyJobOffers,
  setMyConnections,
  setSelectedFile,
  setLodingConvo,
  setSendingFile,
  setSendingMsg,
  setDescription,
  setOrder,
  setOrderStatus,
  setNewMessage,
  setChildId,
  setOppertunity,
  setInvoiceItemsToDisplay,
  setInvoiceCreatedPackages,
  setInvoiceOptions,
  setNewCreatedPackages,
  setNewItemsToDisplay,
  setNewOptions,
  setOppertunityFilter,
  setSelectedPackage,
  setSelectedPrice,
  setShowFollowUp,
  setOppertunityStage,
  setSelectedPackages,
  setPaypalResponse,
} = MessagesSlice.actions;
export default MessagesSlice.reducer;
