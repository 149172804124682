import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import { setFinalProducts, setMonth } from "../../../../redux/slices/LcaSlice";
import LcaCards from "../LcaCards";
import BwvCards from "../BwvCards";
import LcaCards2 from "./LcaCards2";
import BwvCards2 from "./BwvCards2";

const LcaAndBwvShuffle = ({ scrollPopupVisible }) => {
  const dispatch = useDispatch();
  const { finalProducts, Lca, bizzLca, bwv, finalBwv, month } = useSelector(
    (state) => state.lcAds
  );

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [carouselData, setCarouselData] = useState({
    row1: [],
    row2: [],
    row3: [],
  });

  const [filteredProducts, setFilteredProducts] = useState([]);

  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const fetchData = () => {
    const allAds = [...bizzLca, ...bwv];
    const row1 = [];
    const row2 = [];
    const row3 = [];
    allAds.forEach((data) => {
      if (data.bizzValue === 1) {
        row1.push(data);
      } else if (data.bizzValue === 2) {
        row2.push(data);
      } else if (data.bizzValue === 3) {
        row3.push(data);
      }
    });
    setCarouselData({
      row1: shuffleArray(row1),
      row2: shuffleArray(row2),
      row3: shuffleArray(row3),
    });
  };

  useEffect(() => {
    fetchData();
  }, [bizzLca, bwv]);

  const handleMonthChange = (newMonth) => {
    dispatch(setMonth(newMonth));
    if (!newMonth) {
      const initialProducts = Lca.filter((ad) => ad.engagedCouple === 1);
      dispatch(setFinalProducts(initialProducts));
      setFilteredProducts(initialProducts);
      return;
    }

    const filteredByEngagedCouple = Lca.filter((ad) => ad.engagedCouple === 1);
    const updatedFilteredProducts = filterProductsByEventDate(
      filteredByEngagedCouple,
      newMonth
    );

    dispatch(setFinalProducts(updatedFilteredProducts));
    setFilteredProducts(updatedFilteredProducts);
  };

  const filterProductsByEventDate = (products, month) => {
    return products.filter((product) => {
      try {
        const eventDetails = JSON.parse(product.eventDetails);
        return eventDetails.some((event) => {
          const eventDate = new Date(event.eventDate);
          return (
            !isNaN(eventDate.getTime()) &&
            `${eventDate.getFullYear()}-${String(
              eventDate.getMonth() + 1
            ).padStart(2, "0")}` === month
          );
        });
      } catch {
        return false;
      }
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      scrollToTop();
    }
  };

  const handleNextPage = (totalPages) => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    const container = document.querySelector(".hompage-ads-container");
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const sortedProducts = [...finalProducts].sort(
    (a, b) => b.caIdStatus - a.caIdStatus
  );
  const sortedBwv = [...finalBwv].sort((a, b) => b.caIdStatus - a.caIdStatus);

  const currentUsers = sortedProducts.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );
  const currentVendors = sortedBwv.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  const totalPagesForCustomers = Math.ceil(
    sortedProducts.length / usersPerPage
  );
  const totalPagesForVendors = Math.ceil(sortedBwv.length / usersPerPage);

  return (
    <>
      {/* <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
        <div className="flex-grow-1 text-center text-md-start">
          <h6
            className="fw-bold p-1 bg-info text-white"
            style={{
              width: "fit-content",
              borderRadius: "8px",
              margin: "0 auto",
            }}
          >
            {
              filteredProducts.filter(
                (product) =>
                  product.eventDetails &&
                  JSON.parse(product.eventDetails).some(
                    (event) => event.totalGuestCount > 150
                  )
              ).length
            }{" "}
            Products Have 150+ Guests
          </h6>
        </div>
        <div
          className="d-flex flex-grow-1 flex-md-nowrap flex-wrap align-items-center justify-content-center justify-content-md-end"
          style={{ marginTop: 0 }}
        >
          <b className="text-nowrap me-2">Select Month & Year:</b>
          <Input
            type="month"
            className="form-control-sm w-auto"
            value={month}
            onChange={(e) => handleMonthChange(e.target.value)}
          />
        </div>
      </div> */}

      <LcaCards2
        allProducts={currentUsers}
        scrollPopupVisible={scrollPopupVisible}
        carouselData={carouselData}
      />
      <BwvCards2 allProducts={currentVendors} carouselData={carouselData} />

      <div className="pagination pb-3 d-flex justify-content-center">
        <Button
          className="me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="align-self-center">
          Page {currentPage} of{" "}
          {Math.max(totalPagesForCustomers, totalPagesForVendors)}
        </span>
        <Button
          className="ms-2"
          onClick={() =>
            handleNextPage(
              Math.max(totalPagesForCustomers, totalPagesForVendors)
            )
          }
          disabled={
            currentPage ===
            Math.max(totalPagesForCustomers, totalPagesForVendors)
          }
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default LcaAndBwvShuffle;
