import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { updateGurudwaraNews } from "../../../components/Header/Data3";
import Placeholder from "../../../assets/images/Placeholder.png";
import { toast } from "react-toastify";
import "../DashboardPages.css";

const ActiveNews = ({ activeNews, fetchData }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [openMenuId, setOpenMenuId] = useState(null);
  const navigate = useNavigate();
  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };
  const onEdit = async (e, news) => {
    e.stopPropagation();
    navigate("/dashboardpage/createGurdwaraNews", { state: { news } });
  };
  const onDelete = async (e, news) => {
    e.stopPropagation();
    const data = {
      newsStatus: 2,
      updatedBy: user.UserID,
    };
    await updateGurudwaraNews(news.id, data);
    toast.success(`${news.Title} Deleted`);
    fetchData();
  };
  const onPause = async (e, news) => {
    e.stopPropagation();
    const data = { updatedBy: user.UserID, newsStatus: 3 };
    await updateGurudwaraNews(news.id, data);
    toast.success(`${news.Title} Paused`);
    fetchData();
  };

  const handleCardClick = (e, Id) => {
    e.stopPropagation();
    navigate(`/gurdwaraNewsDetails/${Id}`);
  };

  const removeHtmlTags = (text) => {
    return text.replace(/<[^>]+>/g, "");
  };
  return (
    <Row
      className=" requests-container ads"
      style={{ margin: "0 7px", padding: "20px" }}
    >
      {activeNews.map((news) => (
        <Row
          key={news.Id}
          className="need-card"
          style={{ cursor: "pointer" }}
          // onClick={(e) => handleCardClick(e, news?.id)}
        >
          <Col lg={4} xl={4} className="img needs-image p-0">
            <img
              className="w-100"
              style={{ height: "185px" }}
              src={news.Image || Placeholder}
              alt={news.Title}
            />
          </Col>
          <Col lg={8} xl={8} className="need-info">
            <Row>
              <Col
                sm="6"
                xs="12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="pt-2">{news.Title}</p>
              </Col>
              <Col sm="6" xs="12" className="action-buttons">
                {isAdmin && (
                  <button
                    className="px-2 btn-sm mx-2"
                    style={{
                      backgroundColor: "#FFD06D",
                      color: "#000",
                      height: "32px",
                    }}
                    onClick={(e) => onPause(e, news)}
                  >
                    Pause
                  </button>
                )}{" "}
                <Button
                  color="primary"
                  size="sm"
                  className="me-2"
                  onClick={(e) => onEdit(e, news)}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={(e) => onDelete(e, news)}
                >
                  Delete
                </Button>
                {/* <div className="menu-container mx-1">
                  <span
                    className="menu-toggle"
                    onClick={() => toggleMenu(news.Id)}
                  >
                    ⋮
                  </span>
                  {openMenuId === news.Id && (
                    <div className="menu">
                      <p onClick={() => onDelete(news)}>Delete</p>
                    </div>
                  )}
                </div> */}
              </Col>
            </Row>
            <div className="overflow-hidden">
              {/* <h6>{news.Title}</h6> */}
              {/* <p>
                  <FaMapMarker /> {need.Zipcode}
                </p> */}
              <p>
                {" "}
                {news.Description.length > 150
                  ? `${removeHtmlTags(news.Description).slice(0, 150)}...`
                  : removeHtmlTags(news.Description)}
              </p>
            </div>
            {/* <div className="need-footer">
          <p>
            <FaMapMarker /> {need.Zipcode}
          </p>
        </div> */}
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default ActiveNews;
