import React, { useEffect, useState } from "react";
import Helmet from "../../../components/Helmet/Helmet";
import Sidebar from "../../SideBar";
import { Col, Row } from "reactstrap";
import { FadeLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
import ActiveSites from "./ActiveSites";
import PendingSites from "./PendingSites";
import { getAllWebsites } from "../../../redux/slices/ComWebSlice";

const MyCommunityWebSites = () => {
  const [tab, setTab] = useState("active");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { myWebsites, loadingWebSites, error } = useSelector(
    (state) => state.website
  );
  useEffect(() => {
    dispatch(getAllWebsites()); // Dispatch the thunk
  }, [dispatch]); // Add dispatch to dependency array
  return (
    <Helmet title="My Community WebSites">
      <section className="home-after-login bg-white">
        <div className="container">
          <Row>
            <Col sm={3}>
              <Sidebar />
            </Col>
            <Col sm={9}>
              {loadingWebSites ? (
                <FadeLoader // FadeLoader component
                  css={{ margin: "0 auto" }}
                  color={"#36D7B7"}
                  loading={loadingWebSites}
                  className="position-absolute top-50 start-50 translate-middle"
                  //style={{ left: "0%", top: "40%" }}
                />
              ) : (
                <div className="web-sites-dashboard">
                  <div
                    className="text-end px-3 pt-3"
                    style={{ marginTop: "7px" }}
                  >
                    <Button
                      type="button"
                      className="btn btn-danger text-end"
                      onClick={() => {
                        navigate("/dashboardpage/create-website");
                      }}
                    >
                      <BiPlusCircle /> Add New
                    </Button>
                  </div>
                  <div
                    className="col-lg-6 d-flex row mt-3 view-tabs w-100"
                    style={{ marginLeft: "2px" }}
                  >
                    <h5
                      className={`col-sm-4 ${
                        tab === "active" ? "active" : ""
                      } mx-0`}
                      onClick={() => {
                        setTab("active");
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        searchParams.set("tab", "active-sites");
                        navigate(
                          {
                            pathname: location.pathname,
                            search: searchParams.toString(),
                          },
                          { replace: true }
                        );
                      }}
                      style={{
                        textDecoration: tab === "active" ? "underline" : "none",
                        cursor: "pointer",
                        width: "50%",
                      }}
                    >
                      Active
                    </h5>
                    <h5
                      className={`col-sm-4 ${
                        tab === "pending" ? "active " : ""
                      } mx-0`}
                      onClick={() => {
                        setTab("pending");
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        searchParams.set("tab", "pending-sites");
                        navigate(
                          {
                            pathname: location.pathname,
                            search: searchParams.toString(),
                          },
                          { replace: true }
                        );
                      }}
                      style={{
                        textDecoration:
                          tab === "pending" ? "underline" : "none",
                        cursor: "pointer",
                        width: "50%",
                      }}
                    >
                      Pending
                    </h5>
                  </div>
                  {tab === "active" && <ActiveSites />}
                  {tab === "pending" && <PendingSites />}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </Helmet>
  );
};

export default MyCommunityWebSites;
