import React, { useEffect, useState } from "react";
import OrderInfo from "./OrderInfo";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Order.css"; // Additional custom CSS if needed
import { Button } from "reactstrap";
import { setDescription, setOrder } from "../../../redux/slices/MsgSlice";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Payment from "../settingsComponents/Payment";

const OrderCard = ({ orders }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { description, orderStatus } = useSelector((state) => state.messages);
  const [amount, setAmount] = useState(null);
  const [openPaypal, setOpenPaypal] = useState(false);
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const openMsgModal = (order) => {
    setSelectedOrder(order);
    setIsMsgModalOpen(true);
  };

  const closeMsgModal = () => {
    setIsMsgModalOpen(false);
  };

  const handleChatPayment = async (order) => {
    console.log(order?.Id, "Order");
    setIsMsgModalOpen(false);
    setModalIsOpen(true);
    dispatch(setOrder(order?.Id));
    setAmount(parseInt(order?.totalAmount));
    dispatch(setDescription(order?.Message));
  };
  console.log(orders, "orders");
  const closeModal = () => {
    setModalIsOpen(false);
    setAmount(null);
    setOpenPaypal(false);
    dispatch(setDescription(""));
  };
  useEffect(() => {
    if (orders) {
      setAmount(parseInt(orders?.totalAmount));
      dispatch(setDescription(orders?.orderMessage));
    }
  }, [orders?.orderMessage]);

  useEffect(() => {
    if (orderStatus === "COMPLETED") {
      setModalIsOpen(false);
    }
  }, [orderStatus]);
  return (
    <div className="container mt-4">
      <div className="row">
        {orders.map((order) => (
          <div
            key={order.id}
            className="col-lg-4 col-md-6 mb-4"
            // onClick={() => openMsgModal(order)}
          >
            <div className="card h-100">
              <img
                src={
                  order.profilePhoto ||
                  "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                }
                className="card-img-top"
                alt={order.userName.split("@")[0].trim()}
              />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center ">
                  <h5 className="card-title">
                    {order.userName.split("@")[0].trim()}
                  </h5>
                  <p
                    className={`badge ${
                      order.orderStatus.toLowerCase() === "pending"
                        ? "badge-warning"
                        : order.orderStatus.toLowerCase() === "completed"
                        ? "badge-success"
                        : "badge-danger"
                    } p-2 mb-0`}
                  >
                    {order.orderStatus}
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>ID:</b> {order?.Id}
                  </li>
                  <li className="list-group-item">
                    <b>Created At:</b>{" "}
                    {new Date(order.createdAt).toLocaleDateString()}
                  </li>
                  <li className="list-group-item">
                    <b>Total Amount:</b> $
                    {parseFloat(order.totalAmount).toFixed(2)}
                  </li>
                  <li className="list-group-item">
                    <b>Discount:</b>{" "}
                    {order.Discount
                      ? `$${parseFloat(order.Discount).toFixed(2)}`
                      : "0:00"}
                  </li>
                  <li className="list-group-item">
                    <b>Paid Amount:</b>{" "}
                    {order.paidAmount
                      ? `$${parseFloat(order.paidAmount).toFixed(2)}`
                      : `$${parseFloat(
                          order.totalAmount - order.Discount
                        ).toFixed(2)}`}
                  </li>
                  {order?.Message !== "null" && (
                    <li className="list-group-item">
                      <b>Description:</b> {order?.Message}
                    </li>
                  )}
                  <li className="list-group-item">
                    <b>Order Status:</b> {order?.orderStatus}
                  </li>
                  {order?.orderStatus === "PENDING" &&
                    order?.createdBy === user?.UserID &&
                    order?.Message !== "null" && (
                      <li className="list-group-item">
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => handleChatPayment(order)}
                        >
                          Pay Now
                        </Button>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedOrder && (
        <OrderInfo
          order={selectedOrder}
          isOpen={isMsgModalOpen}
          closeModal={closeMsgModal}
        />
      )}
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalBody>
          {/* <div>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </div> */}
          <div>
            <Label>
              Description <b className="text-danger">*</b>
            </Label>
            <Input
              type="textarea"
              name="descrption"
              placeholder="Please Enter Descrption"
              value={description}
              // onChange={(e) => dispatch(setDescription(e.target.value))}
              disabled
            />
          </div>
          <div>
            <Label>Amount</Label>
            <InputGroup>
              <InputGroupText className="fw-bold">$</InputGroupText>
              <Input
                type="number"
                name="amount"
                placeholder="amount"
                value={amount}
                // onChange={handleAmountChange}
                disabled
              />
            </InputGroup>
            <div>
              <Payment
                totalPrice={Number(amount)}
                state={{ chatPay: true }}
                chatDetails={true}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OrderCard;
