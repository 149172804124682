/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "react-chat-elements/dist/main.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import "../Header/WeddingVendors/Bwv.css";
import { GetReviewDatilsByOrderId } from "./Data2";
import { sendReview } from "./Data";
import { toast } from "react-toastify";
import { setDialExt } from "../../redux/slices/IteamFormSlice";

const ReviewService = () => {
  const navigate = useNavigate();
  const { id: cardId } = useParams();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [modelOpen, setModelOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [services, setServices] = useState([]);
  const [testimonial, setTestimonial] = useState("");
  const [selectedService, setSelectedService] = useState(null);

  const GetOrderDetails = async () => {
    const details = await GetReviewDatilsByOrderId(cardId);
    setServices(details);
    if (details.length <= 1) {
      setSelectedService(details[0]);
    }
    console.log(details, "details in feedback");
  };

  useEffect(() => {
    GetOrderDetails();
  }, []);

  const handleStarClick = (star) => {
    setRating(star);
    setRatingError(false);
  };

  const handleModelOpen = () => {
    setModelOpen(!modelOpen);
  };

  const handleServiceChange = (e) => {
    const selectedService = services.find(
      (service) => service.id === parseInt(e.target.value)
    );
    setSelectedService(selectedService);
    console.log(selectedService, "selectedService");
  };

  const submitReview = async () => {
    if (!islogin) {
      toast.error("Please login to submit review.");
      return;
    }
    if (services.length > 1 && !selectedService) {
      toast.error("Please select a service.");
      return;
    }
    if (rating === 0) {
      setRatingError(true);
      toast.error("Please select a rating.");
      return;
    }
    if (!review.trim()) {
      setReviewError(true);
      toast.error("Please provide a review.");
      return;
    }
    const formData = {
      customerId: user.UserID,
      serviceId: selectedService.id,
      ownerId: selectedService.listdetails[0].owner,
      Description: review,
      rating: rating,
      reviewStatus: 0,
      Testimonial: testimonial,
    };
    await sendReview(formData);
    toast.success("Review Submitted");
    if (services.length === 0) {
      navigate(`/vendordetails/${services[0]?.id}`);
    } else if (selectedService) {
      navigate(`/vendordetails/${selectedService?.id}`);
    } else {
      navigate(`/vendordetails/${cardId}`);
    }
    setTestimonial("");
    setRating(0);
    setReview("");
  };
  console.log(services, selectedService, "selectedService");
  return (
    <section className="bg-white h-100">
      <div className="container">
        <Row style={{ height: "100vh" }}>
          <Modal
            isOpen={handleModelOpen}
            toggle={() => {
              if (services.length === 0) {
                navigate(`/vendordetails/${services[0]?.id}`);
              } else if (selectedService) {
                navigate(`/vendordetails/${selectedService?.id}`);
              } else {
                navigate(`/vendordetails/${cardId}`);
              }
            }}
            size={"lg"}
            style={{ top: "40px" }}
          >
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  if (services.length === 0) {
                    navigate(`/vendordetails/${services[0]?.id}`);
                  } else if (selectedService) {
                    navigate(`/vendordetails/${selectedService?.id}`);
                  } else {
                    navigate(-1);
                  }
                }}
              ></button>
            </div>
            <ModalHeader>
              <div className="review-header">
                <img
                  src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                  alt={``}
                  className="review-profile-picture"
                />
                <span className="review-username">
                  {services[0]?.userName ? services[0].userName.split("@")[0].trim() : "Provider"}
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Form>
                  <Row>
                    <Col>
                      {services.length === 1 ? (
                        <FormGroup>
                          <Label>
                            Select the Service{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            value={services[0].serviceName}
                            disabled
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label>
                            Select the Service{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input type="select" onChange={handleServiceChange}>
                            <option value="">Select Service</option>
                            {services.map((opt, index) => (
                              <option key={index} value={opt.id}>
                                {opt.serviceName}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      )}
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>
                          Select Rating <span style={{ color: "red" }}>*</span>
                        </Label>
                        <ReactStars
                          count={5}
                          size={24}
                          activeColor="#ffd700"
                          onChange={handleStarClick}
                          className={
                            ratingError ? "star-input error" : "star-input"
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>
                      Write A Review <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="review"
                      placeholder="Enter Review..."
                      onChange={(e) => {
                        setReview(e.target.value);
                        setReviewError(false);
                      }}
                      className={
                        reviewError ? "review-input error" : "review-input"
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Video Url</Label>
                    <Input
                      type="url"
                      id="Testimonial"
                      placeholder="Enter Video Url..."
                      value={testimonial}
                      onChange={(e) => {
                        setTestimonial(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Form>
              </Row>
              <div className="d-flex justify-content-end mt-3">
                <Button color="primary" onClick={submitReview}>
                  Submit Review
                </Button>{" "}
              </div>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    </section>
  );
};

export default ReviewService;
