import React from "react";
import "./messages.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConvoById } from "../../../components/Header/Data2";
import moment from "moment";
import { Badge } from "@mui/material";
import { styled } from "@mui/system";
import EventDetails from "./AllDropDowns/EventDetails";
import FollowUpTasks from "./AllDropDowns/FollowUpTasks";
import OpportunityStage from "./AllDropDowns/OpportunityStage";
import MsgDropdowns from "./MessageDropDowns/MsgDropDowns";

function MessageItem({ conversation, onSelectConversation }) {
  const { users } = useSelector((state) => state.socket);
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const { searchTerm, meetingDateSearch, oppertunityFilter } = useSelector(
    (state) => state.messages
  );
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const [latest, setLatest] = React.useState(null);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const ViewProfile = (msg) => {
    if (msg.createdBy === user.UserID) {
      navigate(`/userDetails/${msg.customerId}`, { state: msg.ownerId });
    } else {
      navigate(`/userDetails/${msg.createdBy}`, { state: msg.ownerId });
    }
  };
  const OnlineBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700", // Green for online
      color: "#44b700",
      boxShadow: `0 0 8px #44b700`,
      width: 12,
      height: 12,
      borderRadius: "50%",
      border: "2px solid white",
    },
  }));
  const search = searchTerm.toLowerCase() || meetingDateSearch.toLowerCase();
  const searchResults =
    search && conversation
      ? [
          conversation.senderName,
          conversation.customerName,
          conversation.senderEmail,
          conversation.customerEmail,
          conversation.providerPhone,
          conversation.customerPhone,
          conversation.reserveDate,
        ]
          .filter((field) =>
            field?.toLowerCase().includes(search.toLowerCase())
          )
          .join(", ")
      : null;

  const fetchMsgs = async () => {
    if (conversation.Id) {
      const messagesData = await getConvoById(conversation?.Id);
      const filterNonScheduled = messagesData.filter(
        (msg) => msg.createdBy === user.UserID || msg.messageStatus !== 0
      );
      setLatest(filterNonScheduled[0]);
      const filtercount = messagesData.filter(
        (msg) =>
          msg.createdBy != msg.customerId &&
          msg.customerId == user.UserID &&
          msg.seenCustomer == 0
      );
      console.log(filtercount, "filtercount");
      setUnreadCount(filtercount.length);
    }
  };
  const checkOnlineStatus = (conversation) => {
    const otherUserId =
      conversation.createdBy === user.UserID
        ? conversation.customerId
        : conversation.createdBy;
    const otherUser = users && users?.find((user) => user.Id === otherUserId);
    console.log(
      otherUser,
      "otherUser?.onlineStatus================",
      otherUser?.onlineStatus
    );
    return otherUser?.onlineStatus === "online";
  };
  console.log(latest?.Message, "latest ====================");
  console.log(users, "users");
  const userTimeZone = moment.tz.guess();
  React.useEffect(() => {
    fetchMsgs();
  }, [conversation]);
  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}`;
    return formattedDate;
  }
  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }
  console.log(unreadCount, "unreadCount");
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  return (
    <div
      className={`message-item-wrapper ${
        !oppertunityFilter &&
        (conversation.latestType === 7 ||
          conversation.latestType === 8 ||
          conversation.latestType === 9)
          ? "d-none"
          : ""
      }  ${
        selectedConversation && selectedConversation.Id === conversation.Id
          ? "selected"
          : ""
      } ${unreadCount > 0 ? "unread-message" : ""}`}
    >
      <div
        className={`message-item ${unreadCount > 0 ? "unread" : ""} mb-2`}
        onClick={() => onSelectConversation(conversation.Id, conversation)}
      >
        <hr />
        <div className="message-avatar">
          <OnlineBadge
            overlap="circular"
            variant={checkOnlineStatus(conversation) ? "dot" : "standard"}
            // variant={conversation.isOnline ? "dot" : "standard"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <img
              src={
                conversation?.createdBy !== user.UserID
                  ? conversation?.senderPhoto ||
                    "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                  : conversation?.receiverProfilePhoto ||
                    "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
              onClick={() => ViewProfile(conversation)}
              alt="User"
              className="rounded-circle border border-1 border-secondary bg-light avatar shadow p-1 mb-1 d-inline-block"
              width={40}
              height={40}
            />
          </OnlineBadge>
        </div>
        <div className="message-info">
          <div className="name">
            {" "}
            {conversation.createdBy === user.UserID ? (
              <strong
                // onClick={() => ViewProfile(conversation)}
                style={{ fontSize: "small", cursor: "pointer" }}
              >
                <span>
                  {conversation?.customerName &&
                  conversation?.customerName.length > 20
                    ? `${conversation?.customerName.slice(0, 20)}...`
                    : conversation?.customerName}
                </span>
              </strong>
            ) : (
              <strong
                style={{ fontSize: "small", cursor: "pointer" }}
                // onClick={() => ViewProfile(conversation)}
              >
                <span>
                  {conversation?.senderName &&
                  conversation?.senderName.length > 20
                    ? `${conversation?.senderName.slice(0, 20)}...`
                    : conversation?.senderName}
                </span>
              </strong>
            )}
            {
              /* {isAdmin && */
              isAdmin &&
                conversation.postType == 3 &&
                conversation.messageStatus == 1 && (
                  <span className="offer-dot" title={"New offer"}></span>
                )
            }
          </div>
          <div
            className="last-message"
            dangerouslySetInnerHTML={{
              __html:
                unreadCount > 0
                  ? `<strong>${
                      latest?.Message?.length > 100
                        ? `${latest.Message.slice(0, 100)}...`
                        : latest?.Message
                    }</strong>`
                  : latest?.Message?.length > 100
                  ? `${latest.Message.slice(0, 100)}...`
                  : latest?.Message,
            }}
          ></div>
          <div className="search-result-title">
            {searchResults && searchResults}
          </div>
        </div>
        {/* <div className="message-date">2024-08</div> */}
        <div
          className={`message-date mt-1 ${
            latest?.createdBy === user.UserID ? "me-1" : "ms-1"
          }`}
        >
          <span className="me-2">
            {moment
              .utc(latest?.createdAt?.split("T")[0]) // Ensure UTC before converting
              .tz(userTimeZone)
              .format("MMM DD")}
          </span>
          <span>
            {convertTo12HourFormat(
              convertToUserTimezone(latest?.createdAt?.split(" ")[1])
            )}
          </span>
        </div>
        {unreadCount > 0 && (
          <div className="unread-count">
            <span>{unreadCount}</span>
          </div>
        )}
      </div>
      <MsgDropdowns convo={conversation} />
    </div>
  );
}

export default MessageItem;
