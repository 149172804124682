import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Col, Input, Row } from "reactstrap";
import GoogleCalendar from "../../DashboardCalenderPages/Calender";
import { FaSyncAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { setSelectedPackageRate } from "../../../redux/slices/BwvData";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const VendorPackages = ({ parsedCreatedPackages, openHireModal }) => {
  const [searchDetails, setSearchDetails] = useState({
    hallType: "",
    cateringOptions: "",
    countOfGuests: "",
    packages: "",
  });
  const currentHour = new Date().getHours().toString().padStart(2, "0");
  const [selectedHour, setSelectedHour] = useState(`${currentHour}:00`);
  const { selectedServiceDate, selectedPackageRate } = useSelector(
    (state) => state.bwvData
  );
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [packageIndex, setPackageIndex] = useState(null);
  const navigate = useNavigate();
  const [filteredPackages, setFilteredPackages] = useState([]);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSearchDetails({
      ...searchDetails,
      [event.target.name]: event.target.value,
    });
  };
  const handleCart = () => {
    console.log(searchDetails?.packages, "packages");
  };
  const flatRate = () => {
    if (!selectedPackage) {
      toast.warning("Please select a package");
      return;
    }
    dispatch(setSelectedPackageRate(selectedPackage?.totalPrice));
    openHireModal();
  };
  console.log(selectedPackageRate, "selectedPackageRate");
  console.log(parsedCreatedPackages, "parsedCreatedPackages");
  const packageTitles = parsedCreatedPackages.map((packageItem) => {
    return packageItem.packages.map((item) => item.title);
  });
  const flatPackageTitles = [...new Set(packageTitles.flat())];
  const uniqueGuestCounts = [
    ...new Set(parsedCreatedPackages.map((item) => item.guestCount)),
  ];
  const handleSearch = () => {
    const hallTypeMatch = searchDetails.hallType !== "";
    const cateringOptionsMatch = searchDetails.cateringOptions !== "";
    const guestCountMatch = searchDetails.countOfGuests !== "";

    // if (!hallTypeMatch && !cateringOptionsMatch && !guestCountMatch) {
    //   toast.error("Please select at least one search criteria");
    //   return;
    // }

    if (!hallTypeMatch && !cateringOptionsMatch && !guestCountMatch) {
      // setFilteredPackages(parsedCreatedPackages);
      setFilteredPackages([]);
      // toast.error("Please select at least one search criteria");
      return;
    } else {
      const filteredPackages = parsedCreatedPackages.filter((packageItem) => {
        const hallTypeMatch = packageItem.packages.some(
          (packageOption) =>
            packageOption.type.toLowerCase() ===
            searchDetails.hallType.toLowerCase()
        );
        const cateringOptionsMatch = packageItem.packages.some(
          (packageOption) =>
            packageOption.title.toLowerCase() ===
            searchDetails.cateringOptions.toLowerCase()
        );
        const guestCountMatch =
          packageItem.guestCount.toString() ===
          searchDetails.countOfGuests.toString();
        return (
          (hallTypeMatch || searchDetails.hallType === "") &&
          (cateringOptionsMatch || searchDetails.cateringOptions === "") &&
          (guestCountMatch || searchDetails.countOfGuests === "")
        );
      });
      console.log(filteredPackages, "filteredPackages");
      setFilteredPackages(filteredPackages);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchDetails]);

  return (
    <div className="bg-white">
      <div className="text-center">
        <div>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 180 }}
            style={{ marginTop: "8px" }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Deal Factor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={searchDetails.hallType}
              onChange={handleChange}
              label="hallType"
              name="hallType"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {selectedCard?.serviceSelectType &&
                JSON.parse(selectedCard?.serviceSelectType)?.map(
                  (title, index) => (
                    <MenuItem key={index} value={title.value}>
                      {title.label}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Item Options
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={searchDetails.cateringOptions}
              onChange={handleChange}
              label="cateringOptions"
              name="cateringOptions"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {flatPackageTitles.map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 180 }}
            style={{ marginTop: "9px" }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Count of Guests
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={searchDetails.countOfGuests}
              onChange={handleChange}
              label="countOfGuests"
              name="countOfGuests"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {uniqueGuestCounts.map((guestCount, index) => (
                <MenuItem key={index} value={guestCount}>
                  {guestCount}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button color="danger" className="mt-4" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <div className="my-3">
          <Row className="rounded border">
            <Col>
              <Row
                className="d-flex flex-nowrap"
                style={{
                  overflow: "auto hidden",
                  // width: "95%",
                  // overflowX: "hidden",
                  // gap: "10px",
                }}
              >
                {filteredPackages.length > 0 ? (
                  <>
                    {filteredPackages.map((pkg, idx) => (
                      <Col key={idx} lg={6} className="my-4">
                        <div
                          onClick={() => {
                            setPackageIndex(idx);
                            setSelectedPackage(pkg);
                          }}
                        >
                          <Row
                            className={`border `}
                            style={{
                              borderRadius: "25px",
                              cursor: "pointer",
                              marginLeft: "0px",
                              boxShadow:
                                packageIndex === idx
                                  ? "#5435C2 0px 3px 23px 0px"
                                  : "",
                            }}
                          >
                            <Col lg={10}>
                              <h5 className="text-center mb-4 mt-1">
                                {" "}
                                {pkg.title}
                              </h5>
                              <div className="my-4">
                                <Row className="p-2">
                                  {pkg.packages.map((packageItem, index) => (
                                    <Col
                                      key={index}
                                      xs={12}
                                      md={6}
                                      lg={4}
                                      className="my-4 p-0"
                                    >
                                      <div>
                                        <Input
                                          type="checkbox"
                                          className="mt-2"
                                          checked={packageItem.checked}
                                          disabled={true}
                                        />
                                        <span className="ms-1">
                                          <span className="">
                                            {" "}
                                            {packageItem.title}
                                          </span>
                                          <br />
                                          <p
                                            style={{
                                              marginLeft: "25px",
                                              marginBottom: "0px",
                                              fontSize: "9px",
                                            }}
                                          >
                                            {packageItem.description}
                                          </p>
                                        </span>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </Col>
                            <Col
                              lg={2}
                              style={{
                                backgroundColor: "#c3c3c3",
                                display: "flex",
                                alignItems: "center",
                                borderTopRightRadius: "25px",
                                borderBottomRightRadius: "25px",

                                justifyContent: "center",
                              }}
                            >
                              <Row style={{ gap: "10px", fontSize: "12px" }}>
                                <Col lg={12} className="text-center">
                                  Guest Count: <strong>{pkg.guestCount}</strong>
                                </Col>
                                <Col lg={12} className="text-center">
                                  Total Price:{" "}
                                  <strong>${parseInt(pkg.totalPrice)}</strong>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : (
                  <div className="text-center">No Packages Found</div>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        {filteredPackages.length > 0 && (
          <div>
            <Button color="warning" className="my-2" onClick={flatRate}>
              Add to Cart ${selectedPackage?.totalPrice || 0}
            </Button>
          </div>
        )}
        {/* <div className="mt-3">
          <div className="text-center">
            <div className="fs-3 fw-bold">
              Availability <FaSyncAlt className="rotate-icon text-primary" />
            </div>
            <div className="packages_calender">
              <GoogleCalendar
                selectedHour={selectedHour}
                setSelectedHour={setSelectedHour}
                packages={true}
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VendorPackages;
