import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog"; // Import Dialog
import DialogActions from "@mui/material/DialogActions"; // Import DialogActions
import DialogContent from "@mui/material/DialogContent"; // Import DialogContent
import DialogContentText from "@mui/material/DialogContentText"; // Import DialogContentText
import DialogTitle from "@mui/material/DialogTitle"; // Import DialogTitle
import Button from "@mui/material/Button"; // Import Button
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import {
  fetchCommunityList,
  setShowComments,
} from "../../../redux/slices/PostFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetAllComments, GetLikes } from "../../../components/Header/Data3";
import "./Posts.css";

const MyCommunityDboardCard = ({ post }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin";
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false); // State for confirmation dialog
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [allLikes, setAllLikes] = React.useState([]);
  const [allComments, setAllComments] = React.useState([]);
  // Parse galleryImage if it's a JSON string; otherwise, assume it's an array
  const images = React.useMemo(() => {
    try {
      return typeof post.galleryImage === "string"
        ? JSON.parse(post.galleryImage)
        : post.galleryImage || [];
    } catch (e) {
      console.error("Error parsing galleryImage:", e);
      return [];
    }
  }, [post.galleryImage]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleComments = (e, post) => {
    e.preventDefault();
    dispatch(setShowComments(post));
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const fetchLikes = async () => {
    const req = {
      communityId: post.Id,
      createdBy: user?.UserID,
    };
    const response = await GetLikes(req);
    setAllLikes(response);
  };

  const fetchComments = async () => {
    const response = await GetAllComments(post.Id);
    const filteredComments = response.filter(
      (comment) => comment.commentStatus !== 2
    );
    setAllComments(filteredComments || []);
  };

  React.useEffect(() => {
    fetchLikes();
    fetchComments();
  }, [post]);
  const handleEdit = () => {
    navigate(`/dashboardpage/create-post/Updating${post.Id}`, {
      state: { post },
    });
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setOpenDialog(true); // Open confirmation dialog
    handleMenuClose();
  };

  const StatusUpdatePost = async (status) => {
    try {
      const response = await axiosClient.put(
        `Community/updateCommunity/${post.Id}`,
        {
          communityStatus: status,
        }
      );
      if (response.data.statusCode === 200) {
        switch (status) {
          case 1:
            toast.success("Post has been Approved");
            break;
          case 2:
            toast.success("Post has been Deleted");
            break;
          case 3:
            toast.success("Post has been Rejected");
            break;
        }
      }
      dispatch(fetchCommunityList(1));
    } catch (error) {
      console.error("Failed to update post status:", error);
    }
  };

  const handleDeleteConfirm = () => {
    console.log(`Confirmed deletion of post: ${post.id}`);
    StatusUpdatePost(2);
    setOpenDialog(false); // Close dialog after confirmation
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false); // Close dialog without action
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  // Check if Description contains HTML tags
  const isHtml = (str) => /<[a-z][\s\S]*>/i.test(str);

  return (
    <Card className="mycommunity-card">
      <CardHeader
        avatar={
          <Avatar
            alt="Remy Sharp"
            src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
          />
        }
        action={
          <>
            <IconButton
              aria-label="settings"
              aria-controls="post-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="post-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
            </Menu>
          </>
        }
        title={
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "150px", // Adjust this value based on your layout
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {post?.firstName + " " + post?.lastName || "Creator Name"}
          </Typography>
        }
        subheader={
          post.createdAt
            ? new Date(post.createdAt).toLocaleDateString()
            : "September 14, 2016"
        }
      />
      <Box sx={{ position: "relative", height: 194 }}>
        {images.length > 0 ? (
          <>
            <CardMedia
              component="img"
              height="194"
              image={images[currentImageIndex]}
              alt={`${post.Title} - Image ${currentImageIndex + 1}`}
            />
            {images.length > 1 && (
              <>
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "2px 8px",
                    borderRadius: "4px",
                  }}
                >
                  {`${currentImageIndex + 1} / ${images.length}`}
                </Typography>
              </>
            )}
          </>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              color: "text.secondary",
            }}
          >
            <Typography variant="body2">No Images Available</Typography>
          </Box>
        )}
      </Box>
      <CardContent>
        <h5
          onClick={() => {
            if (post.communityStatus === 1) {
              navigate(`/postDetails/${post.Title}`, { state: post });
            } else {
              toast.error("Post is not approved yet");
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {post?.Title || "Untitled"}
        </h5>
        {post?.Description ? (
          isHtml(post.Description) ? (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{ __html: post.Description }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
            >
              {post.Description}
            </Typography>
          )
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontStyle: "italic" }}
          >
            No description available
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          padding: "8px 4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton aria-label="add to favorites" size="small">
              <FavoriteIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <Typography variant="body2" color="text.secondary">
              {allLikes?.totalLikes || 0}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              aria-label="share"
              size="small"
              disabled={post.communityStatus === 0 || !isAdmin}
              onClick={(e) => handleComments(e, post)}
            >
              <ChatBubbleOutlineIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <Typography variant="body2" color="text.secondary">
              {(allComments && allComments?.length) || 0}
            </Typography>
          </Box>
        </Box>

        {isAdmin && post.communityStatus === 0 && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={() => StatusUpdatePost(1)}
              size="small"
              variant="outlined"
              sx={{ color: "green", minWidth: "60px", fontSize: "x-small" }}
            >
              Approve
            </Button>
            <Button
              onClick={() => StatusUpdatePost(3)}
              size="small"
              variant="outlined"
              sx={{ color: "red", minWidth: "60px", fontSize: "x-small" }}
            >
              Reject
            </Button>
          </Box>
        )}
      </CardActions>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delating {post?.Title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the post "
            {post?.Title || "Untitled"}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const MyCommunityCard = ({ myPosts }) => {
  return (
    <div className="news-grid">
      {myPosts.map((post) => (
        <MyCommunityDboardCard key={post.id} post={post} />
      ))}
    </div>
  );
};

export default MyCommunityCard;
