import React, { useEffect, useRef, useState } from "react";
import Payment from "../../settingsComponents/Payment";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import {
  getInvoicePay,
  orderInvoice,
} from "../../../../components/Header/Data3";
import { toast } from "react-toastify";
import { postOrder } from "../../../../components/Header/Data";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import uploadToS3 from "../../../../utils/awsS3Upload";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { setPaypalResponse } from "../../../../redux/slices/MsgSlice";

const InvoicePayment = () => {
  const [pay, setPay] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [order, setOrder] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const { Id: apiId } = useParams();
  const [open, setOpen] = useState(false);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [filePreview, setFilePreview] = useState(null);
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [signature, setSignature] = useState(null);
  const fileInputRef = useRef(null);
  const { paypalResponse } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  const fetchInvoiceData = async () => {
    const response = await getInvoicePay(apiId);
    console.log(response, "fetchInvoiceData");
    setInvoiceDetails(response[0]);
    setSignature(response[0]?.signature);
    setFilePreview(response[0]?.insurance_file);
    if (response[0].order_status === "COMPLETED") {
      setPay(true);
    } else {
      setPay(false);
    }
    dispatch(setPaypalResponse(response[0].order_status));
    // dispatch(setPaypalResponse("COMPLETED"));
    setOrderId(response[0].order_id);
  };

  useEffect(() => {
    fetchInvoiceData();
    console.log(+apiId, "apiId");
  }, [apiId]);

  console.log(paypalResponse, "paypalResponse");

  const handlePaymentDetails = () => {
    let lateFee = 0;
    let totalFee = 0;
    let dueDate;
    if (
      typeof invoiceDetails?.payment_details === "string" &&
      invoiceDetails?.payment_details.startsWith("[")
    ) {
      // If payment_details is an array of objects, parse it and get the first object's amount
      const paymentDetailsArray = JSON.parse(invoiceDetails.payment_details);
      dueDate = new Date(paymentDetailsArray[0].due)
        .toISOString()
        .split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      if (dueDate < today) {
        // If the due date has passed, add a 10% late fee
        lateFee = (paymentDetailsArray[0].amount * 10) / 100;
        console.log(lateFee, "lateFee");
      }
      totalFee = paymentDetailsArray[0].amount;
      return { totalFee, lateFee, dueDate };
    } else {
      // If payment_details is a string, return it as is
      return parseInt(invoiceDetails.payment_details);
    }
  };

  const { totalFee: payAmount, lateFee, dueDate } = handlePaymentDetails();
  console.log(payAmount, "payAmount");

  const handleCreateOrder = async () => {
    if (!signature) {
      toast.error("Please add signature");
      return;
    }
    // if (filePreview || insuranceFile) {
    let folder = "invoicePdf/";
    let pdfUrl;
    console.log(insuranceFile, "filePreview");
    if (invoiceDetails.collect_insurance === 1) {
      try {
        pdfUrl = await uploadToS3(insuranceFile, folder); // Upload to AWS S3
        setInsuranceFile(pdfUrl);
        console.log(pdfUrl, "InvoiceUrl");
      } catch (error) {
        console.error("Failed to upload the PDF. Please try again.");
      }
    }
    // }
    await new Promise((resolve) => setTimeout(resolve, 500));
    const invoiceformData = {
      Id: +apiId,
      messageId: invoiceDetails?.message_id,
      insuranceFile: pdfUrl,
      Signature: signature,
      updatedBy: islogin ? user?.UserID : invoiceDetails?.customer_id,
      Type: "edit",
    };
    console.log(invoiceformData, "invoiceformData");
    await orderInvoice(invoiceformData);

    let formData = {
      customerId: invoiceDetails?.customer_id,
      messageId: invoiceDetails?.message_id,
      totalAmount: payAmount,
      receiverId: invoiceDetails?.created_by,
      lateFee: lateFee,
    };
    const orders = await postOrder(formData);
    setOrder(orders);
    // toast.success("Order Created");
    setPay(true);
    setOpen(false);
    setSignature(null);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file, "handleFileChange");
    setInsuranceFile(file);
    if (!file) {
      toast.error("No file selected.");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result); // Show the preview
    };
    reader.readAsDataURL(file);
  };

  const handleFileRemove = () => {
    setInsuranceFile(null);
    setFilePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  console.log(invoiceDetails, "invoiceDetails");
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  const toggleInvoiceModal = () => {
    setOpenInvoiceModal(!openInvoiceModal);
  };

  const handleViewInvoice = () => {
    const pdfUrl = invoiceDetails?.invoice_pdf;
    // if (pdfUrl) {
    //   const width = 800;
    //   const height = 600;
    //   const left = (window.innerWidth - width) / 2;
    //   const top = (window.innerHeight - height) / 2;
    //   const features = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes`;
    //   window.open(pdfUrl, "_blank", features);
    // }
    if (pdfUrl) {
      toggleInvoiceModal();
    }
  };
  Document.webpackEnabled = true;
  return (
    <div className="text-center d-flex align-items-center justify-content-center inter-font">
      <div
        className="w-75 my-5"
        style={{
          borderRadius: "10px",
          padding: "10px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="my-3">
          <h3>Invoice Payment</h3>
        </div>
        <hr />
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h4>Invoice ID: {invoiceDetails?.Id}</h4>
          <h6> Due Date: {moment(dueDate).format("MMMM DD, YYYY")}</h6>
          <h4>Need Name: {invoiceDetails?.need_name} </h4>
        </div>
        <div className="d-flex justify-content-around my-3 row">
          <div className="col-lg-6 col-sm-12">
            <h5>Customer details</h5>
            <div>
              <p> {invoiceDetails?.customer_name}</p>
              <p> {invoiceDetails?.customer_email}</p>
              <p>{invoiceDetails?.customer_phone}</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <h5>Vendor details</h5>
            <div>
              <p> {invoiceDetails?.provider_name}</p>
              <p> {invoiceDetails?.provider_email}</p>
              <p>{invoiceDetails?.provider_phone}</p>
            </div>
          </div>
          {invoiceDetails?.invoice_pdf && (
            <div>
              <iframe
                src={invoiceDetails?.invoice_pdf}
                width="100%"
                height="500"
              />

              {/* <Button color="primary" onClick={handleViewInvoice}>
              View Invoice
            </Button> */}
              {/* <Modal
              size="lg"
              isOpen={openInvoiceModal}
              toggle={toggleInvoiceModal}
            >
              <ModalBody>
                <embed
                  src={invoiceDetails?.invoice_pdf}
                  width="100%"
                  height="500"
                />
              </ModalBody>
            </Modal> */}
            </div>
          )}
        </div>
        <hr />
        <div className="d-flex flex-column align-items-center justify-content-center">
          {/* <h3>Amount: {isNaN(payAmount) ? 0 : payAmount}</h3> */}
          <Row className="w-100">
            <Col>
              <h5 className="my-3">Amount</h5>
              {lateFee !== 0 && (
                <p className="text-danger my-3">Late Fee (10%)</p>
              )}
              {lateFee !== 0 && <h5 className="my-3">Total</h5>}
            </Col>
            <Col>
              <h5 className="my-3"> ${isNaN(payAmount) ? 0 : payAmount}</h5>
              {lateFee !== 0 && (
                <p className="text-danger my-3 ms-2">
                  ${isNaN(lateFee) ? 0 : lateFee}
                </p>
              )}
              {lateFee !== 0 && (
                <h5 className="my-3">
                  $
                  {isNaN(payAmount + lateFee)
                    ? 0
                    : Math.floor(payAmount + lateFee)}
                </h5>
              )}
            </Col>
          </Row>
          {!pay ? (
            <div className="my-3">
              <Button color="success" onClick={() => setOpen(true)}>
                Pay Now
              </Button>
            </div>
          ) : paypalResponse !== "COMPLETED" ? (
            <div className="w-50 text-center" style={{ marginTop: "-30px" }}>
              <Payment
                totalPrice={lateFee !== 0 ? payAmount + lateFee : payAmount}
                state={{
                  invoicePay: true,
                  order: order,
                  invoice: invoiceDetails,
                }}
              />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center p-3 mt-3">
              <span className="text-success mb-4">
                <FaCheckCircle size={70} />
              </span>
              <h5 className="fw-bolder">PAYMENT COMPLETED SUCCESSFULLY!</h5>
              {/* <h5 className="text-success mt-2">
                Booking Request Sent to the Gurdwara for Approval
              </h5>
              <h5 className="text-success mt-2">
                Please wait up to 48 Hours for the Gurdwara staff to reply to
                you via email
              </h5> */}
              <h5 className="fw-bolder mt-4">Order number: {orderId}</h5>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={open}
        toggle={() => {
          setOpen(false);
          setFilePreview(null);
          setSignature(null);
        }}
      >
        <ModalBody>
          <div>
            {invoiceDetails.collect_insurance === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {!filePreview ? (
                  <form class="file-upload-form mt-3">
                    <label class="file-upload-label" for="file">
                      <div class="file-upload-design">
                        <svg height="1em" viewBox="0 0 640 512">
                          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                        </svg>
                        <h5>Upload proof of insurance converage</h5>
                        <p>Drag and Drop</p>
                        <p>or</p>
                        <span class="browse-button">Browse file</span>
                      </div>
                      <input
                        type="file"
                        id="file"
                        onChange={handleFileChange}
                        innerRef={fileInputRef} // Attach the ref to the input
                      />
                    </label>
                  </form>
                ) : (
                  <div style={{ width: "250px", height: "220px" }}>
                    <div>
                      <div className="d-flex flex-column justify-content-between align-items-center">
                        <embed src={filePreview} width="250" height="220" />
                        <div className="my-4">
                          <button
                            onClick={handleFileRemove}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <FormGroup className="my-2">
              <Label for="signature">
                Signature <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="signature"
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
              />
            </FormGroup>
            <div className="text-end">
              <Button color="success" onClick={handleCreateOrder}>
                Pay Now
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InvoicePayment;
