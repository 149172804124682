import React from "react";
import "./WebsitesInDashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { MdMoreVert } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { getAllWebsites } from "../../../redux/slices/ComWebSlice";

const PendingSites = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSite, setSelectedSite] = React.useState(null);
  const { myWebsites } = useSelector((state) => state.website);
  const inActiveSites = myWebsites.filter((site) =>
    isAdmin
      ? site.websiteStatus == 0 || site.websiteStatus == 3
      : site.websiteStatus == 0 && site.createdBy == user.UserID
  );
  const handleMenuOpen = (event, site) => {
    setAnchorEl(event.currentTarget);
    setSelectedSite(site);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSite(null);
  };

  const handleEdit = (site) => {
    if (site) {
      navigate(`/dashboardpage/create-website`, { state: { site } });
    }
  };

  const handleUpdate = async (site, status) => {
    const req = {
      websiteStatus: status,
      updatedBy: user?.UserID,
    };
    try {
      const response = await axiosClient.put(
        `ads/UpdateCommunityWebsite/${site?.id}`,
        req
      );
      if (response.data.statusCode === 200) {
        toast.success("Updated Successfully");
        dispatch(getAllWebsites());
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (inActiveSites.length === 0) {
    return <div className="pending-sites-no-data">No sites found</div>;
  }

  return (
    <div className="pending-sites-container">
      {inActiveSites.map((site, index) => (
        <div key={index} className="pending-sites-card">
          <div className="pending-sites-header">
            <h5 className="pending-sites-name">{site.Title}</h5>
            <MdMoreVert
              className="pending-sites-menu-icon"
              onClick={(e) => handleMenuOpen(e, site)}
            />
          </div>
          <div>
            <img
              src={site?.Image}
              alt={site.Title}
              className="pending-sites-image"
            />
          </div>
          <p className="pending-sites-description">{site.Description}</p>
          {isAdmin && site.websiteStatus === 0 && (
            <div className="pending-sites-actions mx-2">
              <button
                className="btn btn-success btn-sm"
                onClick={() => handleUpdate(site, 1)}
              >
                Approve
              </button>
              <button
                className=" btn btn-danger btn-sm"
                onClick={() => handleUpdate(site, 3)}
              >
                Reject
              </button>
            </div>
          )}
          {site.websiteStatus === 3 && (
            <div className="pending-sites-actions mx-2">
              <span className="pending-sites-rejected">Rejected</span>
            </div>
          )}
        </div>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => handleEdit(selectedSite)}>Edit</MenuItem>
        <MenuItem onClick={() => handleUpdate(selectedSite, 2)}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PendingSites;
