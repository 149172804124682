import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./Posts.css"; // Import external styles
import { useSelector } from "react-redux";
import MyCommunityCard from "./MyCommunityCard";

const MyLocalNewsPapers = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const { communityList, loading, error } = useSelector(
    (state) => state.postForm
  );
  const [activeExpanded, setActiveExpanded] = useState(false);
  const [pendingExpanded, setPendingExpanded] = useState(false);

  // Filter news by postType
  const news = communityList?.filter(
    (post) => post.postType === "localNewspapers"
  );

  // Filter active and pending news, applying createdBy filter only for non-admins
  const activeNews = news.filter(
    (post) => post.communityStatus == 1 // Admins see all active news
  );
  const pendingNews = news.filter(
    (post) => post.communityStatus == 0  
  );

  return (
    <div style={{ width: "96%" }}>
      <div
        className="category-header"
        onClick={() => setActiveExpanded(!activeExpanded)}
      >
        <h5 className={`category-title ${activeExpanded ? "active" : ""}`}>
          Active News
          {activeExpanded ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </h5>
      </div>
      {activeExpanded && (
        <div className="news-grid">
          {activeNews.length > 0 ? (
            <MyCommunityCard myPosts={activeNews} />
          ) : (
            <h6 className="no-posts">No Active News</h6>
          )}
        </div>
      )}
      <div
        className="category-header mt-1"
        onClick={() => setPendingExpanded(!pendingExpanded)}
      >
        <h5
          className={`category-title pending ${
            pendingExpanded ? "expanded" : ""
          }`}
        >
          Pending News
          {pendingExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h5>
      </div>
      {pendingExpanded && (
        <div className="news-grid">
          {pendingNews.length > 0 ? (
            <MyCommunityCard myPosts={pendingNews} />
          ) : (
            <h6 className="no-posts">No Pending News</h6>
          )}
        </div>
      )}
    </div>
  );
};

export default MyLocalNewsPapers;
