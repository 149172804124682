import React, { useState } from "react";
import { FaRegCopy, FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Button, Col, Input, Row } from "reactstrap";
import { setSelectedPrice } from "../../../redux/slices/MsgSlice";
import { useDispatch } from "react-redux";

const CreatedPackages = ({
  createdPackages,
  handleEditPackage,
  handleDeletePackage,
  editedPackageIndex,
  handleDuplicatePackage,
  state,
  checked,
  setEditedPackageIndex,
}) => {
  const [showAllPackages, setShowAllPackages] = useState({});
  const dispatch = useDispatch();
  const handleShowAllPackages = (idx) => {
    setShowAllPackages((prevShowAllPackages) => ({
      ...prevShowAllPackages,
      [idx]: !prevShowAllPackages[idx],
    }));
  };
  return (
    <div className="mt-2">
      <Row className="align-items-center justify-content-center">
        <Col lg={1} className="text-center created_Package">
          {createdPackages.length > 0 && (
            <h5
              className="createdPakaage created_Package"
              //   style={{
              //     writingMode: "vertical-rl",
              //     textAlign: "center",
              //     fontSize: "24px",
              //     lineHeight: "1.5",
              //     border: "1px solid black",
              //     borderRadius: "10px",
              //     backgroundColor: "#efe3af",
              //     padding: "10px",
              //     width: "fit-content",
              //     transform: "rotate(180deg)",
              //   }}
            >
              Created&nbsp;Packages
            </h5>
          )}
        </Col>
        <Col lg={11}>
          <Row
            className="d-flex flex-nowrap"
            style={{
              overflow: "auto hidden",
              scrollbarWidth: "thin",
              // width: "95%",
              // overflowX: "hidden",
              // gap: "10px",
            }}
          >
            {createdPackages.map((pkg, idx) => (
              <Col key={idx} lg={6} className="my-4">
                <div
                  onClick={() => {
                    dispatch(setSelectedPrice(pkg?.totalPrice));
                    setEditedPackageIndex(idx);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className={`${
                    editedPackageIndex !== idx ? "created_Package" : ""
                  }`}
                >
                  <Row
                    className={`border`}
                    style={{
                      borderRadius: "25px",
                      marginLeft: "0px",
                      boxShadow:
                        editedPackageIndex === idx
                          ? "#5435C2 0px 3px 23px 0px"
                          : "",
                    }}
                  >
                    <Col lg={10}>
                      <h5 className="text-center mb-4 mt-1">
                        {pkg.title || ""}
                      </h5>
                      <div className="my-4 position-relative">
                        <Row className="p-2">
                          {pkg.packages
                            .slice(
                              0,
                              showAllPackages[idx] ? pkg.packages.length : 3
                            )
                            .map((packageItem, index) => (
                              <Col
                                key={index}
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                className="my-4 p-0"
                              >
                                <div>
                                  <Input
                                    type="checkbox"
                                    className="mt-2"
                                    checked={packageItem.checked}
                                    disabled={true}
                                    // name={`package${index}`}
                                    // checked={searchDetails.packages.includes(
                                    //   `package${index}`
                                    // )}
                                    // onChange={(e) => {
                                    //   const newPackages = {
                                    //     ...searchDetails,
                                    //     packages: e.target.checked
                                    //       ? [
                                    //           ...searchDetails.packages,
                                    //           `package${index}`,
                                    //         ]
                                    //       : searchDetails.packages.filter(
                                    //           (pkg) =>
                                    //             pkg !== `package${index}`
                                    //         ),
                                    //   };
                                    //   setSearchDetails(newPackages);
                                    // }}
                                  />
                                  <span className="ms-1">
                                    <span className="">
                                      {" "}
                                      {packageItem.title}
                                    </span>
                                    <br />
                                    <p
                                      style={{
                                        marginLeft: "25px",
                                        marginBottom: "0px",
                                        fontSize: "9px",
                                      }}
                                    >
                                      {packageItem.description}
                                    </p>
                                  </span>
                                </div>
                              </Col>
                            ))}
                          <div
                            style={{
                              position: "absolute",
                              left: "200px",
                              bottom: "0px",
                              zIndex: "1",
                            }}
                            className="created_Package"
                          >
                            {pkg.packages.length > 3 && (
                              <p
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                className="m-0 p-0"
                                onClick={() => handleShowAllPackages(idx)}
                              >
                                {showAllPackages[idx] ? "Hide" : "View All"}
                              </p>
                            )}
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      lg={2}
                      className="dealFactorCardBody"
                      // style={{
                      //   backgroundColor: "#c3c3c3",
                      //   display: "flex",
                      //   alignItems: "center",
                      //   borderTopRightRadius: "25px",
                      //   borderBottomRightRadius: "25px",

                      //   justifyContent: "center",
                      // }}
                    >
                      <Row style={{ gap: "10px", fontSize: "12px" }}>
                        <Col lg={12} className="text-center">
                          Guest Count: <strong>{pkg.guestCount}</strong>
                        </Col>
                        <Col lg={12} className="text-center">
                          Total Price:{" "}
                          <strong>${parseInt(pkg.totalPrice)}</strong>
                        </Col>
                      </Row>
                      <div></div>
                      <div>
                        <strong></strong>
                      </div>
                    </Col>
                  </Row>
                </div>
                {state?.invoice
                  ? checked
                    ? true
                    : false
                  : true && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Button
                          color="primary"
                          className="my-2"
                          onClick={() => handleDuplicatePackage(idx)}
                        >
                          <FaRegCopy />
                        </Button>
                        <Button
                          color="success"
                          className="my-2"
                          onClick={() => handleEditPackage(idx)}
                        >
                          <MdEdit />
                        </Button>
                        <Button
                          color="danger"
                          className="my-2"
                          onClick={() => handleDeletePackage(idx)}
                        >
                          <FaRegTrashAlt />
                        </Button>
                      </div>
                    )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreatedPackages;
