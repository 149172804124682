// Invoices.js
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import { Menu, MenuItem } from "@mui/material";
import CreateInvoice from "../../../../components/Header/modals/CreateInvoice";
import { useSelector } from "react-redux";
import { listInvoices } from "../../../../components/Header/Data3";
import moment from "moment";
import { RiDownloadCloud2Fill } from "react-icons/ri";

const Invoices = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const [viewType, setViewType] = useState("");
  const [invoicesList, setInvoicesList] = useState([]);
  const [selectedInvoice, setselectedInvoice] = useState({});
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const logUser = localStorage.getItem("USER_ROLE");
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(false);
  };

  const [open, setOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };

  const isAdmin = user && user.Role === "admin";

  const fetchInvoiceList = async () => {
    if (isAdmin) {
      const response = await listInvoices();
      console.log(response, "fetchInvoiceList");
      setInvoicesList(response);
    } else {
      let formData = {
        userId: user?.UserID,
      };
      console.log(formData, "firstName");
      const response = await listInvoices(formData);
      console.log(response, "UserResponse");
      setInvoicesList(response);
    }
  };

  useEffect(() => {
    fetchInvoiceList();
  }, [listOpen]);

  const filterByMessageId = invoicesList?.filter((invoice) => {
    return invoice.message_id === selectedConversation?.Id;
  });

  console.log(filterByMessageId, "filterByMessageId");
  console.log(selectedInvoice, "selectedInvoice");

  return (
    <div className="wc-chat-drop">
      <Button
        className="drop-button"
        style={{
          backgroundColor: "#b5e51d",
          color: "black",
          fontSize: "x-small",
        }}
        size="sm"
        onClick={handleClick}
      >
        <span className="drop-button-title">
          Invoice
          <FaChevronDown />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={currentMenu}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          style: {
            minWidth: "120px",
            maxWidth: "200px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setListOpen(true);
            setAnchorEl(null);
            setCurrentMenu(false);
            setViewType("view");
          }}
        >
          List Invoices
        </MenuItem>
        {logUser === "provider" && (
          <MenuItem
            onClick={() => {
              setOpen(true);
              setAnchorEl(null);
              setCurrentMenu(false);
              setViewType("create");
            }}
          >
            Create Invoice
          </MenuItem>
        )}
      </Menu>
      <CreateInvoice
        isOpen={open}
        toggleModal={handleCloseModal}
        viewType={viewType}
        setViewType={setViewType}
        selectedInvoice={selectedInvoice}
      />
      <Modal isOpen={listOpen} size="lg" toggle={() => setListOpen(false)}>
        <ModalHeader>Invoice List</ModalHeader>
        <ModalBody>
          {filterByMessageId?.length > 0 ? (
            <Table responsive className="table-light border m-0">
              <thead className="text-center">
                <tr>
                  {/* <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th> */}
                  <th>Invoice Number</th>
                  {/* <th>Conversation Id</th> */}
                  <th>Created on</th>
                  <th>Download Invoice</th>
                  <th>Signature Invoice</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {filterByMessageId.map((event, i) => (
                  <tr key={i}>
                    {/* <td>{`${event.first_name} ${event.last_name}`}</td>
                    <td>{event.email}</td>
                    <td>{event.phone}</td> */}
                    <td>{event.Id}</td>
                    {/* <td>{event.message_id}</td> */}
                    <td className="text-nowrap">
                      {event.created_at &&
                        moment(event.created_at).format(
                          "MMMM DD, YYYY hh:mm A"
                        )}
                    </td>
                    {event?.invoice_pdf ? (
                      <td>
                        <a
                          href={event?.invoice_pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <RiDownloadCloud2Fill /> Download
                        </a>
                      </td>
                    ) : (
                      <td>Not available</td>
                    )}
                    <td>
                      <span
                        className="text-primary text-decoration-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setListOpen(false);
                          setViewType("view");
                          setOpen(true);
                          setselectedInvoice(event);
                        }}
                      >
                        Open Invoice
                      </span>
                    </td>
                    <td
                      style={{
                        fontSize: "small",
                        fontWeight: "bold",
                        // color: event?.order_id === 0 ? "red" : "green",
                        color: event?.order_status !== "PENDING" && "green",
                        textWrap: "nowrap",
                      }}
                    >
                      {event?.order_status === "COMPLETED"
                        ? "BOOKING DEPOSIT COMPLETED"
                        : event?.order_status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center my-3">No List found</div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Invoices;
