import React, { useState } from "react";
import ActiveNews from "./ActiveNews";
import InActiveNews from "./InActiveNews";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "../DashboardPages.css";
import PausedNews from "./PausedNews";

const MediaOrRadioNews = ({ mediaList, fetchGurudwaraNewsList }) => {
  const [activeNeedsExpanded, setActiveNeedsExpanded] = useState(false);
  const [pendingNeedsExpanded, setpendingNeedsExpanded] = useState(false);
  const [expiredOrPauseNeedsExpanded, setexpiredOrPauseNeedsExpanded] =
    useState(false);
  const activeNews = mediaList.filter((need) => need.newsStatus === 1);
  const pendingNews = mediaList.filter((need) => need.newsStatus === 0);
  const pausedNews = mediaList.filter((need) => need.newsStatus === 3);
  return (
    <div className="category-container d-flex flex-column gap-2 inter-font">
      <div
        className="category-header"
        onClick={() => setActiveNeedsExpanded(!activeNeedsExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            activeNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Active{" "}
          {activeNeedsExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h6>
      </div>
      {activeNeedsExpanded && (
        <>
          {activeNews.length > 0 ? (
            <ActiveNews
              activeNews={activeNews}
              fetchData={fetchGurudwaraNewsList}
            />
          ) : (
            <h6 className="text-center m-5">No Active news</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() => setpendingNeedsExpanded(!pendingNeedsExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            pendingNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Pending {pendingNeedsExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </h6>
      </div>
      {pendingNeedsExpanded && (
        <>
          {pendingNews.length > 0 ? (
            <InActiveNews
              pendingNews={pendingNews}
              fetchData={fetchGurudwaraNewsList}
            />
          ) : (
            <h6 className="text-center m-5">No Pending news</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() =>
          setexpiredOrPauseNeedsExpanded(!expiredOrPauseNeedsExpanded)
        }
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            expiredOrPauseNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all .2s ease-in-out" }}
        >
          Expired/Paused{" "}
          {expiredOrPauseNeedsExpanded ? <FaAngleUp /> : <FaAngleDown />}{" "}
        </h6>
      </div>
      {expiredOrPauseNeedsExpanded && (
        <>
          {pausedNews.length > 0 ? (
            <PausedNews
              pausedNews={pausedNews}
              fetchData={fetchGurudwaraNewsList}
            />
          ) : (
            <h6 className="text-center m-5">No Expired/Paused needs</h6>
          )}
        </>
      )}
    </div>
  );
};

export default MediaOrRadioNews;
