import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Label, Input } from "reactstrap";
import {
  AdPaymentUpdate,
  fetchSettings,
  reciveOrder,
} from "../../../components/Header/Data2";
import { useNavigate, useLocation } from "react-router-dom";
import ThankYouModal from "../Orders/ThankYouPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import {
  CreateGurdwaraDonation,
  createGurudwaraBooking,
  Featured,
  statusUpdateGurdwaraDonation,
  statusUpdateOrder,
  viewPhonePayment,
} from "../../../components/Header/Data";
import { setFeatureService } from "../../../redux/slices/PaymentSlice";
import axiosClient from "../../../axios-client";
import { getMyCartItems } from "../../../components/Header/MyCart";
import {
  setPaymentStatus,
  setResponseId,
} from "../../../redux/slices/GurdwaraSlice";
import {
  setOrderStatus,
  setPaypalResponse,
} from "../../../redux/slices/MsgSlice";
import {
  setDonationClientId,
  setDonationSecretKey,
  setDonationStatus,
  setModalIsOpen,
  setMonthlyDonation,
  setOpenPaypal,
  setSelectedAmount,
  setSelectedGurdwara,
} from "../../../redux/slices/DonationSlice";
import paypalLogo from "../../../assets/images/paypal-logo.png";

const Payment = ({ totalPrice, adDetails, state, chatDetails }) => {
  const paymentSlice = useSelector((state) => state.payment);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gurudwaraId = parseInt(queryParams.get("id"), 10); // Keep
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [thankYou, setThankYou] = useState(false);
  // const [orderDetails, setOrderDetails] = useState([]);
  const [clientId, setClientId] = useState("");
  const logUser = localStorage.getItem("USER_ROLE");
  // console.log(paymentSlice.featureService, "FeatureService");
  const dispatch = useDispatch();
  const { selectedServicePrice, responseId, termsConditions, paypalClientID } =
    useSelector((state) => state.gurdwara);
  const { order, selectedConvo, description, childId } = useSelector(
    (state) => state.messages
  );
  const { donationClientId, selectedGurdwara, monthlyDonation, donationFrom } =
    useSelector((state) => state.gurdwaraDonation);
  const formData = useSelector((state) => state.requestForm);
  const fetchSet = async () => {
    if (state?.bookingGurdwara && paypalClientID) {
      setClientId(paypalClientID); // Use the paypalClientID from the state
    } else if (state?.needPayment) {
      setClientId(formData?.clientId);
    } else {
      const settings = await fetchSettings();
      console.log(settings, "settings");
      const filterSet = settings.filter((set) => set.setting_name === "paypal");
      setClientId(filterSet[0]?.client_id); // Default fetch logic if not bookingGurdwara
    }
  };
  const fetchDonationId = async () => {
    if (state?.donation) {
      setClientId(donationClientId);
    }
  };

  useEffect(() => {
    if (state?.donation) {
      fetchDonationId();
    }
  }, [state?.donation]);
  useEffect(() => {
    if (adDetails || chatDetails || state?.invoicePay) {
      fetchSet();
    }
  }, [
    adDetails,
    chatDetails,
    state?.bookingGurdwara,
    paypalClientID,
    formData?.clientId,
    state?.needPayment,
    state?.chatPay,
    state?.invoicePay,
  ]);
  console.log(clientId, "paypalClientID");
  const placeOrder = async (details) => {
    const orderData = localStorage.getItem("ORDER_DETAILS");
    const orderDetails = orderData ? JSON.parse(orderData) : null;
    if (orderDetails && orderDetails.Id) {
      // navigate(`/feedBack/${orderDetails.Id}`);
      let formData = {
        // details,
        status: details.status,
        messageId: state.selectedConversation
          ? state.selectedConversation.Id
          : 0,
      };
      await reciveOrder(formData, orderDetails.Id); // Access order id directly
      toggleModal();
      localStorage.setItem("ORDER_DETAILS", null);
    } else {
      console.error("Order details are missing or invalid.");
    }
  };

  const handleOfferPayment = async (details) => {
    if (state?.reqPhoneAmount) {
      const orderData = localStorage.getItem("ORDER_DETAILS");
      const orderDetails = orderData ? JSON.parse(orderData) : null;

      const id = state.msg.Id;
      let formData = {
        senderId: user?.UserID,
        requestPhone: logUser === "provider" ? "provider" : "customer",
        paypalResponse: details,
        // createBy: user.UserID === 4 ? 4 : 5,
        createBy:
          selectedConvo.createdBy === user.UserID
            ? user.UserID
            : selectedConvo?.ownerId,
        serviceNeed: state?.msg?.serviceNeed,
        totalAmount: state.reqPhoneAmount,
        Discount: 0,
        paidAmount: state.reqPhoneAmount,
        orderStatus: details.status,
      };
      console.log(formData, "handleOfferPayment");
      await viewPhonePayment(formData, id);
      let updateOrder = {
        createdBy:
          selectedConvo.createdBy === user.UserID
            ? user.UserID
            : selectedConvo?.ownerId,
        orderStatus: details.status,
        orderId: orderDetails?.Id,
        paypalResponse: details,
      };
      await statusUpdateOrder(updateOrder, orderDetails?.Id);
      localStorage.removeItem("ORDER_DETAILS");
    }
  };
  const updateItemOrder = async (details) => {
    if (state?.item) {
      const orderData = localStorage.getItem("ITEM_ORDER_DETAILS");
      const orderDetails = orderData ? JSON.parse(orderData) : null;
      if (orderDetails && orderDetails.id) {
        let formData = {
          orderStatus: details.status,
          cartId: state.itemOrder?.cart_id,
          itemId: state.itemOrder?.item_id,
          updatedBy: user.UserID,
        };
        try {
          const response = await axiosClient.put(
            `item/StatusUpdateItemOrder/${orderDetails.id}`,
            formData
          );
          if (response.data.statusCode === 200) {
            toast.success("Order Placed");
            setTimeout(() => {
              getMyCartItems(user.UserID, dispatch);
            }, 1000);
          }
        } catch {
          toast.error("Payment Incomplete");
        }
        toggleModal();
        localStorage.setItem("ITEM_ORDER_DETAILS", null);
      } else {
        console.error("Order details are missing or invalid.");
      }
    }
  };
  const SponsordAdPayment = async (details) => {
    if (adDetails) {
      const request = {
        createdBy: user.UserID,
        paymentStatus: details.status,
      };
      await AdPaymentUpdate(adDetails.id, request);

      toast.success("Payment Successfull");
    }
  };
  const FeaturingService = async (details) => {
    if (paymentSlice.featureService !== null) {
      const reqData = {
        featuredListing: 1,
        updatedBy: user.UserID,
      };
      await Featured(reqData, paymentSlice.featureService.Id);
      dispatch(setFeatureService(null));
      toggleModal();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  console.log(state, "state");
  const toggleModal = () => {
    setThankYou(!thankYou);
  };

  if (!clientId) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    ); // Or any loading indicator you prefer
  }
  const bookingPaymentStatus = async (details) => {
    if (state?.bookingGurdwara) {
      // console.log(details.status, "detailsStatus");
      let formData = {
        paymentStatus: details.status,
        Amount: selectedServicePrice,
        updatedBy: user?.UserID,
        paymentDetails: details,
        gurudwaraId: gurudwaraId,
        Id: responseId,
      };
      const response = await createGurudwaraBooking(formData);
      // console.log("response", response);
      dispatch(setPaymentStatus(details?.status));
    }
  };
  const ChatPayment = async (details) => {
    if (state?.chatPay) {
      // console.log(details.status, "detailsStatus");
      let formData = {
        customerId: selectedConvo?.customerId,
        postId: selectedConvo?.postId,
        postType: selectedConvo?.postType,
        Description: `payment for chat with ${description}, ${totalPrice}, ${details.status}`,
        ownerId: selectedConvo?.ownerId,
        serviceNeed: "need",
        messageStatus: 8,
        orderStatus: details.status,
        createdBy: user?.UserID,
        payEmail: user?.Email,
        payPhone: `${user?.CountryCode}${user?.Phone}`,
        parentId: selectedConvo?.Id,
        childId: childId,
        Amount: totalPrice,
        orderDescription: description,
        receiverId:
          selectedConvo?.createdBy === user?.UserID
            ? selectedConvo?.customerId
            : selectedConvo?.createdBy,
        userName: user?.UserName,
        Type: "chatpayment",
      };
      await statusUpdateOrder(formData, order);
      dispatch(setOrderStatus(details?.status));
      toggleModal();
    }
  };
  const donateGurdwara = async (details) => {
    if (state?.donation) {
      let formData = {
        gurudwaraId: parseInt(selectedGurdwara),
        Amount: totalPrice,
        recurringDeposit: monthlyDonation ? 1 : 0,
        createdBy: user?.UserID,
      };
      // console.log(formData, "DonationData");
      const response = await CreateGurdwaraDonation(formData);
      let updateDonationData = {
        paymentStatus: details.status,
        paymentDetails: details,
      };
      dispatch(setDonationStatus(details.status));
      // console.log(updateDonationData, "updateDonationData");
      if (details.status === "COMPLETED") {
        await statusUpdateGurdwaraDonation(response?.id, updateDonationData);
        dispatch(setMonthlyDonation(null));
        dispatch(setSelectedAmount(null));
        dispatch(setSelectedGurdwara(null));
        dispatch(setOpenPaypal(false));
        dispatch(setDonationClientId(""));
        dispatch(setDonationSecretKey(""));
      }
    }
  };

  const invoicePayment = async (details) => {
    if (state?.invoicePay) {
      let formData = {
        orderStatus: details.status,
        paypalResponse: details,
        Amount: totalPrice,
        createdBy: islogin ? user?.UserID : state?.invoice.customer_id,
        invoiceId: state?.invoice?.Id,
        Description: `Payment of $${totalPrice} with Order ID: ${state?.order?.Id} and Invoice ID: ${state?.invoice?.Id} has been successfully completed.`,
        messageId: state?.invoice?.message_id,
      };
      await statusUpdateOrder(formData, state?.order?.Id);
      dispatch(setPaypalResponse(details.status));
    }
    // toggleModal();
  };
  // console.log(termsConditions, "termsConditions");
  return (
    <div
      className={`${state?.reqPhoneAmount && totalPrice === 10 && "w-50"} mt-5`}
      style={{
        marginLeft: totalPrice && state?.reqPhoneAmount === 10 && "25%",
      }}
    >
      <PayPalScriptProvider
        options={{
          "client-id": clientId,
          // "client-id":
          //   "AaqY5POsphq_iOg3JGN-H5lxMige1F_HmVlqMiu_sR4RrJzYLg_B8gqjVX1n-0pOlWPe5upN2Zb3q_RU",
          // "client-id": process.env.PAYPAL_CLIENT_ID,
        }}
      >
        <PayPalButtons
          disabled={state?.bookingGurdwara ? termsConditions != 1 : false}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice,
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              console.log("=========", JSON.stringify(details, null, 4));
              console.log("=========22", details.status);
              SponsordAdPayment(details);
              placeOrder(details);
              handleOfferPayment(details);
              FeaturingService(details);
              updateItemOrder(details);
              bookingPaymentStatus(details);
              ChatPayment(details);
              donateGurdwara(details);
              invoicePayment(details);
            });
          }}
          onError={(error) => {
            console.error("PayPal error", error);
          }}
        />
        <ThankYouModal
          show={thankYou}
          onHide={toggleModal}
          totalPrice={totalPrice}
          state={state}
        />
      </PayPalScriptProvider>
    </div>
  );
};
export default Payment;
