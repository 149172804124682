//DealFactors
import React, { useEffect, useRef, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { TbArrowBigLeft } from "react-icons/tb";
import { TbArrowBigRight } from "react-icons/tb";
import ServicePackageCreation from "./ServicePackageCreation";
import CreatedPackages from "./CreatedPackages";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreatedPackages,
  setItemsToDisplay,
  setOptions,
} from "../../../redux/slices/ServiceInfo";
import { toast } from "react-toastify";
import DealFactorItems from "./DealFactorItems";
import CreatableSelect from "react-select/creatable";
import {
  setInvoiceCreatedPackages,
  setInvoiceItemsToDisplay,
  setInvoiceOptions,
  setNewCreatedPackages,
  setNewItemsToDisplay,
  setNewOptions,
  setSelectedPackages,
} from "../../../redux/slices/MsgSlice";
import DealFactorTitle from "./DealFactorTitle";

const DealFactors = ({
  checked,
  state,
  editedPackageIndex,
  setEditedPackageIndex,
}) => {
  const { itemsToDisplay, createdPackages, options } = useSelector(
    (state) => state.serviceInfo
  );
  const {
    invoiceItemsToDisplay,
    invoiceCreatedPackages,
    invoiceOptions,
    newItemsToDisplay,
    newCreatedPackages,
    newOptions,
    selectedPackages,
  } = useSelector((state) => state.messages);
  console.log(
    invoiceItemsToDisplay,
    invoiceCreatedPackages,
    invoiceOptions,
    newItemsToDisplay,
    newCreatedPackages,
    newOptions,
    "DealFactors"
  );
  const dispatch = useDispatch();
  // const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(0);
  const [newItem, setNewItem] = useState({
    Id: count,
    title: "",
    description: "",
    price: null,
    type: null,
    units: "",
  });
  const [modalType, setModalType] = useState("DealFactor");
  const [totalPrice, setTotalPrice] = useState(0);
  const [packageName, setPackageName] = useState("");
  // const [selectedPackages, setSelectedPackages] = useState({});
  const [packageToEdit, setPackageToEdit] = useState(null);
  // const [editedPackageIndex, setEditedPackageIndex] = useState(null);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const [newItems, setNewItems] = useState({});
  const [guestCount, setGuestCount] = useState(0);
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  const defaultOptions = [];

  const [isLoading, setIsLoading] = useState(false);
  // const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState(null);
  const handleInputChange = (dealFactorId, field, value) => {
    if (editedItemIndex !== null && editedPackageIndex !== null) {
      const updatedItems = state?.invoice
        ? checked
          ? [...newItemsToDisplay]
          : [...invoiceItemsToDisplay]
        : [...itemsToDisplay];
      updatedItems[editedItemIndex].packages[editedPackageIndex][field] = value;
      // dispatch(setItemsToDisplay(updatedItems));
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay(updatedItems));
        } else {
          dispatch(setInvoiceItemsToDisplay(updatedItems));
        }
      } else {
        dispatch(setItemsToDisplay(updatedItems));
      }
    }
    setNewItems((prevNewItems) => ({
      ...prevNewItems,
      [dealFactorId]: {
        ...prevNewItems[dealFactorId],
        [field]: value,
      },
    }));
  };
  console.log(options, value, "options");
  // This function will clean and convert the price to a number
  // This function will clean and convert the price to a number
  const convertPriceToNumber = (price) => {
    if (price && typeof price === "string") {
      // Remove the "$" sign and "/person" suffix
      const cleanedPrice = price.replace("$", "").replace("/person", "").trim();
      const numericPrice = parseFloat(cleanedPrice);
      return !isNaN(numericPrice) ? numericPrice : 0;
    }
    return 0; // Return 0 if price is undefined or invalid
  };

  const handlePackageSelection = (dealFactorId, packageIndex, packageItem) => {
    const packageKey = `${dealFactorId}-${packageIndex}`;
    if (packageItem.checked) {
      dispatch(
        setSelectedPackages({ ...selectedPackages, [packageKey]: packageItem })
      );
      // setTotalPrice(
      //   (prevTotalPrice) => prevTotalPrice + parseFloat(packageItem.price)
      // );
    } else {
      dispatch(
        setSelectedPackages(() => {
          const updatedPackages = { ...selectedPackages };
          delete updatedPackages[packageKey];
          return updatedPackages;
        })
      );
      // setTotalPrice(
      //   (prevTotalPrice) => prevTotalPrice - parseFloat(packageItem.price)
      // );
    }
  };

  console.log(selectedPackages, "selectedPackages");
  console.log(checked, state, "Invoice");
  const handleCreatePackage = () => {
    const editedPackageName = packageNameRef.current.textContent;
    const editedTotalPrice = totalPriceRef.current.textContent;
    const selectedItems = Object.values(selectedPackages).filter(
      (item) => item.checked
    );
    if (selectedItems.length === 0) {
      toast.error("Please select at least one item");
      return;
    }
    // Ensure packages are correctly flattened if nested
    const allSelectedPackages = Object.values(selectedPackages).flat();

    const newPkg = {
      title: editedPackageName,
      description: "A bundle of selected packages",
      packages: allSelectedPackages.map((pkg, index) => {
        const dealFactorId = Object.keys(selectedPackages)
          .find((key) => selectedPackages[key] === pkg)
          .split("-")[0];
        // const dealFactor = itemsToDisplay.find(
        //   (item) => item.Id === parseInt(dealFactorId)
        // );
        let dealFactor;
        if (state?.invoice) {
          if (checked) {
            dealFactor = newItemsToDisplay.find(
              (item) => item.Id === parseInt(dealFactorId)
            );
          } else {
            dealFactor = invoiceItemsToDisplay.find(
              (item) => item.Id === parseInt(dealFactorId)
            );
          }
        } else {
          dealFactor = itemsToDisplay.find(
            (item) => item.Id === parseInt(dealFactorId)
          );
        }
        return {
          ...pkg,
          Id: index, // Set the Id property explicitly
          type: dealFactor ? dealFactor.type : null,
        };
      }),
      // totalPrice: allSelectedPackages.reduce((total, pkg) => {
      //   // Clean and convert the price to a number
      //   const priceValue = convertPriceToNumber(pkg.price);

      //   // Add the price to the total
      //   return total + priceValue;
      // }, 0),
      totalPrice: parseInt(editedTotalPrice),
      guestCount: parseInt(guestCount),
    };
    // Add the newly created package to the list of created packages
    // dispatch(setCreatedPackages((prev) => [...prev, newPkg]));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewCreatedPackages((prev) => [...prev, newPkg]));
      } else {
        dispatch(setInvoiceCreatedPackages((prev) => [...prev, newPkg]));
      }
    } else {
      dispatch(setCreatedPackages((prev) => [...prev, newPkg]));
    }
    setTotalPrice(0);
    // Clear the selected packages for the next package creation
    dispatch(setSelectedPackages({}));

    // Update the checked property of the Input component
    // const updatedPackages = [...createdPackages];
    let updatedPackages;
    if (state?.invoice) {
      if (checked) {
        updatedPackages = [...newCreatedPackages];
      } else {
        updatedPackages = [...invoiceCreatedPackages];
      }
    } else {
      updatedPackages = [...createdPackages];
    }
    updatedPackages.push(newPkg);
    updatedPackages.forEach((pkg, index) => {
      if (index === updatedPackages.length - 1) {
        pkg.packages.forEach((packageItem) => {
          packageItem.checked = true;
        });
      }
    });
    // dispatch(setCreatedPackages(updatedPackages));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewCreatedPackages(updatedPackages));
      } else {
        dispatch(setInvoiceCreatedPackages(updatedPackages));
      }
    } else {
      dispatch(setCreatedPackages(updatedPackages));
    }
    setTotalPrice(0);
    packageNameRef.current.textContent = "Package Name";
    totalPriceRef.current.textContent = 0;
    setModalType("DealFactor");
    setGuestCount(0);
  };
  const handleInput = (e) => {
    setTotalPrice(e.target.value);
  };

  const handleAddItem = () => {
    if (editedItemIndex !== null && editedPackageIndex !== null) {
      const updatedItems = state?.invoice
        ? checked
          ? [...newItemsToDisplay]
          : [...invoiceItemsToDisplay]
        : [...itemsToDisplay];
      const updatedPackages = [...updatedItems[editedItemIndex].packages];
      updatedPackages[editedPackageIndex] = {
        ...updatedPackages[editedPackageIndex],
        ...newItem,
      };
      updatedItems[editedItemIndex].packages = updatedPackages;
      // dispatch(setItemsToDisplay(updatedItems));
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay(updatedItems));
        } else {
          dispatch(setInvoiceItemsToDisplay(updatedItems));
        }
      } else {
        dispatch(setItemsToDisplay(updatedItems));
      }
    } else {
      const updatedNewItem = {
        Id: count,
        value: value?.value,
        title: newItem.title,
        description: newItem.description,
        price: newItem.price,
        units: newItem.units,
      };
      console.log(newItem, "newItem");
      if (!value?.value) {
        toast.error("Please create or select the Deal Factor Title");
        return;
      }
      if (!newItem.title) {
        toast.error("Please fill the title");
        return;
      }
      if (!newItem.price) {
        toast.error("Please fill the price");
        return;
      }
      if (!newItem.units) {
        toast.error("Please fill the units");
        return;
      }

      if (
        updatedNewItem.title &&
        updatedNewItem.price &&
        updatedNewItem.units
      ) {
        // const existingDealFactor = itemsToDisplay.find(
        //   (item) => item.Id === newItem.Id
        // );
        let existingDealFactor;

        if (state?.invoice) {
          if (checked) {
            existingDealFactor = newItemsToDisplay.find(
              (item) => item.Id === updatedNewItem.Id
            );
          } else {
            existingDealFactor = invoiceItemsToDisplay.find(
              (item) => item.Id === updatedNewItem.Id
            );
          }
        } else {
          existingDealFactor = itemsToDisplay.find(
            (item) => item.Id === updatedNewItem.Id
          );
        }
        console.log(existingDealFactor, "existingDealFactor");
        if (existingDealFactor) {
          // Add new item to existing deal factor
          const updatedItems = state?.invoice
            ? checked
              ? [...newItemsToDisplay]
              : [...invoiceItemsToDisplay]
            : [...itemsToDisplay];

          const existingDealFactorIndex = updatedItems.findIndex(
            (item) => item.Id === existingDealFactor.Id
          );

          // Make a copy of the existing deal factor
          const updatedItem = { ...updatedItems[existingDealFactorIndex] };

          // Create a copy of the packages array
          const updatedPackages = [...updatedItem.packages, updatedNewItem];

          // Now, set the modified packages array to the copied item
          updatedItem.packages = updatedPackages;

          // Replace the old item with the modified one in updatedItems
          updatedItems[existingDealFactorIndex] = updatedItem;

          // Now dispatch the update to the store
          if (state?.invoice) {
            if (checked) {
              dispatch(setNewItemsToDisplay(updatedItems));
            } else {
              dispatch(setInvoiceItemsToDisplay(updatedItems));
            }
          } else {
            dispatch(setItemsToDisplay(updatedItems));
          }
        } else {
          // Create new deal factor with the item
          const newDealFactorId = count;
          const newItemtoDisplay = {
            Id: newDealFactorId,
            type: value?.value,
            packages: [
              {
                // value: updatedNewItem.value,
                title: updatedNewItem.title,
                description: updatedNewItem.description,
                price: updatedNewItem.price,
                units: updatedNewItem.units,
              },
            ],
          };
          // dispatch(setItemsToDisplay([...itemsToDisplay, newItemToDisplay]));
          if (state?.invoice) {
            if (checked) {
              dispatch(
                setNewItemsToDisplay([...newItemsToDisplay, newItemtoDisplay])
              );
            } else {
              dispatch(
                setInvoiceItemsToDisplay([
                  ...invoiceItemsToDisplay,
                  newItemtoDisplay,
                ])
              );
            }
          } else {
            dispatch(setItemsToDisplay([...itemsToDisplay, newItemtoDisplay]));
          }
        }
        setModal(false);
        setCount((prevCount) => prevCount + 1);
        setNewItem({
          title: "",
          description: "",
          price: null,
          units: "",
          value: "",
        });
      } else {
        console.log("Please fill in all fields");
        toast.error("Please fill in all fields");
      }
    }
    setModalType("DealFactor");
  };

  const handleAddItemToPackages = (dealFactorId) => {
    console.log(newItems[dealFactorId], "newItems");
    console.log(dealFactorId, "dealFactorId");
    if (!newItems[dealFactorId]) {
      toast.error("Please fill in all fields");
      return;
    }
    const newItem = newItems[dealFactorId];

    if (!newItem.title) {
      toast.error("Please enter title");
      return;
    }
    if (!newItem.price) {
      toast.error("Please enter price");
      return;
    }
    if (!newItem.units) {
      toast.error("Please select units");
      return;
    }
    // const existingDealFactor = itemsToDisplay.find(
    //   (item) => item.Id === dealFactorId
    // );
    let existingDealFactor;
    if (state.invoice) {
      if (checked) {
        console.log(checked, "existingDealFactor");
        existingDealFactor = newItemsToDisplay.find(
          (item) => item.Id === dealFactorId
        );
      } else {
        existingDealFactor = invoiceItemsToDisplay.find(
          (item) => item.Id === dealFactorId
        );
      }
    } else {
      existingDealFactor = itemsToDisplay.find(
        (item) => item.Id === dealFactorId
      );
    }

    if (existingDealFactor) {
      // const updatedItems = itemsToDisplay.map((item) => {
      //   if (item.Id === dealFactorId) {
      //     return { ...item, packages: [...item.packages, newItem] };
      //   }
      //   return item;
      // });

      let updatedItems;

      if (state?.invoice) {
        if (checked) {
          updatedItems = newItemsToDisplay.map((item) => {
            if (item.Id === dealFactorId) {
              return { ...item, packages: [...item.packages, newItem] };
            }
            return item;
          });
        } else {
          updatedItems = invoiceItemsToDisplay.map((item) => {
            if (item.Id === dealFactorId) {
              return { ...item, packages: [...item.packages, newItem] };
            }
            return item;
          });
        }
      } else {
        updatedItems = itemsToDisplay.map((item) => {
          if (item.Id === dealFactorId) {
            return { ...item, packages: [...item.packages, newItem] };
          }
          return item;
        });
      }

      // dispatch(setItemsToDisplay(updatedItems));
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay(updatedItems));
        } else {
          dispatch(setInvoiceItemsToDisplay(updatedItems));
        }
      } else {
        dispatch(setItemsToDisplay(updatedItems));
      }
      setNewItems((prevNewItems) => ({
        ...prevNewItems,
        [dealFactorId]: {
          title: "",
          description: "",
          price: "",
          units: "",
        },
      }));
    }
  };
  const toggleModal = () => {
    setModal(false);
    setNewItem({
      title: "",
      description: "",
      price: null,
      units: "",
      type: null,
    });
    setModalType("DealFactor");
    setValue(null);
    setEditedItemIndex(null);
  };
  const packageNameRef = useRef(null);
  const totalPriceRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const laseItemIndex =
      itemsToDisplay.length > 0 &&
      itemsToDisplay[itemsToDisplay.length - 1]?.Id;
    console.log(laseItemIndex, "last item index");
    setCount(laseItemIndex + 1);
  }, [itemsToDisplay.length]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliceSize = windowWidth > 767 ? 2 : 1;
  // Show 3 items at a time
  // const itemsToDisplay = items.slice(currentIndex, currentIndex + 3);
  const handleNext = (length) => {
    if (currentIndex + sliceSize < length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  console.log(selectedPackages, "selectedPackages");
  console.log(itemsToDisplay, "itemsToDisplay");
  console.log(createdPackages, "createdPackages");
  const handleEditPackage = (packageIndex) => {
    // const editedPackageName = createdPackages[packageIndex].title;
    // const editedTotalPrice = createdPackages[packageIndex].totalPrice;
    // const editedGuestCount = createdPackages[packageIndex].guestCount;
    // const packageToEdit = createdPackages[packageIndex];
    let editedPackageName;
    let editedTotalPrice;
    let editedGuestCount;
    let packageToEdit;
    if (state?.invoice) {
      if (checked) {
        editedPackageName = newCreatedPackages[packageIndex].title;
        editedTotalPrice = newCreatedPackages[packageIndex].totalPrice;
        editedGuestCount = newCreatedPackages[packageIndex].guestCount;
        packageToEdit = newCreatedPackages[packageIndex];
      } else {
        editedPackageName = invoiceCreatedPackages[packageIndex].title;
        editedTotalPrice = invoiceCreatedPackages[packageIndex].totalPrice;
        editedGuestCount = invoiceCreatedPackages[packageIndex].guestCount;
        packageToEdit = invoiceCreatedPackages[packageIndex];
      }
    } else {
      editedPackageName = createdPackages[packageIndex].title;
      editedTotalPrice = createdPackages[packageIndex].totalPrice;
      editedGuestCount = createdPackages[packageIndex].guestCount;
      packageToEdit = createdPackages[packageIndex];
    }
    setEditedPackageIndex(packageIndex);
    // const packageToEdit = createdPackages[packageIndex];
    setPackageToEdit(packageIndex);
    const selectedPackagesObj = {};
    packageToEdit.packages.forEach((pkg, index) => {
      // itemsToDisplay.forEach((dealFactor, dealFactorIndex) => {
      //   const packageIndex = dealFactor.packages.findIndex(
      //     (packageItem) =>
      //       packageItem.title === pkg.title &&
      //       packageItem.description === pkg.description &&
      //       packageItem.price === pkg.price &&
      //       packageItem.units === pkg.units
      //   );
      //   if (packageIndex !== -1) {
      //     selectedPackagesObj[`${dealFactor.Id}-${packageIndex}`] = pkg;
      //   }
      // });
      if (state?.invoice) {
        if (checked) {
          newItemsToDisplay.forEach((dealFactor, dealFactorIndex) => {
            const packageIndex = dealFactor.packages.findIndex(
              (packageItem) =>
                packageItem.title === pkg.title &&
                packageItem.description === pkg.description &&
                packageItem.price === pkg.price &&
                packageItem.units === pkg.units
            );
            if (packageIndex !== -1) {
              selectedPackagesObj[`${dealFactor.Id}-${packageIndex}`] = pkg;
            }
          });
        } else {
          invoiceItemsToDisplay.forEach((dealFactor, dealFactorIndex) => {
            const packageIndex = dealFactor.packages.findIndex(
              (packageItem) =>
                packageItem.title === pkg.title &&
                packageItem.description === pkg.description &&
                packageItem.price === pkg.price &&
                packageItem.units === pkg.units
            );
            if (packageIndex !== -1) {
              selectedPackagesObj[`${dealFactor.Id}-${packageIndex}`] = pkg;
            }
          });
        }
      } else {
        itemsToDisplay.forEach((dealFactor, dealFactorIndex) => {
          const packageIndex = dealFactor.packages.findIndex(
            (packageItem) =>
              packageItem.title === pkg.title &&
              packageItem.description === pkg.description &&
              packageItem.price === pkg.price &&
              packageItem.units === pkg.units
          );
          if (packageIndex !== -1) {
            selectedPackagesObj[`${dealFactor.Id}-${packageIndex}`] = pkg;
          }
        });
      }
    });
    setPackageName(editedPackageName);
    setTotalPrice(editedTotalPrice);
    setGuestCount(editedGuestCount);
    dispatch(setSelectedPackages(selectedPackagesObj));
    setTotalPrice(packageToEdit.totalPrice);
    // Update the checked property of the Input component
    // const updatedPackages = [...createdPackages];
    let updatedPackages;
    if (state?.invoice) {
      if (checked) {
        updatedPackages = [...newItemsToDisplay];
      } else {
        updatedPackages = [...invoiceCreatedPackages];
      }
    } else {
      updatedPackages = [...createdPackages];
    }
    updatedPackages[packageIndex] = {
      ...updatedPackages[packageIndex],
      packages: updatedPackages[packageIndex].packages.map((packageItem) =>
        Object.assign({}, packageItem, { checked: true })
      ),
    };
    // dispatch(setCreatedPackages(updatedPackages));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewCreatedPackages(updatedPackages));
      } else {
        dispatch(setInvoiceCreatedPackages(updatedPackages));
      }
    } else {
      dispatch(setCreatedPackages(updatedPackages));
    }
  };
  const handleSaveEditedPackage = () => {
    const editedPackageName = packageNameRef.current.textContent || packageName;
    const editedTotalPrice = totalPriceRef.current.textContent || totalPrice;
    const selectedItems = Object.values(selectedPackages).filter(
      (item) => item.checked
    );
    if (selectedItems.length === 0) {
      toast.error("Please select at least one item");
      return;
    }
    // const updatedPackages = [...createdPackages];
    let updatedPackages;
    if (state?.invoice) {
      if (checked) {
        updatedPackages = [...newCreatedPackages];
      } else {
        updatedPackages = [...invoiceCreatedPackages];
      }
    } else {
      updatedPackages = [...createdPackages];
    }
    updatedPackages[packageToEdit] = {
      ...updatedPackages[packageToEdit],
      title: editedPackageName,
      totalPrice: editedTotalPrice,
      guestCount: guestCount,
      packages: Object.values(selectedPackages)
        .flat()
        .map((packageItem) =>
          Object.assign({}, packageItem, { checked: true })
        ),
    };
    console.log(updatedPackages[packageToEdit].packages, "updatedPackages");
    // Update the packages with the new values of the Deal Factor
    updatedPackages[packageToEdit].packages = updatedPackages[
      packageToEdit
    ].packages.map((packageItem) => {
      // const dealFactor = itemsToDisplay.find(
      //   (item) => item.type === packageItem.type
      // );
      let dealFactor;
      if (state?.invoice) {
        if (checked) {
          dealFactor = newItemsToDisplay.find(
            (item) => item.type === packageItem.type
          );
        } else {
          dealFactor = invoiceItemsToDisplay.find(
            (item) => item.type === packageItem.type
          );
        }
      } else {
        dealFactor = itemsToDisplay.find(
          (item) => item.type === packageItem.type
        );
      }
      if (dealFactor) {
        console.log(dealFactor, packageItem, "dealFactor");
        return {
          ...packageItem,
          title: dealFactor.packages.find(
            (pkg) => pkg.title === packageItem.title
          ).title,
          description: dealFactor.packages.find(
            (pkg) => pkg.title === packageItem.title
          ).description,
          price: dealFactor.packages.find(
            (pkg) => pkg.title === packageItem.title
          ).price,
          units: dealFactor.packages.find(
            (pkg) => pkg.title === packageItem.title
          ).units,
          type: dealFactor.type,
        };
      }
      return packageItem;
    });
    // dispatch(setCreatedPackages(updatedPackages));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewCreatedPackages(updatedPackages));
      } else {
        dispatch(setInvoiceCreatedPackages(updatedPackages));
      }
    } else {
      dispatch(setCreatedPackages(updatedPackages));
    }
    setPackageToEdit(null);
    dispatch(setSelectedPackages({})); // Clear the selectedPackages state
    setPackageName("");
    setTotalPrice(0);
    setGuestCount(0);
    setEditedPackageIndex(null);
  };

  const handleDeletePackage = (packageIndex) => {
    // const updatedPackages = [...createdPackages];
    let updatedPackages;
    if (state?.invoice) {
      if (checked) {
        updatedPackages = [...newCreatedPackages];
      } else {
        updatedPackages = [...invoiceCreatedPackages];
      }
    } else {
      updatedPackages = [...createdPackages];
    }
    updatedPackages.splice(packageIndex, 1);
    // dispatch(setCreatedPackages(updatedPackages));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewCreatedPackages(updatedPackages));
      } else {
        dispatch(setInvoiceCreatedPackages(updatedPackages));
      }
    } else {
      dispatch(setCreatedPackages(updatedPackages));
    }
  };
  const handleDuplicatePackage = (packageIndex) => {
    // const packageToDuplicate = createdPackages[packageIndex];
    let packageToDuplicate;
    if (state?.invoice) {
      if (checked) {
        packageToDuplicate = newCreatedPackages[packageIndex];
      } else {
        packageToDuplicate = invoiceCreatedPackages[packageIndex];
      }
    } else {
      packageToDuplicate = createdPackages[packageIndex];
    }
    console.log(packageToDuplicate, "packageToDuplicate");
    const duplicatedPackage = {
      ...packageToDuplicate,
      title: `Copy of ${packageToDuplicate.title}`,
    };
    // dispatch(setCreatedPackages([...createdPackages, duplicatedPackage]));
    if (state?.invoice) {
      if (checked) {
        dispatch(
          setNewCreatedPackages([...invoiceCreatedPackages, duplicatedPackage])
        );
      } else {
        dispatch(
          setInvoiceCreatedPackages([
            ...invoiceCreatedPackages,
            duplicatedPackage,
          ])
        );
      }
    } else {
      dispatch(setCreatedPackages([...createdPackages, duplicatedPackage]));
    }
  };

  const handleItemClick = (key, index) => {
    const [Id, packageIndex] = key.split("-").map(Number);
    // const selectedItem = itemsToDisplay.find((item) => item.Id === Id);
    let selectedItem;
    if (state?.invoice) {
      if (checked) {
        selectedItem = newItemsToDisplay.find((item) => item.Id === Id);
      } else {
        selectedItem = invoiceItemsToDisplay.find((item) => item.Id === Id);
      }
    } else {
      selectedItem = itemsToDisplay.find((item) => item.Id === Id);
    }
    if (selectedItem) {
      const selectedPackage = selectedItem.packages[packageIndex];
      // const type = options.find((option) => option.value === selectedItem.type);
      let type;
      if (state?.invoice) {
        if (checked) {
          type = newOptions.find(
            (option) => option.value === selectedItem.type
          );
        } else {
          type = invoiceOptions.find(
            (option) => option.value === selectedItem.type
          );
        }
      } else {
        type = options.find((option) => option.value === selectedItem.type);
      }
      console.log(type, "type");
      setNewItem({
        title: selectedPackage.title,
        description: selectedPackage.description,
        price: selectedPackage.price,
        units: selectedPackage.units,
        type: type,
      });
      setValue(type);
      setModalType("DealFactor");
      setEditedItemIndex(Id); // Store the index of the edited item
      setEditedPackageIndex(packageIndex); // Store the index of the edited package
      setModal(true);
    } else {
      console.log("Item not found");
    }
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      // dispatch(setOptions([...options, newOption]));
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewOptions([...newOptions, newOption]));
        } else {
          dispatch(setInvoiceOptions([...invoiceOptions, newOption]));
        }
      } else {
        dispatch(setOptions([...options, newOption]));
      }
      setValue(newOption);
    }, 1000);
  };

  const handleUpdateEditedItem = () => {
    if (editedItemIndex !== null && editedPackageIndex !== null) {
      // const updatedItems = JSON.parse(JSON.stringify(itemsToDisplay));
      let updatedItems;
      if (state?.invoice) {
        if (checked) {
          updatedItems = JSON.parse(JSON.stringify(newItemsToDisplay));
        } else {
          updatedItems = JSON.parse(JSON.stringify(invoiceItemsToDisplay));
        }
      } else {
        updatedItems = JSON.parse(JSON.stringify(itemsToDisplay));
      }
      updatedItems[editedItemIndex].packages[editedPackageIndex] = {
        ...updatedItems[editedItemIndex].packages[editedPackageIndex],
        ...newItem,
      };
      // dispatch(setItemsToDisplay(updatedItems));
      if (state?.invoice) {
        if (checked) {
          dispatch(setNewItemsToDisplay(updatedItems));
        } else {
          dispatch(setInvoiceItemsToDisplay(updatedItems));
        }
      } else {
        dispatch(setItemsToDisplay(updatedItems));
      }
      setModal(false);
      setNewItem({
        title: "",
        description: "",
        price: null,
        units: "",
        type: null,
      });
      setEditedItemIndex(null);
      setEditedPackageIndex(null);
    }
  };

  const renderAddCircle = () => {
    if (state?.invoice) {
      if (checked) {
        return (
          newItemsToDisplay.length > 0 && (
            <span
              className="text-decoration-none text-primary btn btn-sm mt-1"
              onClick={() => setModal(true)}
            >
              <IoMdAddCircle size={30} />
            </span>
          )
        );
      }
      // else {
      //   return (
      //     invoiceItemsToDisplay.length > 0 && (
      //       <span
      //         className="text-decoration-none text-primary btn btn-sm mt-1"
      //         onClick={() => setModal(true)}
      //       >
      //         <IoMdAddCircle size={30} />
      //       </span>
      //     )
      //   );
      // }
    } else {
      return (
        itemsToDisplay.length > 0 && (
          <span
            className="text-decoration-none text-primary btn btn-sm mt-1"
            onClick={() => setModal(true)}
          >
            <IoMdAddCircle size={30} />
          </span>
        )
      );
    }
  };

  const handleDealFactorItemDeletion = (dealFactorId, packageIndex) => {
    // const updatedItems = itemsToDisplay.map((item) => {
    //   if (item.Id === dealFactorId) {
    //     return { ...item, packages: item.packages.filter((pkg, index) => index !== packageIndex) };
    //   }
    //   return item;
    // });
    console.log(dealFactorId, packageIndex, "handleDealFactorItemDeletion");
    let updatedItems;
    if (state?.invoice) {
      if (checked) {
        updatedItems = newItemsToDisplay.map((item) => {
          if (item.Id === dealFactorId) {
            return {
              ...item,
              packages: item.packages.filter(
                (pkg, index) => index !== packageIndex
              ),
            };
          }
          return item;
        });
      } else {
        updatedItems = invoiceItemsToDisplay.map((item) => {
          if (item.Id === dealFactorId) {
            return {
              ...item,
              packages: item.packages.filter(
                (pkg, index) => index !== packageIndex
              ),
            };
          }
          return item;
        });
      }
    } else {
      updatedItems = itemsToDisplay.map((item) => {
        if (item.Id === dealFactorId) {
          return {
            ...item,
            packages: item.packages.filter(
              (pkg, index) => index !== packageIndex
            ),
          };
        }
        return item;
      });
    }
    // dispatch(setItemsToDisplay(updatedItems));
    if (state?.invoice) {
      if (checked) {
        dispatch(setNewItemsToDisplay(updatedItems));
      } else {
        dispatch(setInvoiceItemsToDisplay(updatedItems));
      }
    } else {
      dispatch(setItemsToDisplay(updatedItems));
    }
  };

  return (
    <div className="bg-white">
      <div className="">
        <div className="d-flex justify-content-between p-1">
          <div
            style={{ fontSize: "17px", fontWeight: "600" }}
            className="dealFactors"
          >
            Deal Factors
          </div>
          {/* {itemsToDisplay?.length > 0 && (
            <span
              className="text-decoration-none text-primary btn btn-sm mt-1"
              onClick={() => setModal(true)}
            >
              <IoMdAddCircle size={30} />
            </span>
          )} */}
          {renderAddCircle()}
        </div>
        <div className="my-2 dealFactors">
          <DealFactorTitle
            itemsToDisplay={
              state?.invoice
                ? checked
                  ? newItemsToDisplay
                  : invoiceItemsToDisplay
                : itemsToDisplay
            }
            sliceSize={sliceSize}
            handlePrevious={handlePrevious}
            currentIndex={currentIndex}
            options={
              state?.invoice ? (checked ? newOptions : invoiceOptions) : options
            }
            newItems={newItems}
            selectedPackages={selectedPackages}
            handleInputChange={handleInputChange}
            handlePackageSelection={handlePackageSelection}
            handleAddItemToPackages={handleAddItemToPackages}
            handleDealFactorItemDeletion={handleDealFactorItemDeletion}
            setModal={setModal}
            setItemsToDisplay={
              state?.invoice ? setInvoiceItemsToDisplay : setItemsToDisplay
            }
            handleNext={handleNext}
            setCurrentIndex={setCurrentIndex}
            state={state}
            checked={checked}
          />
        </div>
        <div className={`mt-1 p-1 `}>
          {Object.keys(selectedPackages).length > 0 && (
            <div
            // className={` ${
            //   state?.invoice ? (checked ? "d-block" : " d-none") : "d-block"
            // }`}
            >
              <ServicePackageCreation
                selectedPackages={selectedPackages}
                handlePackageSelection={handlePackageSelection}
                handleCreatePackage={handleCreatePackage}
                totalPrice={totalPrice}
                packageToEdit={packageToEdit}
                handleSaveEditedPackage={handleSaveEditedPackage}
                toggleModal={toggleModal}
                handleItemClick={handleItemClick}
                packageNameRef={packageNameRef}
                totalPriceRef={totalPriceRef}
                packageName={packageName}
                setGuestCount={setGuestCount}
                guestCount={guestCount}
                state={state}
                checked={checked}
              />
            </div>
          )}
          <div
            className={` ${
              state?.invoice ? (checked ? "d-none" : " d-block") : "d-block"
            }`}
          >
            <CreatedPackages
              createdPackages={
                state?.invoice ? invoiceCreatedPackages : createdPackages
              }
              handleEditPackage={handleEditPackage}
              handleDeletePackage={handleDeletePackage}
              handleDuplicatePackage={handleDuplicatePackage}
              editedPackageIndex={editedPackageIndex}
              guestCount={guestCount}
              state={state}
              checked={checked}
              setEditedPackageIndex={setEditedPackageIndex}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        {modalType === "DealFactor" && (
          <ModalHeader toggle={toggleModal}>Add New Item</ModalHeader>
        )}
        {modalType === "DealFactor" ? (
          <ModalBody>
            {editedItemIndex === null && (
              <FormGroup>
                <Label htmlFor="dynamic-select">
                  Deal Factor Title <b className="text-danger">*</b>
                </Label>
                <p style={{ fontSize: "small" }}>
                  Deal Factor is basically anything that causes a price change
                  in your business. For example it might be "Day of the Week" or
                  "Bar options" or "Traveling Distance"
                </p>
                <CreatableSelect
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(newValue) => setValue(newValue)}
                  onCreateOption={handleCreate}
                  options={
                    state?.invoice
                      ? checked
                        ? newOptions
                        : invoiceOptions
                      : options
                  }
                  value={value}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="title">
                Item Title <b className="text-danger">*</b>
              </Label>
              <p style={{ fontSize: "small" }}>
                This is where you list the different options for that Deal
                Factor. For example, "Travel up to 10 miles" would be an item
                title. "Travel up to 75 miles" would be another item title which
                has a different price.{" "}
              </p>
              <Input
                type="text"
                value={newItem.title}
                onChange={(e) =>
                  setNewItem({ ...newItem, title: e.target.value })
                }
                placeholder="Item Title"
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">Item Description</Label>
              <p style={{ fontSize: "small" }}>
                Here we just want you to just explain additional details of that
                item.
              </p>
              <Input
                type="text"
                value={newItem.description}
                onChange={(e) =>
                  setNewItem({ ...newItem, description: e.target.value })
                }
                placeholder="Item Description"
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">
                Item Price <b className="text-danger">*</b>
              </Label>
              <Input
                type="number"
                value={newItem.price}
                onChange={(e) =>
                  setNewItem({ ...newItem, price: e.target.value })
                }
                placeholder="Item Price"
              />
            </FormGroup>
            <FormGroup>
              <Label for="units">
                Select Unit Type <b className="text-danger">*</b>
              </Label>
              <Input
                type="select"
                value={newItem.units}
                onChange={(e) =>
                  setNewItem({ ...newItem, units: e.target.value })
                }
                placeholder="Item unit"
              >
                <option value="">Select....</option>
                <option value="piece">piece</option>
                <option value="box">box</option>
                <option value="pack">pack</option>
                <option value="case">case</option>
                <option value="set">set</option>
                <option value="pair">pair</option>
                <option value="unit">unit</option>
                <option value="hour">hour</option>
                <option value="minute">minute</option>
                <option value="day">day</option>
                <option value="month">month</option>
                <option value="liter">liter</option>
                <option value="pound">pound (lb)</option>
                <option value="ounce">ounce (oz)</option>
                <option value="Inch">Inch</option>
                <option value="foot">foot</option>
                <option value="yard">yard</option>
                <option value="barrel">barrel</option>
                <option value="bag">bag</option>
                <option value="carton">carton</option>
                <option value="event">event</option>
                <option value="person">person</option>
                <option value="use">use</option>
                <option value="visit">visit</option>
              </Input>
            </FormGroup>
          </ModalBody>
        ) : (
          <ModalBody>
            <FormGroup>
              <Label for="title"> Enter Package Name</Label>
              <Input
                type="text"
                onChange={(e) => setPackageName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">
                Enter Amount to {packageToEdit === null ? "Create" : "Update"}{" "}
                Package <b className="text-danger">*</b>
              </Label>
              <Input
                type="number"
                onChange={(e) => setTotalPrice(e.target.value)}
              />
              <div className="mt-2 d-flex justify-content-center">
                {packageToEdit === null ? (
                  <Button
                    color="warning"
                    onClick={() => {
                      if (!totalPrice) {
                        toast.error("Please enter valid amount");
                        return;
                      }
                      handleCreatePackage();
                      toggleModal();
                    }}
                  >
                    Add Package
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => {
                      if (!totalPrice) {
                        toast.error("Please enter valid amount");
                        return;
                      }
                      handleSaveEditedPackage();
                      toggleModal();
                    }}
                  >
                    Save Package
                  </Button>
                )}
              </div>
            </FormGroup>
          </ModalBody>
        )}
        {modalType === "DealFactor" && (
          <ModalFooter>
            <Button
              color={`${editedItemIndex !== null ? "warning" : "primary"}`}
              onClick={
                editedItemIndex !== null
                  ? handleUpdateEditedItem
                  : handleAddItem
              }
            >
              {editedItemIndex !== null
                ? "Update Deal Factor"
                : "Add New Deal Factor"}
            </Button>

            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default DealFactors;
