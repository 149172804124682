import React, { useEffect, useRef, useState } from "react";
import "./messages.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initiateCall,
  msgPhoneEmailDetail,
  postOrder,
  sendMessage,
} from "../../../components/Header/Data";
import { toast } from "react-toastify";
import "./Styles/InputBox.css";
import { LiaPhoneAltSolid } from "react-icons/lia";
import { IoMdAttach } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import {
  FaDollarSign,
  FaFilePdf,
  FaImage,
  FaVideo,
  FaUser,
  FaRegClock,
} from "react-icons/fa";
import { MdGroups } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import uploadToS3 from "../../../utils/awsS3Upload";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import {
  setDescription,
  setMessageText,
  setOrder,
  setOrderStatus,
  setSelectedConversation,
  setSelectedFile,
  setSendingFile,
  setSendingMsg,
} from "../../../redux/slices/MsgSlice";
import Payment from "../settingsComponents/Payment";
import { useSocket } from "../../../contexts/SocketContext";
import ThankYouModal from "../Orders/ThankYouPage";
import MsgScheduleModal from "../../../components/Header/modals/MsgScheduleModal";
import { Tooltip } from "@mui/material";
function MsgInputBox({ fetchMsgs, fetchAllMsg, isChatModal, isAds }) {
  const [showAttachOptions, setShowAttachOptions] = useState(false);
  const [showCallOptions, setShowCallOptions] = useState(false);
  const attachOptionsRef = useRef(null); // Reference for attach-options
  const callOptionsRef = useRef(null); // Reference for attach-options
  const [twilioDetails, setTwilioDetails] = useState({});
  const { sendNewMessage, onMessageReceived } = useSocket();
  const {
    messageText,
    description,
    order,
    orderStatus,
    sendingMsg,
    selectedConvo,
  } = useSelector((state) => state.messages);
  const { selectedAd } = useSelector((state) => state.lcAds);
  const selectedFile = useSelector((state) => state.messages.selectedFile);
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [amount, setAmount] = useState(null);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const toggleModal = () => {
    setThankYou(false);
  };
  const handleModalClick = () => {
    setModalIsOpen(true);
  };
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    // setOpenPaypal(false);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAmount(null);
    setOpenPaypal(false);
    dispatch(setDescription(""));
  };

  const handlePayment = async () => {
    if (!description) {
      toast.error("Please Enter Description");
      return;
    }
    if (!amount) {
      toast.error("Please Enter Amount");
      return;
    }
    let formData = {
      customerId:
        selectedConvo?.createdBy === user?.UserID
          ? selectedConvo?.customerId
          : user?.UserID,
      createdBy: user?.UserID,
      parentId: selectedConvo?.Id,
      totalAmount: amount,
      Message: description,
      orderType: 3,
      receiverId:
        selectedConvo?.createdBy === user?.UserID
          ? selectedConvo?.customerId
          : user?.UserID,
      messageId: selectedConvo?.Id,
      ownerId: selectedConvo?.ownerId,
      Description: `payment for chat with ${description}, ${amount}, PENDING`,
      orderStatus: "PENDING",
      Type: "chatpayment",
    };
    const order = await postOrder(formData);
    toast.success("Order Created and Message Sent");
    setOpenPaypal(true);
    dispatch(setOrder(order?.Id));
    if (order && order?.Id) {
      const messageForm = {
        customerId:
          selectedConvo.customerId === user.UserID
            ? selectedConvo.createdBy
            : selectedConvo.customerId,
        postId: selectedConvo.postId,
        postType: 1, // message 1 or requestQuote 2 or offer my service 3
        parentId:
          selectedConvo.parentId === 0
            ? selectedConvo.Id
            : selectedConvo.parentId,
        Description: `payment for chat with ${description}, ${amount}, PENDING`,
        messageStatus: 8,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedConvo.ownerId,
        userName: user.UserName.split("@")[0].trim(),
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedConvo.postName,
        orderStatus: "PENDING",
        orderId: order?.Id,
        orderMessage: description,
        totalAmount: amount,
        orderType: 3,
      };
      const response = await sendMessage(messageForm);
      if (response.Data) {
        fetchMsgs();
        if (!isChatModal) {
          fetchAllMsg();
        } else {
          fetchMsgs();
        }
        sendNewMessage(response.Data);
      }
    }
  };

  useEffect(() => {
    if (orderStatus === "COMPLETED") {
      console.log(orderStatus, "orderStatus");
      setAmount(null);
      dispatch(setDescription(""));
      setModalIsOpen(false);
      setOpenPaypal(false);
      setThankYou(true);
      dispatch(setOrderStatus(""));
      fetchMsgs();
    }
  }, [orderStatus]);

  const handleSend = async (e, userType) => {
    dispatch(setSendingMsg(true));
    if (messageText.trim()) {
      let messageForm;
      if (selectedConvo) {
        messageForm = {
          customerId:
            selectedConvo.customerId === user.UserID
              ? selectedConvo.createdBy
              : selectedConvo.customerId,
          postId: selectedConvo.postId,
          postType: 1, // message 1 or requestQuote 2 or offer my service 3
          parentId:
            selectedConvo.parentId === 0
              ? selectedConvo.Id
              : selectedConvo.parentId,
          Description: messageText,
          messageStatus: userType === "user" ? 8 : 13,
          createdBy:
            userType === "user" ? user.UserID : selectedConvo?.createdBy,
          roleId: user.RoleId,
          ownerId: selectedConvo.ownerId,
          userName:
            userType === "user" ? user.UserName.split("@")[0].trim() : selectedConvo?.senderName,
          Email: user.Email,
          serviceNeed: "need",
          needName: selectedConvo.postName,
        };
      } else {
        messageForm = {
          customerId: selectedAd?.createdBy,
          postId: selectedAd?.Id,
          postType: 1, // message 1 or requestQuote 2 or offer my service 3
          Description: messageText,
          messageStatus: 8,
          createdBy: user.UserID,
          roleId: user.RoleId,
          ownerId: selectedAd ? selectedAd.createdBy : `0`,
          userName: user.UserName.split("@")[0].trim(),
          Email: user.Email,
          serviceNeed: "text",
          needName: selectedAd?.Title,
        };
      }
      if (!messageForm.Description) {
        toast.error("Enter Message");
        return;
      }
      const response = await sendMessage(messageForm);
      if (response.Data) {
        fetchMsgs();
        if (!isChatModal) {
          fetchAllMsg();
        }
        dispatch(setMessageText(""));
        sendNewMessage(response.Data);
        toast.success("Message Sent");
      }
    }
    dispatch(setSendingMsg(false));
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation(); // Prevent any unintended behavior
    }
  };

  const handleClick = (e) => {
    const offerAmount =
      selectedConvo.finalBudget !== null ? selectedConvo.finalBudget : 0;
    e.stopPropagation();
    if (islogin) {
      navigate("/order/summary", {
        state: {
          selectedConvo,
          offerAmount,
        },
      });
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };
  const fetchMsgPhoneEmailDetail = async () => {
    if (selectedConvo) {
      const responseDetail = await msgPhoneEmailDetail(selectedConvo?.Id);
      setTwilioDetails(responseDetail[0]);
    }
  };

  useEffect(() => {
    fetchMsgPhoneEmailDetail();
  }, [selectedConvo]);
  function formatDate1(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  const now = new Date();
  const formattedDate = formatDate1(now);
  console.log(twilioDetails, "twilioDetails");
  const handleGroupCall = async (e) => {
    e.stopPropagation();
    const phoneDetailsObj = [
      {
        phoneNumber:
          twilioDetails?.created_by_phone === " " ||
          twilioDetails?.created_by_phone === null
            ? ""
            : twilioDetails?.created_by_phone,
        countryCode:
          twilioDetails?.created_by_phone === null ||
          twilioDetails?.created_by_phone === ""
            ? ""
            : twilioDetails?.created_country_code,
      },
      {
        phoneNumber:
          twilioDetails?.receiver_phone === " " ||
          twilioDetails?.receiver_phone === null
            ? ""
            : twilioDetails?.receiver_phone,
        countryCode:
          twilioDetails?.receiver_phone === null ||
          twilioDetails?.receiver_phone === ""
            ? ""
            : twilioDetails?.receiver_country_code,
      },
      {
        phoneNumber:
          twilioDetails?.alternative_phone_one === null ||
          twilioDetails?.alternative_phone_one === ""
            ? ""
            : twilioDetails?.alternative_phone_one,
        countryCode:
          twilioDetails?.alternative_phone_one === null ||
          twilioDetails?.alternative_phone_one === ""
            ? ""
            : twilioDetails?.receiver_country_code,
      },
      {
        phoneNumber:
          twilioDetails?.alternative_phone_two === null ||
          twilioDetails?.alternative_phone_two === ""
            ? ""
            : twilioDetails?.alternative_phone_two,
        countryCode:
          twilioDetails?.alternative_phone_two === null ||
          twilioDetails?.alternative_phone_two === ""
            ? ""
            : twilioDetails?.receiver_country_code,
      },
    ];
    if (
      twilioDetails?.need_phone &&
      twilioDetails?.receiver_phone !== twilioDetails?.need_phone
    ) {
      phoneDetailsObj.push({
        phoneNumber: twilioDetails?.need_phone,
        countryCode:
          twilioDetails?.need_phone === "" || twilioDetails?.need_phone === null
            ? ""
            : twilioDetails?.receiver_country_code,
      });
    }
    const phoneDetails = JSON.stringify(phoneDetailsObj);
    console.log(phoneDetails, "phoneDetails");
    let formData = {
      phoneDetails: phoneDetails,
      email: selectedConvo?.senderEmail,
    };
    toast.success("Group Call Started");
    await initiateCall(formData);
  };

  const handleIndiviualCall = async (e) => {
    e.stopPropagation();
    const phoneDetailsObj = [
      {
        phoneNumber:
          twilioDetails?.created_by_phone === " " ||
          twilioDetails?.created_by_phone === null
            ? ""
            : twilioDetails?.created_by_phone,
        countryCode:
          twilioDetails?.created_by_phone === null ||
          twilioDetails?.created_by_phone === ""
            ? ""
            : twilioDetails?.created_country_code,
      },
      {
        phoneNumber:
          twilioDetails?.Phone === " " || twilioDetails?.Phone === null
            ? ""
            : twilioDetails?.Phone,
        countryCode:
          twilioDetails?.Phone === null || twilioDetails?.Phone === ""
            ? ""
            : twilioDetails?.receiver_country_code,
      },
    ];
    const phoneDetails = JSON.stringify(phoneDetailsObj);
    console.log(phoneDetails, "phoneDetails");
    let formData = {
      phoneDetails: phoneDetails,
      email: selectedConvo?.senderEmail,
    };
    toast.success("Call Started");
    await initiateCall(formData);
  };

  const handleAttach = (e) => {
    e.stopPropagation();
    setShowAttachOptions(!showAttachOptions);
    if (showCallOptions) {
      setShowCallOptions(false); // Close call options if they are open
    }
  };

  const handleCallOptions = (e) => {
    e.stopPropagation();
    setShowCallOptions(!showCallOptions);
    if (showAttachOptions) {
      setShowAttachOptions(false); // Close attach options if they are open
    }
  };

  const handleFileChange = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024 * 1024) {
        toast.error("File size must be less than 5MB.");
        dispatch(setSelectedFile(null));
        return;
      }
      dispatch(setSelectedFile(file));
      setShowAttachOptions(false);
    }
  };

  const handleFileSend = async () => {
    dispatch(setSendingFile(true));
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }
    let fileUrl = null;
    if (selectedFile) {
      try {
        const folder = `messageFile/`;
        fileUrl = await uploadToS3(selectedFile, folder); // Upload to S3 and get URL
      } catch (error) {
        console.error("Error uploading image to S3:", error);
        toast.error("Image upload failed. Please try again.");
      }
    }
    if (!fileUrl) {
      toast.error("Please select a file");
      return;
    }
    let messageForm;
    if (selectedConvo) {
      messageForm = {
        customerId:
          selectedConvo.customerId === user.UserID
            ? selectedConvo.createdBy
            : selectedConvo.customerId,
        postId: selectedConvo.postId,
        postType: 4, // message 1 , requestQuote 2 , offer my service 3 , Attachment 4
        parentId:
          selectedConvo.parentId === 0
            ? selectedConvo.Id
            : selectedConvo.parentId,
        Description: selectedFile.name,
        messageStatus: 8,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedConvo.ownerId,
        userName: user.UserName.split("@")[0].trim(),
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedConvo.postName || "Text message",
        Photo: fileUrl,
      };
      const response = await sendMessage(messageForm);
      if (response.Data) {
        fetchMsgs();
        if (!isChatModal) {
          fetchAllMsg();
        }
        dispatch(setSelectedFile(null));
        setShowAttachOptions(false);
        sendNewMessage(response.Data);
        toast.success("Attachment Sent");
      }
      dispatch(setSendingFile(false));
    } else {
      messageForm = {
        customerId: selectedAd?.createdBy,
        postId: selectedAd?.Id,
        postType: 4, // message 1 or requestQuote 2 or offer my service 3
        Description: selectedFile.name,
        messageStatus: 8,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedAd ? selectedAd.createdBy : `0`,
        userName: user.UserName.split("@")[0].trim(),
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedAd?.Title || "Text message",
      };
      const response = await sendMessage(messageForm);
      if (response.Data) {
        let data = response.Data;
        if (data.parentId == 0) {
          dispatch(setSelectedConversation(data));
        } else {
          fetchMsgs();
        }
        dispatch(setMessageText(""));
        toast.success("Message Sent");
        sendNewMessage(response.Data);
      }
      dispatch(setSelectedFile(null));
      setShowAttachOptions(false);
      dispatch(setSendingFile(false));
    }
  };
  React.useEffect(() => {
    if (selectedFile) {
      handleFileSend();
      console.log(selectedFile, "selectedFile");
    }
  }, [selectedFile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        attachOptionsRef.current &&
        !attachOptionsRef.current.contains(event.target)
      ) {
        setShowAttachOptions(false);
      }
      if (
        callOptionsRef.current &&
        !callOptionsRef.current.contains(event.target)
      ) {
        setShowCallOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="input-box">
      <div className="input-box-icons">
        <Tooltip title="Schedule Message" placement="top" arrow>
          <span onClick={(e) => setScheduleModal(true)}>
            <FaRegClock />
          </span>
        </Tooltip>
        <MsgScheduleModal
          open={scheduleModal}
          close={() => setScheduleModal(false)}
          msg={selectedConvo}
          isChatModal={isChatModal}
          fetchMsgs={fetchMsgs}
          fetchAllMsg={fetchAllMsg}
        />
        <Tooltip title="Pay Amount" placement="top" arrow>
          <span onClick={handleModalClick}>
            <FaDollarSign />
          </span>
        </Tooltip>
        <Modal isOpen={modalIsOpen} toggle={closeModal}>
          <ModalBody>
            {/* <div>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </div> */}
            <div>
              <Label>
                Description <b className="text-danger">*</b>
              </Label>
              <Input
                type="textarea"
                name="descrption"
                placeholder="Please Enter Descrption"
                value={description}
                onChange={(e) => dispatch(setDescription(e.target.value))}
              />
            </div>
            <div>
              <Label>Amount</Label>
              <InputGroup>
                <InputGroupText className="fw-bold">$</InputGroupText>
                <Input
                  type="number"
                  name="amount"
                  placeholder="amount"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </InputGroup>
              <div>
                {!openPaypal ? (
                  <div className="text-center my-3">
                    <Button
                      color="primary"
                      onClick={handlePayment}
                      disabled={
                        parseInt(amount) <= 0 ||
                        amount === null ||
                        amount === ""
                      }
                    >
                      Pay Now
                    </Button>
                  </div>
                ) : (
                  <Payment
                    totalPrice={Number(amount)}
                    state={{ chatPay: true }}
                    chatDetails={true}
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Tooltip title="Create Call" placement="top" arrow>
          <span onClick={(e) => handleCallOptions(e)}>
            <LiaPhoneAltSolid />
          </span>
        </Tooltip>
        {showCallOptions && (
          <div
            className="attach-options"
            style={{ width: "150px", left: "3px", padding: "7px" }}
            ref={callOptionsRef}
          >
            <label>
              <div className="p-1" onClick={(e) => handleIndiviualCall(e)}>
                Individual Call <FaUser />
              </div>
            </label>
            <label>
              <div className="p-1" onClick={(e) => handleGroupCall(e)}>
                Group Call &nbsp;
                <MdGroups size={20} />
              </div>
            </label>
          </div>
        )}
        <Tooltip title="Attach File" placement="top" arrow>
          <span onClick={(e) => handleAttach(e)}>
            <IoMdAttach />
          </span>
        </Tooltip>
      </div>

      {showAttachOptions && (
        <div className="attach-options" ref={attachOptionsRef}>
          <label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            Photo <FaImage />
          </label>
          <label>
            <input
              type="file"
              name="video"
              accept="video/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            Video <FaVideo />
          </label>
          <label>
            <input
              type="file"
              name="document"
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            File <FaFilePdf />
          </label>
        </div>
      )}
      <textarea
        type="text"
        value={messageText}
        onChange={(e) => dispatch(setMessageText(e.target.value))}
        onKeyDown={handleKeyDown}
        placeholder={`${
          isAds
            ? "Type a message & include your Phone Number"
            : "Type a message"
        }`}
        className=" form-control input-box-input"
      />

      {sendingMsg ? (
        <Spinner />
      ) : (
        <button onClick={(e) => handleSend(e, "user")}>
          <IoSend size={25} style={{ cursor: "pointer" }} />
        </button>
      )}
      {isAdmin && selectedConvo?.postType == 3 && (
        <>
          {sendingMsg ? (
            <Spinner />
          ) : (
            <button onClick={(e) => handleSend(e, "admin")}>
              <IoSend size={25} style={{ cursor: "pointer", color: "red" }} />
            </button>
          )}
        </>
      )}
      <ThankYouModal
        show={thankYou}
        onHide={toggleModal}
        // totalPrice={totalPrice}
        state={{ chatPay: true }}
      />
    </div>
  );
}

export default MsgInputBox;
