import React, { useRef, useEffect, useState } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { userAction } from "../../redux/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../src/assets/images/img/wc-logo.png";
import Sikh from "../../../src/assets/images/img/SikhLogo.png";
import Magisticlogo from "../../../src/assets/images/img/megisticLogo2.png";
import { fetchUserDetails } from "./Data";
import { Button, Menu, MenuItem, Fade, styled, Badge } from "@mui/material";
import { AiFillCaretDown, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { io } from "socket.io-client";
import "../../styles/bottom_navigate.css";
import { useLocation } from "react-router-dom";
import {
  FaCartArrowDown,
  FaInbox,
  FaPhoneAlt,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import { MyCart } from "./MyCart";
import { toast } from "react-toastify";
import {
  fetchTemplates,
  setActiveTemps,
  setMyTemplates,
  setPendingTemps,
} from "../../redux/slices/TemplateSlice";
import DonateModal from "./modals/DonateModal";
import {
  setDonationFrom,
  setDonationStatus,
} from "../../redux/slices/DonationSlice";
import ThankYouModal from "../../pages/DashboardPages/Orders/ThankYouPage";
import axiosClient from "../../axios-client";
import { getImagesData } from "../../redux/slices/ImageSlice";
import { useSocket } from "../../contexts/SocketContext";

const Header = ({ setOpen }) => {
  const location = useLocation();
  const route_path = location.pathname;
  let abs_path = route_path.replace(/[^a-zA-Z ]/g, "");
  const isHomePage = location.pathname === "/home";
  const socketBackendUrl = process.env.REACT_APP_SOCKET_BACKEND_URL;
  // const socket = io(socketBackendUrl);
  // const isHomePage = location.pathname === "/" || location.pathname === "/home";
  const isLCA =
    location.pathname === "/Localcommunityads" || location.pathname === "/";
  const isBrowsePage = location.pathname === "/weddingvendors";
  const isMajesticHomePage = location.pathname === "/majesticHomePage";
  const isVendorDetailsPage = location.pathname.includes("/vendordetails");
  const isInvoicePaymentPage = location.pathname.includes("/invoicePayment");
  const dispatch = useDispatch();
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const totalPrice = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const wishcount = useSelector((state) => state.setting.wishcount);
  // const username = islogin && JSON.parse(userinfo).name;
  const basepath = useSelector((state) => state.setting.basepath);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [unreadMsg, setUnreadMsg] = useState(0);
  const { AllTemplates, loading, error, pendingTemps } = useSelector(
    (state) => state.templates
  );
  const { donationStatus, donationFrom } = useSelector(
    (state) => state.gurdwaraDonation
  );
  const { socket } = useSocket();
  const [thankYou, setThankYou] = useState(false);
  useEffect(() => {
    console.log(donationStatus, "donationStatus");
    if (donationFrom === "HEADER" && donationStatus === "COMPLETED") {
      setModalIsOpen(false);
      setModalClosed(true);
      // navigate("/dashboardpage/gurdwaramanagement");
      dispatch(setDonationStatus(""));
      dispatch(setDonationFrom(""));
      setThankYou(true);
    }
  }, [donationStatus]);
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 1,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  console.log(unreadMsg, "unreadMsg");
  const fetchData = async () => {
    if (!user) return;
    const userData = await fetchUserDetails(user?.UserID);
    setUserDetails(userData);
    try {
      const response = await axiosClient.put(
        `message/TotalUnreadMsgByUser/${user.UserID}`
      );
      if (response.data.statusCode === 200) {
        setUnreadMsg(response.data.Data[0].total_count);
      } else {
        console.log(response.data.msg);
      }
    } catch (error) {
      throw new Error("Error fetching userDetails");
    }
  };
  useEffect(() => {
    if (islogin) {
      fetchData();
      if (isAdmin) {
        dispatch(fetchTemplates());
        dispatch(getImagesData()); // Fetch images on component mount
      }
    }
  }, []);

  // const allUsers = localStorage.getItem("onlineUsers");
  // console.log(JSON.parse(allUsers), "onlineUsers");
  const filterTemplates = () => {
    if (!AllTemplates) return { pending: [], active: [], myTemplates: [] };
    let myTemplates = [];
    let pendingTemplates = [];
    let activeTemplates = [];
    if (isAdmin) {
      myTemplates = AllTemplates;
      pendingTemplates = AllTemplates.filter(
        (template) => template.template_status == 0 // Assuming 0 indicates pending
      );
      activeTemplates = AllTemplates.filter(
        (template) => template.template_status == 1 // Assuming 1 indicates active
      );
    } else {
      myTemplates = AllTemplates.filter(
        (template) => template.createdBy == user?.UserID // Filter myTemplates by createdBy === user.UserID
      );
      pendingTemplates = myTemplates.filter(
        (template) => template.template_status == 0 // Assuming 0 indicates pending
      );
      activeTemplates = myTemplates.filter(
        (template) => template.template_status == 1 // Assuming 1 indicates active
      );
    }
    return {
      pending: pendingTemplates,
      active: activeTemplates,
      myTemplates: myTemplates,
    };
  };
  // const { users } = useSelector((state) => state.socket);
  const { pending, active, myTemplates } = filterTemplates();
  useEffect(() => {
    if (islogin) {
      dispatch(setPendingTemps(pending));
      dispatch(setActiveTemps(active));
      dispatch(setMyTemplates(myTemplates));
    }
  }, [AllTemplates]);
  const [anchorEl22, setAnchorEl22] = useState(null);
  const [anchorCall, setAnchorCall] = useState(null);
  const open22 = Boolean(anchorEl22);
  const openCall = Boolean(anchorCall);
  const handleClick22 = (event) => {
    setAnchorEl22(event.currentTarget);
  };
  const handleClickCall = (event) => {
    setAnchorCall(event.currentTarget);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const handleClose22 = () => {
    setAnchorEl22(null);
  };
  const handleDonate = () => {
    dispatch(setDonationFrom("HEADER"));
    setModalIsOpen(true);
    setAnchorEl22(null);
  };
  const handleCloseCall = () => {
    setAnchorCall(null);
  };
  React.useEffect(() => {
    if (socket) {
      socket.on("receive-message", (message) => {
        fetchData();
      });
      socket.on("new-message", (message) => {
        fetchData();
      });
    }
  }, [socket]);

  const userLogout = () => {
    dispatch(userAction.logout());
    setAnchorEl22(null);
    navigate("/login");
    setAnchorCall(null);
  };

  let maxLength = 8;
  let truncatedUsername = "";
  if (islogin) {
    truncatedUsername =
      user.UserName.length > maxLength
        ? `${user.UserName.split("@")[0].trim().slice(0, maxLength)}...`
        : user.UserName.split("@")[0].trim();
  }

  const [showCollapse, setShowCollapse] = useState(false);
  const toggleCollapse = () => {
    setShowCollapse(!showCollapse);
  };
  const handlePeopleClick = () => {
    if (!islogin) {
      toast.info("Please log in to access the People section!");
    } else {
      navigate("/people-in-mycommunity");
    }
    setShowCollapse(!showCollapse);
  };
  const handleItemsClick = () => {
    if (!islogin) {
      toast.info("Please log in to access the items section!");
    } else {
      navigate("/items-for-sale");
    }
    setShowCollapse(!showCollapse);
  };

  document.addEventListener("click", function (event) {
    var isClickInside = document
      .querySelector(".navbar")
      .contains(event.target);
    if (!isClickInside) {
      var navbarToggler = document.querySelector(".navbar-toggler");
      var navbarCollapse = document.querySelector(".navbar-collapse");
      if (navbarCollapse.classList.contains("show")) {
        navbarToggler.click();
      }
    }
  });
  const handleClick = () => {
    islogin ? navigate("/dashboardpage/inbox") : navigate("/login");
  };

  return (
    <>
      <header className="header-top-strip py-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-9">
              <div className="logo pt-3">
                <Link
                  to={
                    isBrowsePage || isVendorDetailsPage || isInvoicePaymentPage
                      ? "/majesticHomePage"
                      : isMajesticHomePage
                      ? "/weddingvendors"
                      : "/home"
                  }
                >
                  {/* {isHomePage || isBrowsePage ? ( */}
                  {isBrowsePage ||
                  isMajesticHomePage ||
                  isVendorDetailsPage ||
                  isInvoicePaymentPage ? (
                    <img
                      className="d-lg-block wc-logo2"
                      src={Magisticlogo}
                      alt="logo"
                    />
                  ) : (
                    <img className="d-lg-block wc-logo" src={Sikh} alt="logo" />
                    // <img className="d-lg-block wc-logo" src={logo} alt="logo" />
                  )}
                </Link>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-8 col-3 px-0"
              style={{ overflow: "visible" }}
            >
              <div className="text-end text-end">
                <div className="my__account userst">
                  <nav className="navbar navbar-expand-lg navbar-light bg-white justify-content-end">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={toggleCollapse}
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className={`collapse navbar-collapse ${
                        showCollapse ? "show" : ""
                      }`}
                    >
                      <ul className="navbar-nav ms-auto align-items-center gap-md-25 toggle-nav-bar mb-nav">
                        {!isHomePage && !isLCA && (
                          <Link
                            className="text-dark lead"
                            to={"/weddingvendors"}
                            style={{
                              fontSize: "15px",
                              fontFamily: "sans-serif",
                              // textDecoration: "none",
                            }}
                            onClick={toggleCollapse}
                          >
                            Vendors
                          </Link>
                        )}
                        <Link
                          className="text-dark lead"
                          to={"/Localcommunityads"}
                          onClick={toggleCollapse}
                          style={{
                            fontSize: "15px",
                            fontFamily: "sans-serif", // textDecoration: "none",
                          }}
                        >
                          Ads
                        </Link>
                        <Link
                          className="text-dark lead"
                          // to={"/people-in-mycommunity"}
                          to={"/people-in-mycommunity"}
                          onClick={handlePeopleClick}
                          style={{
                            fontSize: "15px",
                            fontFamily: "sans-serif", // textDecoration: "none",
                          }}
                        >
                          People
                        </Link>
                        <Link
                          className="text-dark lead"
                          to={"/items-for-sale"}
                          onClick={handleItemsClick}
                          style={{
                            fontSize: "15px",
                            fontFamily: "sans-serif", // textDecoration: "none",
                          }}
                        >
                          Items
                        </Link>

                        <div className="dropdown">
                          <div>
                            <Button
                              id="fade-button2"
                              aria-controls={
                                openCall ? "fade-menu2" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openCall ? "true" : undefined}
                              className="username p-1"
                              onClick={handleClickCall}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#b3e8b3",
                                border: "1px solid green",
                                borderRadius: "40px",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <FaPhoneAlt
                                  className="rounded-circle"
                                  style={{
                                    backgroundColor: "#5ccc5c",
                                    fontSize: "large",
                                  }}
                                />
                                <span className="hide-on-1024"> Call Us</span>
                              </div>
                            </Button>
                          </div>
                        </div>
                        <div onClick={handleClick}>
                          <div
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            <StyledBadge
                              badgeContent={unreadMsg ? unreadMsg : 0}
                              color="secondary"
                              showZero
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <FaInbox size={25} />
                            </StyledBadge>
                          </div>
                        </div>
                        <MyCart />
                        {islogin ? (
                          <div
                            className="d-flex align-items-center  text-white"
                            to={"/login"}
                          >
                            <div className="userimgdiv">
                              <span className="userimg">
                                <img
                                  src={
                                    userDetails?.Photo
                                      ? userDetails?.Photo
                                      : "https://st4.depositphotos.com/6672868/20448/v/600/depositphotos_204485038-stock-illustration-user-vector-icon-simple-illustration.jpg"
                                  }
                                  alt={userDetails?.UserName?.split("@")[0].trim()}
                                  style={{
                                    borderRadius: "50%",
                                    height: "30px",
                                    width: "35px",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>

                            <div className="dropdown">
                              <div>
                                <Button
                                  id="fade-button3"
                                  aria-controls={
                                    open22 ? "fade-menu3" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open22 ? "true" : undefined}
                                  className="username"
                                  onClick={handleClick22}
                                >
                                  <div className="d-flex align-items-center">
                                    <strong>
                                      {user && truncatedUsername.toLowerCase()}
                                    </strong>
                                    <span className="userdropicon">
                                      <AiFillCaretDown />
                                    </span>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center gap-10">
                            <Link
                              to={"/login"}
                              onClick={() => {
                                setOpen(1);
                              }}
                            >
                              <p className="mb-0"> Login </p>
                            </Link>{" "}
                            |
                            <Link to="/Signup">
                              <p className="mb-0"> Sign Up </p>
                            </Link>
                          </div>
                        )}
                        <Menu
                          id="fade-menu3"
                          anchorEl={anchorEl22}
                          open={open22}
                          onClose={handleClose22}
                          TransitionComponent={Fade}
                          disableScrollLock={true}
                        >
                          <Link
                            to={"/dashboardpage"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem onClick={handleClose22}>
                              Dashboard
                            </MenuItem>
                          </Link>
                          <br />
                          <Link
                            to={"/dashboardpage/settings"}
                            style={{ textDecoration: "none" }}
                          >
                            <MenuItem onClick={handleClose22}>Profile</MenuItem>
                          </Link>
                          <MenuItem onClick={handleDonate}>
                            Donate to Gurdwara
                          </MenuItem>
                          <MenuItem onClick={userLogout}>Logout</MenuItem>
                        </Menu>
                        <DonateModal
                          modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen}
                          onClose={() => {
                            setModalClosed(true); // Track that the modal has been closed
                            setModalIsOpen(false); // Close the modal
                          }}
                          handleFormSubmit={true}
                        />
                        <Menu
                          id="fade-menu2"
                          anchorEl={anchorCall}
                          open={openCall}
                          onClose={handleCloseCall}
                          TransitionComponent={Fade}
                        >
                          <Link
                            className="text-dark"
                            style={{
                              fontFamily: "sans-serif", // textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                toggleCollapse();
                                window.location.href = "tel:+18002453610";
                                handleCloseCall();
                              }}
                            >
                              <FaPhoneAlt
                                className="rounded-circle"
                                style={{
                                  // margin: "7px",
                                  padding: "2px",
                                  // backgroundColor: "green",
                                  fontSize: "large",
                                }}
                              />{" "}
                              <small>Call (800)245-3610</small>
                            </MenuItem>
                          </Link>
                        </Menu>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ThankYouModal
        show={thankYou}
        onHide={() => setThankYou(false)}
        state={{ donation: true }}
      />
    </>
  );
};

export default Header;
