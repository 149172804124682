import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";

const AllFollowUpTasks = ({ open, handleClose, followUpTasks, fetchTasks }) => {
  // const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const handleUpdateTask = async (e, task, status) => {
    e.preventDefault();
    const req = {
      Id: task?.id,
      updatedBy: user?.UserId,
      followupStatus: status,
    };
    try {
      const response = await axiosClient.post(
        `message/updateFollowUpTask`,
        req
      );
      if (response.data.statusCode === 200) {
        fetchTasks();
        toast.success("Task updated successfully");
      } else {
        toast.error("Failed to update task");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-template-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="create-template-dialog-title">
        All Follow-Up Tasks
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {followUpTasks && followUpTasks.length === 0 && (
              <p>No follow-up tasks.</p>
            )}
            {followUpTasks && followUpTasks.length > 0 && (
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={styles.headerCell}>#</th>
                    <th style={styles.headerCell}>Task Title</th>
                    <th style={styles.headerCell}>Task Date</th>
                    <th style={styles.headerCell}>Reminder Date</th>
                    <th style={styles.headerCell}>Task Detail</th>
                    <th style={styles.headerCell}>Task Status</th>
                    <th style={styles.headerCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {followUpTasks?.map((data, index) => (
                    <tr key={data.id}>
                      <td style={styles.bodyCell}>{index + 1}</td>
                      <td style={styles.bodyCell}>{data.Title}</td>
                      <td style={styles.bodyCell}>{data.taskDate}</td>
                      <td style={styles.bodyCell}>{data.reminderDate}</td>
                      <td style={styles.bodyCell}>{data.taskDetail}</td>
                      <td
                        style={{
                          ...styles.bodyCell, // Spread the existing styles properly
                          color:
                            data.followupStatus === "COMPLETED"
                              ? "green"
                              : "red",
                        }}
                      >
                        {data.followupStatus.toUpperCase()}
                      </td>

                      <td style={styles.bodyCell}>
                        {data.followupStatus == "COMPLETED" ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              handleUpdateTask(e, data, "DELETE");
                            }}
                          >
                            Delete
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              handleUpdateTask(e, data, "COMPLETED");
                            }}
                          >
                            Completed
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  headerCell: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
  },
  bodyCell: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
  },
};

export default AllFollowUpTasks;
