import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";

const BwvServiceInfo = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [showAllDetails, setShowAllDetails] = useState(false); // Toggle state
  console.log(selectedCard.customDetails, "selectedCard");

  // Filter fields where is_visible is true
  const filteredDetails = Object.entries(selectedCard?.customDetails || {})
    .filter(([key, value]) => value?.[0]?.is_visible) // Only keep fields where is_visible: true
    .map(([key, value]) => ({ key, values: value })); // Restructure data for easier rendering

  // Determine the number of options to show initially
  const visibleOptions = showAllDetails
    ? filteredDetails
    : filteredDetails.slice(0, 4);

  return (
    <>
      <h6 style={{ fontWeight: "bold" }} className="text-center pb-3">
        Business Details
      </h6>
      {filteredDetails.length === 0 && (
        <p className="text-center">No Details Available</p>
      )}
      {visibleOptions.map(({ key, values }, index) => (
        <Col sm={6} className="d-flex pb-3" key={index}>
          <div>
            <p
              style={{ fontSize: "large", fontWeight: "bold", color: "black" }}
            >
              {key}
            </p>
            <div>
              {values.length > 0 ? (
                values.map((item, idx) => (
                  <Tooltip title={item.option_name} key={idx}>
                    <button
                      key={idx}
                      type="button"
                      className="btn btn-outline-secondary small-button"
                    >
                      {item.option_name}
                    </button>
                  </Tooltip>
                ))
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
        </Col>
      ))}
      {filteredDetails.length > 4 && (
        <div className="text-center mt-1">
          <button
            className="btn btn-link"
            style={{ backgroundColor: "#e6e6e6" }}
            onClick={() => setShowAllDetails(!showAllDetails)}
          >
            {showAllDetails ? "View Less Details ↑" : "View More Details ↓"}
          </button>
        </div>
      )}
    </>
  );
};

export default BwvServiceInfo;
