import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
} from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaPlusCircle, FaCheckCircle } from "react-icons/fa";
import {
  fetchCategories,
  postSponsordAd,
  updateSponsordAd,
} from "../../../components/Header/Data";
import "../DashboardPages.css";
import PayPalModal from "../PayPalModal";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import uploadToS3 from "../../../utils/awsS3Upload";
import Select from "react-select";

const AddNewAds = () => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const location = useLocation();
  const [price, setPrice] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adDetails, setAdDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [sponsordAd, setSponsordAd] = useState({
    Header: "",
    description: "",
    Photo: "",
    startAt: "",
    endAt: "",
    Url: "",
    Status: 0,
    createdBy: user?.UserID || "",
    Email: user?.Email || "",
    categoryID: "",
    Location: "",
    Radius: 0,
  });
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    if (location.state) {
      const {
        startAt,
        endAt,
        Photo,
        id,
        Url,
        Header,
        description,
        categoryID,
        Location,
        Radius,
      } = location.state;
      setSponsordAd({
        startAt: startAt.substring(0, 10),
        endAt: endAt.substring(0, 10),
        Photo: Photo,
        id: id,
        Status: 0,
        Url: Url,
        Header: Header,
        description: description,
        categoryID: categoryID || "",
        Location: Location || "",
        Radius: Radius || 0,
      });
      setImage(Photo);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchCat = async () => {
      const categoriesData = await fetchCategories();
      setCategories(categoriesData);
    };
    fetchCat();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const folder = "sponcerAd/";
      const imageUrl = await uploadToS3(file, folder);
      setImage(imageUrl);
      setSponsordAd((prev) => ({ ...prev, Photo: imageUrl }));
    }
  };

  const scrollToForm = () => {
    window.scrollTo(0, 0);
    if (formRef.current) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const validateForm = () => {
    const today = new Date().toISOString().split("T")[0];

    if (!sponsordAd.Header.trim()) {
      scrollToForm();
      return "Please enter the ad header";
    }
    if (sponsordAd.Header.length > 100) {
      scrollToForm();
      return "Header must not exceed 100 characters";
    }
    if (!sponsordAd.description.trim()) {
      scrollToForm();
      return "Please enter the ad description";
    }
    if (sponsordAd.description.length > 500) {
      scrollToForm();
      return "Description must not exceed 500 characters";
    }
    if (!sponsordAd.Url.trim()) {
      scrollToForm();
      return "Please enter a valid URL";
    }
    if (!/^(https?:\/\/)/i.test(sponsordAd.Url)) {
      scrollToForm();
      return "URL must start with http:// or https://";
    }
    if (!sponsordAd.categoryID) {
      scrollToForm();
      return "Please select a category";
    }
    if (!sponsordAd.Photo) {
      scrollToForm();
      return "Please upload an ad image";
    }
    if (!sponsordAd.startAt) {
      scrollToForm();
      return "Please select a start date";
    }
    if (!sponsordAd.endAt) {
      scrollToForm();
      return "Please select an end date";
    }
    if (sponsordAd.startAt < today) {
      scrollToForm();
      return "Start date must be today or later";
    }
    if (sponsordAd.endAt < today) {
      scrollToForm();
      return "End date must be today or later";
    }
    if (sponsordAd.startAt >= sponsordAd.endAt) {
      scrollToForm();
      return "End date must be after start date";
    }

    if (sponsordAd.Location && !/^\d{5,6}$/.test(sponsordAd.Location)) {
      scrollToForm();
      return "Zipcode must be 5-6 digits";
    }
    return "";
  };

  const handleRequest = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const errorMessage = validateForm();
    if (errorMessage) {
      setValidationError(errorMessage);
      return; // scrollToForm is already called in validateForm
    }
    setValidationError("");
    setIsLoading(true);

    try {
      if (location.state) {
        sponsordAd.updatedBy = user.UserID;
        await updateSponsordAd(sponsordAd, sponsordAd.id);
        setValidationError("Ad Updated Successfully");
        navigate("/dashboardpage/sponsored-ads");
        window.scroll(0, 0);
      } else {
        const response = await postSponsordAd(sponsordAd);
        setPaymentModal(true);
        setAdDetails(response.data.Data);
        setValidationError("Ad Sent To Admin For Approval");
        navigate("/dashboardpage/sponsored-ads");
        window.scroll(0, 0);
      }
    } catch (error) {
      setValidationError("An error occurred while processing your request");
      scrollToForm();
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "Location") {
      if (isNaN(value) || value.length > 6) return;
    }

    setSponsordAd((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const calculatePrice = () => {
      if (sponsordAd.startAt && sponsordAd.endAt) {
        const startDate = new Date(sponsordAd.startAt);
        const endDate = new Date(sponsordAd.endAt);
        if (endDate < startDate) {
          setPrice(0);
          return;
        }
        const durationInMilliseconds = endDate - startDate;
        const durationInMonths =
          durationInMilliseconds / (1000 * 60 * 60 * 24 * 30);
        const price = Math.ceil(durationInMonths) * 1;
        setPrice(price);
      }
    };
    calculatePrice();
  }, [sponsordAd.startAt, sponsordAd.endAt]);

  const formattedCategories = categories.map((cat) => ({
    value: cat.Id,
    label: cat.Name,
  }));

  return (
    <section className="home-after-login bg-white">
      <div className="container" style={{ position: "relative" }}>
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="mt-3">
            <Row className="m-3 w-100 my-5" style={{ marginBottom: "20%" }}>
              <p
                onClick={() => navigate(-1)}
                style={{
                  cursor: "pointer",
                  padding: "0px",
                  fontSize: "18px",
                  color: "#000",
                  zIndex: 999,
                  fontWeight: "bold",
                }}
                className="mb-2"
              >
                <FaArrowLeft
                  style={{
                    marginRight: "6px",
                    fontSize: "18px",
                    marginTop: "-3px",
                  }}
                />
                Back
              </p>
              <Row className="form-container p-4 mb-5" ref={formRef}>
                <Form>
                  {validationError && (
                    <Alert
                      color={
                        validationError.includes("Successfully")
                          ? "success"
                          : "danger"
                      }
                      className="mb-3"
                    >
                      {validationError}
                    </Alert>
                  )}
                  <h4 className="mb-2">Ad Information</h4>
                  <FormGroup>
                    <Label>Type Ad Header (Service you Offer OR Seek)</Label>
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      type="text"
                      name="Header"
                      value={sponsordAd.Header}
                      onChange={handleInputChange}
                      maxLength={100}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Description</Label>
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      type="textarea"
                      name="description"
                      value={sponsordAd.description}
                      onChange={handleInputChange}
                      maxLength={500}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Link</Label>
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      placeholder="http://www.example.com"
                      type="url"
                      name="Url"
                      value={sponsordAd.Url}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <Row>
                    <Col lg="6" md="12">
                      <FormGroup>
                        <Label>Category</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          options={formattedCategories}
                          value={formattedCategories.find(
                            (category) =>
                              category.value === sponsordAd.categoryID
                          )}
                          onChange={(selectedOption) =>
                            setSponsordAd((prev) => ({
                              ...prev,
                              categoryID: selectedOption.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="12">
                      <FormGroup>
                        <Label>Zipcode</Label>
                        <Input
                          type="text"
                          name="Location"
                          value={sponsordAd.Location}
                          onChange={handleInputChange}
                          maxLength={6}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="2" md="12">
                      <FormGroup>
                        <Label>Radius</Label>
                        <Input
                          type="select"
                          name="Radius"
                          value={sponsordAd.Radius}
                          onChange={handleInputChange}
                        >
                          <option value="0">0</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="60">60</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="90">90</option>
                          <option value="100">100</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ position: "relative" }}>
                    <Col lg="4" md="12">
                      <FormGroup>
                        <Label for="imageUpload" className="m-0">
                          Ad Image<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="imageUpload"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          name="images"
                          accept="image/*"
                        />
                      </FormGroup>
                      <div
                        className="ad-image-box"
                        onClick={() =>
                          document.getElementById("imageUpload").click()
                        }
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {image ? (
                          <img
                            src={image}
                            alt="ad-Uploaded Image"
                            className="uploaded-image image-fluid rounded"
                          />
                        ) : (
                          <FaPlusCircle className="upload-icon" />
                        )}
                      </div>
                    </Col>
                    <Col lg="8" md="12">
                      <Row>
                        <Col lg="6" md="12">
                          <FormGroup>
                            <Label>Start Date</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              type="date"
                              name="startAt"
                              value={sponsordAd.startAt || ""}
                              onChange={handleInputChange}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" md="12">
                          <FormGroup>
                            <Label>End Date</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              type="date"
                              name="endAt"
                              value={sponsordAd.endAt || ""}
                              onChange={handleInputChange}
                              min={
                                sponsordAd.startAt ||
                                new Date().toISOString().split("T")[0]
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <p className="m-2 text-center">
                          A <b className="text-primary">$ {price}</b> bill will
                          be added to your Cart
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row className="form-container">
                <h4>Preview</h4>
                <div className="preview-ads row">
                  <Col
                    style={{
                      borderRight: "1px solid gray",
                      textAlign: "center",
                    }}
                    lg={4}
                  >
                    <img
                      src={sponsordAd?.Photo || PlaceHolder}
                      style={{ width: "235px", height: "135px" }}
                      className="img-fluid rounded"
                    />
                  </Col>
                  <Col style={{ backgroundColor: "#F5F7FA" }} lg={8}>
                    <div>
                      <span>
                        Sponsored by {user?.UserName.split("@")[0].trim()}
                      </span>
                      <h5>
                        <b>{sponsordAd?.Header}</b>
                      </h5>
                    </div>
                  </Col>
                </div>
              </Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <Button
                  type="submit"
                  className="btn btn-danger"
                  onClick={handleRequest}
                  disabled={isLoading}
                >
                  <FaCheckCircle />{" "}
                  {location.state ? "Update Ad" : "Request Admin Approval"}
                </Button>
                <PayPalModal
                  isOpen={paymentModal}
                  closeModal={() => setPaymentModal(false)}
                  price={price}
                  adDetails={adDetails}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AddNewAds;
