import React, { useEffect, useState } from "react";
import { getGurudwaraNewsList } from "../../../components/Header/Data3";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Editor, EditorProvider } from "react-simple-wysiwyg";
import { FadeLoader } from "react-spinners";

const GurdwaraNewsDetail = () => {
  const [newsDetails, setNewsDetails] = useState([]);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { Id: apiId } = useParams();
  const [loading, setLoading] = useState(false);

  const fetchGurudwaraNewsList = async () => {
    setLoading(true);
    const formData = {
      Id: parseInt(apiId),
    };
    const newsList = await getGurudwaraNewsList(formData);
    setNewsDetails(newsList);
    console.log(newsList, "newsList");
    setLoading(false);
  };
  console.log(apiId, "apiId");
  useEffect(() => {
    if (apiId) {
      fetchGurudwaraNewsList();
    }
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "200px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }
  return (
    <div className="text-center">
      {newsDetails.map((news, index) => (
        <div key={index}>
          <h2 className="my-3 w-75 m-auto">{news.Title}</h2>
          <img
            src={news.Image}
            alt={news.Title}
            loading="lazy"
            className="my-3"
            style={{ width: "400px", height: "300px" }}
          />
          <div className="w-75 m-auto my-3">
            <b className="mx-5">Posted By: {news.Author}</b>
            <b className="mx-5">
              Edited on :{" "}
              {moment(news.createdAt).format("DD MMM YYYY, hh:mm A")}
            </b>
          </div>
          <div className="my-3 w-75 m-auto">
            <EditorProvider>
              <Editor
                value={news.Description}
                readOnly={true}
                toolbar={false}
                toolbarHidden={true}
              />
            </EditorProvider>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GurdwaraNewsDetail;
