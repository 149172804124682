import React, { useState, useEffect, useCallback } from "react";
import MessageList from "./MessagesList";
import ChatWindow from "./ChatWindow";
import { useDispatch, useSelector } from "react-redux";
import { getAllMessages } from "../../../components/Header/Data";
import { batch } from "react-redux";
import {
  setConversations,
  setFinalConvos,
  setLoadingNotif,
  setLodingConvo,
  setMessages,
  setMyConnections,
  setMyJobOffers,
  setNewMessage,
  setOppertunity,
  setOrder,
  setOrderStatus,
  setSelectedConversation,
  setTemps,
} from "../../../redux/slices/MsgSlice";
import { FadeLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAllTemplates,
  GetConnections,
  GetItemOrderById,
  GetJobOffers,
} from "../../../components/Header/Data3";
import { getConvoById, MessageSeen } from "../../../components/Header/Data2";
import { useSocket } from "../../../contexts/SocketContext";
import { filterConversations } from "./Filters";
import debounce from "lodash/debounce"; // Install lodash if not already present

const InboxPage2 = () => {
  const { sendNewMessage, socket } = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isMobileView, setIsMobileView] = useState(false);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = user && user.Role === "admin";
  const {
    oppertunity,
    convos,
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    unreadSearch,
    guestCount,
    showFollowUp,
    oppertunityFilter,
  } = useSelector((state) => state.messages);
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const logUser = localStorage.getItem("USER_ROLE");

  const fetchData = async () => {
    setIsLoading(true);
    dispatch(setLoadingNotif(true));
    const connectionResponse = await GetConnections(user.UserID);
    const jobResponse = await GetJobOffers(user.UserID);
    batch(() => {
      dispatch(setMyConnections(connectionResponse));
      dispatch(setMyJobOffers(jobResponse));
      dispatch(setLoadingNotif(false));
    });
    await fetchAllMsg();
    setIsLoading(false);
  };
console.log(selectedConversation, "selectedConversation");
  const fetchAllMsg = useCallback(
    debounce(async () => {
      console.log("fetchAllMsg called");
      const msgForm =
        user.RoleId === 1
          ? { roleId: 1 }
          : { customerId: user.UserID, createdBy: user.UserID };
      const messagesData = await getAllMessages(msgForm);
      const filteredMessages = messagesData.filter(
        (msg) => msg.parentId === 0 && msg.messageStatus !== 0
      );
      dispatch(setConversations(filteredMessages));
      const filters = {
        searchTerm,
        meetingDateSearch,
        eventMonthsearch,
        unreadSearch,
        guestCount,
        showFollowUp,
        oppertunityFilter,
      };
      const filteredConvos = filterConversations(
        filteredMessages,
        filters,
        user
      );
      console.log("fetchAllMsg filteredConvos:", filteredConvos);
      dispatch(setFinalConvos(filteredConvos));
      setIsLoading(false);
      window.scroll(0, 0);
    }, 300), // Debounce for 300ms
    [
      user,
      searchTerm,
      meetingDateSearch,
      eventMonthsearch,
      unreadSearch,
      guestCount,
      showFollowUp,
      oppertunityFilter,
      dispatch,
    ]
  );

  useEffect(() => {
    if (convos.length > 0) {
      const sortConv = [...convos].sort((a, b) => {
        const dateA = a.updatedAt || a.createdAt;
        const dateB = b.updatedAt || b.createdAt;
        return new Date(dateB) - new Date(dateA);
      });

      if (!selectedConversation) {
        dispatch(setSelectedConversation(sortConv[0]));
      } else {
        const updatedSelected = convos.find(
          (conv) => conv.Id === selectedConversation.Id
        );
        if (updatedSelected) {
          dispatch(setSelectedConversation(updatedSelected));
        }
      }
    }
  }, [convos, selectedConversation, dispatch]);

  useEffect(() => {
    if (selectedConversation) {
      dispatch(
        setOppertunity(
          selectedConversation?.latestType === null
            ? 0
            : selectedConversation?.latestType
        )
      );
    }
  }, [selectedConversation, dispatch]);

  const fetchMsgs = async () => {
    dispatch(setLodingConvo(true));
    const messagesData = await getConvoById(selectedConversation?.Id);
    const filterNonScheduled = messagesData.filter(
      (msg) =>
        msg.createdBy === user.UserID ||
        isAdmin ||
        (msg.messageStatus !== 0 && msg.messageStatus !== 14)
    );
    dispatch(setMessages(filterNonScheduled));
    dispatch(setOrder(null));
    dispatch(setOrderStatus(""));
    dispatch(setLodingConvo(false));
  };

  const fetchTemplates = async () => {
    const response = await GetAllTemplates();
    const active = response.filter((temp) => temp.template_status == 1);
    dispatch(setTemps(active));
  };

  useEffect(() => {
    fetchTemplates();
    fetchData();
  }, [user?.UserID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
      if (window.innerWidth > 767) setShowChatWindow(false);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const read = async (request, message) => {
    await MessageSeen(message.parentId, request);
  };

  const handleReceivedMessage = useCallback(
    (message) => {
      fetchAllMsg();
      if (selectedConversation?.Id === message.parentId) {
        let request =
          message.createdBy === user.UserID
            ? { createdBy: user.UserID }
            : { customerId: user.UserID };
        read(request, message);
      }
    },
    [selectedConversation, user?.UserID, fetchAllMsg]
  );

  useEffect(() => {
    socket.on("receive-message", handleReceivedMessage);
    return () => socket.off("receive-message", handleReceivedMessage);
  }, [socket, handleReceivedMessage]);

  const handleSelectConversation = async (conversationId, msg) => {
    const isUserInvolved =
      msg?.createdBy === user.UserID || msg?.customerId === user.UserID;
    if (isUserInvolved) {
      const messagesData = await getConvoById(conversationId);
      const latest = messagesData?.[0];
      const request =
        latest?.customerId === user.UserID
          ? { customerId: user.UserID }
          : { createdBy: user.UserID };
      await MessageSeen(conversationId, request);
    }
    if (selectedConversation?.Id !== conversationId) {
      dispatch(setMessages(null));
    }
    const conversation = convos.find((conv) => conv.Id === conversationId);
    dispatch(setSelectedConversation(conversation));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("chat", conversationId);
    navigate(
      { pathname: location.pathname, search: searchParams.toString() },
      { replace: true }
    );
    if (isMobileView) setShowChatWindow(true);
    if (selectedConversation?.Id !== conversationId) {
      await fetchAllMsg(); // Debounced call
    }
    dispatch(setOppertunity(msg?.latestType ?? 0));
  };

  const handleBackToList = () => setShowChatWindow(false);

  return (
    <div
      className="message-container inter-font"
      style={{ height: logUser === "provider" ? "95vh" : "90vh" }}
    >
      {isLoading ? (
        <FadeLoader
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute top-50 start-50 translate-middle"
        />
      ) : (
        <>
          {!isMobileView || !showChatWindow ? (
            <MessageList onSelectConversation={handleSelectConversation} />
          ) : null}
          {selectedConversation && (!isMobileView || showChatWindow) && (
            <ChatWindow
              conversation={selectedConversation}
              onBack={handleBackToList}
              isMobileView={isMobileView}
              fetchData={fetchData}
              fetchAllMsg={fetchAllMsg}
              fetchMsgs={fetchMsgs}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InboxPage2;
