import React, { useState } from "react";
import {
  FaEnvelope,
  FaFileAlt,
  FaRegComment,
  FaRegEnvelope,
  FaRegStar,
  FaChevronRight,
  FaChevronDown,
  FaSearch,
  FaTimes,
  FaSearchPlus,
  FaThumbtack,
} from "react-icons/fa";
import FilterIcon from "./sliders.svg";
import { Menu, MenuItem, Collapse, Select, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setEventDateSearch,
  setEventMonthsearch,
  setGuestCount,
  setMeetingDateSearch,
  setOppertunityFilter,
  setSearchTerm,
  setShowFollowUp,
  setUnreadSearch,
} from "../../../redux/slices/MsgSlice";
import { Input } from "reactstrap";

const SearchFilter = ({ clearSearch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null); // Track expanded menu
  const {
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    guestCount,
    oppertunityFilter,
  } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpandedMenu(null); // Close any expanded submenus when the main menu closes
  };
  const clearfilterOptions = () => {
    dispatch(setMeetingDateSearch(""));
    dispatch(setEventDateSearch(""));
    dispatch(setEventMonthsearch(""));
    dispatch(setGuestCount(""));
    dispatch(setOppertunityFilter(null));
    dispatch(setSearchTerm(""));
    dispatch(setUnreadSearch(false));
    dispatch(setShowFollowUp(false));
    handleClose();
  };
  const toggleSubMenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu); // Toggle the submenu
  };
  const handleMeetingDateChange = (event) => {
    dispatch(setMeetingDateSearch(event.target.value));
    // handleClose();
  };

  const handleCount = (event) => {
    dispatch(setGuestCount(event.target.value));
  };
  const handleFollowUp = () => {
    dispatch(setShowFollowUp(true));
  };
  const handleMonthChange = (newMonth) => {
    dispatch(setEventMonthsearch(newMonth));
  };

  const renderSearchBar = () => (
    <>
      <FaSearch className="search-icon" />
      <input
        type="text"
        placeholder="Search by Phone Number, Name, Email"
        value={searchTerm}
        onChange={(e) => dispatch(setSearchTerm(e.target.value))}
        className="search-input"
      />
      {searchTerm && <FaTimes className="clear-icon" onClick={clearSearch} />}
      <img
        src={FilterIcon}
        alt="Filter Icon"
        className="filter-icon ms-1"
        onClick={handleClick}
      />
    </>
  );
  const handleChange = (event) => {
    dispatch(setOppertunityFilter(event.target.value));
    handleClose();
  };
  const handleUnread = () => {
    dispatch(setUnreadSearch(true));
    handleClose();
  };
  return (
    <div className="search-bar-container">
      {renderSearchBar()}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "200px", // Adjust width as needed
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem
          onClick={clearfilterOptions}
          className="wc-msg-filter-menu-item bg-danger text-white"
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Clear Filters</span>
        </MenuItem>
        <MenuItem
          onClick={handleUnread}
          className="wc-msg-filter-menu-item"
          // disabled
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Unread</span>
          <FaRegEnvelope />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className="wc-msg-filter-menu-item"
          disabled
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Favorites</span>
          <FaRegStar />
        </MenuItem>
        <MenuItem
          onClick={() => toggleSubMenu("opportunity")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "opportunity" ? "selectedMenu" : ""
          }`}
        >
          {expandedMenu === "opportunity" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Opportunity Stage</span>
        </MenuItem>
        <Collapse in={expandedMenu === "opportunity"}>
          <MenuItem className="wc-msg-filter-menu-item">
            <FormControl sx={{ minWidth: 135 }}>
              <Select
                value={oppertunityFilter ?? 0} // If null, default to 0
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  fontSize: "x-small",
                  backgroundColor: "lightgray",
                }}
              >
                <MenuItem value={0}>
                  <span>Opportunity Stage</span>
                </MenuItem>
                <MenuItem value={1}>Hot Lead ❤️‍🔥</MenuItem>
                <MenuItem value={2}>Cold Lead 🥶</MenuItem>
                <MenuItem value={3}>Invoice Sent</MenuItem>
                <MenuItem value={4}>Booked</MenuItem>
                <MenuItem value={5}>Event Completed</MenuItem>
                <MenuItem value={6}>Meeting Scheduled</MenuItem>
                <MenuItem value={7}>Not Interested</MenuItem>
                <MenuItem value={8}>Booked Elsewhere</MenuItem>
                <MenuItem value={9}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>
        </Collapse>
        <MenuItem
          onClick={() => toggleSubMenu("meetingDate")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "meetingDate" ? "selectedMenu" : ""
          }`}
        >
          {expandedMenu === "meetingDate" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Search meeting Date</span>
        </MenuItem>
        <Collapse in={expandedMenu === "meetingDate"}>
          <MenuItem className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>
              <input
                type="date"
                placeholder="Date"
                className="meeting-date-input"
                value={meetingDateSearch}
                onChange={handleMeetingDateChange}
              />
            </span>
          </MenuItem>
        </Collapse>
        <MenuItem
          onClick={() => toggleSubMenu("eventDate")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "eventDate" ? "selectedMenu" : ""
          }`}
        >
          {expandedMenu === "eventDate" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Search event Date</span>
        </MenuItem>
        <Collapse in={expandedMenu === "eventDate"}>
          <MenuItem className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>
              <Input
                type="month"
                name="month"
                className="form-control-sm w-auto"
                value={eventMonthsearch} // Bind the value to state
                onChange={(e) => handleMonthChange(e.target.value)}
                placeholder="Select Month"
              />
            </span>
          </MenuItem>
        </Collapse>
        {/* guestCount Parent Menu */}
        <MenuItem
          onClick={() => toggleSubMenu("guestCount")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "guestCount" ? "selectedMenu" : ""
          }`}
        >
          {/* Chevron on the left */}
          {expandedMenu === "guestCount" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Guest Count</span>
          <FaFileAlt />
        </MenuItem>
        <Collapse in={expandedMenu === "guestCount"}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <span
              style={{
                position: "absolute",
                left: "75px",
                top: "45%",
                transform: "translateY(-50%)",
              }}
            >
              &lt;
            </span>
            <input
              type="number"
              placeholder="count"
              className="guest-count-input"
              value={guestCount}
              onChange={handleCount}
              style={{ paddingLeft: "20px" }} // Adjust padding to avoid overlap
            />
          </div>
        </Collapse>

        {/* Other Parent Menu */}
        <MenuItem
          onClick={handleFollowUp}
          className={`wc-msg-filter-menu-item `}
        >
          {/* Chevron on the left */}
          {expandedMenu === "followup" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Show Follow Up</span>
          <FaThumbtack />
        </MenuItem>
        <MenuItem
          onClick={() => toggleSubMenu("other")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "other" ? "selectedMenu" : ""
          }`}
          disabled
        >
          {/* Chevron on the left */}
          {expandedMenu === "other" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Other</span>
          <FaRegComment />
        </MenuItem>
        <Collapse in={expandedMenu === "other"}>
          <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>Other 1</span>
          </MenuItem>
          <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>Other 2</span>
          </MenuItem>
        </Collapse>
      </Menu>
    </div>
  );
};

export default SearchFilter;
